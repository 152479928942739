import { Injectable } from '@angular/core';
import {serviceTypes } from '../contentpage/serviceTypesData';
import { serviceTypeModel } from '../models/serviceTypeModel';

@Injectable({
  providedIn: 'root'
})
export class ServiceTypeService {
   
  constructor() { }
  getAllServices(){
    return serviceTypes;  
  }
}
