import { NgModule } from '@angular/core'; 
import { PreloadAllModules, RouterModule ,Routes} from '@angular/router'; 
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
//import { AuthenticationGuard } from 'microsoft-adal-angular6'; //Vaishnavi Commented on 20 Feb 2023
 import { HomeComponent} from '../app/home/home.component';
 import { DealersResolver } from './services/dealersResolver.service'
import { ServiceinfoComponent } from './serviceinfo/serviceinfo.component';
import { AlldealersMapviewComponent } from './alldealers-mapview/alldealers-mapview.component';
import { TestComponent } from './test/test.component';



const routes: Routes = [
    { path: '', component: HomeComponent, resolve: { dealers: DealersResolver}},   //route for home component     
    { path: 'home', component: HomeComponent, resolve: { dealers: DealersResolver} },  //route for home component 
    { path: 'dealer', component: HomeComponent, resolve: { dealers: DealersResolver}}, //route for getting particular dealer    
    // { path: 'admin', loadChildren:'./admin/admin.module#AdminModule',canActivate: [AuthenticationGuard]},//pathMatch:'full' ,canActivate: [AuthenticationGuard] }, //router for redirecting to admin
   { path: 'admin', loadChildren:'./admin/admin.module#AdminModule'},
   //{ path: 'admin', component: HomeComponent, resolve: { dealers: DealersResolver}},
   { path: 'service_info', component: ServiceinfoComponent}, //route for getting service info page    
   
   //added on 10-11-2022 by sandeep, comment for prod
//    { path: 'test', component: TestComponent, resolve: { dealers: DealersResolver}}, //route for getting testing page    
   
   { path: '**', component: PageNotFoundComponent } //when route is invalid

   
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }