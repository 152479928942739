export class mapPopup {
    uid: any;
	title: string;
    services: any[];
    phone: any;
    address: any;
    country: any;
    timings: any;
    status: string;

}