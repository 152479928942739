import {serviceTypeModel } from '../models/serviceTypeModel';

//static service type Data
export const serviceTypes: serviceTypeModel[] = [  

    /** modified by sandeep on Jun 14, 2023 */
    { id: 6, name: 'LCV'},
    { id: 2, name: 'Truck' },
    { id: 9, name: 'Truck_EMEA' },
    { id: 5, name: 'Trailer'},
    { id: 10, name: 'Trailer_EMEA' },
    { id: 4, name: 'Engineless'},
    { id: 3, name: 'APU' },  
    { id: 7, name: 'eCool'},
    { id: 8, name: '24/7'},
    { id: 1, name: 'Mobile Service' } ,
  
  ];