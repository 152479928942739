import {dealerAddressDetailModel } from '../models/dealerAddressDetailModel';
import {dealerTimingModel } from '../models/dealerTimingModel';
import {dealerServiceTypeModel} from '../models/dealerServiceTypeModel';

export class dealerModel{
    uid: number;
    name : string="" ;
    contactName:string="";
    dealerLatitude:string="";
    dealerLongitude="";
    dealerAddressDetailModel:dealerAddressDetailModel;    
    country:string= "";
    dealerTimingModel:dealerTimingModel[]=[];
    phone:string ="";
    mobile:string="";
    fax:string= "";
    webUrl:string= "";
    eMail:string= "";
    otherInformation:string= "";
    distance:any;
    dealerLevel:"";
    dealerOfYear:any;
    dealerServiceTypeModel: dealerServiceTypeModel[] =[];
    createdBy:any;
    modifiedBy:any;

    // added by sandeep on dec 12, 2022
    region: string;

    // added by sandeep on jun 16, 2023
    europeFields: any;
    
    createdDate: string;
    modifiedDate: string;
}

