import { Component, OnInit } from '@angular/core';
import { dealerModel } from '../models/dealerModel';
import { DealerService } from '../services/dealer.service';
import { marker } from '../models/markerModel';

import { dealerTimingModel } from '../models/dealerTimingModel';
import { mapPopup } from '../models/mapPopupModel';
import { DataServiceService } from '../services/data-service.service';
import { languagePhrasesModel } from '../models/languagePhrasesModel';
import { Subscription } from 'rxjs';
import { GeocodeService } from '../services/geocode.service';

@Component({
  selector: 'app-alldealers-mapview',
  templateUrl: './alldealers-mapview.component.html',
  styleUrls: ['./alldealers-mapview.component.css']
})
export class AlldealersMapviewComponent implements OnInit {

  // modified by sandeep on sep 27, 2023
  latitude: number = 33.490570;  //31.6570969; //51.673858;
  longitude: number = -39.107822; //-106.2398643; //7.815982;
  markers: marker[] = [];
  dealersList: dealerModel[];

  dealerTimingModel: dealerTimingModel;
  dealerTimingModelObj: dealerTimingModel;
  languagePhrases: languagePhrasesModel;
  subscriptionPhrases: Subscription;
  dealerData: mapPopup;
  previous: any;
  allTranslations: any;

    /** added by sandeep on Aug 18, 2023 */
    ranks: any = [
      {rank: 1, serviceType_uid : 6},
        {rank: 2, serviceType_uid : 2},
        {rank: 3, serviceType_uid : 9},
        {rank: 4, serviceType_uid : 5},
        {rank: 5, serviceType_uid : 10},
        {rank: 6, serviceType_uid : 4},
        {rank: 7, serviceType_uid : 3},
        {rank: 8, serviceType_uid : 7},
        {rank: 9, serviceType_uid : 8},
        {rank: 10, serviceType_uid : 1}
    ]

  constructor(private _dealerService: DealerService,
    private _dataService: DataServiceService,
    // added by sandeep on sep 27, 2023
    private _geocodeService: GeocodeService
    ) { }

  ngOnInit() {
    /** added by sandeep on Sep 29, 2023 */
    // let coordinates = this._geocodeService.getLocationCoordinates();
    // console.log(coordinates);
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position.coords);
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      // this.setCoordinates(position.coords.latitude, position.coords.longitude);
      // alert('Location accessed'+ position.coords.latitude)
    },function(){
        // alert('User not allowed')
    })


    /** added by sandeep on Jun 26, 2023 */
    // this._dealerService.getAllDealers().subscribe((data: any) => {
    this._dealerService.getAllDealersV2().subscribe((data: any) => {
      this.dealersList = data.dealers;
      // console.log(this.dealersList);
      this.addToMarkers();

    }); 

    this.dealerData = new mapPopup;
    this.getPhrases();
    this.getAllTranslations();
    // console.log('d')
    
   }

  addToMarkers() {
    // console.log('addtomarkers');
    var temp: marker = {latitude: '51.373858', longitude: '7.215982', index: 0};
    // var temp2: any = {latitude: '51.673858', longitude: 7.815982};
    this.dealersList.forEach((element, index) => {
      this.markers.push({latitude: element.dealerLatitude, longitude: element.dealerLongitude, index: index});
      // this.markers.push(temp2);
    
    });
    // console.log(this.markers);
  }

  clickedMarker(info: any, infowindow: any) {
    // console.log(infowindow);
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;

    var dealer = this.dealersList[info.index];
    this.dealerData = new mapPopup;
    this.dealerData.uid = dealer.uid;
    this.dealerData.title = dealer.name;
    this.dealerData.services = dealer.dealerServiceTypeModel;

    if(this.dealerData.services != null) {
      this.dealerData.services.sort((a, b) => {
        return this.ranks.find(_ => _.serviceType_uid === a.serviceType_uid).rank - this.ranks.find(_ => _.serviceType_uid === b.serviceType_uid).rank
      })
    }

    this.dealerData.phone = dealer.phone;
    this.dealerData.address = dealer.dealerAddressDetailModel;
    this.dealerData.country = dealer.country;

    this.dealerData.timings = "";
    if (dealer.dealerTimingModel) {

        this.dealerTimingModel = new dealerTimingModel;
        this.dealerTimingModelObj = new dealerTimingModel;
        this.dealerTimingModel = JSON.parse(JSON.stringify(dealer.dealerTimingModel));
        var resp = this._dataService.dealerTiming(this.dealerTimingModel);

        resp.forEach(element => {
          var re = /Monday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Monday);
          re = /Tuesday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Tuesday);
          re = /Wednesday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Wednesday);
          re = /Thursday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Thursday);
          re = /Friday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Friday);
          re = /Saturday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Saturday);
          re = /Sunday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Sunday);
          re = /Closed/gi;
          element.startTime = element.startTime.replace(re, this.languagePhrases.Closed);
        });
        this.dealerData.timings = resp;
    }

    this.dealerData.status = "Unknown";
      this._dealerService.getDealerOpenClose(dealer.uid).subscribe(res => {
        this.dealerData.status = res.open;
      },
      error => {
        console.log(error);
      });

    // console.log(dealer,this.dealerData);
  }

  onMapClick() {
    // console.log(this.previous, infowindow, event)
    if (this.previous) {
      this.previous.close();
    }
    
  }

  /*
      * get phrases for multi language
  */
  getPhrases() {
    this.subscriptionPhrases = this._dataService.getPhrases().subscribe(message => {
      this.languagePhrases = message.text;
      // this.getCurrentUrlDealerID();
      // console.log(message);
    }, error => {
      console.log(error);
    });
    // console.log(this.languagePhrases)
  }

  titleClick(id: any) {
    // console.log(id);
    this._dataService.setMapviewToggle(false, id);
  }

  getAllTranslations() {
    this._dataService.getAllTranslations().subscribe(translations => {
      this.allTranslations = translations;
      // console.log(this.allTranslations)
    }, error => {
      console.log(error);
    });
  }

}





