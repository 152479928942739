export class advanceFilterModel {
    // region: string;
    /** added by sandeep on dec 15, 2022 */
    trueRegion: string;

    region: any;

    distance: string;
    dealerServices: any[];
    selectedStates: any[];
    selectedCities: any[];
    postalCode: any;
    userLat:string;
    userLong:string;
    kmMiles:any;
}