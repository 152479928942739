import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '../app/services/translate.service';
import { Router } from '@angular/router';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6'; //Vaishnavi commented on 20 Feb 2023
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  //styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Dealer Locator';
  constructor(private route: ActivatedRoute, private translate: TranslateService, private router: Router,
    /*private msAdal: MsAdalAngular6Service, */ //Vaishnavi commented on 20 Feb 2023
    private spinnerService: Ng4LoadingSpinnerService
  ) {    
  }
  ngOnInit() {
    /**on init start loader spinner */
    this.spinnerService.show();
  }

}
