//Static Regions List
export const AllRegions: any = [
  { id: 1, name: 'All Countries' },
  { id: 2, name: 'ARGENTINA' },
  { id: 3, name: 'BRAZIL' },
  { id: 4, name: 'CANADA' },
  // {id: 5, name: 'Central, Caribbean and Rest of South America'},
  { id: 5, name: 'CHILE' },
  { id: 6, name: 'COLOMBIA' },
  { id: 7, name: 'COSTA RICA' },
  { id: 8, name: 'ECUADOR' },
  { id: 9, name: 'EL SALVADOR' },
  //{ id: 10, name: 'ESTADOS UNIDOS' }, //Vaishnavi commented on 14 March 2023
  { id: 10, name: 'GUATEMALA' },
  { id: 11, name: 'HONDURAS' },
  { id: 12, name: 'MEXICO' },
  { id: 13, name: 'NICARAGUA' },
  { id: 14, name: 'PANAMA' },
  { id: 15, name: 'PERU'},
  { id: 16, name: 'PUERTO RICO' },
  { id: 17, name: 'REPUBLICA DOMINICANA'},
  { id: 18, name: 'UNITED STATES' }, //Vaishnavi updated the 'USA' with  'UNITED STATES' on 14 March 2023
  { id: 19, name: 'URUGUAY'},
  { id: 20, name: 'VENEZUELA' },

  //added on 07-07-2022 by sandeep
 
  
];