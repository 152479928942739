import { Injectable } from '@angular/core';
import { Http, Response } from "@angular/http";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { managemanualModel } from '../models/managemanualModel';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Observable } from 'rxjs/internal/Observable';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ManageManualService {
  baseUrl = environment.apiBaseUrl + 'api/managemanual/';
  // baseUrl = "http://localhost:22598/api/ManageManual/"
  savedToken: any = "";
 httpOptions = {
   headers: new HttpHeaders({
      'Content-Type': 'application/json',
     'Authorization': 'Bearer ' + this.savedToken
  })
 };

  constructor(private http: HttpClient) {

    
   }

  getAllManuals() {
    /** modified by sandeep on Jun 1, 2023 */
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
  // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    // return this.http.get<any>(this.baseUrl + 'getall?ApiAccessKey=9ZQxFMZB4o4gWvBnAlLZEQpuFOFWNEyzm65aV5huSjc=&MobAppKey=com.carriertransicold.dealerlocator'//,{headers}
    return this.http.get<any>(this.baseUrl + 'getallV2?ApiAccessKey=9ZQxFMZB4o4gWvBnAlLZEQpuFOFWNEyzm65aV5huSjc=&MobAppKey=com.carriertransicold.dealerlocator'//,{headers}
    
    );
  }
  getAllFilterManuals(manualName:any) {
    /** modified by sandeep on Jun 1, 2023 */
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
  // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    // return this.http.get<any>(this.baseUrl + 'GetAllSearch?manual_Name='+manualName//,{headers}
    return this.http.get<any>(this.baseUrl + 'GetAllSearchV2?manual_Name='+manualName//,{headers}
    
    );
  }

  getManuals(id:any): any {
    debugger
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";

    /** modified by sandeep on Jun 1, 2023 */
    // return this.http.get<managemanualModel>(this.baseUrl + 'Get/'+id);//,{headers}
    return this.http.get<managemanualModel>(this.baseUrl + 'GetManualV2/'+id);//,{headers}
  
  }

  deleteManual(id: any): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/ManageManual/';
    // this.baseUrl = "http://localhost:22598/api/ManageManual/";
    var user = localStorage.getItem('currentUser');
    return this.http.post<any>(this.baseUrl + 'delete/?user=' + user + '&id=' + id, this.httpOptions)
  }
  upManual(id:any):any{
    // this.baseUrl = environment.apiBaseUrl + 'api/ManageManual/';
    
    //var user = localStorage.getItem('currentUser');
    //return this.http.post<any>(this.baseUrl + 'upManual/?user=' + user +'&id=' + id, this.httpOptions)
    return this.http.post<any>(this.baseUrl + 'upManual/?id=' + id, this.httpOptions)
  }
  downManual(id:any):any{
    
    // this.baseUrl = environment.apiBaseUrl + 'api/ManageManual/';
    //var user = localStorage.getItem('currentUser');
    //return this.http.post<any>(this.baseUrl + 'downManual/?user=' + user +'&id=' + id, this.httpOptions)
    return this.http.post<any>(this.baseUrl + 'downManual/?id=' + id, this.httpOptions)
  }
  getManualHistory(id: any): any {
    /** modified by sandeep on Jun 1, 2023 */
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    // return this.http.get<any>(this.baseUrl + 'GetManualHistory?ManualID='+id);
    return this.http.get<any>(this.baseUrl + 'GetManualHistoryV2?ManualID='+id);
  
  }

  updateManual(managemanual: managemanualModel): Observable<managemanualModel> {
    /** modified by sandeep on Jun 1, 2023 */
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    managemanual.Modified_By = localStorage.getItem('currentUser');
    // return this.http.put<managemanualModel>(this.baseUrl + 'update/', managemanual, this.httpOptions);
    return this.http.put<managemanualModel>(this.baseUrl + 'updateV2/', managemanual, this.httpOptions);
  
  }

  saveManual(managemanual: managemanualModel): Observable<managemanualModel> {
    /** modified by sandeep on Jun 1, 2023 */
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    managemanual.Modified_By = localStorage.getItem('currentUser');
    // return this.http.post<managemanualModel>(this.baseUrl + 'add?managemanual=', managemanual, this.httpOptions);
    return this.http.post<managemanualModel>(this.baseUrl + 'addV2?managemanual=', managemanual, this.httpOptions);
  
  }

 
}