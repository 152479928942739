import { Component, OnInit } from '@angular/core';
import { managemanualModel } from '../models/managemanualModel';
import { ManageManualService } from '../services/managemanual.service';
import { Subscription } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from 'src/environments/environment';
import { AppInsightService } from '../services/AppInsight.service';

@Component({
  selector: 'app-serviceinfo',
  templateUrl: './serviceinfo.component.html',
  styleUrls: ['./serviceinfo.component.css']
})
export class ServiceinfoComponent implements OnInit {

  // allManuals : managemanualModel;
  allManuals : managemanualModel[] = [];
  displayManuals: managemanualModel[] = [];
  baseUrl = environment.apiBaseUrl ;
  searchText: string = "";
  noManual: string = "No Manuals Found..";
  
  constructor(private managemanualService: ManageManualService,
    private spinnerService: Ng4LoadingSpinnerService,
    private appInsightsService: AppInsightService
    ) { }

  ngOnInit() {
    this.getAllManuals();
  }

  getAllManuals() {
    this.spinnerService.show();
    this.noManual = "Loading...";
    this.managemanualService.getAllManuals().subscribe((manuals: any) => {
      // console.log(manuals);
      this.allManuals = manuals.manuals;
      this.displayManuals = this.allManuals;
      this.spinnerService.hide();
      this.noManual = "No Manuals Found..";
    });
  }

  /** added by sandeep on july 5, 2023 */
  /** to log an application insight event when manual clicked */
  manualClick(path: string) {
    // console.log("Service Info Manual", {'FileName': path});
    this.appInsightsService.logEvent("Service Info Manual", {'FileName': path});
  }

  search() {
    if(this.searchText != "") {
      this.displayManuals = this.allManuals.filter(manual => {
        return manual.Manual_Name.toLocaleLowerCase().match(this.searchText.toLocaleLowerCase());
      });
    }
    else {
      this.getAllManuals();
    }
  }

}
