import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, catchError, tap, map, switchMap } from 'rxjs/operators';
import { MapsAPILoader } from '@agm/core';
import { from,of  } from 'rxjs';
import { locationModel } from '../models/locationModel';
 

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GeocodeService {
  private geocoder: any;

  /** added by sandeep on May 24, 2023 */
  private location: locationModel;
 

  constructor(private mapLoader: MapsAPILoader) { }


  private initGeocoder() {   
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if(!this.geocoder) {
      return from(this.mapLoader.load())
      .pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }

  geocodeAddress(location: string): Observable<any>  {     
    return this.waitForMapsToLoad().pipe(
      // filter(loaded => loaded),
      switchMap(() => {
        return new Observable(observer => {
          this.geocoder.geocode({'address': location}, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              //console.log('Geocoding complete!');
              observer.next({
                lat: results[0].geometry.location.lat(), 
                lng: results[0].geometry.location.lng(),
                 country: results[0].formatted_address
              });
            } else {
                console.log('Error - ', results, ' & Status - ', status);
                observer.next({});
            }
            observer.complete();
          });
        })        
      })
    )
  }

  /** added by sandeep on May 24, 2023 */
  getLocationCoordinates() {
    return this.location;
  }

  /** added by sandeep on May 24, 2023 */
  setLocationCoordinates(lat: any, lng: any) {
    this.location = new locationModel;
    this.location.lat = lat;
    this.location.lng = lng;
    // console.log('current location - ', this.location);
  }
}
