import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filterModel } from '../models/filterModel';
import { dealerModel } from '../models/dealerModel';
import { advanceFilterModel } from '../models/advanceFilterModel';
import { languagePhrasesModel } from '../models/languagePhrasesModel';
@Injectable({
  providedIn: 'root'
})
export class DataServiceService {
  country: string[] = [];

  //added by sandeep on dec 9, 2022
  region: string[] = [];

  private subject = new Subject<any>();
  private subjectAdvSearchFilter = new Subject<any>();
  private subjectSendPhrases = new Subject<any>();
  public hideDistanceServiceType: boolean = false;

  /** added by sandeep on May 10, 2023 */
  private allDealerMapviewToggle = new BehaviorSubject<any>({toggleValue: false, id: 0});

  /** added by sandeep on May 17, 2023 */
  private subjectAllTranslations = new Subject<any>();

  sendMessage(filterData: filterModel) {
    //console.log('sendMessage '+filterData);
    this.subject.next({ text: filterData });
  }
  // getMessage(): Observable<any> {
  //   //console.log('getMessage ');
  //   return this.subject.asObservable();
  // }

  getMessage: Observable<any> = this.subject.asObservable();
  

  sendAdvanceSearchFilterMessage(filterData: advanceFilterModel) {
    this.subjectAdvSearchFilter.next({ text: filterData });
  }
  getAdvanceSearchFilterMessage(): Observable<any> {
    return this.subjectAdvSearchFilter.asObservable();
  }

  sendPhrases(phrases: languagePhrasesModel) {
    this.subjectSendPhrases.next({ text: phrases });
  }
  getPhrases(): Observable<any> {
    return this.subjectSendPhrases.asObservable();
  }


  getSelectedCountries(selectedCountry: any) {

    this.country = [];
    if (selectedCountry && selectedCountry != 'All Countries' && selectedCountry !='Tous les pays'&& selectedCountry != 'Todos os países' && selectedCountry != 'Todos los países' ) {
      if (selectedCountry == "USA") {
        this.country.push("UNITED STATES")
      }
      else
        this.country.push(selectedCountry);
    }
    // switch (selectedCountry) {
    //   case 1: {
    //     this.country = [];
    //     break;
    //   }
    //   case 2: {
    //     this.country.push("ARGENTINA");
    //     break;
    //   }
    //   case 3: {
    //     this.country.push("BRAZIL");
    //     break;
    //   }
    //   case 4: {
    //     this.country.push("CANADA");
    //     break;
    //   }
    //   case 5: {
    //     this.country.push("CHILE");
    //     this.country.push("COLOMBIA");
    //     this.country.push("COSTA RICA");
    //     this.country.push("EL SALVADOR");
    //     this.country.push("ESTADOS UNIDOS");
    //     this.country.push("GUATEMALA");
    //     this.country.push("HONDURAS");
    //     this.country.push("NICARAGUA");
    //     this.country.push("PANAMA");
    //     this.country.push("PUERTO RICO");
    //     this.country.push("REPUBLICA DOMINICANA");
    //     this.country.push("VENEZUELA");
    //     break;
    //   }
    //   case 6: {
    //     this.country.push("MEXICO");
    //     break;
    //   }
    //   case 7: {
    //     this.country.push("UNITED STATES");        
    //     break;
    //   }
    //   case 8: {
    //     this.country.push("MEXICO");
    //     break;
    //   }
    //   case 9: {
    //     this.country.push("UNITED STATES");        
    //     break;
    //   }
    //   case 10: {
    //     this.country.push("MEXICO");
    //     break;
    //   }
    //   case 11: {
    //     this.country.push("UNITED STATES");        
    //     break;
    //   }

    //   case 12: {
    //     this.country.push("MEXICO");
    //     break;
    //   }
    //   case 13: {
    //     this.country.push("UNITED STATES");        
    //     break;
    //   }
    //   case 14: {
    //     this.country.push("MEXICO");
    //     break;
    //   }
    //   case 15: {
    //     this.country.push("UNITED STATES");        
    //     break;
    //   }
    //   case 16: {
    //     this.country.push("MEXICO");
    //     break;
    //   }
    //   case 17: {
    //     this.country.push("UNITED STATES");        
    //     break;
    //   }
    //   case 18: {
    //     this.country.push("UNITED STATES");        
    //     break;
    //   }
    // default: {
    //   break;
    // }
    //}
    return this.country;

  }

  filterCountryData(items: any, countries: string[]) {
    if (countries && countries.length > 0 && items) {
      return items.filter(function (item) {
        for (var ctry of countries) {
          if (ctry === item.country) {
            return item;
          }
        }
      })
    }
    else
      return items;
  }

  filterCountry(items: any, countries: string[]) {
    if (countries && countries.length > 0 && items) {
      return items.filter(function (item) {
        for (var ctry of countries) {
          if (ctry === item.name) {
            return item;
          }
        }
      })
    }
    else
      return items;
  }
  filterCities(items: any, selectedStates: any[]) {
    // var returnResult = [];
    // if (selectedStates && selectedStates.length > 0) {
    //   items.filter(function (item) {
    //     selectedStates.forEach(element => {
    //       if (element.id === item.StateCode) {
    //         returnResult.push(item);
    //       }
    //     });
    //   });

    //   return returnResult;

    var returnResult = [];
    if (selectedStates && selectedStates.length > 0) {
      items.filter(function (item) {
        // selectedStates.forEach(element => {
        //   if (element.id === item.StateCode) {
        //     returnResult.push(item);
        //   }
        // });
        if (selectedStates.some(x => x.id == item.StateCode)) {
          returnResult.push(item);
        }
      });
      return returnResult;
    }
    else
      return items;
  }

  converTime(time) {
    let hour = (time.split(':'))[0];
    let min = (time.split(':'))[1] ? (time.split(':'))[1] : '00';
    let part = hour >= 12 ? 'pm' : 'am';
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return (`${hour}:${min} ${part}`)
  }
  dealerTiming(dealerTimingModel: any): any {
    let resp = new Array<any>();
    for (var i = 0; i < dealerTimingModel.length; i++) {
      if (resp.length > 0) {
        var length = resp.length;
        if (dealerTimingModel[i].startTime == resp[length - 1].startTime && dealerTimingModel[i].endTime == resp[length - 1].endTime) {
          resp[length - 1].openingDay = resp[length - 1].openingDay + '-' + dealerTimingModel[i].openingDay;
        }
        else {
          resp.push(dealerTimingModel[i]);
        }
      }
      else {
        resp.push(dealerTimingModel[i]);
      }
    }

    for (var i = 0; i < resp.length; i++) {
      var occurance = resp[i].openingDay.split("-").length - 1
      if (resp[i].startTime === "") {
        resp[i].startTime = "Closed";
      }
      if (occurance > 1) {
        var left = resp[i].openingDay.substring(0, resp[i].openingDay.indexOf("-"));
        var right = resp[i].openingDay.substring(resp[i].openingDay.length, resp[i].openingDay.lastIndexOf("-"));
        resp[i].openingDay = left + right;
      }
    }

    resp.forEach(day => {
      if (day.startTime && day.endTime)
        if (day.startTime !== "Closed") {

          var start = this.converTime(day.startTime)
          var end = this.converTime(day.endTime);
          day.startTime = start + ' to ' + end;
        }

    });
    return resp;
  }

  //added by sandeep on dec 9, 2022
  getSelectedTrueRegion(selectedTrueRegion: any) {

    this.region = [];
    if (selectedTrueRegion && selectedTrueRegion != 'All Regions') {
      this.region.push(selectedTrueRegion);
    }
    return this.region;
  }


  //added by sandeep on dec 9, 2022
  filterTrueRegionData(items: any, regions: string[]) {
    if (regions && regions.length > 0 && items) {
      return items.filter(function (item) {
        for (var reg of regions) {
          if (reg === item.region) {
            return item;
          }
        }
      })
    }
    else
      return items;
  }

  /** added by sandeep on May 10, 2023 */
  setMapviewToggle(value: boolean, id: any) {
    this.allDealerMapviewToggle.next({toggleValue: value, id: id});
  }

  getMapviewToggle() {
    return this.allDealerMapviewToggle.asObservable();
  }

  /** added by sandeep on May 17, 2023 */
  sendAllTranslations(translations: any) {
    // console.log('data-service -> translations -> ', translations)
    this.subjectAllTranslations.next(translations);
  }

  /** added by sandeep on May 17, 2023 */
  getAllTranslations() {
    // console.log('data-service : ', this.subjectAllTranslations.asObservable());
    return this.subjectAllTranslations.asObservable();
  }

  /** added by sandeep on May 17, 2023 */
  /*** to get the translated names from the translation object */
  getTranslatedName(translation: any = {}, field: string): string {
    // console.log(field)
    // if(!translation[field.trim().toLowerCase()]) {
    //   console.log(field)
    //   return 'No trans';
    // }
    
    // return translation[field.trim().toLowerCase()] || 'No Trans';
    return translation[field.trim().toLowerCase()] || field;
  }

}

