import { Component, OnInit, ElementRef , ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { languagePhrasesModel } from '../models/languagePhrasesModel';
import { DataServiceService } from '../services/data-service.service';
import { ModalModule, BsModalService, BsModalRef } from '../../../node_modules/ngx-bootstrap';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  subscriptionPhrases: Subscription;
  languagePhrases: languagePhrasesModel;

  @ViewChild('generateFootermodal') generateFootermodal: ElementRef;
  modalFooterRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: "my-modal"
  };

  constructor(private _dataService: DataServiceService,
    private modalService: BsModalService,) { }

  ngOnInit() {
    this.getPhrases();
  }

  /*
      * get language phrases for footer
   */
  getPhrases() {
    this.subscriptionPhrases = this._dataService.getPhrases().subscribe(message => {
      this.languagePhrases = message.text;
    });
  }

/*
      * open footer modal
   */
  openFooterModal(){
    this.modalFooterRef = this.modalService.show(this.generateFootermodal, this.config);
  }
}
