//import {citiesModel} from '../models/citiesModel';

export class statesModel
{     
    /**
     *
     */
    constructor(id?,name?) {
        this.id=id;
        this.name=name;
       
    }
    //id: number;id: ++count,
    id: string;
    name : string;  
    countryCode: string; 
}