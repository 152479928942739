 
 /**
  * static data to load dealer levels
  */
 
 export const dealerLevelData= [    
    {name:'Gold'},
    {name:'Silver'},
    {name:'Platinum'},
    {name:'Bronze'}     
 ]