import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
 
import { ModalModule, BsModalService, BsModalRef } from '../../../node_modules/ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '../services/translate.service';
import { languagePhrasesModel } from '../models/languagePhrasesModel';
import { DataServiceService } from '../services/data-service.service';
import { GeocodeService } from '../services/geocode.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild('generatemodal') generatemodal: ElementRef;
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: "my-modal"
  };
  cookieAccepted: boolean = false;
  defaultLanguage: any;
  selectedLanguge: any;
  languagePhrases: languagePhrasesModel;
  privacyUrl: any;

  /** added by sandeep on May 17, 2023 */
  allTranslations: any;

  constructor(
    private modalService: BsModalService,
    private cookieService: CookieService, 
    private translate: TranslateService,
    private _dataService: DataServiceService,
    private _geocodeService: GeocodeService
    ) {
  }

  ngOnInit() {
  /*
      * get cookie accepted value from cookies
   */
    this.cookieAccepted = this.cookieService.check('cookieAccepted');
    const value: string = this.cookieService.get('cookieAccepted');

    // this.defaultLanguage = this.cookieService.check('defaultLanguage');
    // const valueLanguage: string = this.cookieService.get('defaultLanguage');

    if (value !== 'true') {
      this.cookieAccepted = false;
    }
    else { this.cookieAccepted = true; }
    

      /*
      * get default language of browser
   */
      var defaultBrowserLang = (window.navigator.language).substring(0, 2); // navigator.language;

      if (defaultBrowserLang) {
        if (defaultBrowserLang === 'en') {
          this.setLang('en');
        }
        else  if (defaultBrowserLang === 'fr') 
        { this.setLang('fr'); }
        else  if (defaultBrowserLang === 'es') 
        { this.setLang('sp'); }
        else  if (defaultBrowserLang === 'pt') 
        { this.setLang('po'); } 
        else{
          this.setLang('en');
        }
      }
    
  }

    /*
      * set default language of the website and change phrases as per the language
   */
  setLang(languageCode: any) {
    // console.log(languageCode)
    //this.cookieService.set('defaultLanguage', languageCode, 30);
    this.selectedLanguge = languageCode;

    this.translate.use(languageCode).subscribe((data: any) => {
      this.languagePhrases = new languagePhrasesModel();
      this.languagePhrases = data;     
      this.sendPhrases(this.languagePhrases);
    }, err => console.log(err));

    
    /** added by sandeep on May 17, 2023 */
    this.translate.getAllTranslations().subscribe(translations => {
      this.allTranslations = translations;
      if(languageCode == "en")
        this.sendAllTranslations(translations.english);
      else if(languageCode == "fr")
        this.sendAllTranslations(translations.french);
      else if(languageCode == "sp")
        this.sendAllTranslations(translations.spanish);
      else if(languageCode == "po")
        this.sendAllTranslations(translations.portuguese);
      else if(languageCode == "ar")
        this.sendAllTranslations(translations.arabic);
      else if(languageCode == "ch")
        this.sendAllTranslations(translations.chinese);
      else if(languageCode == "fc")
        this.sendAllTranslations(translations.french_canadian);
      else if(languageCode == "pl")
        this.sendAllTranslations(translations.polish);
      else if(languageCode == "cz")
        this.sendAllTranslations(translations.czech);
      //else if(languageCode == "ne")
        //this.sendAllTranslations(translations.netherlands);
      // Added German lang - Namratha K - 21st May 2024
      else if(languageCode == "ge")
        this.sendAllTranslations(translations.german);
      else if(languageCode == "du")
        this.sendAllTranslations(translations.dutch);
      else if(languageCode == "it")
        this.sendAllTranslations(translations.italian);
      else
        this.sendAllTranslations(translations.english);
      
      // console.log(this.allTranslations);
    }, error => {
      console.log(error);
    });
  }

    /*
      * send default language to other components
   */
  sendPhrases(phrases: languagePhrasesModel): void {
    this._dataService.sendPhrases(phrases);
  }

   /*
      * open download mobile app modal 
   */
  openDownloadAppModal() {
    this.modalRef = this.modalService.show(this.generatemodal, this.config);
  }
  /*
      *  on accept cookies click
   */
  acceptCookie() {
    this.cookieService.set('cookieAccepted', 'true', 30);
    this.cookieAccepted = !this.cookieAccepted;
  }

  /*
      * open android link
   */
  openAndroid() {
    var androidLink = 'https://play.google.com/store/apps/details?id=com.carriertransicold.locator';

    this.modalRef.hide();
    var win = window.open(androidLink, '_blank'); win.focus();
    win.focus();
  }

  /*
      * open ios link
   */
  openIstore() {
    var istoreLink = 'https://itunes.apple.com/us/app/carrier-dealer-locator/id638907917';
    this.modalRef.hide();
    var win = window.open(istoreLink, '_blank');
    win.focus();
  }

  /*
      * on reject cookies
   */
  rejectCookie() {
    this.cookieService.set('cookieAccepted', 'false', 30);
    this.cookieAccepted = !this.cookieAccepted;
  }

  /** added by sandeep on May 17, 2023 */
    /*
      * send alltranslations to other components based on the language selection
   */
  sendAllTranslations(translations: any) {
    this._dataService.sendAllTranslations(translations);
  }

  /** added by sandeep on May 23, 2023 */
  getLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.setCoordinates(position.coords.latitude, position.coords.longitude);
      // alert('Location accessed'+ position.coords.latitude)
    },function(){
        // alert('User not allowed')
    })
    // ,{maximumAge: 0, timeout: 2000}
  }

  /** added by sandeep on May 30, 2023 */
  setCoordinates(lat: number, long: number) {
    this._geocodeService.setLocationCoordinates(lat, long);
    // console.log('called');
  }

}
