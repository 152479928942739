export class dealerAddressDetailModel {
    address1: string = "";
    address2: string = "";
    city: string = "";
    dealer_uid: number;
    postalCode: string = "";
    state: string = "";

    /** added by sandeep on July 7, 2023 */
    stateCode: string = "";
}