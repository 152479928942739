export class citiesModel{

    constructor(id?,name?) {
        this.id=id;
        this.name=name;
        
    }
    id: string;
    name : string;
    countryCode: string;
    stateCode: string;     
}