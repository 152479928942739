
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
  })
export class commercialModelservice {
    baseUrl = environment.apiBaseUrl + 'api/CommercialModel/';

    commercialValue: string = '';
    alarmValue: string = '';
  
    data: any = {};

    constructor(private http: HttpClient) { }
  
    use(lang: string) {
      //const langPath = `./assets/i18n/${lang || 'en'}.json`;
      const langPath = `../../assets/i18n/${lang || 'en'}.json`;
      //console.log(langPath);
     return this.http.get(langPath);
  
    }
  
    /** added by Ubed */
    getAllcommercialModel() {
      this.baseUrl = environment.apiBaseUrl + 'api/CommercialModel/';
      // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
      return this.http.get<any>(this.baseUrl + 'GetCommercialModelList?ApiAccessKey=9ZQxFMZB4o4gWvBnAlLZEQpuFOFWNEyzm65aV5huSjc=&MobAppKey=com.carriertransicold.dealerlocator');
    }

    submitForm(commercialValue: string, alarmValue: string): Observable<any> {
        //console.log(commercialValue);
        //console.log(alarmValue);
        const requestBody = { commercialValue, alarmValue };
        //console.log(this.baseUrl + 'GetAlarm?commercialName=' + commercialValue + '&alarmCode=' + alarmValue);
        return this.http.get<any>(this.baseUrl + 'GetAlarm?commercialName=' + commercialValue + '&alarmCode=' + alarmValue);
    }

        
  }