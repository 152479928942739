import { Injectable } from '@angular/core';
import { DealerService } from './dealer.service';

import { Resolve } from '@angular/router';

import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class DealersResolver implements Resolve<any> {
  constructor(private dealerService: DealerService) {}

  resolve(route: ActivatedRouteSnapshot) {
    /** modified by sandeep on Apr 6th, 2023 */
    // return this.dealerService.getAllDealers();
    return this.dealerService.getAllDealersV2();
  }
}