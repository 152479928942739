import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class AllRegionsService {
  baseUrl = environment.apiBaseUrl + 'api/countrycitystate/';
  // baseUrl = "http://localhost:22598/api/CountryCityState/";
  // baseUrl = environment.apiBaseUrl + 'api/Test/';
  constructor(private http: HttpClient) { }

  /** getAllCountriesList() is added in replacement of getAllRegions() method */
  // getAllRegions() {
  //   // this.baseUrl = environment.apiBaseUrl + 'api/CountryCityState/';
     
  //   return this.http.get(this.baseUrl + 'getallregions?ApiAccessKey=9ZQxFMZB4o4gWvBnAlLZEQpuFOFWNEyzm65aV5huSjc=&MobAppKey=com.carriertransicold.dealerlocator');
  // }

  saveCity(city, state, country): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/CountryCityState/';
    var user = localStorage.getItem('currentUser');
    return this.http.post(this.baseUrl + 'AddCity?city=' + city + '&state=' + state + '&country=' + country + '&user=' + user, httpOptions);
  }

  saveState( state, country): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/CountryCityState/';
    var user = localStorage.getItem('currentUser');
    return this.http.post(this.baseUrl + 'AddState?state=' + state + '&country=' + country + '&user=' + user, httpOptions);
    //return this.http.post(this.baseUrl + 'AddState?state=' + state + '&country=' + country, httpOptions);
  }

  updateCity(city, state, country, newCityValue): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/CountryCityState/';
    var user = localStorage.getItem('currentUser');
    return this.http.put(this.baseUrl + 'UpdateCity?city=' + city + '&state=' + state + '&country=' + country + '&newCityValue=' + newCityValue + '&user=' + user, httpOptions);
  }

  updateState( state, country, newStateValue): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/CountryCityState/';
    var user = localStorage.getItem('currentUser');
    return this.http.put(this.baseUrl + 'UpdateState?state=' + state + '&country=' + country + '&newStateValue=' + newStateValue + '&user=' + user, httpOptions);
    //return this.http.put(this.baseUrl + 'UpdateState?state=' + state + '&country=' + country + '&newStateValue=' + newStateValue , httpOptions);
  }

  deleteCity(city, stateCode, state, countryCode, country): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/CountryCityState/';
    var user = localStorage.getItem('currentUser');
    return this.http.post(this.baseUrl + 'DeleteCity?city=' + city + '&stateCode=' + stateCode + '&state=' + state + '&countryCode=' + countryCode + '&country=' + country + '&user=' + user, httpOptions);
  }

  deleteState(state, countryCode, country): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/CountryCityState/';
    var user = localStorage.getItem('currentUser');
    return this.http.post(this.baseUrl + 'DeleteState?state=' + state + '&countryCode=' + countryCode + '&country=' + country + '&user=' + user, httpOptions);
    //return this.http.post(this.baseUrl + 'DeleteState?state=' + state + '&countryCode=' + countryCode + '&country=' + country , httpOptions);
  }

  
  /// added by sandeep on 5-11-2022
  getAllCountriesList() {
    // return AllRegions
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = 'http://localhost:22598/api/Test/'; // for local
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    return this.http.get(this.baseUrl + 'GetAllRegionsV2?ApiAccessKey=9ZQxFMZB4o4gWvBnAlLZEQpuFOFWNEyzm65aV5huSjc=&MobAppKey=com.carriertransicold.dealerlocator', httpOptions);
    // return this.http.get(this.baseUrl + 'getallregions?ApiAccessKey=9ZQxFMZB4o4gWvBnAlLZEQpuFOFWNEyzm65aV5huSjc=&MobAppKey=com.carriertransicold.dealerlocator');
  
  }

  /// added by sandeep on 12-12-2022
  getAllOnlyRegionsList() {
    // return only Regions
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    return this.http.get(this.baseUrl + 'GetOnlyRegions?ApiAccessKey=9ZQxFMZB4o4gWvBnAlLZEQpuFOFWNEyzm65aV5huSjc=&MobAppKey=com.carriertransicold.dealerlocator', httpOptions);
    // return this.http.get(this.baseUrl + 'getallregions?ApiAccessKey=9ZQxFMZB4o4gWvBnAlLZEQpuFOFWNEyzm65aV5huSjc=&MobAppKey=com.carriertransicold.dealerlocator');
  
  }

  /// added by sandeep on 05-01-2023
  saveRegion( regionName): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    var user = localStorage.getItem('currentUser') || "Test";
    return this.http.post(this.baseUrl + 'AddRegion?regionName=' + regionName + '&user=' + user, httpOptions);
  }

  /// added by sandeep on 11-01-2023
  deleteRegion(region ): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    var user = localStorage.getItem('currentUser') || "Test";
    return this.http.post(this.baseUrl + 'DeleteRegion?regionName=' + region + '&user=' + user, httpOptions);
  }

  // added by sandeep on 25-01-2023
  updateRegion( oldRegionName, newRegionValue): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    var user = localStorage.getItem('currentUser') || "Test";
    return this.http.put(this.baseUrl + 'UpdateRegion?oldRegionName=' + oldRegionName + '&newRegionName=' + newRegionValue + '&user=' + user, httpOptions);
  }

  /** added by sandeep on 31-01-2023 */
  saveCountry(countryCode, regionCode, callingCode: number) {
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    var user = localStorage.getItem('currentUser') || "Test";
    return this.http.post(this.baseUrl + 'AddCountry?countryCode=' + countryCode + '&regionCode=' + regionCode + '&callingCode=' + callingCode + '&user=' + user, httpOptions);
  }

  /** added by sandeep on 01-02-2023 */
  getUnassignedCountries() {
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    return this.http.get(this.baseUrl + 'GetUnassignedCountries', httpOptions);

  }

  /** added by sandeep on 08-02-2023 */
  deleteCountry(countryName, regionCode) {
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    var user = localStorage.getItem('currentUser') || "Test";
    return this.http.post(this.baseUrl + 'DeleteCountry?countryName=' + countryName + '&regionCode=' + regionCode + '&user=' + user, httpOptions);
  
  }

  /** added by sandeep on 09-02-2023 */
  updateCountry(countryName, regionCode, newCountryName, callingCode: number) {
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    var user = localStorage.getItem('currentUser') || "Test";
    return this.http.put(this.baseUrl + 'UpdateCountry?countryName=' + countryName + '&regionCode=' + regionCode + '&newCountryName=' + newCountryName + '&callingCode=' + callingCode + '&user=' + user, httpOptions);
  
  }

  /** added by sandeep on 27-06-2023 for inserting europe api dealers */
  getCountryCode(countryName, cityName) {
    this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "http://localhost:22598/api/Test/"; //for local
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    return this.http.get<any>(this.baseUrl + 'GetCountryCode?country=' + countryName + '&city=' + cityName, httpOptions);
  
  }

}
