export var data = {
    "centers": [
        {
            "ID": 558,
            "ADDRESS_LINE_1": "Al Quoz Industrial Area 3",
            "ADDRESS_LINE_2": "Po Box 7427 bt 2nd & 3rd interchange, Sheikh Zayeed road, al Quoz",
            "CITY": "Dubai",
            "COUNTRY": "U.A.E",
            "COUNTRY_CODE": "AE",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "(+971) 4 3718585  ",
            "ALT_PHONE": "(+971) 55 6088408",
            "MOBILE_PHONE": "(+971) 50 9001833",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+971) 4 3389559",
            "EMAIL_ADDRESS": "ase@ase.ae",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Dubai",
            "COMPAGNY_NAME": "AL SHIWARI ENTERPRISES LLC",
            "LATITUDE": "25.163611",
            "LONGITUDE": "55.230278",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "1",
            "CONTACT": "Sam KOSHY",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:20:00,2:8:00:20:00,3:8:00:20:00,4:8:00:20:00,6:8:00:20:00,7:8:00:20:00"
        },
        {
            "ID": 699,
            "ADDRESS_LINE_1": "Fujairah",
            "ADDRESS_LINE_2": "",
            "CITY": "Fujairah",
            "COUNTRY": "U.A.E",
            "COUNTRY_CODE": "AE",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "+971 9 2239160",
            "ALT_PHONE": "+971 56 66815270",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+971 9 2239150",
            "EMAIL_ADDRESS": "fujairahservcie@asa.ae",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Fujairah",
            "COMPAGNY_NAME": "AL SHIRAWI ENTERPRISES LLC",
            "LATITUDE": "25.069167",
            "LONGITUDE": "56.3",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "1",
            "CONTACT": "Mr MADASAMY",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,6:8:00:17:00,7:8:00:17:00"
        },
        {
            "ID": 700,
            "ADDRESS_LINE_1": "PO Box 31281",
            "ADDRESS_LINE_2": "",
            "CITY": "Ral Al Khaimah",
            "COUNTRY": "U.A.E",
            "COUNTRY_CODE": "AE",
            "POSTAL_CODE": "000",
            "MAIN_PHONE": "+971 7 2239069",
            "ALT_PHONE": "+971 50 4534811",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+971 7 2239068",
            "EMAIL_ADDRESS": "ifran.uk@asa.ae",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Ral Al Khaimah",
            "COMPAGNY_NAME": "AL SHIRAWI ENTERPRISES LLC",
            "LATITUDE": "25.818611",
            "LONGITUDE": "56.010556",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "1",
            "CONTACT": "Ifran ULLAH KHAN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,6:8:00:17:00,7:8:00:17:00"
        },
        {
            "ID": 701,
            "ADDRESS_LINE_1": "Mussafah Sector No 40",
            "ADDRESS_LINE_2": "PO Box 34045",
            "CITY": "Abu Dhabi",
            "COUNTRY": "U.A.E",
            "COUNTRY_CODE": "AE",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "+971 2 5513613",
            "ALT_PHONE": "+971 55 5174278",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+971 2 5507863",
            "EMAIL_ADDRESS": "suman@ase.ae",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Abu Dhabi",
            "COMPAGNY_NAME": "AL SHIRAWI ENTERISES LLC",
            "LATITUDE": "24.348822",
            "LONGITUDE": "54.48492",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "1",
            "CONTACT": "Mr SUMAN",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,6:8:00:17:00,7:8:00:17:00"
        },
        {
            "ID": 4,
            "ADDRESS_LINE_1": "252, Arshakunyats",
            "ADDRESS_LINE_2": "",
            "CITY": "Erevan",
            "COUNTRY": "Armenia",
            "COUNTRY_CODE": "AM",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "+374 10 444 470",
            "ALT_PHONE": "+374 10 444 470",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+374 93 600 650",
            "EMAIL_ADDRESS": "k.baghdasaryan@inbox.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Erevan",
            "COMPAGNY_NAME": "TRANSCOLD DIAGNOSE",
            "LATITUDE": "40.126561",
            "LONGITUDE": "44.490648",
            "DD": "1",
            "TRUCK": "0",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Karen BAGHDASARYAN",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:18:00,2:9:00:18:00,3:9:00:18:00,4:9:00:18:00,5:9:00:18:00"
        },
        {
            "ID": 5,
            "ADDRESS_LINE_1": "Tagerbachstraße 6",
            "ADDRESS_LINE_2": "",
            "CITY": "Sankt Florian",
            "COUNTRY": "Austria",
            "COUNTRY_CODE": "AT",
            "POSTAL_CODE": "4490",
            "MAIN_PHONE": "(+43) 7224 674 05120",
            "ALT_PHONE": "(+43) 664 8150616",
            "MOBILE_PHONE": "(+43) 664 815 0660",
            "ASSISTANCE_PHONE": "0800 291039",
            "FAX_PHONE": "(+43) 7224 67405 133",
            "EMAIL_ADDRESS": "wolfgang.Eckert@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Sankt Florian",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD AUSTRIA GMBH",
            "LATITUDE": "48.228",
            "LONGITUDE": "14.392",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Wolfgang ECKERT",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:12:00:12:30:16:30,2:7:30:12:00:12:30:16:30,3:7:30:12:00:12:30:16:30,4:7:30:12:00:12:30:16:30,5:7:30:12:30::,6::::,7::::"
        },
        {
            "ID": 6,
            "ADDRESS_LINE_1": "Industriezentrum, Niederösterreich Süd",
            "ADDRESS_LINE_2": "Straße 15, Objekt M66",
            "CITY": "Wiener Neudorf",
            "COUNTRY": "Austria",
            "COUNTRY_CODE": "AT",
            "POSTAL_CODE": "A-2351",
            "MAIN_PHONE": "+43 2236 660664      ",
            "ALT_PHONE": "+43 664 8150617",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 291039",
            "FAX_PHONE": "+43 2236 660664 20     ",
            "EMAIL_ADDRESS": "rubak.richard@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Wiener Neudorf",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD AUSTRIA GMBH",
            "LATITUDE": "48.061667",
            "LONGITUDE": "16.165833",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. RUBAK Richard",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:30,2:8:00:::16:30,3:8:00:::16:30,4:8:00:::16:30,5:8:00:12:30::,6::::,7::::"
        },
        {
            "ID": 7,
            "ADDRESS_LINE_1": "Langer Weg 101",
            "ADDRESS_LINE_2": "",
            "CITY": "Kalsdorf bei Graz ",
            "COUNTRY": "Austria",
            "COUNTRY_CODE": "AT",
            "POSTAL_CODE": "8401",
            "MAIN_PHONE": "(+43) 3135 51550",
            "ALT_PHONE": "(+43) 664 8150618",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 291039",
            "FAX_PHONE": "(+43) 3135 515506 ",
            "EMAIL_ADDRESS": "daniel.leskovar@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Graz",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD AUSTRIA GMBH",
            "LATITUDE": "46.96933",
            "LONGITUDE": "15.447335",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Daniel Leskovar",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:00:::17:00,2:7:00:::17:00,3:7:00:::17:00,4:7:00:::17:00,5:8:00:13:00::,6::::,7::::"
        },
        {
            "ID": 8,
            "ADDRESS_LINE_1": "Lagerhausstraße 24",
            "ADDRESS_LINE_2": "",
            "CITY": "Wals",
            "COUNTRY": "Austria",
            "COUNTRY_CODE": "AT",
            "POSTAL_CODE": "5071",
            "MAIN_PHONE": "(+43) 662 850500",
            "ALT_PHONE": "(+43) 664 3072488",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 291039",
            "FAX_PHONE": "(+43) 662 850500 50",
            "EMAIL_ADDRESS": "info@reischl-kfz.at",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Salzburg",
            "COMPAGNY_NAME": "REISCHL KFZ-TECHNIK GMBH",
            "LATITUDE": "47.787783",
            "LONGITUDE": "12.977101",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. REISCHL Rupert",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:::17:00,2:7:30:::17:00,3:7:30:::17:00,4:7:30:::17:00,5:7:30:::16:00,6::::,7::::"
        },
        {
            "ID": 11,
            "ADDRESS_LINE_1": "Fallegasse 9",
            "ADDRESS_LINE_2": "",
            "CITY": "Klagenfurt",
            "COUNTRY": "Austria",
            "COUNTRY_CODE": "AT",
            "POSTAL_CODE": "9020",
            "MAIN_PHONE": "(+43) 463 318348",
            "ALT_PHONE": "(+43) 664 3408107",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 291039",
            "FAX_PHONE": "(+43) 463 3183484",
            "EMAIL_ADDRESS": "info@pieschl.at",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Klagenfurt",
            "COMPAGNY_NAME": "PIESCHL TRANSPORTKUHLUNG & AUTOELEKTRIK e.U.",
            "LATITUDE": "46.607889",
            "LONGITUDE": "14.337845",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. Horst STROMBERGER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:12:30::,6::::,7::::"
        },
        {
            "ID": 12,
            "ADDRESS_LINE_1": "Alemannenstraße 12",
            "ADDRESS_LINE_2": "",
            "CITY": "Rankweil",
            "COUNTRY": "Austria",
            "COUNTRY_CODE": "AT",
            "POSTAL_CODE": "6830",
            "MAIN_PHONE": "(+43) 5522 44248 0",
            "ALT_PHONE": "(+43) 664 41410387",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 291039",
            "FAX_PHONE": "(+43) 5522 4424820",
            "EMAIL_ADDRESS": "office@steinhauser-fahrzeugbau.at",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Rankweil",
            "COMPAGNY_NAME": "STEINHAUSER FAHRZEUBAU GMBH & CO KG",
            "LATITUDE": "47.273912",
            "LONGITUDE": "9.649392",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. Michael GOJO",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 692,
            "ADDRESS_LINE_1": "Schlöglstraße 83",
            "ADDRESS_LINE_2": "Hall in Tirol",
            "CITY": "Innsbruck",
            "COUNTRY": "Austria",
            "COUNTRY_CODE": "AT",
            "POSTAL_CODE": "A-6020",
            "MAIN_PHONE": "0043 5223 23828",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "0043 660 4100047",
            "ASSISTANCE_PHONE": "0800 291039",
            "FAX_PHONE": "+43 512 343552",
            "EMAIL_ADDRESS": "info.tirol@reischl-kfz.at",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Innsbruck",
            "COMPAGNY_NAME": "REISCHL KFZ-TECHNIK GMBH",
            "LATITUDE": "47.273611",
            "LONGITUDE": "11.475",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Bernhard KOGLER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::16:00,6::::,7::::"
        },
        {
            "ID": 21,
            "ADDRESS_LINE_1": "Akkerhage 8 ",
            "ADDRESS_LINE_2": "",
            "CITY": "Gent",
            "COUNTRY": "Belgium",
            "COUNTRY_CODE": "BE",
            "POSTAL_CODE": "9000",
            "MAIN_PHONE": "(+32) 92 434 747",
            "ALT_PHONE": "(+32) 92 434 740",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 99310",
            "FAX_PHONE": "(+32) 92 21 44 13",
            "EMAIL_ADDRESS": "tom.de.cooman@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Gent",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD BELGIUM BVBA",
            "LATITUDE": "51.018358",
            "LONGITUDE": "3.735094",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tom de COOMAN",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:00,2:8:30:::17:00,3:8:30:::17:00,4:8:30:::17:00,5:8:30:::17:00,6::::,7::::"
        },
        {
            "ID": 23,
            "ADDRESS_LINE_1": "Koralenhoeve 8A",
            "ADDRESS_LINE_2": "",
            "CITY": "Wommelgem",
            "COUNTRY": "Belgium",
            "COUNTRY_CODE": "BE",
            "POSTAL_CODE": "2160",
            "MAIN_PHONE": "(+32) 3 354 23 00",
            "ALT_PHONE": "(+32) 3 354 23 00",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 99310",
            "FAX_PHONE": "(+32) 3 354 23 33",
            "EMAIL_ADDRESS": "Freddy.DeRijs@Carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Antwerpen",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD BELGIUM BVBA",
            "LATITUDE": "51.206426",
            "LONGITUDE": "4.524103",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Freddy DeRijs",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:00,2:8:30:::17:00,3:8:30:::17:00,4:8:30:::17:00,5:8:30:::17:00,6::::,7::::"
        },
        {
            "ID": 24,
            "ADDRESS_LINE_1": "140 Rue de l'Abbaye",
            "ADDRESS_LINE_2": "",
            "CITY": "Herstal",
            "COUNTRY": "Belgium",
            "COUNTRY_CODE": "BE",
            "POSTAL_CODE": "4040",
            "MAIN_PHONE": "(+32) 4 248 34 17",
            "ALT_PHONE": "(+32) 4 248 34 17",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 99310",
            "FAX_PHONE": "(+32) 4 248 25 41",
            "EMAIL_ADDRESS": "t.gaude@refricar.be",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Liege",
            "COMPAGNY_NAME": "REFRICAR S.A.",
            "LATITUDE": "50.696458",
            "LONGITUDE": "5.629635",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Thierry Gaudé",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:00,2:8:30:::17:00,3:8:30:::17:00,4:8:30:::17:00,5:8:30:::17:00,6::::,7::::"
        },
        {
            "ID": 25,
            "ADDRESS_LINE_1": "Stoomtuigstraat 24",
            "ADDRESS_LINE_2": "",
            "CITY": "Hooglede-Gits",
            "COUNTRY": "Belgium",
            "COUNTRY_CODE": "BE",
            "POSTAL_CODE": "8830",
            "MAIN_PHONE": "(+32) 51 24 55 61",
            "ALT_PHONE": "(+32) 51 24 55 61",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 99310",
            "FAX_PHONE": "(+32) 51 24 55 67",
            "EMAIL_ADDRESS": "info@westcool.be",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Roeselare",
            "COMPAGNY_NAME": "WESTCOOL BVBA",
            "LATITUDE": "50.985648",
            "LONGITUDE": "3.120611",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jan LEDAINE",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:00,2:8:30:::17:00,3:8:30:::17:00,4:8:30:::17:00,5:8:30:::17:00,6::::,7::::"
        },
        {
            "ID": 26,
            "ADDRESS_LINE_1": "IZ Centrum - Zuid 3035",
            "ADDRESS_LINE_2": "",
            "CITY": "Houthalen",
            "COUNTRY": "Belgium",
            "COUNTRY_CODE": "BE",
            "POSTAL_CODE": "3530",
            "MAIN_PHONE": "(+32) 11 60 48 41",
            "ALT_PHONE": "(+32) 11 60 48 41",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 99310",
            "FAX_PHONE": "(+32) 11 52 31 57",
            "EMAIL_ADDRESS": "Mohamed.azdi-ahmed@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Hasselt",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD BELGIUM BVBA",
            "LATITUDE": "51.015186",
            "LONGITUDE": "5.364718",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mohamed Azdi-Ahmed",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:00,2:8:30:::17:00,3:8:30:::17:00,4:8:30:::17:00,5:8:30:::17:00,6::::,7::::"
        },
        {
            "ID": 999,
            "ADDRESS_LINE_1": "STR. VRAH KITKA  20",
            "ADDRESS_LINE_2": "",
            "CITY": "PETRICH",
            "COUNTRY": "",
            "COUNTRY_CODE": "BG",
            "POSTAL_CODE": "2850",
            "MAIN_PHONE": "+38975432474 ",
            "ALT_PHONE": "+38978432774",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info-bg@maniservice.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "PETRICH",
            "COMPAGNY_NAME": "MANI SERVICE LTD",
            "LATITUDE": "41.397746",
            "LONGITUDE": "23.187461",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tony Sekulov",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6::::,7::::"
        },
        {
            "ID": 1000,
            "ADDRESS_LINE_1": "22 Abagar Str. ",
            "ADDRESS_LINE_2": " Sofia City Logistic Park",
            "CITY": "Sofia",
            "COUNTRY": "",
            "COUNTRY_CODE": "BG",
            "POSTAL_CODE": "1138",
            "MAIN_PHONE": "+359 889 20 90 11",
            "ALT_PHONE": "+359 888 95 41 31",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "k.stankov@arcticservice.eu",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Sofia",
            "COMPAGNY_NAME": "Arctic Service Ltd",
            "LATITUDE": "42.641844",
            "LONGITUDE": "23.393934",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Kiril Stankov",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::19:30,2:7:30:::19:30,3:7:30:::19:30,4:7:30:::19:30,5:7:30:::19:30,6:8:00:::17:00,7::::"
        },
        {
            "ID": 1004,
            "ADDRESS_LINE_1": "Rogoshko shose str",
            "ADDRESS_LINE_2": "10, Northern Industrial Region",
            "CITY": "Plovdiv",
            "COUNTRY": "",
            "COUNTRY_CODE": "BG",
            "POSTAL_CODE": "4003",
            "MAIN_PHONE": "00359887521265",
            "ALT_PHONE": "00359883347790",
            "MOBILE_PHONE": "00359879053339",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Car_clima@abv.bg",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Plovdiv",
            "COMPAGNY_NAME": "Car Clima EOOD",
            "LATITUDE": "42.167323",
            "LONGITUDE": "24.781507",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Rumen Filipov",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 1005,
            "ADDRESS_LINE_1": "ZPZ",
            "ADDRESS_LINE_2": "Varna Komers ",
            "CITY": "Varna",
            "COUNTRY": "",
            "COUNTRY_CODE": "BG",
            "POSTAL_CODE": "9000",
            "MAIN_PHONE": "00359878427444",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "mtfrigo@abv.bg",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Varna",
            "COMPAGNY_NAME": "MT FRIGO Ltd",
            "LATITUDE": "43.212689",
            "LONGITUDE": "27.866689",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marin Stanchev",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::17:30,2:9:00:::17:30,3:9:00:::17:30,4:9:00:::17:30,5:9:00:::17:30,6::::,7::::"
        },
        {
            "ID": 1006,
            "ADDRESS_LINE_1": "MOL Anatlo Georgiev",
            "ADDRESS_LINE_2": "ulica Reduta 2                           ",
            "CITY": "Vidin",
            "COUNTRY": "",
            "COUNTRY_CODE": "BG",
            "POSTAL_CODE": "3700",
            "MAIN_PHONE": "00359878976736",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "galabin_georgiev@abv.bg",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Vidin",
            "COMPAGNY_NAME": "Vaias LTD",
            "LATITUDE": "43.996303",
            "LONGITUDE": "22.862806",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Galabin Georgiev",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:30,2:8:30:::17:30,3:8:30:::17:30,4:8:30:::17:30,5:8:30:::17:30,6:8:30:::13:00,7::::"
        },
        {
            "ID": 1007,
            "ADDRESS_LINE_1": "SO Biznis Park Meden Rudnik",
            "ADDRESS_LINE_2": "",
            "CITY": "Burgas",
            "COUNTRY": "",
            "COUNTRY_CODE": "BG",
            "POSTAL_CODE": "8011",
            "MAIN_PHONE": "00359896661777",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "office@seal-bs.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Burgas",
            "COMPAGNY_NAME": "Seal LTD",
            "LATITUDE": "42.446388",
            "LONGITUDE": "27.433462",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Kiril Kovtorov",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:30:::18:00,2:8:30:::18:00,3:8:30:::18:00,4:8:30:::18:00,5:8:30:::18:00,6::::,7::::"
        },
        {
            "ID": 1008,
            "ADDRESS_LINE_1": "Gredored 6",
            "ADDRESS_LINE_2": "",
            "CITY": "Ruse",
            "COUNTRY": "",
            "COUNTRY_CODE": "BG",
            "POSTAL_CODE": "7009",
            "MAIN_PHONE": "00359879869600",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "office@climaservice-bg.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Ruse",
            "COMPAGNY_NAME": "Termokraft EOOD",
            "LATITUDE": "43.85311",
            "LONGITUDE": "26.02836",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Anatoly Obreskov",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::14:00,7::::"
        },
        {
            "ID": 1009,
            "ADDRESS_LINE_1": "Klokotnica 99",
            "ADDRESS_LINE_2": "",
            "CITY": "Jambol",
            "COUNTRY": "",
            "COUNTRY_CODE": "BG",
            "POSTAL_CODE": "8600",
            "MAIN_PHONE": "00359882284987 ",
            "ALT_PHONE": "00359887935320",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "office@ivago-bg.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Jambol",
            "COMPAGNY_NAME": "Ivago Bulgaria Ltd",
            "LATITUDE": "42.468891",
            "LONGITUDE": "26.49206",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Aleksandar Stoikov",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:30,2:8:00:::16:30,3:8:00:::16:30,4:8:00:::16:30,5:8:00:::16:30,6::::,7::::"
        },
        {
            "ID": 1045,
            "ADDRESS_LINE_1": "Oborishte 4 Street",
            "ADDRESS_LINE_2": "",
            "CITY": "Veliko Tarnovo",
            "COUNTRY": "",
            "COUNTRY_CODE": "BG",
            "POSTAL_CODE": "5000",
            "MAIN_PHONE": "+359876572092",
            "ALT_PHONE": "+359888977107",
            "MOBILE_PHONE": "+359888977107",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "bulfrigo.d@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Veliko Tarnovo",
            "COMPAGNY_NAME": "Bulfrigo LLC",
            "LATITUDE": "43.071359",
            "LONGITUDE": "25.555591",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Dimitar Lazarov",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:30,2:8:30:::17:30,3:8:30:::17:30,4:8:30:::17:30,5:8:30:::17:30,6::::,7::::"
        },
        {
            "ID": 15,
            "ADDRESS_LINE_1": "Po Box 70242",
            "ADDRESS_LINE_2": "",
            "CITY": "Sitra-Ma'Ameer",
            "COUNTRY": "Bahrain",
            "COUNTRY_CODE": "BH",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+973) 17703045",
            "ALT_PHONE": "(+973) 39785177",
            "MOBILE_PHONE": "(+973) 39785177",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+973) 17703084",
            "EMAIL_ADDRESS": "info@cgsbah.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Sitra-Ma'Ameer",
            "COMPAGNY_NAME": "CGS (COLDSTORES GROUP OF SAUDI ARABIA)",
            "LATITUDE": "26.156709",
            "LONGITUDE": "50.621384",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr Irshad ANNAN",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": ""
        },
        {
            "ID": 836,
            "ADDRESS_LINE_1": "M1 Nkoyaaphiri",
            "ADDRESS_LINE_2": "Mogoditshane",
            "CITY": "Gaborone",
            "COUNTRY": "Botswana",
            "COUNTRY_CODE": "BW",
            "POSTAL_CODE": "00267",
            "MAIN_PHONE": "+26 771 802737",
            "ALT_PHONE": "+27 0800004503",
            "MOBILE_PHONE": "+26 769 79255",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "davis.dzuda@yahpp.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Gaborone",
            "COMPAGNY_NAME": "TEDROCK",
            "LATITUDE": "-24.63",
            "LONGITUDE": "25.83",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Davis DZUDA",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:6:30:16:30,2:6:30:16:30,3:6:30:16:30,4:6:30:16:30,5:6:30:16:30"
        },
        {
            "ID": 16,
            "ADDRESS_LINE_1": "10 km of Road M2 Minsk-National airport Minsk",
            "ADDRESS_LINE_2": "",
            "CITY": "Minsk Region",
            "COUNTRY": "Belarus",
            "COUNTRY_CODE": "BY",
            "POSTAL_CODE": "220000",
            "MAIN_PHONE": "(+ 375) 17 266 74 26",
            "ALT_PHONE": "(+375) 29 683 45 10",
            "MOBILE_PHONE": " (+375) 29 683 45 10",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+375) 17 266 08 25",
            "EMAIL_ADDRESS": "dydyshko@carrierbel.by",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Minsk",
            "COMPAGNY_NAME": "PUE BELTRANSKHOLOD",
            "LATITUDE": "53.968999",
            "LONGITUDE": "27.745215",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sergey DYDYSHKO, Ivan ZENCHENKO",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:30,2:8:30:::17:30,3:8:30:::17:30,4:8:30:::17:30,5:8:30:::17:30,6::::,7::::"
        },
        {
            "ID": 895,
            "ADDRESS_LINE_1": "Gorodskaya Str, 76",
            "ADDRESS_LINE_2": "",
            "CITY": "Brest",
            "COUNTRY": "",
            "COUNTRY_CODE": "BY",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+375) 33 6703670",
            "ALT_PHONE": "(+375) 29 8 044 404",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+375) 33 6 703 670",
            "EMAIL_ADDRESS": "sp_kurs@mail.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Brest",
            "COMPAGNY_NAME": "JV Kurs LLC",
            "LATITUDE": "52.135005",
            "LONGITUDE": "23.742708",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Gennadiy Hobotenko",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 934,
            "ADDRESS_LINE_1": "Parkovaya street, 9 D, Dachnaya Village",
            "ADDRESS_LINE_2": "Orsha district",
            "CITY": "Vitebsk region",
            "COUNTRY": "",
            "COUNTRY_CODE": "BY",
            "POSTAL_CODE": "211386",
            "MAIN_PHONE": "+375295551275",
            "ALT_PHONE": "+375292127028",
            "MOBILE_PHONE": "+375297106819",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "avtoforservice@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Vitebsk region",
            "COMPAGNY_NAME": "Autoforservice LLC",
            "LATITUDE": "54.499393",
            "LONGITUDE": "30.341898",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Filippov Andrey Mikhailovich",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::21:00,2:9:00:::21:00,3:9:00:::21:00,4:9:00:::21:00,5:9:00:::21:00,6:9:00:::21:00,7:9:00:::21:00"
        },
        {
            "ID": 517,
            "ADDRESS_LINE_1": "Rütmatt",
            "ADDRESS_LINE_2": "",
            "CITY": "Ruswil",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "6017",
            "MAIN_PHONE": "(+41) 041 496 96 96",
            "ALT_PHONE": "(+41) 079 204 66 88",
            "MOBILE_PHONE": " (+41) 079 204 66 88",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "(+41) 041 496 96 90",
            "EMAIL_ADDRESS": "b.buehler@eurobus.ch",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Luzern",
            "COMPAGNY_NAME": "ROTTAL AUTO AG",
            "LATITUDE": "47.079328",
            "LONGITUDE": "8.137221",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. BÜHLER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:12:00:13:30:17:30,2:7:00:12:00:13:30:17:30,3:7:00:12:00:13:30:17:30,4:7:00:12:00:13:30:17:30,5:7:00:12:00:13:30:17:30,6::::,7::::"
        },
        {
            "ID": 518,
            "ADDRESS_LINE_1": "Grossmattstrasse 13",
            "ADDRESS_LINE_2": "",
            "CITY": "Rudolfstetten",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "8964",
            "MAIN_PHONE": "(+41) 056 633 36 45",
            "ALT_PHONE": "(+41) 056 633 36 45",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "(+41) 056 633 36 48",
            "EMAIL_ADDRESS": "beat.schmid@wernergehrig.ch",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Zurich",
            "COMPAGNY_NAME": "Werner GEHRIG",
            "LATITUDE": "47.375593",
            "LONGITUDE": "8.388017",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Beat SCHMID ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:12:00:13:00:17:00,2:7:00:12:00:13:00:17:00,3:7:00:12:00:13:00:17:00,4:7:00:12:00:13:00:17:00,5:7:00:12:00:13:00:17:00"
        },
        {
            "ID": 519,
            "ADDRESS_LINE_1": "Kühlhausstrasse",
            "ADDRESS_LINE_2": "",
            "CITY": "Gossau",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "9200",
            "MAIN_PHONE": "(+41) 071 380 05 36",
            "ALT_PHONE": "(+41) 071 380 05 36",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "(+41) 071 380 05 39",
            "EMAIL_ADDRESS": "ruedi.reusser@emilfrey.ch",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "St. Gallen",
            "COMPAGNY_NAME": "EMIL FREY AG",
            "LATITUDE": "47.409499",
            "LONGITUDE": "9.283082",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ruedi REUSSER ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:12:00:13:00:17:00,2:7:00:12:00:13:00:17:00,3:7:00:12:00:13:00:17:00,4:7:00:12:00:13:00:17:00,5::::,6::::,7::::"
        },
        {
            "ID": 521,
            "ADDRESS_LINE_1": "Oberaustrasse 2",
            "ADDRESS_LINE_2": "",
            "CITY": "Trimmis",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "7203",
            "MAIN_PHONE": "(+41) 081 354 99 88",
            "ALT_PHONE": "(+41) 081 354 99 70",
            "MOBILE_PHONE": " (+41) 079 681 20 28",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "(+41) 081 354 99 89",
            "EMAIL_ADDRESS": " info@mayertrimmis.ch",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Chur",
            "COMPAGNY_NAME": "MAYER  AG",
            "LATITUDE": "46.906345",
            "LONGITUDE": "9.557333",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Hans BACHMANN ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:12:00:13:30:19:00,2:7:30:12:00:13:30:19:00,3:7:30:12:00:13:30:19:00,4:7:30:12:00:13:30:19:00,5:7:30:12:00:13:30:16:00"
        },
        {
            "ID": 522,
            "ADDRESS_LINE_1": "Via San Mamete",
            "ADDRESS_LINE_2": "",
            "CITY": "Mezzovico",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "6805",
            "MAIN_PHONE": "(+41) 091 935 96 00",
            "ALT_PHONE": "(+41) 091 935 96 12",
            "MOBILE_PHONE": "(+41) 079 207 25 26",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "(+41) 091 935 96 01",
            "EMAIL_ADDRESS": "a.schnoz@autoag.ch",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Ticino",
            "COMPAGNY_NAME": "AUTO SA TICINO",
            "LATITUDE": "46.091523",
            "LONGITUDE": "8.920931",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Alfred SCHNOZ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:12:00:13:00:18:00,2:7:30:12:00:13:00:17:30,3:7:30:12:00:13:00:17:30,4:7:30:12:00:13:00:17:30,5:7:30:12:00"
        },
        {
            "ID": 523,
            "ADDRESS_LINE_1": "Route de la Gruyere 12",
            "ADDRESS_LINE_2": "",
            "CITY": "Fribourg",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "1709",
            "MAIN_PHONE": "(+41) 026 424 38 08",
            "ALT_PHONE": "(+41) 079 447 38 08",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "(+41) 026 424 78 58",
            "EMAIL_ADDRESS": "angelozph@vtxmail.ch",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Fribourg",
            "COMPAGNY_NAME": "PHILIPPE ANGELOZ SA",
            "LATITUDE": "46.79414",
            "LONGITUDE": "7.141634",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Christophe ANGELOZ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:12:00:13:30:18:00,2:7:30:12:00:13:30:18:00,3:7:30:12:00:13:30:18:00,4:7:30:12:00:13:30:18:00,5:7:30:12:00:13:30:18:00"
        },
        {
            "ID": 526,
            "ADDRESS_LINE_1": "Sägeweg 8",
            "ADDRESS_LINE_2": "",
            "CITY": "Studen",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "2557",
            "MAIN_PHONE": "(+41) 032 374 74 20",
            "ALT_PHONE": "(+41) 079 330 25 05",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "(+41) 032 374 74 21",
            "EMAIL_ADDRESS": "roland.schoeni@camionrep.ch",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bern",
            "COMPAGNY_NAME": "CAMIONREP AG STUDEN ",
            "LATITUDE": "47.110268",
            "LONGITUDE": "7.310479",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Roland  SCHÖNI ",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::18:00,2:7:00:::18:00,3:7:00:::18:00,4:7:00:::18:00,5:7:00:::18:00,6::::,7::::"
        },
        {
            "ID": 528,
            "ADDRESS_LINE_1": "Pomona 14",
            "ADDRESS_LINE_2": "",
            "CITY": "Visp",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "3930",
            "MAIN_PHONE": "(+41) 027 948 08 50",
            "ALT_PHONE": "(+41) 079 628 42 30",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "(+41) 027 948 08 55",
            "EMAIL_ADDRESS": "visp@garagesauber.ch",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Brig",
            "COMPAGNY_NAME": "GARAGE SAURER S.A.",
            "LATITUDE": "46.30068",
            "LONGITUDE": "7.864752",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Daniel CLEMENZ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:12:00:13:30:17:30,2:7:30:12:00:13:30:17:30,3:7:30:12:00:13:30:17:30,4:7:30:12:00:13:30:17:30,5:7:30:12:00:13:30:17:30"
        },
        {
            "ID": 529,
            "ADDRESS_LINE_1": "Flachsackerstrasse 10",
            "ADDRESS_LINE_2": "",
            "CITY": "Frenkendorf",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "4402",
            "MAIN_PHONE": "(+41) 061 906 96 96",
            "ALT_PHONE": "(+41) 061 901 83 83",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "(+41) 061 901 20 22",
            "EMAIL_ADDRESS": "info@neftruckcenter.ch",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Basel",
            "COMPAGNY_NAME": "NEF TRUCKCENTER AG",
            "LATITUDE": "47.514643",
            "LONGITUDE": "7.718346",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::18:00,2:7:00:::18:00,3:7:00:::18:00,4:7:00:::18:00,5:7:00:::18:00,6:7:00:12:00::,7::::"
        },
        {
            "ID": 532,
            "ADDRESS_LINE_1": "Rue des Peupliers 14",
            "ADDRESS_LINE_2": "",
            "CITY": "Conthey",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "1964",
            "MAIN_PHONE": "(+41) 027 345 41 41",
            "ALT_PHONE": "(+41) 079 615 01 69",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "(+41) 027 345 41 40",
            "EMAIL_ADDRESS": "admin@garagesauber.ch",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Conthey",
            "COMPAGNY_NAME": "GARAGE SAURER S.A.",
            "LATITUDE": "46.223931",
            "LONGITUDE": "7.311412",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Fabien DUBUIS",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:12:00:13:30:17:30,2:7:30:12:00:13:30:17:30,3:7:30:12:00:13:30:17:30,4:7:30:12:00:13:30:17:30,5:7:30:12:00:13:30:17:30"
        },
        {
            "ID": 533,
            "ADDRESS_LINE_1": "Stationsstrasse 88",
            "ADDRESS_LINE_2": "",
            "CITY": "Rothenburg",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "6023",
            "MAIN_PHONE": "(+41) 041 262 09 06",
            "ALT_PHONE": "(+41) 079 341 95 49",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "(+41) 041 262 09 07",
            "EMAIL_ADDRESS": "m.estermann@cooltrans.ch",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Rothenburg",
            "COMPAGNY_NAME": "COOLTRANS AG",
            "LATITUDE": "47.095604",
            "LONGITUDE": "8.253136",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mark ESTERMANN",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:12:00:13:00:17:30,2:7:30:12:00:13:00:17:30,3:7:30:12:00:13:00:17:30,4:7:30:12:00:13:00:17:30,5:7:30:12:00:13:00:16:30,6::::,7::::"
        },
        {
            "ID": 534,
            "ADDRESS_LINE_1": "42, rue de la Bergere 42",
            "ADDRESS_LINE_2": "",
            "CITY": "Meyrin",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "1217",
            "MAIN_PHONE": "(+41) 022 989 39 10",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "(+41) 022 989 39 18",
            "EMAIL_ADDRESS": "geneve@larag.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Geneve",
            "COMPAGNY_NAME": "LARAG SA SATIGNY",
            "LATITUDE": "46.224607",
            "LONGITUDE": "6.055827",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Pierre VIVET",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:19:00,2:7:00:19:00,3:7:00:19:00,4:7:00:19:00,5:7:00:19:00"
        },
        {
            "ID": 662,
            "ADDRESS_LINE_1": "Goldschlagistrasse 19",
            "ADDRESS_LINE_2": "Zweigniederlassung NF",
            "CITY": "Schlieren",
            "COUNTRY": "Switzerland",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "8952",
            "MAIN_PHONE": "(+41) 044 738 38 38",
            "ALT_PHONE": "(+41) 044 738 38 38",
            "MOBILE_PHONE": "(+41) 044 738 38 38",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Zurich",
            "COMPAGNY_NAME": "MERCEDES-BENZ AUTOMOBIL AG",
            "LATITUDE": "47.401016",
            "LONGITUDE": "8.439123",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::19:00,2:7:00:::19:00,3:7:00:::19:00,4:7:00:::19:00,5:7:00:::19:00,6:7:30:12:00:12:30:16:00,7::::"
        },
        {
            "ID": 877,
            "ADDRESS_LINE_1": "Russmatten 32",
            "ADDRESS_LINE_2": "",
            "CITY": "Härkingen",
            "COUNTRY": "",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "4624",
            "MAIN_PHONE": "+41 62 388 99 77",
            "ALT_PHONE": "+41 79 301 89 25",
            "MOBILE_PHONE": "+41 76 674 19 99",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "+41 62 388 99 79",
            "EMAIL_ADDRESS": "rleuenberger@transcold.ch",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Härkingen",
            "COMPAGNY_NAME": "TRANSCOLD AG",
            "LATITUDE": "47.315877",
            "LONGITUDE": "7.811286",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Roland Leuenberger",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:12:00:13:30:17:30,2:7:30:12:00:13:30:17:30,3:7:30:12:00:13:30:17:30,4:7:30:12:00:13:30:17:30,5:7:30:12:00:13:30:17:30,6::::,7::::"
        },
        {
            "ID": 883,
            "ADDRESS_LINE_1": "Route de la Venoge 3",
            "ADDRESS_LINE_2": "",
            "CITY": "Aclens",
            "COUNTRY": "",
            "COUNTRY_CODE": "CH ",
            "POSTAL_CODE": "1123",
            "MAIN_PHONE": "0041 21 867 00 11",
            "ALT_PHONE": "+41 79 436 68 47",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 838839",
            "FAX_PHONE": "0041 21 732 12 32",
            "EMAIL_ADDRESS": "Info.atelier.aclens@volvo.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Aclens",
            "COMPAGNY_NAME": "Volvo Group (Suisse) SA - Truck Center Aclens",
            "LATITUDE": "46.562754",
            "LONGITUDE": "6.524761",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Serge Philipona",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:15:12:00:13:00:18:00,2:7:15:12:00:13:00:18:00,3:7:15:12:00:13:00:18:00,4:7:15:12:00:13:00:18:00,5:7:15:12:00:13:00:18:00,6:7:00:12:00::,7::::"
        },
        {
            "ID": 40,
            "ADDRESS_LINE_1": "Nupaky 310",
            "ADDRESS_LINE_2": "Obchodni zona",
            "CITY": "Nupaky",
            "COUNTRY": "Czech Republic",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "251 01",
            "MAIN_PHONE": "(+420) 602 181 496",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+420) 602 270 004",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "michal.lnenicka@bschlazeni.cz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Praha",
            "COMPAGNY_NAME": "BS – Transportni chlazeni spol. s r.o.",
            "LATITUDE": "49.990581",
            "LONGITUDE": "14.598577",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Michal LNENICKA",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 11,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:00:::18:00,2:7:00:::18:00,3:7:00:::18:00,4:7:00:::18:00,5:7:00:::18:00,6::::,7::::"
        },
        {
            "ID": 41,
            "ADDRESS_LINE_1": "Ksirova 259",
            "ADDRESS_LINE_2": "",
            "CITY": "Horni Herspice",
            "COUNTRY": "Czech Republic",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "619 00",
            "MAIN_PHONE": "(+420) 602 624 089",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@frigocar.cz",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Brno",
            "COMPAGNY_NAME": "Frigocar s.r.o.",
            "LATITUDE": "49.161832",
            "LONGITUDE": "16.620066",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Martin DVORAK",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:00:::15:30,2:7:00:::15:30,3:7:00:::15:30,4:7:00:::15:30,5:7:00:::15:30,6::::,7::::"
        },
        {
            "ID": 42,
            "ADDRESS_LINE_1": "Vostice 112/IV",
            "ADDRESS_LINE_2": "",
            "CITY": "Vysoke Myto",
            "COUNTRY": "Czech Republic",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "566 01",
            "MAIN_PHONE": "(+420) 724 242 343",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+420) 602 144 616 ",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+420) 465 422 277",
            "EMAIL_ADDRESS": "roman.mara@bschlazeni.cz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Vysoke Myto",
            "COMPAGNY_NAME": "BS – Transportni chlazeni spol. s r.o.",
            "LATITUDE": "49.924842",
            "LONGITUDE": "16.185844",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Roman MARA",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::18:00,2:7:00:::18:00,3:7:00:::18:00,4:7:00:::18:00,5:7:00:::18:00,6::::,7::::"
        },
        {
            "ID": 43,
            "ADDRESS_LINE_1": "Palhanecka 15",
            "ADDRESS_LINE_2": "",
            "CITY": "Opava",
            "COUNTRY": "Czech Republic",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "747 07",
            "MAIN_PHONE": "(+420) 602 489 145",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+420) 602 489 145 ",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+420) 553 752 171",
            "EMAIL_ADDRESS": "freecold@email.cz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Opava",
            "COMPAGNY_NAME": "FREECOLD S.R.O",
            "LATITUDE": "49.956894",
            "LONGITUDE": "17.8757",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Michal ULBRICHT",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::15:30,2:7:00:::15:30,3:7:00:::15:30,4:7:00:::15:30,5:7:00:::15:30,6::::,7::::"
        },
        {
            "ID": 45,
            "ADDRESS_LINE_1": "Areal EUROVIA",
            "ADDRESS_LINE_2": "U Dálnice 261",
            "CITY": "Chlumec u Ústí nad Labem",
            "COUNTRY": "Czech Republic",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "403 39",
            "MAIN_PHONE": "(+420) 602 279 209",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+420) 602 279 209",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+420 475 656 252",
            "EMAIL_ADDRESS": "tomas.cernik@seznam.cz",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Chlumec u Ústí nad Labem",
            "COMPAGNY_NAME": "TOMAS CERNIK",
            "LATITUDE": "50.705061",
            "LONGITUDE": "13.934183",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tomas CERNIK",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6::::,7::::"
        },
        {
            "ID": 46,
            "ADDRESS_LINE_1": "Hnevotinska 530 /53",
            "ADDRESS_LINE_2": "",
            "CITY": "Olomouc",
            "COUNTRY": "Czech Republic",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "779 00",
            "MAIN_PHONE": "(+420) 602 489 174",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+420) 585 316 570",
            "EMAIL_ADDRESS": "autoterm.sro@seznam.cz",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Olomouc",
            "COMPAGNY_NAME": "AUTOTERM BECICA & SEMBERA S.R.O.",
            "LATITUDE": "49.583172",
            "LONGITUDE": "17.222679",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Petr BECICA",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6::::,7::::"
        },
        {
            "ID": 47,
            "ADDRESS_LINE_1": "Slovanska alej 32",
            "ADDRESS_LINE_2": "",
            "CITY": "Plzen",
            "COUNTRY": "Czech Republic",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "326 00",
            "MAIN_PHONE": "(+420) 724 537 672",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+420) 602 160 321",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+420) 377 482 174",
            "EMAIL_ADDRESS": "info@servischlazenisro.cz",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Plzen",
            "COMPAGNY_NAME": "SERVIS CHLAZENI S.R.O",
            "LATITUDE": "49.726511",
            "LONGITUDE": "13.410144",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ales ORTINSKY, Radek KOCI",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::15:30,2:7:00:::15:30,3:7:00:::15:30,4:7:00:::15:30,5:7:00:::15:30,6::::,7::::"
        },
        {
            "ID": 48,
            "ADDRESS_LINE_1": "Hradecka 816",
            "ADDRESS_LINE_2": "",
            "CITY": "Jicin",
            "COUNTRY": "Czech Republic",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "506 01",
            "MAIN_PHONE": "(+420) 724 741 565",
            "ALT_PHONE": "(+420) 724 307 662",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Marek.gilar@cscargo.cz",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Jicin",
            "COMPAGNY_NAME": "C.S.CARGO a.s. - divize Truck Point",
            "LATITUDE": "50.434343",
            "LONGITUDE": "15.375087",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marek GILAR",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::16:30,2:7:00:::16:30,3:7:00:::16:30,4:7:00:::16:30,5:7:00:::16:30,6:6:00:::14:30,7::::"
        },
        {
            "ID": 738,
            "ADDRESS_LINE_1": "Luzicka 1645",
            "ADDRESS_LINE_2": "",
            "CITY": "Humpolec",
            "COUNTRY": "Czech Republic",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "396 01",
            "MAIN_PHONE": "(+420) 607 132 440",
            "ALT_PHONE": "(+420) 607 132 440",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "chlazeni@crplus.cz",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Humpolec",
            "COMPAGNY_NAME": "CR Plus s.r.o",
            "LATITUDE": "49.552222",
            "LONGITUDE": "15.356389",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Lukas Zacek",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:15:30,2:7:00:15:30,3:7:00:15:30,4:7:00:15:30,5:7:00:15:30"
        },
        {
            "ID": 810,
            "ADDRESS_LINE_1": "Libice nad Cidlinou 302",
            "ADDRESS_LINE_2": "",
            "CITY": "Libice n.C",
            "COUNTRY": "Czech Republic",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "28907",
            "MAIN_PHONE": "+420737250308 +420737250300 ",
            "ALT_PHONE": "+420325600786",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+420737250308 +420737250300 ",
            "EMAIL_ADDRESS": "info@burger-transport.cz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Libice n.C",
            "COMPAGNY_NAME": "BURGER Transport, s.r.o",
            "LATITUDE": "50.144639",
            "LONGITUDE": "15.174342",
            "DD": "0",
            "TRUCK": "0",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Milan BOUCEK",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::15:30,2:7:00:::15:30,3:7:00:::15:30,4:7:00:::15:30,5:7:00:::15:30,6::::,7::::"
        },
        {
            "ID": 811,
            "ADDRESS_LINE_1": "Nemanicka 2721",
            "ADDRESS_LINE_2": "",
            "CITY": "Ceske Budejovice ",
            "COUNTRY": "Czech Republic",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "37010",
            "MAIN_PHONE": "00420 602 272 950",
            "ALT_PHONE": "00420 602 473 869",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Jaroslav.vesely@transportklima.cz",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Ceske Budejovice ",
            "COMPAGNY_NAME": "Transport  Klima,s.r.o.",
            "LATITUDE": "49.002586",
            "LONGITUDE": "14.480171",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "JaroslavVesely",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::15:30,2:7:00:::15:30,3:7:00:::15:30,4:7:00:::15:30,5:7:00:::15:30,6::::,7::::"
        },
        {
            "ID": 997,
            "ADDRESS_LINE_1": "K Cihelnám 100",
            "ADDRESS_LINE_2": "",
            "CITY": "Plzeň Černice",
            "COUNTRY": "",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "32600",
            "MAIN_PHONE": "+420 606753597",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Jarda@chlazeniplzen.cz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Plzeň Černice",
            "COMPAGNY_NAME": "Chalzeni Plzen ",
            "LATITUDE": "49.689266",
            "LONGITUDE": "13.425152",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jaroslav Schreiber",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::15:30,2:7:00:::15:30,3:7:00:::15:30,4:7:00:::15:30,5:7:00:::15:30,6::::,7::::"
        },
        {
            "ID": 1022,
            "ADDRESS_LINE_1": "Hlinska 457/2a",
            "ADDRESS_LINE_2": "",
            "CITY": "Ceske Budejovice",
            "COUNTRY": "",
            "COUNTRY_CODE": "CZ ",
            "POSTAL_CODE": "370 01",
            "MAIN_PHONE": "+420 724 261 155, +420 606 676 005",
            "ALT_PHONE": "+420 725 940 380",
            "MOBILE_PHONE": "+420 724 261 155",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "lidral@nicotrans.cz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Ceske Budejovice",
            "COMPAGNY_NAME": "Nicotrans a.s.",
            "LATITUDE": "48.979768",
            "LONGITUDE": "14.502821",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Papaj Milan, Lidral Jan",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::19:30,2:7:00:::19:30,3:7:00:::19:30,4:7:00:::19:30,5:7:00:::19:30,6:7:00:::19:30,7:7:00:::19:30"
        },
        {
            "ID": 141,
            "ADDRESS_LINE_1": "Eichenstrasse C 2",
            "ADDRESS_LINE_2": "",
            "CITY": "Grünheide ",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "15537",
            "MAIN_PHONE": "(+49) 3362 79790",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 3362 797917",
            "EMAIL_ADDRESS": "carsten.heymann@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Berlin",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD DEUTSCHLAND GmbH ",
            "LATITUDE": "52.417674",
            "LONGITUDE": "13.802535",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Carsten HEYMANN",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 142,
            "ADDRESS_LINE_1": "Moorfleeter strasse 45",
            "ADDRESS_LINE_2": "",
            "CITY": "Hamburg",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "22113",
            "MAIN_PHONE": "(+49) 40 7313453",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "0",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 40 7322587",
            "EMAIL_ADDRESS": "heiner.kalck@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Hamburg",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD DEUTSCHLAND GmbH ",
            "LATITUDE": "53.523959",
            "LONGITUDE": "10.091947",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Heiner Kalck",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 144,
            "ADDRESS_LINE_1": "Galileistrasse 3",
            "ADDRESS_LINE_2": "",
            "CITY": "Hannover - Langenhagen",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "30853",
            "MAIN_PHONE": "(+49) 511 519335 0",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 511 519335  24",
            "EMAIL_ADDRESS": "balzer@truckxxgroup.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Hannover",
            "COMPAGNY_NAME": "TRANSPORTKÜHLUNG HANNOVER GMBH",
            "LATITUDE": "52.454732",
            "LONGITUDE": "9.725359",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. BALZER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:7:30:::18:00,2:7:30:::18:00,3:7:30:::18:00,4:7:30:::18:00,5:7:30:::18:00,6:7:30:12:30::,7::::"
        },
        {
            "ID": 146,
            "ADDRESS_LINE_1": "Lübecker Strasse 5-7",
            "ADDRESS_LINE_2": "",
            "CITY": "Georgsmarienhütte",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "49124",
            "MAIN_PHONE": "(+49) 5401 485252",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 5401 485253",
            "EMAIL_ADDRESS": "Arne.Jansen@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Osnabrück",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD DEUTSCHLAND GmbH ",
            "LATITUDE": "52.224934",
            "LONGITUDE": "8.086174",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Arne Jansen ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 9,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 147,
            "ADDRESS_LINE_1": "Meinhardstrasse 7",
            "ADDRESS_LINE_2": "",
            "CITY": "Dortmund",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "44379",
            "MAIN_PHONE": "(+49) 231 5890003",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 231 5890005",
            "EMAIL_ADDRESS": "service@fahrzeugkaelte.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Dortmund",
            "COMPAGNY_NAME": "FAHRZEUGKAELTE DORTMUND  GMBH",
            "LATITUDE": "51.507113",
            "LONGITUDE": "7.40103",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. AIGNER ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:30,2:8:00:::16:30,3:8:00:::16:30,4:8:00:::16:30,5:8:00:::16:30,6::::,7::::"
        },
        {
            "ID": 148,
            "ADDRESS_LINE_1": "Karl-Morian-Straße 34a",
            "ADDRESS_LINE_2": "",
            "CITY": "Duisburg",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "47167",
            "MAIN_PHONE": "(+49) 203 5180848",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 203 5180849",
            "EMAIL_ADDRESS": "info@westkaelte-duisburg.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Duisburg",
            "COMPAGNY_NAME": "WESTKÄLTE GMBH",
            "LATITUDE": "51.489106",
            "LONGITUDE": "6.793676",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. SPÖNEMANN",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 149,
            "ADDRESS_LINE_1": "Opel Strasse 7 - 11",
            "ADDRESS_LINE_2": "",
            "CITY": "Heppenheim",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "64646",
            "MAIN_PHONE": "(+49) 6252 99 680",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 6252 99 6820",
            "EMAIL_ADDRESS": "norman.gaul@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Heppenheim",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD DEUTSCHLAND GmbH",
            "LATITUDE": "49.646555",
            "LONGITUDE": "8.625437",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Norman Gaul",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 10,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 150,
            "ADDRESS_LINE_1": "Ecke Merziger - Koblenzer Straße",
            "ADDRESS_LINE_2": "",
            "CITY": "Saarbrücken",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "66115",
            "MAIN_PHONE": "(+49) 681 99267 0",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 681 99267 22",
            "EMAIL_ADDRESS": "info@boschservice-metzinger.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Saarbrücken",
            "COMPAGNY_NAME": "METZINGER GMBH",
            "LATITUDE": "49.241215",
            "LONGITUDE": "6.938993",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. DIESEL, Mrs. BURKHART",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:7:30:::18:00,2:7:30:::18:00,3:7:30:::18:00,4:7:30:::18:00,5:7:30:::18:00,6:8:00:::13:00,7::::"
        },
        {
            "ID": 151,
            "ADDRESS_LINE_1": "Weidacherstrasse 14",
            "ADDRESS_LINE_2": "",
            "CITY": "Filderstadt",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "70794",
            "MAIN_PHONE": "(+49) 711 2030080",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 711 20300845",
            "EMAIL_ADDRESS": "info@tkv-stuttgart.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Stuttgart",
            "COMPAGNY_NAME": "TKV Stuttgart Transportkühlmaschinen Vertriebsgesellschaft mbH",
            "LATITUDE": "48.675265",
            "LONGITUDE": "9.202726",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr GRAU",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 152,
            "ADDRESS_LINE_1": "Gewerbebogen 4",
            "ADDRESS_LINE_2": "",
            "CITY": "Forstinning",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "85661",
            "MAIN_PHONE": "+49 8121 2259914",
            "ALT_PHONE": "+49 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "+49 8121 2259915",
            "EMAIL_ADDRESS": "michael.zwerger@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "München",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD DEUTSCHLAND GmbH",
            "LATITUDE": "48.171051",
            "LONGITUDE": "11.920681",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Michael ZWERGER ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 9,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 153,
            "ADDRESS_LINE_1": "Polyphonstrasse 2 ",
            "ADDRESS_LINE_2": "",
            "CITY": "Leipzig ",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "04159",
            "MAIN_PHONE": "(+49) 1622754 384",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+49) 1622754 384",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Torsten.maier@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Leipzig ",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD DEUTSCHLAND GmbH ",
            "LATITUDE": "51.0",
            "LONGITUDE": "12.31503",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Torsten MAIER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 154,
            "ADDRESS_LINE_1": "Neuenweiherstraße 15",
            "ADDRESS_LINE_2": "",
            "CITY": "Erlangen",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "91056",
            "MAIN_PHONE": "(+49) 9131 992939",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 9131 991305",
            "EMAIL_ADDRESS": "frankenkaelte@t-online.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Nürnberg",
            "COMPAGNY_NAME": "FRANKENKÄLTE FAHRZEUGKÜHLUNG OHG",
            "LATITUDE": "49.559158",
            "LONGITUDE": "10.964162",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. KIRCHBACH, Mr. FELLINGER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 155,
            "ADDRESS_LINE_1": "An der Irler Höhe 40",
            "ADDRESS_LINE_2": "",
            "CITY": "Regensburg",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "93055",
            "MAIN_PHONE": "(+49) 941 6048 7640",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 941 6048 7641",
            "EMAIL_ADDRESS": "frankenkaelte@t-online.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Regensburg",
            "COMPAGNY_NAME": "FRANKENKÄLTE FAHRZEUGKÜHLUNG OHG",
            "LATITUDE": "49.012689",
            "LONGITUDE": "12.139367",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. KIRCHBACH, Mr. FELLINGER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 156,
            "ADDRESS_LINE_1": "An der Autobahn 52-54",
            "ADDRESS_LINE_2": "",
            "CITY": "Oyten",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "28876",
            "MAIN_PHONE": "(+49) 4207 9171833",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 4207 9171811",
            "EMAIL_ADDRESS": "uwe.wulff@anders-kraftfahrzeuge.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Bremen / Oyten",
            "COMPAGNY_NAME": "ANDERS KRAFTFAHRZEUGE GmbH",
            "LATITUDE": "53.058059",
            "LONGITUDE": "9.048958",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. WULFF",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:8:00:::13:00,7::::"
        },
        {
            "ID": 158,
            "ADDRESS_LINE_1": "Kurschnerwende 2",
            "ADDRESS_LINE_2": "",
            "CITY": "Lübeck",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "23556",
            "MAIN_PHONE": "(+49) 451 89951 0",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 451 89951 92",
            "EMAIL_ADDRESS": "jens.burgstaller@man.eu",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Lübeck",
            "COMPAGNY_NAME": "MAN TRUCK UND BUS DEUTSCHLAND GMBH",
            "LATITUDE": "53.868661",
            "LONGITUDE": "10.610561",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Mr. BURGSTALLER",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::20:00,2:7:00:::20:00,3:7:00:::20:00,4:7:00:::20:00,5:7:00:::20:00,6:7:30:::14:00,7::::"
        },
        {
            "ID": 159,
            "ADDRESS_LINE_1": "Robert-Bosch-Straße 18",
            "ADDRESS_LINE_2": "",
            "CITY": "Köln",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "50769",
            "MAIN_PHONE": "(+49) 221 8013888",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 221 8013889",
            "EMAIL_ADDRESS": "carrier@eigro.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Köln",
            "COMPAGNY_NAME": "EIGRO TRANSPORTKÄLTE GMBH",
            "LATITUDE": "51.036152",
            "LONGITUDE": "6.92349",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. KELLNER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:8:00:::12:00,7::::"
        },
        {
            "ID": 160,
            "ADDRESS_LINE_1": "Maxhütte - Gewerbering 16",
            "ADDRESS_LINE_2": "",
            "CITY": "Zwickau ",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "08056",
            "MAIN_PHONE": "(+49) 375 295806",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "(+49) 171 6724387",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 375 295809",
            "EMAIL_ADDRESS": "ruelke-zw@t-online.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Zwickau ",
            "COMPAGNY_NAME": "RÜLKE KUHLANLAGEN GmbH",
            "LATITUDE": "50.704544",
            "LONGITUDE": "12.447596",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. Ulli RULKE",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::17:00,2:7:30:::17:00,3:7:30:::17:00,4:7:30:::17:00,5:7:30:::17:00,6::::,7::::"
        },
        {
            "ID": 163,
            "ADDRESS_LINE_1": "Zollerstraße 7",
            "ADDRESS_LINE_2": "",
            "CITY": "Aretsried",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "86850",
            "MAIN_PHONE": "(+49) 8236 999576",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 8236 999658",
            "EMAIL_ADDRESS": "Daniel.Hultsch@fta-gmbh.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Augsburg / Aretsried",
            "COMPAGNY_NAME": "FAHRZEUGTECHNIK  ARETSRIED GMBH",
            "LATITUDE": "48.308575",
            "LONGITUDE": "10.654228",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Daniel Hultsch",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 9,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 164,
            "ADDRESS_LINE_1": "Feld-Vorstmann-Straße 7",
            "ADDRESS_LINE_2": "",
            "CITY": "Bendorf",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "56170",
            "MAIN_PHONE": "(+49) 2622 6604",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 2622 6621",
            "EMAIL_ADDRESS": "info@hoefer-gmbh.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Koblenz / Bendorf",
            "COMPAGNY_NAME": "HÖFER GMBH",
            "LATITUDE": "50.427809",
            "LONGITUDE": "7.567391",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. HÖFER",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:30,2:8:00:::16:30,3:8:00:::16:30,4:8:00:::16:30,5:8:00:::16:30,6:8:00:12:00::,7::::"
        },
        {
            "ID": 165,
            "ADDRESS_LINE_1": "Bahnhofstrasse 90",
            "ADDRESS_LINE_2": "",
            "CITY": "Oerlinhausen",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "33813",
            "MAIN_PHONE": "(+49) 5205 98460",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 5205 984615",
            "EMAIL_ADDRESS": "sennestadt@bosch-ploeger.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Bielefeld ",
            "COMPAGNY_NAME": "KARL PLÖGER GMBH",
            "LATITUDE": "51.97985",
            "LONGITUDE": "8.71458",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. LESSMANN, Mr. PLÖGER",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:30,2:8:00:::17:30,3:8:00:::17:30,4:8:00:::17:30,5:8:00:::17:30,6:8:00:12:30::,7::::"
        },
        {
            "ID": 167,
            "ADDRESS_LINE_1": "Rottweiler Strasse 70 - 72",
            "ADDRESS_LINE_2": "",
            "CITY": "Villingen-Schwenningen",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "78056",
            "MAIN_PHONE": "(+49) 7720 9787 14",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 7720 978787",
            "EMAIL_ADDRESS": "lieb-eiskalt@web.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Villingen-Schenningen",
            "COMPAGNY_NAME": "LIEB KG",
            "LATITUDE": "48.064648",
            "LONGITUDE": "8.550727",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. Edgar LIEB",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::17:00,2:7:00:::17:00,3:7:00:::17:00,4:7:00:::17:00,5:7:00:::17:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 168,
            "ADDRESS_LINE_1": "Am Steinsfelder Wasser 15",
            "ADDRESS_LINE_2": "",
            "CITY": "Suhl",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "98527",
            "MAIN_PHONE": "(+49) 3681 420056",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 3681 420057",
            "EMAIL_ADDRESS": "thilo.hupe1@freenet.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Suhl",
            "COMPAGNY_NAME": "THILO HUPE FAHRZEUG - KUHL - HEIZ & HEBETECHNIK",
            "LATITUDE": "50.617327",
            "LONGITUDE": "10.646642",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. HUPE, Mrs. HUPE",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 171,
            "ADDRESS_LINE_1": "Nordfeldstrasse 2",
            "ADDRESS_LINE_2": "",
            "CITY": "Versmold",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "33775",
            "MAIN_PHONE": "(+49) 5423 5733",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 5423 930076",
            "EMAIL_ADDRESS": "tks@knemeyer.org",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Versmold",
            "COMPAGNY_NAME": "TKS KNEMEYER GmbH",
            "LATITUDE": "52.043768",
            "LONGITUDE": "8.147156",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. KNEMEYER",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 172,
            "ADDRESS_LINE_1": "Heerstraßenbenden 13a",
            "ADDRESS_LINE_2": "",
            "CITY": "Rheinbach",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "53359",
            "MAIN_PHONE": "(+49) 2226 16823 0",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 2226 16823 66",
            "EMAIL_ADDRESS": "carrier@eigro.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Rheinbach",
            "COMPAGNY_NAME": "EIGRO TRANSPORTKÄLTE GMBH",
            "LATITUDE": "50.635989",
            "LONGITUDE": "6.950161",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. RAMACHER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:8:00:::12:00,7::::"
        },
        {
            "ID": 177,
            "ADDRESS_LINE_1": "Am Hundesand 6",
            "ADDRESS_LINE_2": "",
            "CITY": "Lingen / Ems",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "49809",
            "MAIN_PHONE": "(+49) 591 912500",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "(+49) 170 2427754",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 591 9125046",
            "EMAIL_ADDRESS": "info@ortmann-lingen.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Lingen / Ems",
            "COMPAGNY_NAME": "ORTMANN AUTO TECHNIK GMBH",
            "LATITUDE": "52.519342",
            "LONGITUDE": "7.348459",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. ORTMANN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 178,
            "ADDRESS_LINE_1": "Richthofenstrasse 42",
            "ADDRESS_LINE_2": "",
            "CITY": "Kitzingen",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "97318",
            "MAIN_PHONE": "(+49) 9321 923081",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 9321 923083",
            "EMAIL_ADDRESS": "info@hartmann-transportkaelte.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kitzingen",
            "COMPAGNY_NAME": "HARTMANN TRANSPORTKALTE",
            "LATITUDE": "49.745476",
            "LONGITUDE": "10.170357",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jan HADASCHICK",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:30,2:8:30:::17:30,3:8:30:::17:30,4:8:30:::17:30,5:8:30:::17:30,6:8:30:12:30::,7::::"
        },
        {
            "ID": 179,
            "ADDRESS_LINE_1": "Kaiserstuhlstrasse 4-6",
            "ADDRESS_LINE_2": "",
            "CITY": "Freiburg",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "79106",
            "MAIN_PHONE": "(+49) 761 508811",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 761 506813",
            "EMAIL_ADDRESS": "bosch-service-reichenbach@t-online.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Freiburg",
            "COMPAGNY_NAME": "REICHENBACH BOSCH SERVICE - Inh Thomas Selzer",
            "LATITUDE": "48.009779",
            "LONGITUDE": "7.847049",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. SELZER, Mr. HERBSTRITT",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::17:00,2:7:30:::17:00,3:7:30:::17:00,4:7:30:::17:00,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 182,
            "ADDRESS_LINE_1": "Kaschowe Damm 1a",
            "ADDRESS_LINE_2": "",
            "CITY": "Grimmen",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "18507",
            "MAIN_PHONE": "+49 38326 456428",
            "ALT_PHONE": "+49 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "+49 38326 456427",
            "EMAIL_ADDRESS": "kuehlung@grimmen4411.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Grimmen",
            "COMPAGNY_NAME": "AUTO CENTER GRIMMEN GMBH & CO",
            "LATITUDE": "54.110835",
            "LONGITUDE": "13.055876",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. MULLER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::18:00,2:7:00:::18:00,3:7:00:::18:00,4:7:00:::18:00,5:7:00:::18:00,6::::,7::::"
        },
        {
            "ID": 183,
            "ADDRESS_LINE_1": "Meißner Weg 1",
            "ADDRESS_LINE_2": "",
            "CITY": "Nossen",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "01683",
            "MAIN_PHONE": "0049 35242 44810",
            "ALT_PHONE": "0049 1522 2740767",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(0049) 35242 448 11",
            "EMAIL_ADDRESS": "cris.schneider@man-bodenbach.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Nossen",
            "COMPAGNY_NAME": "Schneider Nutzfahrzeug Service GmbH",
            "LATITUDE": "51.079167",
            "LONGITUDE": "13.266667",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Cris Schneider",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::21:00,2:7:00:::21:00,3:7:00:::21:00,4:7:00:::21:00,5:7:00:::21:00,6:7:00:::15:00,7::::"
        },
        {
            "ID": 185,
            "ADDRESS_LINE_1": "Wellseedamm 15",
            "ADDRESS_LINE_2": "",
            "CITY": "Kiel",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "24145",
            "MAIN_PHONE": "(+49) 431 7198625",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 431 7198628",
            "EMAIL_ADDRESS": "info@kehs.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kiel",
            "COMPAGNY_NAME": "KEHS Transportkühlung & Nutzfahrzeugtechnik GmbH",
            "LATITUDE": "54.281888",
            "LONGITUDE": "10.148556",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. Diedrichsen",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 186,
            "ADDRESS_LINE_1": "Friedrich Stoll Strasse 22",
            "ADDRESS_LINE_2": "",
            "CITY": "Bad Nauheim",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "61231",
            "MAIN_PHONE": "(+49) 6032 9285013",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 6003 828467",
            "EMAIL_ADDRESS": "info@kaelte-nuhn.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bad Nauheim",
            "COMPAGNY_NAME": "HERBERT NUHN TRANSPORTKAELTE",
            "LATITUDE": "50.361202",
            "LONGITUDE": "8.754493",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Michael Nuhn",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 187,
            "ADDRESS_LINE_1": "Werkstrasse 1",
            "ADDRESS_LINE_2": "",
            "CITY": "Schuettorf",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "48465",
            "MAIN_PHONE": "(+49) 5923 9611 0",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "(+49) 151 12629033",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 5923 9611 21",
            "EMAIL_ADDRESS": "marco.kokkelink@schevel.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Schuettorf",
            "COMPAGNY_NAME": "Autohaus Schevel GmbH",
            "LATITUDE": "52.32944",
            "LONGITUDE": "7.24325",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marco KOKKELINK",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::20:30,2:7:30:::20:30,3:7:30:::20:30,4:7:30:::20:30,5:7:30:::20:30,6:7:30:12:30::,7::::"
        },
        {
            "ID": 189,
            "ADDRESS_LINE_1": "Wimpfener Str 120+122",
            "ADDRESS_LINE_2": "",
            "CITY": "Heilbronn",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "74078",
            "MAIN_PHONE": "(+49) 7131 29348",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 7131 29373",
            "EMAIL_ADDRESS": "speiser@weilbacher.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Heilbronn",
            "COMPAGNY_NAME": "AUTOHAUS R WEILBACHER GmbH",
            "LATITUDE": "49.18441",
            "LONGITUDE": "9.200621",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr SPEISER",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6:7:00:12:00::,7::::"
        },
        {
            "ID": 190,
            "ADDRESS_LINE_1": "Gewerbering 37",
            "ADDRESS_LINE_2": "",
            "CITY": "Lohne",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "49393",
            "MAIN_PHONE": "(+49) 4442  9860 0",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 4442 9860 66",
            "EMAIL_ADDRESS": "varol.uenal@boecker-gruppe.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Lohne",
            "COMPAGNY_NAME": "KAROSSERIE Und FAHRZEUGBAU BÖCKER GMBH",
            "LATITUDE": "52.65832",
            "LONGITUDE": "8.207431",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. Varol UNAL",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:17:00,6:7:30:12:00"
        },
        {
            "ID": 209,
            "ADDRESS_LINE_1": "Im Ochsenstall 6",
            "ADDRESS_LINE_2": "",
            "CITY": "Karlsdorf",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "76689",
            "MAIN_PHONE": "(+49) 7251 9706 0",
            "ALT_PHONE": "(+49) 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 7251 9706 50",
            "EMAIL_ADDRESS": "meister@STAIGER-gruppe.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Karlsruhe",
            "COMPAGNY_NAME": "STAIGER NUTZFAHRZEUGE GMBH",
            "LATITUDE": "49.147159",
            "LONGITUDE": "8.549638",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::19:00,2:7:30:::19:00,3:7:30:::19:00,4:7:30:::19:00,5:7:30:::19:00,6:7:30:::13:00,7::::"
        },
        {
            "ID": 729,
            "ADDRESS_LINE_1": "Frederikshavener Str.99",
            "ADDRESS_LINE_2": "",
            "CITY": "Bremerhaven",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "27574",
            "MAIN_PHONE": "0471 98400 0",
            "ALT_PHONE": "01520 1550106",
            "MOBILE_PHONE": "01520 1550089",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "0471 98400 10",
            "EMAIL_ADDRESS": "d.hahn@tiemann.eu",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Bremerhaven",
            "COMPAGNY_NAME": "W TIEMANN GmbH & Co KG",
            "LATITUDE": "53.497977",
            "LONGITUDE": "8.624844",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr Dennis HAHN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:17:00,2:7:30:17:00,3:7:30:17:00,4:7:30:17:00,5:7:30:20:00,6:7:30:17:00"
        },
        {
            "ID": 790,
            "ADDRESS_LINE_1": "Dierkower Damm 38c",
            "ADDRESS_LINE_2": "",
            "CITY": "Rostock",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "18146",
            "MAIN_PHONE": "+49 381 650025",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "+49 381 650093",
            "EMAIL_ADDRESS": "steffen.seher@man.eu",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Rostock",
            "COMPAGNY_NAME": "MAN TRUCK & BUS ",
            "LATITUDE": "54.101986",
            "LONGITUDE": "12.150529",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr SEHER",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::20:00,2:7:00:::20:00,3:7:00:::20:00,4:7:00:::20:00,5:7:00:::22:00,6:7:30:::15:30,7::::"
        },
        {
            "ID": 791,
            "ADDRESS_LINE_1": "18 Warnstedestrasse",
            "ADDRESS_LINE_2": "",
            "CITY": "Hamburg",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "22525",
            "MAIN_PHONE": "+49 40 547399",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "+49 547399 92",
            "EMAIL_ADDRESS": "jens.burgstaller@man.eu",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Hamburg",
            "COMPAGNY_NAME": "MAN TRUCK & BUS",
            "LATITUDE": "53.585079",
            "LONGITUDE": "9.929792",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr BURGSTALLER",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:19:00,2:7:30:19:00,3:7:30:19:00,4:7:30:19:00,5:7:30:19:00,6:7:30:14:00"
        },
        {
            "ID": 796,
            "ADDRESS_LINE_1": "Haseler Wweg 1",
            "ADDRESS_LINE_2": "",
            "CITY": "Oldenburg",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "26125",
            "MAIN_PHONE": "004944193093  0",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "0049 441930 93 94",
            "EMAIL_ADDRESS": "info@autohaus-munderloh.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Oldenburg",
            "COMPAGNY_NAME": "Herman Munderloh Kraftfahrzeuge GmbH & Co. KG",
            "LATITUDE": "53.180347",
            "LONGITUDE": "8.23168",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. Hergen Kruse",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30,6:7:30:13:00"
        },
        {
            "ID": 842,
            "ADDRESS_LINE_1": "Schutterwälder Str. 22-24",
            "ADDRESS_LINE_2": "",
            "CITY": "Ottendorf - Okrilla",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "01458",
            "MAIN_PHONE": "+49 0 35205 451130 ",
            "ALT_PHONE": "+49 0 152 37009643",
            "MOBILE_PHONE": "+49 0 172 5778024",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "+49 0 35205 461131",
            "EMAIL_ADDRESS": "info@dittloff-kaeltetechnik.eu",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Ottendorf - Okrilla",
            "COMPAGNY_NAME": "Dittloff Kältetechnik & Transportkühlung",
            "LATITUDE": "51.177034",
            "LONGITUDE": "13.834349",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. Martin Dittloff",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 843,
            "ADDRESS_LINE_1": "Am Golfplatz 2, ",
            "ADDRESS_LINE_2": "",
            "CITY": "Großbeeren",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "14979",
            "MAIN_PHONE": "+49 0 33701 74540",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "+49 0 33701 745424",
            "EMAIL_ADDRESS": "carrier@eigro-berlin.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Großbeeren",
            "COMPAGNY_NAME": "EIGRO TRANSPORTKALTE Berlin GmbH",
            "LATITUDE": "52.348611",
            "LONGITUDE": "13.270278",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr ISSELMANN",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:8:00:::12:00,7::::"
        },
        {
            "ID": 845,
            "ADDRESS_LINE_1": "Appendorfer Weg. 1",
            "ADDRESS_LINE_2": "",
            "CITY": "Osterweddingen",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "39171",
            "MAIN_PHONE": "+49 0 39205 4101 0",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "+49 0 39205 4101 11",
            "EMAIL_ADDRESS": "info@nutzfahrzeuge-service.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Osterweddingen",
            "COMPAGNY_NAME": "Nutzfahrzeuge & Service GmbH Co. KG",
            "LATITUDE": "52.0825",
            "LONGITUDE": "11.573611",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. Josef Simkes",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::20:00,2:7:00:::20:00,3:7:00:::20:00,4:7:00:::20:00,5:7:00:::20:00,6:7:00:12:00::,7::::"
        },
        {
            "ID": 846,
            "ADDRESS_LINE_1": "Pamplona Strasse 4",
            "ADDRESS_LINE_2": "",
            "CITY": "Paderborn",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "33106",
            "MAIN_PHONE": "+49 5251 77400 ",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "+49 5251 774040",
            "EMAIL_ADDRESS": "nutzfahrzeugzentrum-paderborn@rosier.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Paderborn",
            "COMPAGNY_NAME": "Autohaus Heinrich Rosier GmbH & CoKG",
            "LATITUDE": "51.685768",
            "LONGITUDE": "8.72781",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Christian BROK",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:8:00:::17:00,7::::"
        },
        {
            "ID": 847,
            "ADDRESS_LINE_1": "An der Autobahn 2 – 8",
            "ADDRESS_LINE_2": "",
            "CITY": "Kirchberg/Jagst",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "74592",
            "MAIN_PHONE": "+49 7904 9459 0",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "+49 7904 945 180",
            "EMAIL_ADDRESS": "office@lkw-stegmaier.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Kirchberg/Jagst",
            "COMPAGNY_NAME": "Stegmaier Nutzfahrzeuge GmbH Co. KG",
            "LATITUDE": "49.174167",
            "LONGITUDE": "9.959167",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Mr. Otto Köhler",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::18:00,2:7:00:::18:00,3:7:00:::19:00,4:7:00:::19:00,5:7:00:::20:00,6:7:00:::13:00,7::::"
        },
        {
            "ID": 848,
            "ADDRESS_LINE_1": "Klingelswiese 2",
            "ADDRESS_LINE_2": "",
            "CITY": "Andernach ",
            "COUNTRY": "Germany",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "56626",
            "MAIN_PHONE": "+49 2632 30977 0",
            "ALT_PHONE": "+49 151 16063985",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "+49 2632 30977 29",
            "EMAIL_ADDRESS": "carrier@eigro.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Andernach ",
            "COMPAGNY_NAME": "EIGRO TRANSPORTKÄLTE GMBH",
            "LATITUDE": "50.42625",
            "LONGITUDE": "7.38577",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr GRIEGER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:8:00:::12:00,7::::"
        },
        {
            "ID": 863,
            "ADDRESS_LINE_1": "Karatas Str 3",
            "ADDRESS_LINE_2": "",
            "CITY": "Memmingen",
            "COUNTRY": "",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "87700",
            "MAIN_PHONE": "(+49) 8331 4901584",
            "ALT_PHONE": "(+49) 8331 4901584",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 8331 49050 89",
            "EMAIL_ADDRESS": "daniel.hultsch@fta-gmbh.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Memmingen",
            "COMPAGNY_NAME": "FAHRZEUGTECHNIK  ARETSRIED GMBH",
            "LATITUDE": "48.003003",
            "LONGITUDE": "10.153011",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Daniel Hultsch",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": "",
            "MOBILEVAN_NUMBER": "",
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 864,
            "ADDRESS_LINE_1": "Magirusstraße 12",
            "ADDRESS_LINE_2": "",
            "CITY": "Langenau",
            "COUNTRY": "",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "89129",
            "MAIN_PHONE": "+49 7345 204 9988",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+49 176 4351 6207",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "+49 7345 204 9989",
            "EMAIL_ADDRESS": "mp@coolmar.eu",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Langenau",
            "COMPAGNY_NAME": "COOLMAR GMBH",
            "LATITUDE": "48.498421",
            "LONGITUDE": "10.08305",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Michael Plazko",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:18:00::,2:8:00:18:00::,3:8:00:18:00::,4:8:00:18:00::,5:8:00:18:00::,6:8:00:12:00::,7::::"
        },
        {
            "ID": 900,
            "ADDRESS_LINE_1": "Seckenhauser Straße 7",
            "ADDRESS_LINE_2": "",
            "CITY": "Weyhe",
            "COUNTRY": "",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "28844",
            "MAIN_PHONE": "+49 421 89905 0",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "+49 421 89905 91",
            "EMAIL_ADDRESS": "Uwe.Evers@wernerautomobile.DE",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Weyhe",
            "COMPAGNY_NAME": "Autohaus Werner GmbH ",
            "LATITUDE": "52.987222",
            "LONGITUDE": "8.783333",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Uwe Evers",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:6:30:22:00::,2:6:30:22:00::,3:6:30:22:00::,4:6:30:22:00::,5:6:30:22:00::,6:6:30:16:00::,7::::"
        },
        {
            "ID": 927,
            "ADDRESS_LINE_1": "5 Alte Mittelhäuser Str",
            "ADDRESS_LINE_2": "",
            "CITY": "Erfurt",
            "COUNTRY": "",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "99091",
            "MAIN_PHONE": "(0049) 361 74425 0",
            "ALT_PHONE": "(0049) 1638909012",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(0049) 361 74425 22",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Erfurt",
            "COMPAGNY_NAME": "Grimm & Partner Fahrzeugbau GmbH&Co.KG",
            "LATITUDE": "51.026667",
            "LONGITUDE": "11.004722",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Frank Seiler",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::18:00,2:7:00:::18:00,3:7:00:::18:00,4:7:00:::18:00,5:7:00:::18:00,6:7:00:12:30::,7::::"
        },
        {
            "ID": 928,
            "ADDRESS_LINE_1": "Max - von - Laue - Straße ",
            "ADDRESS_LINE_2": "",
            "CITY": "Rheda Wiedenbrück",
            "COUNTRY": "",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "433378",
            "MAIN_PHONE": "(+49) 2522 59004 59",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49) 2382 789 860",
            "EMAIL_ADDRESS": "simon.unbereit@auto-senger.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Rheda Wiedenbrück",
            "COMPAGNY_NAME": "Senger Südwestfalen GmbH",
            "LATITUDE": "51.829167",
            "LONGITUDE": "8.214336",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Simon Unbereit",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::17:00,2:7:30:::17:00,3:7:30:::17:00,4:7:30:::17:00,5:7:30:::17:00,6::::,7::::"
        },
        {
            "ID": 929,
            "ADDRESS_LINE_1": "Drei Linden 4,",
            "ADDRESS_LINE_2": "",
            "CITY": "Schutterwald",
            "COUNTRY": "",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "77652",
            "MAIN_PHONE": "(0049) 781 9204 0",
            "ALT_PHONE": "(0049) 15121591262",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(0049) 781 9204 80",
            "EMAIL_ADDRESS": "Rainer.Fesenmeier@man.eu",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Offenburg",
            "COMPAGNY_NAME": "MAN Truck & Bus Deutschland GmbH",
            "LATITUDE": "48.454882",
            "LONGITUDE": "7.906538",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Rainer Fesenmeier",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::18:00,2:7:30:::18:00,3:7:30:::18:00,4:7:30:::18:00,5:7:30:::18:00,6::::,7::::"
        },
        {
            "ID": 944,
            "ADDRESS_LINE_1": "Oberfeld 13",
            "ADDRESS_LINE_2": "",
            "CITY": "Bichl",
            "COUNTRY": "",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "83673",
            "MAIN_PHONE": "0049 160 8298302",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "tksupply@gmx.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bichl",
            "COMPAGNY_NAME": "TK Supply Inh.",
            "LATITUDE": "47.72308",
            "LONGITUDE": "11.41384",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Dennis Belloth",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::17:00,2:7:30:::17:00,3:7:30:::17:00,4:7:30:::17:00,5:7:30:::17:00,6::::,7::::"
        },
        {
            "ID": 951,
            "ADDRESS_LINE_1": "Frankfurter Straße 46",
            "ADDRESS_LINE_2": "",
            "CITY": "Friedberg",
            "COUNTRY": "",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "61169",
            "MAIN_PHONE": "(+49)(0)06031 6907 0",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "(+49)(0)06031 6907 90",
            "EMAIL_ADDRESS": "roman.schulz@man.eu",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Friedberg",
            "COMPAGNY_NAME": "MAN Truck & Bus Deutschland GmbH",
            "LATITUDE": "50.318733",
            "LONGITUDE": "8.747064",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Roman Schulz",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::18:00,2:7:30:::18:00,3:7:30:::18:00,4:7:30:::18:00,5:7:30:::18:00,6::::,7::::"
        },
        {
            "ID": 965,
            "ADDRESS_LINE_1": "Denzlinger Straße 44",
            "ADDRESS_LINE_2": "",
            "CITY": "Emmendingen",
            "COUNTRY": "",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "79312",
            "MAIN_PHONE": "07641 4602-300",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "07641 4602-8300",
            "EMAIL_ADDRESS": "Nfz-zentrum@sschmolck.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Emmendingen",
            "COMPAGNY_NAME": "Schmolck GmbH & Co. KG",
            "LATITUDE": "48.107724",
            "LONGITUDE": "7.848697",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Thomas Bischoff",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:8:00:::16:00,7::::"
        },
        {
            "ID": 975,
            "ADDRESS_LINE_1": "Sanderhaeuserstr.89",
            "ADDRESS_LINE_2": "",
            "CITY": "Kassel",
            "COUNTRY": "",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "34123",
            "MAIN_PHONE": "0049 (0)561 4756763",
            "ALT_PHONE": "0049 5401 5105",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "0049 (0)561 4756764",
            "EMAIL_ADDRESS": "info@nta-kassel.de",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Kassel",
            "COMPAGNY_NAME": "NTA Nuhn Transportkaete Anlagenbau GmbH & Co.KG",
            "LATITUDE": "51.311425",
            "LONGITUDE": "9.530366",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Michael Nuhn",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 998,
            "ADDRESS_LINE_1": "Beverbrucher Straße 33",
            "ADDRESS_LINE_2": "",
            "CITY": "Garrel",
            "COUNTRY": "",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "49681",
            "MAIN_PHONE": "0049 4474 4779955",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "0049 1749624157",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "cw.transportkaelte@t-online.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Garrel",
            "COMPAGNY_NAME": "CW Transportkälteservice - NFZ Service",
            "LATITUDE": "52.951263",
            "LONGITUDE": "8.0508",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Christian Wittkopf",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6::::,7::::"
        },
        {
            "ID": 1020,
            "ADDRESS_LINE_1": "Kruppstraße 11",
            "ADDRESS_LINE_2": "",
            "CITY": "Möglingen",
            "COUNTRY": "",
            "COUNTRY_CODE": "DE",
            "POSTAL_CODE": "71696",
            "MAIN_PHONE": "0049 (0)7141 97993 0",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "0049 (0)176 80417017",
            "ASSISTANCE_PHONE": "0800 1808180",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "carrier@akv-transportkuehlung.de",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Möglingen",
            "COMPAGNY_NAME": "AKV Transport-Kälte-Zentrum GmbH",
            "LATITUDE": "48.88828",
            "LONGITUDE": "9.14542",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Rainer Boelke",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6::::,7::::"
        },
        {
            "ID": 49,
            "ADDRESS_LINE_1": "Industrivej 30",
            "ADDRESS_LINE_2": "",
            "CITY": "Padborg",
            "COUNTRY": "Denmark",
            "COUNTRY_CODE": "DK ",
            "POSTAL_CODE": "6330",
            "MAIN_PHONE": "(+45) 74 67 00 15",
            "ALT_PHONE": "(+45) 74 67 00 15",
            "MOBILE_PHONE": "(+45) 20 25 16 36",
            "ASSISTANCE_PHONE": "80881832",
            "FAX_PHONE": "(+45) 74 67 57 51",
            "EMAIL_ADDRESS": "Kjeld.Hansen@Carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Padborg",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD SCANDINAVIA A/S",
            "LATITUDE": "54.837348",
            "LONGITUDE": "9.346597",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Kjeld HANSEN",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 11,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:7:30:::21:30,2:7:30:::21:30,3:7:30:::21:30,4:7:30:::21:30,5:7:30:::21:30,6::::,7::::"
        },
        {
            "ID": 50,
            "ADDRESS_LINE_1": "Maribovej 9A - 9B",
            "ADDRESS_LINE_2": "",
            "CITY": "Vejle",
            "COUNTRY": "Denmark",
            "COUNTRY_CODE": "DK ",
            "POSTAL_CODE": "7100",
            "MAIN_PHONE": "(+45) 41 33 40 68",
            "ALT_PHONE": "(+45) 20 34 62 12",
            "MOBILE_PHONE": "(+45) 41 33 40 68",
            "ASSISTANCE_PHONE": "80881832",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "vejle@esbjergtrailerservice.dk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Vejle",
            "COMPAGNY_NAME": "ESBJERG TRAILER SERVICE",
            "LATITUDE": "55.743929",
            "LONGITUDE": "9.603258",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Lars Østergaard Nielsen",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:16:00,2:8:00:16:00,3:8:00:16:00,4:8:00:16:00,5:8:00:16:00"
        },
        {
            "ID": 52,
            "ADDRESS_LINE_1": "Geminivej 64",
            "ADDRESS_LINE_2": "",
            "CITY": "Greve",
            "COUNTRY": "Denmark",
            "COUNTRY_CODE": "DK ",
            "POSTAL_CODE": "2670",
            "MAIN_PHONE": "(+45) 43 69 07 77",
            "ALT_PHONE": "(+45) 43 69 07 77",
            "MOBILE_PHONE": "(+45) 43 69 07 77",
            "ASSISTANCE_PHONE": "80881832",
            "FAX_PHONE": "(+45) 43 69 35 55",
            "EMAIL_ADDRESS": "info@dansk-kole-service.dk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kobenhavn",
            "COMPAGNY_NAME": "DANSK KOLE SERVICE APS",
            "LATITUDE": "55.606546",
            "LONGITUDE": "12.296441",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Peter KLINGE",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:30:17:00,2:7:30:17:00,3:7:30:17:00,4:7:30:17:00,5:7:00:15:00"
        },
        {
            "ID": 56,
            "ADDRESS_LINE_1": "Nordvej 12",
            "ADDRESS_LINE_2": "",
            "CITY": "Frederikshavn",
            "COUNTRY": "Denmark",
            "COUNTRY_CODE": "DK ",
            "POSTAL_CODE": "9900",
            "MAIN_PHONE": "+45 98 47 91 04 ",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "+45 20 58 65 03",
            "ASSISTANCE_PHONE": "80881832",
            "FAX_PHONE": "+45 98 47 91 03",
            "EMAIL_ADDRESS": "pjl@mannord.dk",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Frederikshavn",
            "COMPAGNY_NAME": "MAN Nordjylland",
            "LATITUDE": "57.3911",
            "LONGITUDE": "10.512038",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Torben JENSEN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::16:00,2:7:00:::16:00,3:7:00:::16:00,4:7:00:::16:00,5:7:00:::15:00,6::::,7::::"
        },
        {
            "ID": 58,
            "ADDRESS_LINE_1": "Atletikvej 1",
            "ADDRESS_LINE_2": "",
            "CITY": "Svenstrup",
            "COUNTRY": "Denmark",
            "COUNTRY_CODE": "DK ",
            "POSTAL_CODE": "9230",
            "MAIN_PHONE": "(+45) 98 38 99 66",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "(+45) 20 44 99 66",
            "ASSISTANCE_PHONE": "80881832",
            "FAX_PHONE": "(+45) 98 38 99 86",
            "EMAIL_ADDRESS": "Vks548@man.dk",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Svenstrup",
            "COMPAGNY_NAME": "MAN LAST & BUS A/S",
            "LATITUDE": "56.985657",
            "LONGITUDE": "9.858327",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Steen HOSBOND",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:18:00,2:7:30:18:00,3:7:30:18:00,4:7:30:18:00,5:7:30:17:30"
        },
        {
            "ID": 60,
            "ADDRESS_LINE_1": "Kokbjerg 2",
            "ADDRESS_LINE_2": "",
            "CITY": "Kolding",
            "COUNTRY": "Denmark",
            "COUNTRY_CODE": "DK ",
            "POSTAL_CODE": "6000",
            "MAIN_PHONE": "(+45) 76 32 15 00",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80881832",
            "FAX_PHONE": "(+45) 75 51 71 08",
            "EMAIL_ADDRESS": "ole.nielsen@man.eu",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Kolding",
            "COMPAGNY_NAME": "MAN LAST & BUS A/S",
            "LATITUDE": "55.53349",
            "LONGITUDE": "9.473866",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ole Nielsen",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::21:30,2:7:30:::21:30,3:7:30:::21:30,4:7:30:::21:30,5:7:30:::19:00,6::::,7::::"
        },
        {
            "ID": 709,
            "ADDRESS_LINE_1": "Made Industrvej 14",
            "ADDRESS_LINE_2": "",
            "CITY": "Esbjerg",
            "COUNTRY": "Denmark",
            "COUNTRY_CODE": "DK ",
            "POSTAL_CODE": "6705",
            "MAIN_PHONE": "+45 75 15 43 22",
            "ALT_PHONE": "+45 20 34 62 12",
            "MOBILE_PHONE": "+45 40 19 25 11",
            "ASSISTANCE_PHONE": "80881832",
            "FAX_PHONE": "+45 75 15 38 94",
            "EMAIL_ADDRESS": "Bjarne@esbjergtrailerservice.dk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Esbjerg",
            "COMPAGNY_NAME": "ESBJERG TRAILER SERVICE",
            "LATITUDE": "55.460714",
            "LONGITUDE": "8.497353",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Bjarne SCHOTZ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6::::,7::::"
        },
        {
            "ID": 838,
            "ADDRESS_LINE_1": "Silvervej 2",
            "ADDRESS_LINE_2": "",
            "CITY": "Hirtshals",
            "COUNTRY": "Denmark",
            "COUNTRY_CODE": "DK ",
            "POSTAL_CODE": "9850",
            "MAIN_PHONE": "+45 31 10 02 72",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+45 91 89 44 34",
            "ASSISTANCE_PHONE": "80881832",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "TIP_Hirtshals@tipeurope.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Hirtshals",
            "COMPAGNY_NAME": "TIP Trailer Services",
            "LATITUDE": "57.57772",
            "LONGITUDE": "9.982358",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ole MADSEN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:7:00:::15:30,2:7:00:::15:30,3:7:00:::15:30,4:7:00:::15:30,5:7:00:::15:30,6::::,7::::"
        },
        {
            "ID": 939,
            "ADDRESS_LINE_1": "Lundholmvej 10",
            "ADDRESS_LINE_2": "",
            "CITY": "Holstebro",
            "COUNTRY": "",
            "COUNTRY_CODE": "DK ",
            "POSTAL_CODE": "7500",
            "MAIN_PHONE": "96106089",
            "ALT_PHONE": "22391538",
            "MOBILE_PHONE": "24645550",
            "ASSISTANCE_PHONE": "80881832",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Holstebro",
            "COMPAGNY_NAME": "TJ Truck Service APS",
            "LATITUDE": "56.382478",
            "LONGITUDE": "8.616275",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "thomas@tjtruck.dk",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::15:30,2:7:30:::15:30,3:7:30:::15:30,4:7:30:::15:30,5:7:30:::15:00,6::::,7::::"
        },
        {
            "ID": 1012,
            "ADDRESS_LINE_1": "Litauen Alle´ 7",
            "ADDRESS_LINE_2": "",
            "CITY": "Taastrup",
            "COUNTRY": "",
            "COUNTRY_CODE": "DK ",
            "POSTAL_CODE": "2630",
            "MAIN_PHONE": "+45 20550495",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+46 101450555",
            "ASSISTANCE_PHONE": "80881832",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "tip_taastrup@tipeurope.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Taastrup",
            "COMPAGNY_NAME": "TIP Trailer Services A/S",
            "LATITUDE": "55.638758",
            "LONGITUDE": "12.243509",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:00,6::::,7::::"
        },
        {
            "ID": 887,
            "ADDRESS_LINE_1": "5eme Km route de Batna ",
            "ADDRESS_LINE_2": "N°01Cité Nassim ",
            "CITY": "Constantine",
            "COUNTRY": "",
            "COUNTRY_CODE": "DZ ",
            "POSTAL_CODE": "25000",
            "MAIN_PHONE": "(+213) 770878851",
            "ALT_PHONE": "(+213) 670054602",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+213) 31621717",
            "EMAIL_ADDRESS": "s.djafer@motorest-dz.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Constantine",
            "COMPAGNY_NAME": "MOTOREST EURL",
            "LATITUDE": "36.343064",
            "LONGITUDE": "6.640846",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Seifeddine Djafer",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00::,2:8:00:17:00::,3:8:00:17:00::,4:8:00:17:00::,5::::,6::::,7:8:00:17:00::"
        },
        {
            "ID": 888,
            "ADDRESS_LINE_1": "Lot N°02 Cité Ben Hamouda ",
            "ADDRESS_LINE_2": "Dar El baida ",
            "CITY": "Alger",
            "COUNTRY": "",
            "COUNTRY_CODE": "DZ ",
            "POSTAL_CODE": "16000",
            "MAIN_PHONE": "(+213) 770878851",
            "ALT_PHONE": "(+213) 670054602",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+213) 31621717",
            "EMAIL_ADDRESS": "s.djafer@motorest-dz.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Alger",
            "COMPAGNY_NAME": "MOTOREST EURL",
            "LATITUDE": "36.727173",
            "LONGITUDE": "3.20617",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Seifeddine Djafer",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00::,2:8:00:17:00::,3:8:00:17:00::,4:8:00:17:00::,5::::,6::::,7:8:00:17:00::"
        },
        {
            "ID": 64,
            "ADDRESS_LINE_1": "Sinikivi tee 1, Rae vald, Lehmja kula",
            "ADDRESS_LINE_2": "",
            "CITY": "Tallinn",
            "COUNTRY": "Estonia",
            "COUNTRY_CODE": "EE",
            "POSTAL_CODE": "75306",
            "MAIN_PHONE": "(+372) 6604901",
            "ALT_PHONE": "(+372) 5042078",
            "MOBILE_PHONE": "(+372) 50 42 078",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+372) 6604916",
            "EMAIL_ADDRESS": " foilpoint@foilpoint.ee",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Tallinn",
            "COMPAGNY_NAME": "FOILPOINT LLC",
            "LATITUDE": "59.367231",
            "LONGITUDE": "24.871695",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marko TREI",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:18:00,2:9:00:18:00,3:9:00:18:00,4:9:00:18:00,5:9:00:18:00"
        },
        {
            "ID": 671,
            "ADDRESS_LINE_1": "Roheline 76",
            "ADDRESS_LINE_2": "",
            "CITY": "Parnu",
            "COUNTRY": "Estonia",
            "COUNTRY_CODE": "EE",
            "POSTAL_CODE": "80041",
            "MAIN_PHONE": "+372 6651251",
            "ALT_PHONE": "+372 507 1477",
            "MOBILE_PHONE": "+372 6651256",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+372 6651255",
            "EMAIL_ADDRESS": "scania@scania.ee",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Parnu",
            "COMPAGNY_NAME": "Scania Eesti AS Pärnu filiaal",
            "LATITUDE": "58.4075",
            "LONGITUDE": "24.493889",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Hando Ruusmaa",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,5:8:00:17:00,6:8:00:17:00"
        },
        {
            "ID": 63,
            "ADDRESS_LINE_1": "KM28 Cairo/Alex Desert Road",
            "ADDRESS_LINE_2": "Abu Rawash Industrial Zone",
            "CITY": "Giza",
            "COUNTRY": "Egypt",
            "COUNTRY_CODE": "EG",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "+201226092362",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+201226092362",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "mina.roshdy@miraco.com.eg",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Giza",
            "COMPAGNY_NAME": "MIRACO INTERNATIONAL TRADING",
            "LATITUDE": "30.064786",
            "LONGITUDE": "31.24958",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Mina Roshdy",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5::::,6::::,7:8:00:::17:00"
        },
        {
            "ID": 459,
            "ADDRESS_LINE_1": "Avda Endarlaza s/n - Ctra. Ir",
            "ADDRESS_LINE_2": "Barrio Behobia",
            "CITY": "IRUN",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "20305",
            "MAIN_PHONE": "(+34) 943628477",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": " (+34) 619316542",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 943632099",
            "EMAIL_ADDRESS": "efbehobia@hotmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "GUIPUZCOA",
            "COMPAGNY_NAME": "ELECTRO FRIO-BEHOBIA SL ",
            "LATITUDE": "43.33801",
            "LONGITUDE": "-1.754715",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ivan BALERDI",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:00:15:00:20:00,2:9:00:13:00:15:00:20:00,3:9:00:13:00:15:00:20:00,4:9:00:13:00:15:00:20:00,5:9:00:13:00:15:00:20:00,6::::,7::::"
        },
        {
            "ID": 460,
            "ADDRESS_LINE_1": "Cmno. La Silla S/N",
            "ADDRESS_LINE_2": "Plg. Ind. N85 parc 264",
            "CITY": "SAN GONERA LA SECA",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "30835",
            "MAIN_PHONE": "(+34) 968891362",
            "ALT_PHONE": "(+34) 670361160",
            "MOBILE_PHONE": "(+34) 625653350",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 968806951",
            "EMAIL_ADDRESS": "andres@friotransur.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "MURCIA",
            "COMPAGNY_NAME": "FRIO TRANSUR SL",
            "LATITUDE": "37.95691",
            "LONGITUDE": "-1.249995",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Andres PINTADO",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 13,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:13:30:15:30:19:00,2:8:30:13:30:15:30:19:00,3:8:30:13:30:15:30:19:00,4:8:30:13:30:15:30:19:00,5:8:30:13:30:15:30:19:00"
        },
        {
            "ID": 461,
            "ADDRESS_LINE_1": "Ctra. N-550km 154,500 ",
            "ADDRESS_LINE_2": "Nave 2 - 3",
            "CITY": "MOS",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "36415",
            "MAIN_PHONE": "(+34) 986344144",
            "ALT_PHONE": "(+34) 649969996",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 986344712",
            "EMAIL_ADDRESS": "susi@ernsl.es",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "PONTEVEDRA",
            "COMPAGNY_NAME": "ELECTRO REFRIGERATION DEL NOROESTE SL   ",
            "LATITUDE": "42.18019",
            "LONGITUDE": "-8.613749",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Oscar Gonzalez",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:00:15:00:19:00,2:9:00:13:00:15:00:19:00,3:9:00:13:00:15:00:19:00,4:9:00:13:00:15:00:19:00,5:9:00:13:00:15:00:19:00,6::::,7::::"
        },
        {
            "ID": 462,
            "ADDRESS_LINE_1": "Diseminado carril del Nevero nº 2500",
            "ADDRESS_LINE_2": "",
            "CITY": "VICAR - ALMERIA",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "04738",
            "MAIN_PHONE": "(+34) 950344869",
            "ALT_PHONE": "(+34) 610268880",
            "MOBILE_PHONE": "0",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 950539549",
            "EMAIL_ADDRESS": "administracion@loremarfred.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "VICAR - ALMERIA",
            "COMPAGNY_NAME": "LOREMAR FRED",
            "LATITUDE": "36.794504",
            "LONGITUDE": "-2.634938",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Enrique LORENTE",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:14:00:16:00:20:00,2:9:00:14:00:16:00:20:00,3:9:00:14:00:16:00:20:00,4:9:00:14:00:16:00:20:00,5:9:00:14:00:16:00:20:00"
        },
        {
            "ID": 464,
            "ADDRESS_LINE_1": "C/ Cobalto 65-67",
            "ADDRESS_LINE_2": "",
            "CITY": "CORNELLA",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "8940",
            "MAIN_PHONE": "(+34) 934745154",
            "ALT_PHONE": "(+34) 638423555",
            "MOBILE_PHONE": " (+34) 609850662",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 933777428",
            "EMAIL_ADDRESS": "fridiel@fridiel.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "BARCELONA",
            "COMPAGNY_NAME": "FRIDIEL SL",
            "LATITUDE": "41.354069",
            "LONGITUDE": "2.09088",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Manuel TENA",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:30,2:8:00:::18:30,3:8:00:::18:30,4:8:00:::18:30,5:8:00:::18:30,6::::,7::::"
        },
        {
            "ID": 466,
            "ADDRESS_LINE_1": "Avenida Comte Serrallo 83",
            "ADDRESS_LINE_2": "Ronda Solidaritat  S/N (Junto a A-7)",
            "CITY": "L'Alcudia",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "46250",
            "MAIN_PHONE": "(+34) 962403750",
            "ALT_PHONE": "(+34) 607804442",
            "MOBILE_PHONE": "(+34) 609575217/ 750865 ",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 962400149",
            "EMAIL_ADDRESS": "almacen@indufret.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "VALENCIA",
            "COMPAGNY_NAME": "INDUFRET SL",
            "LATITUDE": "39.205991",
            "LONGITUDE": "-0.500022",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Manolo Monzon",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00,6::::,7::::"
        },
        {
            "ID": 467,
            "ADDRESS_LINE_1": "Pog Ind Pla de la Vallonga",
            "ADDRESS_LINE_2": "C/ Nieves Nº 20",
            "CITY": "ALICANTE",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "03006",
            "MAIN_PHONE": "(+34) 965280788",
            "ALT_PHONE": "(+34) 686435926",
            "MOBILE_PHONE": "(+34) 606325364",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 965287702",
            "EMAIL_ADDRESS": "admon3@polifret.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "ALICANTE",
            "COMPAGNY_NAME": "POLIFRET SAL",
            "LATITUDE": "38.34913",
            "LONGITUDE": "-0.549723",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tomas SOLER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:13:30:16:00:19:00,2:8:00:13:30:16:00:19:00,3:8:00:13:30:16:00:19:00,4:8:00:13:30:16:00:19:00,5:8:00:13:30:16:00:19:00,6::::,7::::"
        },
        {
            "ID": 468,
            "ADDRESS_LINE_1": "C/Veintiuno naves 1,3,5",
            "ADDRESS_LINE_2": "Pog Ind. La Red",
            "CITY": "ALCALA DE GUADAIRA",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "41500",
            "MAIN_PHONE": "(+34) 955630916",
            "ALT_PHONE": "(+34) 620064393",
            "MOBILE_PHONE": "(+34) 629637126 ",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 955631894",
            "EMAIL_ADDRESS": "carriersevilla@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "SEVILLA",
            "COMPAGNY_NAME": "ELECTROFRIO LA RED",
            "LATITUDE": "37.37486",
            "LONGITUDE": "-5.890005",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Estela Reyes/Fran Ruiz",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:13:30:15:30:20:00,2:8:00:13:30:15:30:20:00,3:8:00:13:30:15:30:20:00,4:8:00:13:30:15:30:20:00,5:8:00:13:30:15:30:20:00,6::::,7::::"
        },
        {
            "ID": 470,
            "ADDRESS_LINE_1": "Pol. Ind. Cortijo Real",
            "ADDRESS_LINE_2": "C/ El Espa",
            "CITY": "ALGECIRAS",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "11206",
            "MAIN_PHONE": "(+34) 956102339",
            "ALT_PHONE": "(+34) 607830888",
            "MOBILE_PHONE": "(+34) 607830888",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 956604161",
            "EMAIL_ADDRESS": "sofrial@sofrial.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "CADIZ",
            "COMPAGNY_NAME": "SOFRIAL",
            "LATITUDE": "36.111239",
            "LONGITUDE": "-5.467629",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Juan Carlos Sánchez",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:8:00:13:00:15:00:19:00,2:8:00:13:00:15:00:19:00,3:8:00:13:00:15:00:19:00,4:8:00:13:00:15:00:19:00,5:8:00:13:00:15:00:19:00,6::::,7::::"
        },
        {
            "ID": 471,
            "ADDRESS_LINE_1": "C/ Sant Josep n°3",
            "ADDRESS_LINE_2": "Pog Ind Mas Aliu",
            "CITY": "AIGUAVIVA (GIRONA)",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "17181",
            "MAIN_PHONE": "(+34) 972244107",
            "ALT_PHONE": "(+34) 659067922",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 972245524",
            "EMAIL_ADDRESS": "refriauto@refriauto.net",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "GERONA",
            "COMPAGNY_NAME": "REFRIAUTO",
            "LATITUDE": "41.94624",
            "LONGITUDE": "2.784063",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ernesto PINSACH, José FONTAROSA",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:13:00:15:00:19:00,2:8:30:13:00:15:00:19:00,3:8:30:13:00:15:00:19:00,4:8:30:13:00:15:00:19:00,5:8:30:13:00:15:00:19:00,6::::,7::::"
        },
        {
            "ID": 472,
            "ADDRESS_LINE_1": "Pog Ind Cogullada",
            "ADDRESS_LINE_2": "C/ Benjamin Franklin 18",
            "CITY": "ZARAGOZA",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "50014",
            "MAIN_PHONE": "(+34) 976472936",
            "ALT_PHONE": "(+34) 669455266",
            "MOBILE_PHONE": "(+34) 669455266",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 976473738",
            "EMAIL_ADDRESS": "iberauto@iberauto.es",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "ZARAGOZA",
            "COMPAGNY_NAME": "IBERAUTO COGULLADA, S.L.",
            "LATITUDE": "41.668119",
            "LONGITUDE": "-0.86762",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mariano SANZ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:14:00:16:00:19:00,2:9:00:14:00:16:00:19:00,3:9:00:14:00:16:00:19:00,4:9:00:14:00:16:00:19:00,5:9:00:14:00:16:00:19:00"
        },
        {
            "ID": 473,
            "ADDRESS_LINE_1": "C/ 1-4 Parcela 33",
            "ADDRESS_LINE_2": "Poligono Ind Estación",
            "CITY": "LORQUI",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "30564",
            "MAIN_PHONE": "(+34) 968687835",
            "ALT_PHONE": "(+34) 687865251",
            "MOBILE_PHONE": "(+34) 671662201 ",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 968676709",
            "EMAIL_ADDRESS": "Gruporecepcion@refritrans.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "MURCIA",
            "COMPAGNY_NAME": "REFRITRANS 2001, S.L.",
            "LATITUDE": "38.097147",
            "LONGITUDE": "-1.236823",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Enrique",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 9,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:14:00:16:00:19:30,2:8:30:14:00:16:00:19:30,3:8:30:14:00:16:00:19:30,4:8:30:14:00:16:00:19:30,5:8:30:14:00:16:00:19:30,6::::,7::::"
        },
        {
            "ID": 474,
            "ADDRESS_LINE_1": "Pol. Ind Manzanares",
            "ADDRESS_LINE_2": "C/2 - parc-19",
            "CITY": "MANZANARES",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "13200",
            "MAIN_PHONE": "(+34) 926644759",
            "ALT_PHONE": "(+34) 669857275",
            "MOBILE_PHONE": "(+34) 669857275",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 926644761",
            "EMAIL_ADDRESS": "pablo.electrofrio@de-tomelloso.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "C. REAL",
            "COMPAGNY_NAME": "ELECTROFRIO O. ZARATE .",
            "LATITUDE": "39.01753",
            "LONGITUDE": "-3.36329",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Pablo ORTIZ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:14:00:15:30:19:00,2:9:00:14:00:15:30:19:00,3:9:00:14:00:15:30:19:00,4:9:00:14:00:15:30:19:00,5:9:00:14:00:15:30:19:00,6::::,7::::"
        },
        {
            "ID": 475,
            "ADDRESS_LINE_1": "Plg Ing El Palmer",
            "ADDRESS_LINE_2": "C/ Torno, 29",
            "CITY": "PUERTO DE SANTA MARIA - CADIZ",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "11500",
            "MAIN_PHONE": "(+34) 956 859838",
            "ALT_PHONE": "(+34) 607204755",
            "MOBILE_PHONE": "(+34) 610704229",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 956859851",
            "EMAIL_ADDRESS": "a.munoz@servitrans.es",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "CADIZ",
            "COMPAGNY_NAME": "SERVITRANS MANTENIMIENTOS, S.L.U.",
            "LATITUDE": "36.61744",
            "LONGITUDE": "-6.203955",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Antonio MUNOZ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 477,
            "ADDRESS_LINE_1": "CL Vitoria 274",
            "ADDRESS_LINE_2": "Pgo Taglosa 44-45",
            "CITY": "BURGOS",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "09007",
            "MAIN_PHONE": "(+34) 947480150",
            "ALT_PHONE": "(+34) 629464996",
            "MOBILE_PHONE": "(+34) 689319232",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 947483428",
            "EMAIL_ADDRESS": "antonio@mrobleno.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "BURGOS",
            "COMPAGNY_NAME": "T MUNOZ ROBLEÑO",
            "LATITUDE": "42.357022",
            "LONGITUDE": "-3.648663",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Antonio MUNOZ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:00:15:00:18:00,2:9:00:13:00:15:00:18:00,3:9:00:13:00:15:00:18:00,4:9:00:13:00:15:00:18:00,5:9:00:13:00:15:00:18:00"
        },
        {
            "ID": 478,
            "ADDRESS_LINE_1": "P.L. Ceao - Rua dos Artesans",
            "ADDRESS_LINE_2": "Parcela 24",
            "CITY": "LUGO",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "27003",
            "MAIN_PHONE": "(+34) 982209397",
            "ALT_PHONE": "(+34) 616441480",
            "MOBILE_PHONE": "(+34) 679422926",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 982 20 93 98",
            "EMAIL_ADDRESS": "frigoalfonso@frogoalfonso.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "LUGO",
            "COMPAGNY_NAME": "FRIGPRIGICOS ALFONSO",
            "LATITUDE": "43.04686",
            "LONGITUDE": "-7.571564",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jose Manuel DIAZ LOPEZ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:18:00,2:8:00:18:00,3:8:00:18:00,4:8:00:18:00,5:8:00:18:00"
        },
        {
            "ID": 480,
            "ADDRESS_LINE_1": "Pog. Ind. II",
            "ADDRESS_LINE_2": "C/Rio Frio, s/n",
            "CITY": "GUIJUELO (SALAMANCA)",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "37770",
            "MAIN_PHONE": "(+34) 923581705",
            "ALT_PHONE": "(+34) 676438508",
            "MOBILE_PHONE": "(+34) 676438508",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 923581705",
            "EMAIL_ADDRESS": "electrofrioguijuelo@electrofrioguijuelo.e.movistar.es",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "SALAMANCA",
            "COMPAGNY_NAME": "E.F.GUIJUELO",
            "LATITUDE": "40.56621",
            "LONGITUDE": "-5.666636",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": " Juan Pedro Martin",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:14:00:16:00:19:00,2:9:00:14:00:16:00:19:00,3:9:00:14:00:16:00:19:00,4:9:00:14:00:16:00:19:00,5:9:00:14:00:16:00:19:00,6::::,7::::"
        },
        {
            "ID": 483,
            "ADDRESS_LINE_1": "C/ Torres Quevedo, 14",
            "ADDRESS_LINE_2": "P.I La Grela",
            "CITY": "La Coruna",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "15008",
            "MAIN_PHONE": "(+34) 981 255249",
            "ALT_PHONE": "(+34) 607432836",
            "MOBILE_PHONE": "(+34) 608581142",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "t.becar@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "A. CORUÑA",
            "COMPAGNY_NAME": "T. BECAR",
            "LATITUDE": "43.354236",
            "LONGITUDE": "-8.425355",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Manolo LAGO   ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::19:00,2:9:00:::19:00,3:9:00:::19:00,4:9:00:::19:00,5:9:00:::19:00,6::::,7::::"
        },
        {
            "ID": 485,
            "ADDRESS_LINE_1": "Avd de las Palmeras 10 Nave 11-A",
            "ADDRESS_LINE_2": "Pog Ind La Sendilla",
            "CITY": "CIEMPOZUELO",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "28350",
            "MAIN_PHONE": "(+34) 91 8093613",
            "ALT_PHONE": "(+34) 659379976",
            "MOBILE_PHONE": "(+34) 665692664",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 91 8093593",
            "EMAIL_ADDRESS": "Ramirez.admon@siberiam.es",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "MADRID",
            "COMPAGNY_NAME": "SIBERIAM EQUIPOS, SL",
            "LATITUDE": "40.13356",
            "LONGITUDE": "-3.654483",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Manuel MATAMOROS",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:14:00:15:30:18:30,2:9:00:14:00:15:30:18:30,3:9:00:14:00:15:30:18:30,4:9:00:14:00:15:30:18:30,5:9:00:14:00:15:30:18:30,6::::,7::::"
        },
        {
            "ID": 606,
            "ADDRESS_LINE_1": "Calle Guijuelo 15",
            "ADDRESS_LINE_2": "",
            "CITY": "CASTELLANOS DE MORISCOS",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "37439",
            "MAIN_PHONE": "(+34) 923 355517",
            "ALT_PHONE": "(+34) 669 80 13 46",
            "MOBILE_PHONE": "(+34) 669 80 13 46",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "electrofriosalamanca@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "SALAMANCA",
            "COMPAGNY_NAME": "E.F.GUIJUELO",
            "LATITUDE": "41.0095",
            "LONGITUDE": "-5.608664",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Gonzalo MARTIN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00,6::::,7::::"
        },
        {
            "ID": 608,
            "ADDRESS_LINE_1": "Pog. Granadal",
            "ADDRESS_LINE_2": "C/ Gema 22",
            "CITY": "Córdoba",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "14014",
            "MAIN_PHONE": "(+34) 957257398",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+34) 619048426",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": " (+34) 957764357",
            "EMAIL_ADDRESS": "tallerescazorla@hotmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "CORDOBA",
            "COMPAGNY_NAME": "T. CAZORLA",
            "LATITUDE": "37.88968",
            "LONGITUDE": "-4.748358",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Bartolom",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00"
        },
        {
            "ID": 611,
            "ADDRESS_LINE_1": "pog. Ind Los Olivares",
            "ADDRESS_LINE_2": "C/ Mancha Real , 15",
            "CITY": "Jaén",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "23009",
            "MAIN_PHONE": "(+34) 953 280116",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "abslsefri@hotmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "JAEN",
            "COMPAGNY_NAME": "SERFRI",
            "LATITUDE": "37.80084",
            "LONGITUDE": "-3.780543",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Angel BUENO",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00"
        },
        {
            "ID": 612,
            "ADDRESS_LINE_1": "C/ Escritora Gertrudis Gomez de ",
            "ADDRESS_LINE_2": "Avellaneda Nº 12",
            "CITY": "Malaga",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "29196",
            "MAIN_PHONE": "(+34) 952 038509",
            "ALT_PHONE": "(+34) 653104648",
            "MOBILE_PHONE": "(+34) 616719200 ",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "remafries@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "MALAGA",
            "COMPAGNY_NAME": "REMASFRI MALAGA",
            "LATITUDE": "36.707889",
            "LONGITUDE": "-4.518701",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Manuel NAVARRO",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00,6::::,7::::"
        },
        {
            "ID": 614,
            "ADDRESS_LINE_1": "Parque empresarial de Antequera",
            "ADDRESS_LINE_2": "C/ Aragon 24",
            "CITY": "Antequera",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "29200",
            "MAIN_PHONE": "(+34) 952733537",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+34) 653518541",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@fiberglas.es",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "MALAGA",
            "COMPAGNY_NAME": "FIBERGLAS REPARACIONES",
            "LATITUDE": "37.041416",
            "LONGITUDE": "-4.525779",
            "DD": "0",
            "TRUCK": "0",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Javier SÁNCHEZ",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00,6::::,7::::"
        },
        {
            "ID": 615,
            "ADDRESS_LINE_1": "Crt H-101 PK 0,2",
            "ADDRESS_LINE_2": "Parque Huelva Empresarial, Parc 1,5",
            "CITY": "Huelva",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "21007",
            "MAIN_PHONE": "(+34) 959232408",
            "ALT_PHONE": " (+34) 650493633",
            "MOBILE_PHONE": "(+34) 619712631",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": " (+34) 959230339",
            "EMAIL_ADDRESS": "asesor@camarina.es",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "HUELVA",
            "COMPAGNY_NAME": "T. CAMARINA",
            "LATITUDE": "37.303322",
            "LONGITUDE": "-6.886775",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Manuel Martin Garcia",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00"
        },
        {
            "ID": 622,
            "ADDRESS_LINE_1": "Ctra. Nacional VI , Km 395",
            "ADDRESS_LINE_2": "",
            "CITY": "Camponaraya",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "24410",
            "MAIN_PHONE": "(+34) 987450142",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+34) 650914891",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": " (+34) 987450064",
            "EMAIL_ADDRESS": "saygal@bosch-bcs.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "LEON",
            "COMPAGNY_NAME": "SAYGAL",
            "LATITUDE": "42.565977",
            "LONGITUDE": "-6.662178",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Miguel",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00"
        },
        {
            "ID": 631,
            "ADDRESS_LINE_1": "C/ Pamplona ",
            "ADDRESS_LINE_2": "ID05, Nº30",
            "CITY": "Parcela",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "06800",
            "MAIN_PHONE": "(+34) 924378591",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+34) 609543214",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 924378614",
            "EMAIL_ADDRESS": "tallerespelaez@hotmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "MERIDA",
            "COMPAGNY_NAME": "T. PELAEZ",
            "LATITUDE": "38.9275",
            "LONGITUDE": "-6.389652",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Agustin PELAEZ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00,6::::,7::::"
        },
        {
            "ID": 632,
            "ADDRESS_LINE_1": "Vía Galileo 7A",
            "ADDRESS_LINE_2": "Pog Ind Tambre",
            "CITY": "Santiago de Compostela",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "15890",
            "MAIN_PHONE": "(+34) 670392304",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+34) 670392303",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": " (+34) 981801253",
            "EMAIL_ADDRESS": "elfri@mundo-r.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "A. CORUÑA",
            "COMPAGNY_NAME": "ELFRI",
            "LATITUDE": "42.91141",
            "LONGITUDE": "-8.517788",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Jose Luis",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00"
        },
        {
            "ID": 633,
            "ADDRESS_LINE_1": "Pog Ind Pereiro de Aguilar",
            "ADDRESS_LINE_2": "Vial Principalm Parcelas 81, 82 y 83, Fase 2B",
            "CITY": " Pereiro de Aguilar (Ourense)",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "32792",
            "MAIN_PHONE": "(+34) 988 362046",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@hosfri.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Pereiro de Aguilar (Ourense)",
            "COMPAGNY_NAME": "HOSFRI ORENSE",
            "LATITUDE": "42.324367",
            "LONGITUDE": "-7.811246",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Samuel",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00,6::::,7::::"
        },
        {
            "ID": 636,
            "ADDRESS_LINE_1": "C/ Gremio de Toneleros 2",
            "ADDRESS_LINE_2": "Pog Ind Son Castello",
            "CITY": "Palma de Mallorca",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "07009",
            "MAIN_PHONE": "(+34) 971432049",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "almacentaller@telefonica.net",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "PALMA DE MALLORCA",
            "COMPAGNY_NAME": "T. MOREY",
            "LATITUDE": "39.604275",
            "LONGITUDE": "2.662092",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Cristobal",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00"
        },
        {
            "ID": 637,
            "ADDRESS_LINE_1": "Ctra. Ciudalella s/n",
            "ADDRESS_LINE_2": "",
            "CITY": "Es Mercadal",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "07740",
            "MAIN_PHONE": "(+34) 971375328",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 971375444",
            "EMAIL_ADDRESS": "joan@ponssegui.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "MENORCA (ISLAS BALEARES)",
            "COMPAGNY_NAME": "T. PONS SEGUI",
            "LATITUDE": "39.98861",
            "LONGITUDE": "4.087809",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Joan",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00"
        },
        {
            "ID": 639,
            "ADDRESS_LINE_1": "C/ Guincho 11",
            "ADDRESS_LINE_2": "",
            "CITY": "Arrecife",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "35500",
            "MAIN_PHONE": "(+34) 92880227",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": " (+34) 928807737",
            "EMAIL_ADDRESS": "autoairejaga@hotmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "LANZAROTE (ISLAS CANARIAS)",
            "COMPAGNY_NAME": "AUTOAIRE JAGA",
            "LATITUDE": "28.975424",
            "LONGITUDE": "-13.540392",
            "DD": "1",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Ivan Alonso",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00"
        },
        {
            "ID": 642,
            "ADDRESS_LINE_1": "Pog Ind Parc 25",
            "ADDRESS_LINE_2": "",
            "CITY": "San Adrian",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "31570",
            "MAIN_PHONE": "(+34) 948670526",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "talleresfelixsl@yahoo.es",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "NAVARRA",
            "COMPAGNY_NAME": "T. FELIX",
            "LATITUDE": "42.34106",
            "LONGITUDE": "-1.939806",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Félix RUIZ",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00"
        },
        {
            "ID": 643,
            "ADDRESS_LINE_1": "Pog. Ind Ibarzaharra 27",
            "ADDRESS_LINE_2": "Manzana 9 ",
            "CITY": "Valle de Trapaga",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "48510",
            "MAIN_PHONE": "(+34) 94 4723132",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+34 647994935",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": " +34 944723186",
            "EMAIL_ADDRESS": "tautoelectro@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "VIZCAYA",
            "COMPAGNY_NAME": "AUTO ELECTRO",
            "LATITUDE": "43.29582",
            "LONGITUDE": "-3.009186",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Fernando Orza",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00"
        },
        {
            "ID": 644,
            "ADDRESS_LINE_1": "Ctra. Zaragoza Pog Trambarria",
            "ADDRESS_LINE_2": "Camino de la Balsa 2",
            "CITY": "Alfaro",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "26540",
            "MAIN_PHONE": "(+34) 941180161",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+34) 672 175 157",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "luisma.tlv@mercedes-benz.es",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "LOGRO",
            "COMPAGNY_NAME": "T. LUIS VIDAL",
            "LATITUDE": "42.161129",
            "LONGITUDE": "-1.729252",
            "DD": "0",
            "TRUCK": "0",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Luis VIDAD",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00"
        },
        {
            "ID": 705,
            "ADDRESS_LINE_1": "Camino Patria s/n",
            "ADDRESS_LINE_2": "",
            "CITY": "Motril",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "18613",
            "MAIN_PHONE": "0034 958822810",
            "ALT_PHONE": "0034 600589139",
            "MOBILE_PHONE": "(+34) 646978140",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "0034 958605750",
            "EMAIL_ADDRESS": "24horascarrier@majumamotor.es",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Granada",
            "COMPAGNY_NAME": "MAJUMA MOTOR",
            "LATITUDE": "36.72645",
            "LONGITUDE": "-3.542165",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Manolo VILCKEZ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:13:00:15:00:19:00,2:9:00:13:00:15:00:19:00,3:9:00:13:00:15:00:19:00,4:9:00:13:00:15:00:19:00,5:9:00:13:00:15:00:19:00"
        },
        {
            "ID": 718,
            "ADDRESS_LINE_1": "Pog San Cristobal (Ampliacion)",
            "ADDRESS_LINE_2": "C/ Hidrogeno n 45",
            "CITY": "VALLADOLID",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "47012",
            "MAIN_PHONE": "+34 983 313781",
            "ALT_PHONE": "+34 649724248",
            "MOBILE_PHONE": "+34 649724248",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "+34 983 313781",
            "EMAIL_ADDRESS": "friovalladolid@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "VALLADOLID",
            "COMPAGNY_NAME": "Frio Valladolid",
            "LATITUDE": "41.60357",
            "LONGITUDE": "-4.699659",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Juan Pedro Martin MACHADO",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:19:00,2:9:00:19:00,3:9:00:19:00,4:9:00:19:00,5:9:00:19:00"
        },
        {
            "ID": 720,
            "ADDRESS_LINE_1": "Paraje la Ballabona",
            "ADDRESS_LINE_2": "Autovia Murcia-Almeria KM 537",
            "CITY": "Antas",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "04628",
            "MAIN_PHONE": "+35 950 619121",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "0",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "+34 950 452911",
            "EMAIL_ADDRESS": "carroceria@friocarroceriaseuropa.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "ALMERIA",
            "COMPAGNY_NAME": "Friocarroceias Europa",
            "LATITUDE": "37.28839",
            "LONGITUDE": "-1.936107",
            "DD": "0",
            "TRUCK": "0",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Pedro CASTILLIO / Andres PINTADO",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::19:00,2:9:00:::19:00,3:9:00:::19:00,4:9:00:::19:00,5:9:00:::19:00,6::::,7::::"
        },
        {
            "ID": 722,
            "ADDRESS_LINE_1": "Pog el Matorral",
            "ADDRESS_LINE_2": "C/ Arad n 14",
            "CITY": "Puerto del Rosario",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "35600",
            "MAIN_PHONE": "++34 928 163695",
            "ALT_PHONE": "+34 6 306 82143",
            "MOBILE_PHONE": "+34 6 293 62923",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "angel@jamdclima.es",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "CANARIAS",
            "COMPAGNY_NAME": "JAMD Climatizacion",
            "LATITUDE": "28.435261",
            "LONGITUDE": "-13.879595",
            "DD": "1",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Angel",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:19:00,2:9:00:19:00,3:9:00:19:00,4:9:00:19:00,5:9:00:19:00"
        },
        {
            "ID": 786,
            "ADDRESS_LINE_1": "Pog El Sagre - Area Niu",
            "ADDRESS_LINE_2": "C/ La Fusta, Parc 30-31",
            "CITY": "Lerida",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "25191",
            "MAIN_PHONE": "+34 973205808",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "(+34) 659330900",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(34) 973210914",
            "EMAIL_ADDRESS": "electroauto.llanas@hotmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Lerida",
            "COMPAGNY_NAME": "ELECTROAUTO",
            "LATITUDE": "41.62133",
            "LONGITUDE": "0.657678",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jordi LLANAS",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:19:00,2:9:00:19:00,3:9:00:19:00,4:9:00:19:00,5:9:00:19:00"
        },
        {
            "ID": 802,
            "ADDRESS_LINE_1": "C/ VENUS, 48 NAU 8 INT",
            "ADDRESS_LINE_2": "Pog ind Colon II",
            "CITY": "TERRASSA - BARCELONA",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "08228",
            "MAIN_PHONE": "+34 93 7839700",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "+34 696646917",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 937856965",
            "EMAIL_ADDRESS": "sfarres@grupjorsan.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "TERRASSA - BARCELONA",
            "COMPAGNY_NAME": "SERVEI ELECTRIC AUTOMOCIO S.L",
            "LATITUDE": "41.541285",
            "LONGITUDE": "2.032943",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "1",
            "CONTACT": "Santiago Farres",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:13:00:15:00:17:00,2:9:00:13:00:15:00:17:00,3:9:00:13:00:15:00:17:00,4:9:00:13:00:15:00:17:00,5:9:00:13:00:15:00:17:00"
        },
        {
            "ID": 806,
            "ADDRESS_LINE_1": "C/ Granadero, 26",
            "ADDRESS_LINE_2": "Pog Ind Arinaga fase 2",
            "CITY": "Agüimes, Las Palmas",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "35118",
            "MAIN_PHONE": "(+34) 928071161",
            "ALT_PHONE": "(+34) 652300802",
            "MOBILE_PHONE": "(+34) 652360321",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "arinaga2@radiadorespaco.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Agüimes, Las Palmas",
            "COMPAGNY_NAME": "Radiadores Paco",
            "LATITUDE": "27.865063",
            "LONGITUDE": "-15.425647",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Esther Vega",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:30,2:8:00:::17:30,3:8:00:::17:30,4:8:00:::17:30,5:8:00:::17:30,6::::,7::::"
        },
        {
            "ID": 817,
            "ADDRESS_LINE_1": "Parque Empresarial Príncipe Felipe PP-6",
            "ADDRESS_LINE_2": "Parcela 2B 12B",
            "CITY": "Guadix",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "18500",
            "MAIN_PHONE": "+34 958 330464",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34)958467419",
            "EMAIL_ADDRESS": "Carrier.audillisa@autodistribucion.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Granada",
            "COMPAGNY_NAME": "Auto Distribución Illiberis",
            "LATITUDE": "37.33779",
            "LONGITUDE": "-3.091567",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jose Carlos Martinez Hernandez",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:12:00:14:00:19:00,2:8:30:12:00:14:00:19:00,3:8:30:12:00:14:00:19:00,4:8:30:12:00:14:00:19:00,5:8:30:12:00:14:00:19:00,6:8:30:::14:00,7::::"
        },
        {
            "ID": 852,
            "ADDRESS_LINE_1": "C/ Noain Nº 11 (Ciudad del Transporte)",
            "ADDRESS_LINE_2": "",
            "CITY": "Imarcoain",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "31119",
            "MAIN_PHONE": "+34 948570660",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+34 619316542",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "efbehobia@hotmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Imarcoain",
            "COMPAGNY_NAME": "E.F.Behobia NAVARRA",
            "LATITUDE": "42.727924",
            "LONGITUDE": "-1.617152",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mauricio Ramirez",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:12:00:14:00:19:00,2:8:30:12:00:14:00:19:00,3:8:30:12:00:14:00:19:00,4:8:30:12:00:14:00:19:00,5:8:30:12:00:14:00:19:00"
        },
        {
            "ID": 860,
            "ADDRESS_LINE_1": "Pog Ind El Oliveral, C/ H",
            "ADDRESS_LINE_2": "Ribaroja del Turia",
            "CITY": "Valencia",
            "COUNTRY": "Spain",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "46394",
            "MAIN_PHONE": "+34 961116066",
            "ALT_PHONE": "+34 609575211",
            "MOBILE_PHONE": "+34 628527724",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@indufret.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Valencia",
            "COMPAGNY_NAME": "Indufret Valencia",
            "LATITUDE": "39.481469",
            "LONGITUDE": "-0.530036",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Domingo Ortiz Soler",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:30,2:8:00:::18:30,3:8:00:::18:30,4:8:00:::18:30,5:8:00:::18:30,6::::,7::::"
        },
        {
            "ID": 865,
            "ADDRESS_LINE_1": "Luxemburgo, Nº 5",
            "ADDRESS_LINE_2": "",
            "CITY": "Barcelona",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "8520",
            "MAIN_PHONE": "(+34) 627536155",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+34) 699848865",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@simmer.es",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Les Franqueses del Valles",
            "COMPAGNY_NAME": "SIMMER",
            "LATITUDE": "41.640956",
            "LONGITUDE": "2.281783",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Javier Fernandez o Jose Acuña",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": "",
            "MOBILEVAN_NUMBER": "",
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:00:18:30,2:9:00:13:30:15:00:18:30,3:9:00:13:30:15:00:18:30,4:9:00:13:30:15:00:18:30,5:9:00:13:30:15:00:18:30,6::::,7::::"
        },
        {
            "ID": 867,
            "ADDRESS_LINE_1": "CARRETERA GENERAL",
            "ADDRESS_LINE_2": "ARAYA -MAL PAIS,247KM.0,5 ",
            "CITY": "Tenerife",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "38550",
            "MAIN_PHONE": "(+34) 680567381",
            "ALT_PHONE": "(+34) 680567381",
            "MOBILE_PHONE": "(+34) 680567381",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "manfriauto@hotmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Arafo",
            "COMPAGNY_NAME": "MANFRIAUTO",
            "LATITUDE": "28.340931",
            "LONGITUDE": "-16.388932",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Rafael. Escobar",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": "",
            "MOBILEVAN_NUMBER": "",
            "STATUS": "1",
            "HOURS": "1::::,2::::,3::::,4::::,5::::,6::::,7::::"
        },
        {
            "ID": 868,
            "ADDRESS_LINE_1": "Unidad mobil",
            "ADDRESS_LINE_2": "",
            "CITY": "La Palma",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "38780",
            "MAIN_PHONE": "(+34) 619131887",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+34) 619131887",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "raul_aguilar@hotmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "La Palma",
            "COMPAGNY_NAME": "RAUL ADAN AGUIAR RODRIGUEZ",
            "LATITUDE": "28.68351",
            "LONGITUDE": "-17.76421",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Raul Adan Aguiar",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:15:00::,2:8:00:15:00::,3:8:00:15:00::,4:8:00:15:00::,5:8:00:15:00::,6:8:00:13:00::,7::::"
        },
        {
            "ID": 874,
            "ADDRESS_LINE_1": "C/ Valle de Fornela P-11",
            "ADDRESS_LINE_2": "Pog Indu de Léon Fase II",
            "CITY": "Léon",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "24009",
            "MAIN_PHONE": "+34 987346766",
            "ALT_PHONE": "+34 679828005",
            "MOBILE_PHONE": "+34 609818444",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@tecnofrioleon.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Léon",
            "COMPAGNY_NAME": "TECNOFRIO LEON S.L",
            "LATITUDE": "42.549382",
            "LONGITUDE": "-5.583239",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Javier Fidalgo Santos",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:00,2:9:00:13:30:15:30:19:00,3:9:00:13:30:15:30:19:00,4:9:00:13:30:15:30:19:00,5:9:00:13:30:15:30:19:00,6::::,7::::"
        },
        {
            "ID": 878,
            "ADDRESS_LINE_1": "Pog Ind Guadiel",
            "ADDRESS_LINE_2": "C/ Linares, 115-116",
            "CITY": "Guarroman",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "23210",
            "MAIN_PHONE": "(+34) 953 67 20 81   ",
            "ALT_PHONE": "(+34) 686 05 83 16",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34) 953 67 51 76",
            "EMAIL_ADDRESS": "recepcionjaen@autohandia.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Guarroman",
            "COMPAGNY_NAME": "T.M.E. Auto-Handia",
            "LATITUDE": "38.149767",
            "LONGITUDE": "-3.718967",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": " Aitor Morillas Nieto",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": "",
            "MOBILEVAN_NUMBER": "",
            "STATUS": "1",
            "HOURS": "1:8:00:::19:00,2:8:00:::19:00,3:8:00:::19:00,4:8:00:::19:00,5:8:00:::19:00,6::::,7::::"
        },
        {
            "ID": 880,
            "ADDRESS_LINE_1": "Avenida Alcodar 38-43",
            "ADDRESS_LINE_2": "Poligono Industrial Alcodar",
            "CITY": "Gandía",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "46701",
            "MAIN_PHONE": "(+34) 96 2507656 ",
            "ALT_PHONE": "(+34)  618330668",
            "MOBILE_PHONE": "(+34)  618330577",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "teamtrailergandia@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Valencia ",
            "COMPAGNY_NAME": "TEAMTRAILER, SL",
            "LATITUDE": "38.981514",
            "LONGITUDE": "-0.18256",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Juan Sarrio Ferrer",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:13:30:15:30:20:00,2:8:00:13:30:15:30:20:00,3:8:00:13:30:15:30:20:00,4:8:00:13:30:15:30:20:00,5:8:00:13:30:15:30:20:00,6:8:00:14:00::,7::::"
        },
        {
            "ID": 882,
            "ADDRESS_LINE_1": "Avd de Hijar, 39",
            "ADDRESS_LINE_2": "Almazora",
            "CITY": "Castellon",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "12550",
            "MAIN_PHONE": "+34 964 743550",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+34 618814892",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "vilafred@hotmail.es",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Castellon",
            "COMPAGNY_NAME": "VILAFRED",
            "LATITUDE": "39.964919",
            "LONGITUDE": "-0.081487",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Rafael Branchadell Ebri",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:14:00:16:00:20:00,2:9:00:14:00:16:00:20:00,3:9:00:14:00:16:00:20:00,4:9:00:14:00:16:00:20:00,5:9:00:14:00:16:00:20:00,6::::,7::::"
        },
        {
            "ID": 884,
            "ADDRESS_LINE_1": "C/ Sierra Morena 19",
            "ADDRESS_LINE_2": "",
            "CITY": "Pinto",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "28320",
            "MAIN_PHONE": "",
            "ALT_PHONE": "+34 615 60 03 29.",
            "MOBILE_PHONE": "+34  646 01 43 34",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "administracion@friopinto.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Pinto",
            "COMPAGNY_NAME": "Centro Tecnico Frio Pinto SL",
            "LATITUDE": "40.266993",
            "LONGITUDE": "-3.695099",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Elena Torres",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:14:00:15:30:19:00,2:8:30:14:00:15:30:19:00,3:8:30:14:00:15:30:19:00,4:8:30:14:00:15:30:19:00,5:8:30:14:00:15:30:19:00,6::::,7::::"
        },
        {
            "ID": 885,
            "ADDRESS_LINE_1": "Cami de les fabriques, S/N",
            "ADDRESS_LINE_2": "Sta Margarida i els Monjos",
            "CITY": "Barcelona",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "08730",
            "MAIN_PHONE": "+34 938186392, 938980831",
            "ALT_PHONE": "+34 677797894",
            "MOBILE_PHONE": "+34 638616688",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "taller@dieselpenedes.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Barcelona",
            "COMPAGNY_NAME": "SERVICETRUCK PREMIUM S.L",
            "LATITUDE": "41.31566",
            "LONGITUDE": "1.665685",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jose Madrid",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:14:00:16:00:20:00,2:9:00:14:00:16:00:20:00,3:9:00:14:00:16:00:20:00,4:9:00:14:00:16:00:20:00,5:9:00:14:00:16:00:20:00,6::::,7::::"
        },
        {
            "ID": 886,
            "ADDRESS_LINE_1": "Avda. Italia 12",
            "ADDRESS_LINE_2": "Centro de Transportes",
            "CITY": "Coslada",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "28820",
            "MAIN_PHONE": "+34 915879234",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+34 650849694",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "+34 91 6707426",
            "EMAIL_ADDRESS": "taller.coslada@siberiam.es",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Madrid",
            "COMPAGNY_NAME": "SIBERIAM FRIO INDUSTRIAL CENTRO",
            "LATITUDE": "40.436977",
            "LONGITUDE": "-3.553755",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Victot Alfonso HINCAPIE",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:14:00:15:00:18:00,2:9:00:14:00:15:00:18:00,3:9:00:14:00:15:00:18:00,4:9:00:14:00:15:00:18:00,5:9:00:14:00:15:00:18:00,6::::,7::::"
        },
        {
            "ID": 926,
            "ADDRESS_LINE_1": "Ctra. Madrid",
            "ADDRESS_LINE_2": "Km 261 Nave Izquierda",
            "CITY": "Benavente",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "49600",
            "MAIN_PHONE": "+34980633468",
            "ALT_PHONE": "+34676055435",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@tallerescasa.es",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Benavente",
            "COMPAGNY_NAME": "ELECTROMECANICOS CASA, S.L",
            "LATITUDE": "41.991407",
            "LONGITUDE": "-5.644541",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Raul Calderon Alonso",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:14:00:16:00:20:00,2:9:00:14:00:16:00:20:00,3:9:00:14:00:16:00:20:00,4:9:00:14:00:16:00:20:00,5:9:00:14:00:16:00:20:00,6::::,7::::"
        },
        {
            "ID": 931,
            "ADDRESS_LINE_1": "Autovía Sierra Nevada- Costa Tropical A44",
            "ADDRESS_LINE_2": "Vía de Servicio.",
            "CITY": "Peligros (Granada)",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "18210",
            "MAIN_PHONE": "(+34) 958330464",
            "ALT_PHONE": "(+34)  649957578",
            "MOBILE_PHONE": "(+34)  608046471",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "(+34)  958 467419",
            "EMAIL_ADDRESS": "Carrier.audillisa@autodistribucion.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Peligros (Granada)",
            "COMPAGNY_NAME": "AUTO DISTRIBUCION ILLIBERIS",
            "LATITUDE": "37.22644",
            "LONGITUDE": "-3.635671",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Juan Carlos Martinez Hernandez",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:30:::20:00,2:7:30:::20:00,3:7:30:::20:00,4:7:30:::20:00,5:7:30:::20:00,6:9:00:::14:00,7::::"
        },
        {
            "ID": 958,
            "ADDRESS_LINE_1": "Pog Ind La Granadina",
            "ADDRESS_LINE_2": "Camino de Fiel, 29",
            "CITY": "San Isidro (Alicante)",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "03349",
            "MAIN_PHONE": "(+34) 693 78 04 62",
            "ALT_PHONE": "(+34) 667 86 36 44",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "admon3@polifret.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "San Isidro (Alicante)",
            "COMPAGNY_NAME": "Polifret San Isidro",
            "LATITUDE": "38.169729",
            "LONGITUDE": "-0.853003",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Constantino Cerezo",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:14:00:15:30:18:30,2:8:30:14:00:15:30:18:30,3:8:30:14:00:15:30:18:30,4:8:30:14:00:15:30:18:30,5:8:30:14:00:15:30:18:30,6::::,7::::"
        },
        {
            "ID": 967,
            "ADDRESS_LINE_1": "C/ DES Mayans Nº 39",
            "ADDRESS_LINE_2": "Pog. Montecristo",
            "CITY": "San Antonio,  Ibiza",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "07800",
            "MAIN_PHONE": "(+34)  646376690",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "cuberoalgar@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "San Antonio,  Ibiza",
            "COMPAGNY_NAME": "CUBERO 2.0 C.B",
            "LATITUDE": "38.940872",
            "LONGITUDE": "1.415035",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "1",
            "CONTACT": "Esteban Cubero Algar",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:13:30:15:30:19:30,2:9:00:13:30:15:30:19:30,3:9:00:13:30:15:30:19:30,4:9:00:13:30:15:30:19:30,5:9:00:13:30:15:30:19:30,6::::,7::::"
        },
        {
            "ID": 982,
            "ADDRESS_LINE_1": "Bº San Martin s/n Parc 6",
            "ADDRESS_LINE_2": "Merca Santander",
            "CITY": "Peñacastillo",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "39011",
            "MAIN_PHONE": "(+34) 942355432",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+34) 607771771",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "+34942354220",
            "EMAIL_ADDRESS": "taller@tdfcantabria.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Peñacastillo",
            "COMPAGNY_NAME": "Reparaciones frigoríficas Cantabria",
            "LATITUDE": "43.442965",
            "LONGITUDE": "-3.822924",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jose Manuel Saldaña Ruiz",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:13:00:15:00:19:00,2:9:00:13:00:15:00:19:00,3:9:00:13:00:15:00:19:00,4:9:00:13:00:15:00:19:00,5:9:00:13:00:15:00:19:00,6::::,7::::"
        },
        {
            "ID": 983,
            "ADDRESS_LINE_1": "Calle Basaldea 9",
            "ADDRESS_LINE_2": "Poligono Industrial de Jundiz",
            "CITY": "Vitoria (Alava)",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "01015",
            "MAIN_PHONE": "(34) 945108448",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(34) 652125167",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "isabelcp036@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Vitoria (Alava)",
            "COMPAGNY_NAME": "Frio Muñoz Ortega",
            "LATITUDE": "42.839777",
            "LONGITUDE": "-2.730874",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Antonio Muñoz Ortega",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:13:00:15:00:19:00,2:9:00:13:00:15:00:19:00,3:9:00:13:00:15:00:19:00,4:9:00:13:00:15:00:19:00,5:9:00:13:00:15:00:19:00,6::::,7::::"
        },
        {
            "ID": 1042,
            "ADDRESS_LINE_1": "C/ Mare de Deu del Far, 15",
            "ADDRESS_LINE_2": "P.I. Mallolles, Vic",
            "CITY": "Barcelona",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "08500",
            "MAIN_PHONE": "+34 93 135 90 55",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+34 654 977 230",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "gestio@servitruck.cat",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Barcelona",
            "COMPAGNY_NAME": "Servitruck 2020, S.L.",
            "LATITUDE": "41.95216",
            "LONGITUDE": "2.27994",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Cory Jaro",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:13:00:15:00:19:00,2:9:00:13:00:15:00:19:00,3:9:00:13:00:15:00:19:00,4:9:00:13:00:15:00:19:00,5:9:00:13:00:15:00:19:00,6::::,7::::"
        },
        {
            "ID": 1059,
            "ADDRESS_LINE_1": "Carretera Nacional 634, KM. 382,700",
            "ADDRESS_LINE_2": "",
            "CITY": "Asturias",
            "COUNTRY": "",
            "COUNTRY_CODE": "ES",
            "POSTAL_CODE": "33580",
            "MAIN_PHONE": "+34 985 730 805",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+34 635 933 153",
            "ASSISTANCE_PHONE": "900993213",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Severino.cuervo@laruedagrupo.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Asturias",
            "COMPAGNY_NAME": "LA RUEDA, S.L.",
            "LATITUDE": "43.380645",
            "LONGITUDE": "-5.581013",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Severino CUERVO",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:13:00:15:00:19:00,2:8:30:13:00:15:00:19:00,3:8:30:13:00:15:00:19:00,4:8:30:13:00:15:00:19:00,5:8:30:13:00:15:00:19:00,6::::,7::::"
        },
        {
            "ID": 69,
            "ADDRESS_LINE_1": "Tiilitie 4",
            "ADDRESS_LINE_2": "",
            "CITY": "Nastola",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "15560",
            "MAIN_PHONE": "(+358) 37 625 385",
            "ALT_PHONE": "(+358) 400 355 610",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "(+358) 37 625 387",
            "EMAIL_ADDRESS": "ttoy@thermo-tek.fi",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Lahti",
            "COMPAGNY_NAME": "THERMO-TEK OY",
            "LATITUDE": "60.945567",
            "LONGITUDE": "25.903552",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ari LAVIKKA",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:16:00,2:7:00:16:00,3:7:00:16:00,4:7:00:16:00,5:7:00:16:00"
        },
        {
            "ID": 70,
            "ADDRESS_LINE_1": "Metsalinnankatu 31",
            "ADDRESS_LINE_2": "",
            "CITY": "Huittinen",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "32700",
            "MAIN_PHONE": "(+358) 400 632 975",
            "ALT_PHONE": "(+358) 400 632 975",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "(+358) 2 569 371",
            "EMAIL_ADDRESS": "petri.savolainen@dnainternet.net",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Huittinen",
            "COMPAGNY_NAME": "PESA-SERVICE OY",
            "LATITUDE": "61.165904",
            "LONGITUDE": "22.726113",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Petri SAVOLAINEN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:16:00,2:8:00:16:00,3:8:00:16:00,4:8:00:16:00,5:8:00:16:00"
        },
        {
            "ID": 71,
            "ADDRESS_LINE_1": "Laukaantie 9",
            "ADDRESS_LINE_2": "",
            "CITY": "Jyväskylä",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "40350",
            "MAIN_PHONE": "(+358) 14 637 680",
            "ALT_PHONE": "(+358) 50 3308980",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "(+358) 14 637 681",
            "EMAIL_ADDRESS": "refrigo@kolumbus.fi",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Jyväskylä",
            "COMPAGNY_NAME": "REFRIGO",
            "LATITUDE": "62.283266",
            "LONGITUDE": "25.806026",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Juha KALLANTIE",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,5:8:00:17:00"
        },
        {
            "ID": 73,
            "ADDRESS_LINE_1": "Rataskatu 18",
            "ADDRESS_LINE_2": "",
            "CITY": "Seinäjoki",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "60320",
            "MAIN_PHONE": "(+358) 500 687241",
            "ALT_PHONE": "(+358) 500 687241",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "(+358) 6 4288504",
            "EMAIL_ADDRESS": "konepalvelu@suomi24.fi",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Seinäjoki",
            "COMPAGNY_NAME": "KONEPALVELU LAMMI",
            "LATITUDE": "62.786144",
            "LONGITUDE": "22.768006",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tero LAMMI",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": ""
        },
        {
            "ID": 80,
            "ADDRESS_LINE_1": "Arinakatu 9",
            "ADDRESS_LINE_2": "",
            "CITY": "Mikkeli",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "50170",
            "MAIN_PHONE": "(+358) 10 289 6922",
            "ALT_PHONE": "(+358) 50 373 2359",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "(+358) 10 289 6937",
            "EMAIL_ADDRESS": "jan-markus.heikkinen@raskaspari.fi",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Mikkeli",
            "COMPAGNY_NAME": "RASKASPARI OY",
            "LATITUDE": "61.707787",
            "LONGITUDE": "27.32052",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jan-Markus HEIKKINEN",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": ""
        },
        {
            "ID": 81,
            "ADDRESS_LINE_1": "Lukkotie 9",
            "ADDRESS_LINE_2": "",
            "CITY": "Joensuu",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "80100",
            "MAIN_PHONE": "(+358) 10 289 6902",
            "ALT_PHONE": "(+358) 10 289 6900",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "(+358) 10 289 6917",
            "EMAIL_ADDRESS": "petri.keronen@raskaspari.fi",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Joensuu",
            "COMPAGNY_NAME": "RASKASPARI OY",
            "LATITUDE": "62.6242",
            "LONGITUDE": "29.770889",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Petri KERONEN",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": ""
        },
        {
            "ID": 82,
            "ADDRESS_LINE_1": "Juustotie 14",
            "ADDRESS_LINE_2": "",
            "CITY": "Toholampi",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "69300",
            "MAIN_PHONE": "(+358) 44 309 4273",
            "ALT_PHONE": "(+358) 44 309 4273",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "(+358) 44 309 4273",
            "EMAIL_ADDRESS": "tuomas.peltokorpi@kotinet.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Toholampi",
            "COMPAGNY_NAME": "KYLMAHUOLTO T PELTOKORPI",
            "LATITUDE": "63.784989",
            "LONGITUDE": "24.270601",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tuomas PELTOKORPI",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:16:00,2:8:00:16:00,3:8:00:16:00,4:8:00:16:00,5:8:00:16:00"
        },
        {
            "ID": 83,
            "ADDRESS_LINE_1": "Karjalankatu 10",
            "ADDRESS_LINE_2": "",
            "CITY": "Kouvola",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "45130",
            "MAIN_PHONE": "(+358) 40 534 2465",
            "ALT_PHONE": "(+358) 40 534 2465",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "(+358) 73 1014 0323",
            "EMAIL_ADDRESS": "auto.kylma@elisanet.fi",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kouvola",
            "COMPAGNY_NAME": "AUTO-KYLMÄ P. HÅLLMAN",
            "LATITUDE": "60.879055",
            "LONGITUDE": "26.763082",
            "DD": "0",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Petri HÅLMAN",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:16:00,2:8:00:16:00,3:8:00:16:00,4:8:00:16:00,5:8:00:16:00"
        },
        {
            "ID": 587,
            "ADDRESS_LINE_1": "Luolakalliontie 5",
            "ADDRESS_LINE_2": "",
            "CITY": "Lieto",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "21420",
            "MAIN_PHONE": "(+358) (0) 20 798 0280",
            "ALT_PHONE": "(+358) (0) 400 947 244",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "(+358) (0) 207 980 288",
            "EMAIL_ADDRESS": "markku.jyranti@thermohuolto.fi",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Turku",
            "COMPAGNY_NAME": "TURUN THERMOHUOLTO OY",
            "LATITUDE": "60.463132",
            "LONGITUDE": "22.431834",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Markku JYR",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:16:00,2:7:00:16:00,3:7:00:16:00,4:7:00:16:00,5:7:00:16:00"
        },
        {
            "ID": 588,
            "ADDRESS_LINE_1": "Johtokatu 6",
            "ADDRESS_LINE_2": "",
            "CITY": "Mikkeli",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "50130",
            "MAIN_PHONE": "(+358) (0) 400 554 511",
            "ALT_PHONE": "(+358) (0) 400 554 511",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "juha.hytonen@alklaite.fi",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Mikkeli",
            "COMPAGNY_NAME": "ALK-LAITE KY",
            "LATITUDE": "61.711194",
            "LONGITUDE": "27.281134",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Juha HYT",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:16:00,2:7:00:16:00,3:7:00:16:00,4:7:00:16:00,5:7:00:16:00"
        },
        {
            "ID": 589,
            "ADDRESS_LINE_1": "Kranaattikuja 1",
            "ADDRESS_LINE_2": "",
            "CITY": "Kuopio",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "70800",
            "MAIN_PHONE": "(+358) (0) 503 063 008",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "posti@kylmakuusisto.fi",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kuopio",
            "COMPAGNY_NAME": "KYLMÄKONEHUOLTO KUUSISTO OY",
            "LATITUDE": "62.867586",
            "LONGITUDE": "27.630723",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Karel KUUSISTO",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:16:00,2:7:00:16:00,3:7:00:16:00,4:7:00:16:00,5:7:00:16:00"
        },
        {
            "ID": 590,
            "ADDRESS_LINE_1": "Lumijoentie 8",
            "ADDRESS_LINE_2": "",
            "CITY": "Oulu",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "90400",
            "MAIN_PHONE": "(+358) (0) 700 9425",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "heikki.jussila@mekaner.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Oulu",
            "COMPAGNY_NAME": "MEKANER OY",
            "LATITUDE": "64.994964",
            "LONGITUDE": "25.449829",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Heikki Jussila",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:16:00,2:7:00:16:00,3:7:00:16:00,4:7:00:16:00,5:7:00:16:00"
        },
        {
            "ID": 794,
            "ADDRESS_LINE_1": "Vesalantie 7",
            "ADDRESS_LINE_2": "",
            "CITY": "Pirkkala / Tampere",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "33960",
            "MAIN_PHONE": "+358 41 430 2934",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "+358 41 430 2934",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "flexmech@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Pirkkala / Tampere",
            "COMPAGNY_NAME": "FLEXMECH",
            "LATITUDE": "61.44638",
            "LONGITUDE": "23.64168",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Aki Siekkinen",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:17:00,2:9:00:17:00,3:9:00:17:00,4:9:00:17:00,5:9:00:17:00"
        },
        {
            "ID": 812,
            "ADDRESS_LINE_1": "Rahtarinkatu 1 (Vuosaari Harbour) ",
            "ADDRESS_LINE_2": "",
            "CITY": "Helsinki",
            "COUNTRY": "Finland",
            "COUNTRY_CODE": "FI",
            "POSTAL_CODE": "0980",
            "MAIN_PHONE": "+358 40 131 0900",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 113221",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@autocool.fi",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Helsinki",
            "COMPAGNY_NAME": "HELSINGIN AUTOKYLMA",
            "LATITUDE": "60.224206",
            "LONGITUDE": "25.169533",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jarno Ilmonen",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:19:00,2:7:00:19:00,3:7:00:19:00,4:7:00:19:00,5:7:00:19:00"
        },
        {
            "ID": 84,
            "ADDRESS_LINE_1": "Rue Louis armand",
            "ADDRESS_LINE_2": "Z.I. Jean Malèze",
            "CITY": "Bon Encontre",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "47240",
            "MAIN_PHONE": "(+33) 5 53 69 20 00",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 5 53 96 14 16",
            "EMAIL_ADDRESS": "petracco@wanadoo.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Agen",
            "COMPAGNY_NAME": "ETS PETRACCO",
            "LATITUDE": "44.182158",
            "LONGITUDE": "0.684135",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Robert PETRACCO",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 85,
            "ADDRESS_LINE_1": "ZI Les Milles",
            "ADDRESS_LINE_2": "85, rue Georges Claude",
            "CITY": "Aix-En-Provence",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "13852",
            "MAIN_PHONE": "(+33) 4 42 60 05 05",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": " (+33) 6 24 33 26 20",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "atf.aix@azur-trucks.net",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Aix-En-Provence",
            "COMPAGNY_NAME": "AGT AZUR TRUCK FROID 13 AIX EN PROVENCE",
            "LATITUDE": "43.491183",
            "LONGITUDE": "5.382051",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "David Castro",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 10,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:13:30:18:00,2:8:00:12:00:13:30:18:00,3:8:00:12:00:13:30:18:00,4:8:00:12:00:13:30:18:00,5:8:00:12:00:13:30:18:00,6::::,7::::"
        },
        {
            "ID": 86,
            "ADDRESS_LINE_1": "661 rue des platanes",
            "ADDRESS_LINE_2": "Les chauvauds",
            "CITY": "Champniers",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "16430",
            "MAIN_PHONE": "(+33) 5 45 95 57 31",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": " (+33) 6 08 53 53 96",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 5 45 25 04 19",
            "EMAIL_ADDRESS": "ppequin@cdfroid.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Champniers",
            "COMPAGNY_NAME": "CD FROID 16 Angouleme",
            "LATITUDE": "45.709356",
            "LONGITUDE": "0.183774",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Philippe PEQUIN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 87,
            "ADDRESS_LINE_1": "ZA des Césardes",
            "ADDRESS_LINE_2": "54, Chemin de la Croix",
            "CITY": "Seynod",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "74600",
            "MAIN_PHONE": "(+33) 4 50 52 67 13",
            "ALT_PHONE": "(+33) 800 913148",
            "MOBILE_PHONE": "(+33) 06 22 34 10 03 ",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 4 50 69 72 27",
            "EMAIL_ADDRESS": "Atf.annecy@azur-trucks.net",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Annecy",
            "COMPAGNY_NAME": "AGT AZUR TRUCK FROID (74) SEYNOD",
            "LATITUDE": "45.887161",
            "LONGITUDE": "6.074171",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Thomas Laurant",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:19:00,2:8:00:12:00:14:00:19:00,3:8:00:12:00:14:00:19:00,4:8:00:12:00:14:00:19:00,5:8:00:12:00:14:00:19:00,6::::,7::::"
        },
        {
            "ID": 88,
            "ADDRESS_LINE_1": "ZI Est d'Arras",
            "ADDRESS_LINE_2": "4 Rue Camille Guérin",
            "CITY": "Tilloy-Les-Mofflaines",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "62217",
            "MAIN_PHONE": "(+33) 3 21 58 11 00",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 3 21 58 20 68",
            "EMAIL_ADDRESS": "arras-froid-service@orange.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Arras",
            "COMPAGNY_NAME": "ARRAS FROID SERVICE",
            "LATITUDE": "50.289154",
            "LONGITUDE": "2.811728",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Michel PALASZ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:30,2:8:00:12:00:14:00:18:30,3:8:00:12:00:14:00:18:30,4:8:00:12:00:14:00:18:30,5:8:00:12:00:14:00:18:30,6::::,7::::"
        },
        {
            "ID": 90,
            "ADDRESS_LINE_1": "135 Av pierre Semard M I N Avignon",
            "ADDRESS_LINE_2": "Bâtiment K",
            "CITY": "Avignon",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "84000",
            "MAIN_PHONE": "(+33) 4 90 89 79 54",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 4 90 87 63 46",
            "EMAIL_ADDRESS": "sfma@ils.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Avignon",
            "COMPAGNY_NAME": "STATION FRIGORIFIQUE - M.I.N. AVIGNON",
            "LATITUDE": "43.933197",
            "LONGITUDE": "4.837782",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Bernard RICHET",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:19:00,2:8:00:12:00:14:00:19:00,3:8:00:12:00:14:00:19:00,4:8:00:12:00:14:00:19:00,5:8:00:12:00:14:00:19:00,6::::,7::::"
        },
        {
            "ID": 91,
            "ADDRESS_LINE_1": "248, Rue des Mousquetaires",
            "ADDRESS_LINE_2": "Z.I. de Juston",
            "CITY": "Castets Des Landes",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "40260",
            "MAIN_PHONE": "(+33) 5 58 55 05 91",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 5 58 55 05 13",
            "EMAIL_ADDRESS": "applic.froid40-64@wanadoo.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Dax",
            "COMPAGNY_NAME": "APPLIC FROID",
            "LATITUDE": "43.883186",
            "LONGITUDE": "-1.144627",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Eric ONDINA",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:17:00,6::::,7::::"
        },
        {
            "ID": 92,
            "ADDRESS_LINE_1": "Rue du Rond Buisson",
            "ADDRESS_LINE_2": "Z.I. de Thise",
            "CITY": "Thise",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "25220",
            "MAIN_PHONE": "(+33) 3 81 80 12 11",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 3 81 88 76 56",
            "EMAIL_ADDRESS": "frigest@wanadoo.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Besan",
            "COMPAGNY_NAME": "FRIG'EST",
            "LATITUDE": "47.267713",
            "LONGITUDE": "6.057544",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jean-Luc LONCHAMPT",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:19:00,2:8:00:12:00:14:00:19:00,3:8:00:12:00:14:00:19:00,4:8:00:12:00:14:00:19:00,5:8:00:12:00:14:00:19:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 93,
            "ADDRESS_LINE_1": "5, rue de Lugan",
            "ADDRESS_LINE_2": "Z.I. Lugan",
            "CITY": "Begles",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "33130",
            "MAIN_PHONE": "(+33) 5 56 85 99 54",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 5 56 49 61 39",
            "EMAIL_ADDRESS": "contact@applicfroid33.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Bordeaux",
            "COMPAGNY_NAME": "APPLIC FROID SARL",
            "LATITUDE": "44.788761",
            "LONGITUDE": "-0.539001",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Eric ONDINA",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 94,
            "ADDRESS_LINE_1": "6 Rue Barthélémy Thimonnier ",
            "ADDRESS_LINE_2": "",
            "CITY": "Bourg-En-Bresse",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "'01000",
            "MAIN_PHONE": "(+33) 4 74 23 34 51 ",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 6 16 33 86 54",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 4 74 22 98 89",
            "EMAIL_ADDRESS": "Atf.bourg@azur-trucks.net",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Bourg-En-Bresse",
            "COMPAGNY_NAME": "AGT AZUR TRUCK FROID (01) BOURG EN BRESSE",
            "LATITUDE": "46.218442",
            "LONGITUDE": "5.221081",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Pierre CHAVANEL",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 95,
            "ADDRESS_LINE_1": "Z.I. du Teinchurier",
            "ADDRESS_LINE_2": "",
            "CITY": "Brive-La-Gaillarde",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "19100",
            "MAIN_PHONE": "(+33) 5 55 87 57 52 ",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 6 78 87 93 02",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 5 55 87 57 80",
            "EMAIL_ADDRESS": "carrier.efs@wanadoo.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Brive-La-Gaillarde",
            "COMPAGNY_NAME": "EURO-FROID-SERVICE",
            "LATITUDE": "45.151084",
            "LONGITUDE": "1.481481",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "William PAULMIER",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:19:00,2:8:00:12:00:14:00:19:00,3:8:00:12:00:14:00:19:00,4:8:00:12:00:14:00:19:00,5:8:00:12:00:14:00:19:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 96,
            "ADDRESS_LINE_1": "Avenue de l'industrie",
            "ADDRESS_LINE_2": "",
            "CITY": "Giberville",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "14730",
            "MAIN_PHONE": "(+33) 2 31 35 61 80",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 2 31 35 61 76",
            "EMAIL_ADDRESS": "contact@laurentservices.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Giberville, Caen",
            "COMPAGNY_NAME": "LAURENT SERVICES",
            "LATITUDE": "49.169299",
            "LONGITUDE": "-0.281042",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Laurent GUELLE, Jean-François REBILLON",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 97,
            "ADDRESS_LINE_1": "11, rue de Muids",
            "ADDRESS_LINE_2": "",
            "CITY": "Ingre",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "45140",
            "MAIN_PHONE": "(+33) 2 38 72 56 60  ",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 2 38 72 56 60",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 2 38 72 56 63",
            "EMAIL_ADDRESS": "ECC3@wanadoo.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Orléans",
            "COMPAGNY_NAME": "SARL Equipement Carrosserie Climatisation",
            "LATITUDE": "47.931167",
            "LONGITUDE": "1.843193",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "David PEDRO",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:17:00"
        },
        {
            "ID": 98,
            "ADDRESS_LINE_1": "26, Route de Soissons",
            "ADDRESS_LINE_2": "",
            "CITY": "Chauny",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "02300",
            "MAIN_PHONE": "(+33) 3 23 39 14 40",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": " (+33) 6 88 38 58 27",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 3 23 39 20 12",
            "EMAIL_ADDRESS": "froidetapplications.jpj@wanadoo.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Chauny",
            "COMPAGNY_NAME": "FROID ET APPLICATIONS",
            "LATITUDE": "49.605364",
            "LONGITUDE": "3.223511",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jean-Pierre JEANNESSON",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:13:30:17:30,2:8:00:12:00:13:30:17:30,3:8:00:12:00:13:30:17:30,4:8:00:12:00:13:30:17:30,5:8:00:12:00:13:30:17:30,6::::,7::::"
        },
        {
            "ID": 99,
            "ADDRESS_LINE_1": "ZA Les Pradeaux",
            "ADDRESS_LINE_2": "9 Rue Gambettar",
            "CITY": "Gerzat",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "63360",
            "MAIN_PHONE": " (+33) 4 73 24 36 19",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 4 73 91 16 54",
            "EMAIL_ADDRESS": "Atf.clermont@azur-trucks.net",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Gerzat",
            "COMPAGNY_NAME": "AGT AZUR TRUCK FROID (63) GERZAT",
            "LATITUDE": "45.947241",
            "LONGITUDE": "3.144128",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Julien CONDUCHE",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 100,
            "ADDRESS_LINE_1": "ZAE de Boulouze",
            "ADDRESS_LINE_2": "",
            "CITY": "Fauverney",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "21110",
            "MAIN_PHONE": "(+33) 3 80 65 25 68",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 3 80 65 27 36",
            "EMAIL_ADDRESS": "frigest.dijon@wanadoo.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Fauverney",
            "COMPAGNY_NAME": "FRIG'EST",
            "LATITUDE": "47.259131",
            "LONGITUDE": "5.148064",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mathieu LONCHAMPT",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:19:00,2:8:00:12:00:14:00:19:00,3:8:00:12:00:14:00:19:00,4:8:00:12:00:14:00:19:00,5:8:00:12:00:14:00:19:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 101,
            "ADDRESS_LINE_1": "11, rue des Glairaux",
            "ADDRESS_LINE_2": "",
            "CITY": "Saint Egreve",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "38120",
            "MAIN_PHONE": "(+33) 4 76 75 33 85",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 4 76 75 68 57",
            "EMAIL_ADDRESS": "rcleyetmollard@durandservices.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Grenoble",
            "COMPAGNY_NAME": "DURAND SERVICES",
            "LATITUDE": "45.218899",
            "LONGITUDE": "5.675769",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Raphael CLEYET MOLLART",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00"
        },
        {
            "ID": 102,
            "ADDRESS_LINE_1": "Rue des Cerisiers",
            "ADDRESS_LINE_2": "Zone Industrielle",
            "CITY": "Les Gonds",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "17100",
            "MAIN_PHONE": "06 78 64 51 78",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 5 46 93 12 65",
            "EMAIL_ADDRESS": "ppequin@cdfroid.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Saintes",
            "COMPAGNY_NAME": "CD FROID 17",
            "LATITUDE": "45.708037",
            "LONGITUDE": "-0.625041",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Aléxis MACHEFERT or  Philippe PEQUIN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00"
        },
        {
            "ID": 103,
            "ADDRESS_LINE_1": "29, rue Ernest Sylvain Bollee",
            "ADDRESS_LINE_2": "",
            "CITY": "Arnage",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "72230",
            "MAIN_PHONE": "(+33) 2 43 21 63 91",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 2 43 21 65 84",
            "EMAIL_ADDRESS": "goupil-electro-diesel@wanadoo.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Le Mans",
            "COMPAGNY_NAME": "STATION ELECTRO DIESEL",
            "LATITUDE": "47.948805",
            "LONGITUDE": "0.179665",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Roland GOUPIL",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 104,
            "ADDRESS_LINE_1": "135, rue du Mont de Terre",
            "ADDRESS_LINE_2": "",
            "CITY": "Fretin",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "59273",
            "MAIN_PHONE": "(+33) 3 20 62 18 10",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "contact@atoutfroid.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Lille",
            "COMPAGNY_NAME": "ATOUT FROID SERVICES",
            "LATITUDE": "50.583262",
            "LONGITUDE": "3.131079",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Cyril DEBAS",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:8:00:12:30:13:30:18:00,2:8:00:12:30:13:30:18:00,3:8:00:12:30:13:30:18:00,4:8:00:12:30:13:30:18:00,5:8:00:12:30:13:30:18:00,6::::,7::::"
        },
        {
            "ID": 105,
            "ADDRESS_LINE_1": "Zi du Porzo",
            "ADDRESS_LINE_2": "",
            "CITY": "Kervignac",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "56700",
            "MAIN_PHONE": "(+33) 2 97 81 66 86",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 2 97 81 61 23",
            "EMAIL_ADDRESS": "efs.56@orange.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kervignac",
            "COMPAGNY_NAME": "EFS 56",
            "LATITUDE": "47.782261",
            "LONGITUDE": "-3.329957",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Joel LEREZOLIER",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:19:00,2:8:00:12:00:14:00:19:00,3:8:00:12:00:14:00:19:00,4:8:00:12:00:14:00:19:00,5:8:00:12:00:14:00:19:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 106,
            "ADDRESS_LINE_1": "21 rue Marcel Merieux",
            "ADDRESS_LINE_2": "",
            "CITY": "Corbas",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "69960",
            "MAIN_PHONE": "(+33) 4 72 50 31 52",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 623893253",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "atf.corbas@azur-trucks.net",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Lyon",
            "COMPAGNY_NAME": "AGT AZUR TRUCK FROID 69 CORBAS",
            "LATITUDE": "45.67073",
            "LONGITUDE": "4.920625",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Eric MEYER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 10,
            "MOBILEVAN_NUMBER": 5,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:13:30:18:00,2:8:00:12:00:13:30:18:00,3:8:00:12:00:13:30:18:00,4:8:00:12:00:13:30:18:00,5:8:00:12:00:13:30:18:00,6::::,7::::"
        },
        {
            "ID": 107,
            "ADDRESS_LINE_1": "ZI TILLY",
            "ADDRESS_LINE_2": "116 ROUTE DE THIONVILLE",
            "CITY": "Woippy",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "57140",
            "MAIN_PHONE": "(+33) 3 87 33 20 12",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": " (+33) 6 78 40 19 31",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 3 87 31 36 60",
            "EMAIL_ADDRESS": "fca.metz.lorraine@orange.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Metz",
            "COMPAGNY_NAME": "FCA METZ-LORRAINE",
            "LATITUDE": "49.160778",
            "LONGITUDE": "6.157694",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Cyrille MULLER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 109,
            "ADDRESS_LINE_1": "Rue du Ponan",
            "ADDRESS_LINE_2": "ZI du Vern",
            "CITY": "Landivisiau",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "29400",
            "MAIN_PHONE": "(+33) 2 98 24 74 47",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) (0)6 07 57 17 38",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 2 98 24 98 88",
            "EMAIL_ADDRESS": "efs.29@orange.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Brest",
            "COMPAGNY_NAME": "SARL E.F.S. 29",
            "LATITUDE": "48.520101",
            "LONGITUDE": "-4.072688",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Matthieu LEMOINE",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 110,
            "ADDRESS_LINE_1": "ZAC extension Garosud",
            "ADDRESS_LINE_2": "1056 rue francois joseph gossec",
            "CITY": "Lattes, Montpellier",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "34970",
            "MAIN_PHONE": "(+33) 4 67 07 31 60",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 4 67 69 17 03 ",
            "EMAIL_ADDRESS": "frsmontpellier@yahoo.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Montpellier",
            "COMPAGNY_NAME": "FROID ROUTIER SERVICE - Montpellier",
            "LATITUDE": "43.580985",
            "LONGITUDE": "3.869221",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Oliver VERMOT",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 111,
            "ADDRESS_LINE_1": "863, rue Gustave Eiffel ",
            "ADDRESS_LINE_2": "ZI Dynapole",
            "CITY": "Ludres",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "54710",
            "MAIN_PHONE": "(+33) 3 83 25 77 55",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 3 83 25 28 46",
            "EMAIL_ADDRESS": "fca.nancy.lorraine@orange.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Nancy",
            "COMPAGNY_NAME": "FCA NANCY-LORRAINE",
            "LATITUDE": "48.61425",
            "LONGITUDE": "6.201684",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Didier ICHTERTZ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 112,
            "ADDRESS_LINE_1": "ZI LA Gesvrine",
            "ADDRESS_LINE_2": "4 Rue Ampère",
            "CITY": "La Chapelle Sur Erdre",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "44240",
            "MAIN_PHONE": "(+33) 2 40 93 52 81",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "philippe.gabette@efs-44.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Nantes",
            "COMPAGNY_NAME": "AGT BS Invest - EFS 44",
            "LATITUDE": "47.259544",
            "LONGITUDE": "-1.552294",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Philippe Gabette",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 113,
            "ADDRESS_LINE_1": "947 Chemin des Iscles",
            "ADDRESS_LINE_2": "",
            "CITY": "Saint Laurent Du Var",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "06700",
            "MAIN_PHONE": "(+33) 4 92 13 80 66",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 4 93 89 33 49",
            "EMAIL_ADDRESS": "aft.06@azur-trucks.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Nice",
            "COMPAGNY_NAME": "FROID ROUTIER Cote D'Azur",
            "LATITUDE": "43.708303",
            "LONGITUDE": "7.18338",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Benjamin JUMEL",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 114,
            "ADDRESS_LINE_1": "5, Avenue de Vistrenque",
            "ADDRESS_LINE_2": "Zone Euro 2000",
            "CITY": "Caissargues Village",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "30132",
            "MAIN_PHONE": "(+33) 4 66 87 21 85",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 4 66 87 21 85",
            "EMAIL_ADDRESS": "frsnimes@yahoo.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Nimes",
            "COMPAGNY_NAME": "FROID ROUTIER SERVICE",
            "LATITUDE": "43.795429",
            "LONGITUDE": "4.384387",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Olivier VERMOT",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 115,
            "ADDRESS_LINE_1": "3 Rue du Pontpin",
            "ADDRESS_LINE_2": "ZA de l'Ecluse",
            "CITY": "Yffiniac",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "22120",
            "MAIN_PHONE": "(+33) 2 96 72 70 89",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 6 84 17 97 79",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 2 96 72 52 34",
            "EMAIL_ADDRESS": "emeraude-froid-service@wanadoo.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Saint-Brieuc",
            "COMPAGNY_NAME": "EMERAUDE FROID SERVICE",
            "LATITUDE": "48.477246",
            "LONGITUDE": "-2.670209",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jérôme BERNAZZANI",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00:14:00:17:00,7::::"
        },
        {
            "ID": 116,
            "ADDRESS_LINE_1": "Rue Louis Antoine de Bougainville",
            "ADDRESS_LINE_2": "Z.A. Les Grands Albert",
            "CITY": "La Crêche",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "79260",
            "MAIN_PHONE": "05 49 75 05 32",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 5 49 75 08 95",
            "EMAIL_ADDRESS": "ppequin@cdfroid.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Niort",
            "COMPAGNY_NAME": "CD FROID 79",
            "LATITUDE": "46.34944",
            "LONGITUDE": "-0.296249",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Philippe PEQUIN  or  Yann BRAUD ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 117,
            "ADDRESS_LINE_1": "Z.I. Saint Charles",
            "ADDRESS_LINE_2": "Avenue de Milan",
            "CITY": "Perpignan",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "66000",
            "MAIN_PHONE": "(+33) 4 68 55 38 74",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 4 68 55 76 10",
            "EMAIL_ADDRESS": "perpignan@froidroutierservice.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Perpignan",
            "COMPAGNY_NAME": "FROID ROUTIER SERVICE",
            "LATITUDE": "42.690674",
            "LONGITUDE": "2.854541",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Stephane L'HOMME",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 118,
            "ADDRESS_LINE_1": "Z I de la Turbanière",
            "ADDRESS_LINE_2": "",
            "CITY": "Brece",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "35530",
            "MAIN_PHONE": "(+33) 2 99 50 85 11",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "mikael.bizette@efs-35.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Rennes",
            "COMPAGNY_NAME": "AGT BS Invest - EFS 35",
            "LATITUDE": "48.109344",
            "LONGITUDE": "-1.504912",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mikael BIZETTE",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:13:00:18:00,2:8:00:12:00:13:00:18:00,3:8:00:12:00:13:00:18:00,4:8:00:12:00:13:00:18:00,5:8:00:12:00:13:00:18:00,6::::,7::::"
        },
        {
            "ID": 119,
            "ADDRESS_LINE_1": "15 Avenue du Commandant Bicheray",
            "ADDRESS_LINE_2": "M.I.N. de Rouen",
            "CITY": "Rouen",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "76042",
            "MAIN_PHONE": "(+33) 2 35 80 19 77",
            "ALT_PHONE": " (+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "christophe.lefebvre@efs-76.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Rouen",
            "COMPAGNY_NAME": "AGT BS Invest - EFS 76",
            "LATITUDE": "49.449818",
            "LONGITUDE": "1.050417",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Christophe Lefebvre",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:13:30:18:00,2:8:00:12:00:13:30:18:00,3:8:00:12:00:13:30:18:00,4:8:00:12:00:13:30:18:00,5:8:00:12:00:13:30:18:00,6::::,7::::"
        },
        {
            "ID": 120,
            "ADDRESS_LINE_1": "11 Avenue Jeanne Garnerin",
            "ADDRESS_LINE_2": "",
            "CITY": "Wissous",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "91320",
            "MAIN_PHONE": "(+33) 1 60 19 78 80",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "stephane.delasalle@efs-91.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Wissous",
            "COMPAGNY_NAME": "AGT - BS Invest EFS 91",
            "LATITUDE": "48.728814",
            "LONGITUDE": "2.313678",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Stephane Delasalle",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:00:::18:00,2:7:00:::18:00,3:7:00:::18:00,4:7:00:::18:00,5:7:00:::18:00,6::::,7::::"
        },
        {
            "ID": 121,
            "ADDRESS_LINE_1": "ZA Neptune 1",
            "ADDRESS_LINE_2": "Rue Louise Michel",
            "CITY": "Saint-Lô",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "50000",
            "MAIN_PHONE": "(+33) 2 33 05 80 80",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 2 33 05 51 31",
            "EMAIL_ADDRESS": "bc@laurentservices.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Saint-Lô",
            "COMPAGNY_NAME": "LAURENT REFRIGERATION TRANSPORT  ",
            "LATITUDE": "49.101184",
            "LONGITUDE": "-1.071017",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Bertrand CARABIE, Jean-François REBILLON",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 122,
            "ADDRESS_LINE_1": "ZAC de la villette aux aulnes",
            "ADDRESS_LINE_2": "9 rue rené Cassin",
            "CITY": "Mitry Mory",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "77290",
            "MAIN_PHONE": "(+33) 1 60 03 00 38",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "jeanjacques.cornet@efs-77.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Paris-Nord",
            "COMPAGNY_NAME": "AGT BS Invest EFS 77",
            "LATITUDE": "48.969217",
            "LONGITUDE": "2.574985",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jean-Jacques Cornet  Nicolas Neang",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:13:30:18:00,2:8:00:12:00:13:30:18:00,3:8:00:12:00:13:30:18:00,4:8:00:12:00:13:30:18:00,5:8:00:12:00:13:30:18:00,6::::,7::::"
        },
        {
            "ID": 123,
            "ADDRESS_LINE_1": "1, Impasse Joseph Imbs",
            "ADDRESS_LINE_2": "",
            "CITY": "Holtzheim",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "67810",
            "MAIN_PHONE": "(+33) 3 88 10 10 30",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 6 74 61 59 77",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 3 90 20 29 02 ",
            "EMAIL_ADDRESS": "fca.alsace@fca-groupe.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Holtzheim",
            "COMPAGNY_NAME": "FCA ALSACE (67) Strasbourg",
            "LATITUDE": "48.559118",
            "LONGITUDE": "7.65303",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Fabien Crépin",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 124,
            "ADDRESS_LINE_1": "133 avenue de Bergeron",
            "ADDRESS_LINE_2": "",
            "CITY": "Bruguieres",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "31150",
            "MAIN_PHONE": "+33 5 34 27 38 60",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 5 34 27 38 69",
            "EMAIL_ADDRESS": "mm@tmes31.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Toulouse",
            "COMPAGNY_NAME": "TMES",
            "LATITUDE": "43.702955",
            "LONGITUDE": "1.404212",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Romuald MARIE",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 125,
            "ADDRESS_LINE_1": "38, rue de suède",
            "ADDRESS_LINE_2": "",
            "CITY": "Tours",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "37100",
            "MAIN_PHONE": "(+33) 2 47 51 08 01",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 2 47 51 07 60",
            "EMAIL_ADDRESS": "goupil-electro-diesel@wanadoo.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Tours",
            "COMPAGNY_NAME": "STATION ELECTRO DIESEL",
            "LATITUDE": "47.43405",
            "LONGITUDE": "0.688695",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Laurent REDON",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 126,
            "ADDRESS_LINE_1": " 70 avenue du pdt John Fitzgerald Kennedy",
            "ADDRESS_LINE_2": "P. A de Limoges Sud",
            "CITY": "Limoges",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "87000",
            "MAIN_PHONE": "(+33) 5 55 09 81 22",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 6 78 87 93 02",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 5 55 06 16 89",
            "EMAIL_ADDRESS": "carrier.efs@wanadoo.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Limoges",
            "COMPAGNY_NAME": "EUROFROID SERVICE",
            "LATITUDE": "45.804531",
            "LONGITUDE": "1.28458",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "William PAULMIER",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:19:00,2:8:00:12:00:14:00:19:00,3:8:00:12:00:14:00:19:00,4:8:00:12:00:14:00:19:00,5:8:00:12:00:14:00:19:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 127,
            "ADDRESS_LINE_1": "8 rue de l'Aubépine",
            "ADDRESS_LINE_2": "",
            "CITY": "La Veuve",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "51520",
            "MAIN_PHONE": "(+33) 3 26 71 10 26",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 6 78 00 32 21",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 3 26 64 54 31",
            "EMAIL_ADDRESS": "froid.champagne-ardenne@orange.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "La Veuve",
            "COMPAGNY_NAME": "FROID CHAMPAGNE ARDENNE",
            "LATITUDE": "49.036333",
            "LONGITUDE": "4.322389",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "François FERREIRA",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 128,
            "ADDRESS_LINE_1": "Route du Lamentin",
            "ADDRESS_LINE_2": "",
            "CITY": "Fort De France",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "97219",
            "MAIN_PHONE": "(+33) 5 96 75 59 39",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "+33 5 96 696 45 42 30",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 5 96 75 53 73",
            "EMAIL_ADDRESS": "caraibefroid@wanadoo.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Martinique",
            "COMPAGNY_NAME": "CARAÏBES FROID",
            "LATITUDE": "14.631497",
            "LONGITUDE": "-61.036842",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Maurice MAGDELONNETTE",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:12:00:13:30:16:00,2:7:30:12:00:13:30:16:00,3:7:30:12:00:13:30:16:00,4:7:30:12:00:13:30:16:00,5:7:30:12:00:13:30:16:00,6::::,7::::"
        },
        {
            "ID": 130,
            "ADDRESS_LINE_1": "B.P. 418",
            "ADDRESS_LINE_2": "",
            "CITY": "Papeete",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "98713",
            "MAIN_PHONE": "(00 689) 82 13 37",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(00 689) 83 13 14",
            "EMAIL_ADDRESS": "polynesie-froid@mail.pf",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Polyn",
            "COMPAGNY_NAME": "POLYNESIE FROID",
            "LATITUDE": "-17.534979",
            "LONGITUDE": "-149.569609",
            "DD": "0",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Bernard LYSER",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00"
        },
        {
            "ID": 131,
            "ADDRESS_LINE_1": "46 ZA Galmot",
            "ADDRESS_LINE_2": "",
            "CITY": "Cayenne",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "97300",
            "MAIN_PHONE": "05 94 38 34 75",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "06 94 23 25 91",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "05 94 38 34 73",
            "EMAIL_ADDRESS": "laurent.lay@transfroid.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Guyanes fran",
            "COMPAGNY_NAME": "TRANSFROID SERVICES",
            "LATITUDE": "4.92851",
            "LONGITUDE": "-52.328718",
            "DD": "0",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Laurent LAY",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00"
        },
        {
            "ID": 132,
            "ADDRESS_LINE_1": "18, Lotissement Gael Bretelle",
            "ADDRESS_LINE_2": "Salle d'Asile Petit Perou",
            "CITY": "Abymes",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "97139",
            "MAIN_PHONE": "0 590 84 81 68",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "06 90 56 55 25",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "0 590 84 71 01",
            "EMAIL_ADDRESS": "o.alexandre@wanadoo.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Guadeloupe",
            "COMPAGNY_NAME": "GUADELOUPE FROID ROUTIER SERVICE",
            "LATITUDE": "16.274047",
            "LONGITUDE": "-61.502581",
            "DD": "0",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Oliver ALEXANDRE",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00"
        },
        {
            "ID": 134,
            "ADDRESS_LINE_1": "Z.I. de Furiani",
            "ADDRESS_LINE_2": "",
            "CITY": "Bastia",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "20600",
            "MAIN_PHONE": "(+33) 4 95 38 50 17",
            "ALT_PHONE": " (+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 4 95 38 79 85",
            "EMAIL_ADDRESS": "giuliani.frc@orange.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bastia",
            "COMPAGNY_NAME": "FROID ROUTIER CORSE",
            "LATITUDE": "42.663535",
            "LONGITUDE": "9.439938",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Pascal GIULIANI",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 137,
            "ADDRESS_LINE_1": "",
            "ADDRESS_LINE_2": "",
            "CITY": "Reims",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "51000",
            "MAIN_PHONE": "(+33) 3 23 39 14 40",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 6 86 27 65 33 ",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 3 23 39 20 12",
            "EMAIL_ADDRESS": "froidetapplications.jpj@wanadoo.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Reims",
            "COMPAGNY_NAME": "FROID ET APPLICATIONS",
            "LATITUDE": "49.258346",
            "LONGITUDE": "4.031714",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Romuald STOS",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1::::,2::::,3::::,4::::,5::::,6::::,7::::"
        },
        {
            "ID": 138,
            "ADDRESS_LINE_1": "Mobile Center",
            "ADDRESS_LINE_2": "",
            "CITY": "Bourges",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "18000",
            "MAIN_PHONE": "+33 2 48 02 98 75",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "+33 6 64 09 74 07   +33 6 17 59 20 28",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "+33 2 38 72 56 63",
            "EMAIL_ADDRESS": "Ecc3@wanadoo.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bourges",
            "COMPAGNY_NAME": "SARL Equipment Carrosserie Climatisation",
            "LATITUDE": "47.081012",
            "LONGITUDE": "2.398782",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "David PEDRO",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:17:00,6::::,7::::"
        },
        {
            "ID": 476,
            "ADDRESS_LINE_1": "Zi du Capiscol",
            "ADDRESS_LINE_2": "8 rue Paul Langevin",
            "CITY": "Beziers",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "34500",
            "MAIN_PHONE": "(+33) 4 67 76 66 65",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 6 11 30 86 26 ",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 4 67 76 66 70",
            "EMAIL_ADDRESS": "beziers@froidroutierservice.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Beziers",
            "COMPAGNY_NAME": "FROID ROUTIER SERVICE Beziers",
            "LATITUDE": "43.333185",
            "LONGITUDE": "3.279505",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Matthieu RIGAL",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:19:00,2:8:00:12:00:14:00:19:00,3:8:00:12:00:14:00:19:00,4:8:00:12:00:14:00:19:00,5:8:00:12:00:14:00:19:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 659,
            "ADDRESS_LINE_1": "Mobile Center",
            "ADDRESS_LINE_2": "",
            "CITY": "Chartres",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "28000",
            "MAIN_PHONE": "(+33) 2 38 72 56 60",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 6 17 59 20 28",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 2 38 72 56 63",
            "EMAIL_ADDRESS": "ECC3@wanadoo.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Chartres",
            "COMPAGNY_NAME": "SARL Equipement Carrosserie Climatisation",
            "LATITUDE": "48.445088",
            "LONGITUDE": "1.528945",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "David PEDRO",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:17:00,6::::,7::::"
        },
        {
            "ID": 695,
            "ADDRESS_LINE_1": "ZA de l'etang",
            "ADDRESS_LINE_2": "",
            "CITY": "Chateauneuf Du Rhone, Montelimar",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "28780",
            "MAIN_PHONE": "04 75 00 22 52",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "04 74 0091 14",
            "EMAIL_ADDRESS": "frsmontelimer@yahoo.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Chateauneuf Du Rhone, Montelimar",
            "COMPAGNY_NAME": "FROID ROUTIER SERVICE - Montelimar",
            "LATITUDE": "44.493333",
            "LONGITUDE": "4.75",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Stephane GIAIOURAS",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6::::,7::::"
        },
        {
            "ID": 704,
            "ADDRESS_LINE_1": "Résurgat 2",
            "ADDRESS_LINE_2": "14 rue RENE CASSIN ",
            "CITY": "Outreau",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "62230",
            "MAIN_PHONE": "03 21 87 26 96",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "03 21 10 37 26",
            "EMAIL_ADDRESS": "cr@boulognefroid.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Outreau",
            "COMPAGNY_NAME": "BOULOGNE FROID SERVICES",
            "LATITUDE": "50.70478",
            "LONGITUDE": "1.60678",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Christian RICART",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 741,
            "ADDRESS_LINE_1": "12 Av Jean Joxé",
            "ADDRESS_LINE_2": "M.I.N. d’ANGERS",
            "CITY": "Angers",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "49109",
            "MAIN_PHONE": "06 08 83 42 07",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "06 08 83 42 07    06 85 71 14 25",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "05 49 75 08 95",
            "EMAIL_ADDRESS": "clecourt@cdfroid.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Angers",
            "COMPAGNY_NAME": "CD Froid Angers",
            "LATITUDE": "47.481066",
            "LONGITUDE": "-0.543146",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Cyril LECOURT or Philippe PEQUIN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 745,
            "ADDRESS_LINE_1": "Mobil van based in : Zone de Mongie",
            "ADDRESS_LINE_2": "",
            "CITY": "Les Essarts",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "85140",
            "MAIN_PHONE": "06 85 05 74 71",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "06 85 71 14 25",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "05 49 75 08 95",
            "EMAIL_ADDRESS": "ppequin@cdfroid.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Les Essarts",
            "COMPAGNY_NAME": "CD Froid 85 La Roche Sur Yon",
            "LATITUDE": "46.78531",
            "LONGITUDE": "-1.188283",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "David CHACUN or Philippe PEQUIN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 746,
            "ADDRESS_LINE_1": "7 avenue Paul Langevin",
            "ADDRESS_LINE_2": "",
            "CITY": "Perigny",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "17180",
            "MAIN_PHONE": "06 73 69 09 95",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "06 73 69 09 95    06 85 71 14 25",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "05 46 44 93 43",
            "EMAIL_ADDRESS": "ppequin@cdfroid.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Perigny",
            "COMPAGNY_NAME": "CD Froid La Rochelle",
            "LATITUDE": "46.161445",
            "LONGITUDE": "-1.098713",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Romuald MARIE   Philippe PEQUIN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 747,
            "ADDRESS_LINE_1": "ZI de Caldaniccia",
            "ADDRESS_LINE_2": "",
            "CITY": "Sarrola Carcopino",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "20167",
            "MAIN_PHONE": "04 95 38 50 17",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "04 95 33 19 48",
            "EMAIL_ADDRESS": "giuliani.frc@orange.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Sarrola Carcopino",
            "COMPAGNY_NAME": "FROID ROUTIER CORSE AJACCIO",
            "LATITUDE": "41.993308",
            "LONGITUDE": "8.83269",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Vincent BARBIER",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 755,
            "ADDRESS_LINE_1": "45 Route de St Jean",
            "ADDRESS_LINE_2": "",
            "CITY": "Gap",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "05000",
            "MAIN_PHONE": "04 92 45 54 55",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "04 92 45 54 55",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "04 92 51 86 46",
            "EMAIL_ADDRESS": "barneaudtrucks@wanadoo.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Gap",
            "COMPAGNY_NAME": "BARNEAUD TRUCKS",
            "LATITUDE": "44.533899",
            "LONGITUDE": "6.04457",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Pierre  REBELLES",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 756,
            "ADDRESS_LINE_1": "1 Bld de Verdun",
            "ADDRESS_LINE_2": "BP 329",
            "CITY": "Aurillac",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "15003",
            "MAIN_PHONE": "04 71 64 99 92",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "+33 5 55 8757 52",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "04 71 64 60 20",
            "EMAIL_ADDRESS": "sylvain.fontalive@flaraud.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Aurillac",
            "COMPAGNY_NAME": "AURILIS / FAURAUD",
            "LATITUDE": "44.912532",
            "LONGITUDE": "2.440987",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sylvain FNTALIVE",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:::17:00,7::::"
        },
        {
            "ID": 798,
            "ADDRESS_LINE_1": "Mobile point",
            "ADDRESS_LINE_2": "",
            "CITY": "Cambon",
            "COUNTRY": "France",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "81990",
            "MAIN_PHONE": "+33 5 34 27 38 60",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "+33 5 34 27 38 69",
            "EMAIL_ADDRESS": "mm@tmes.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Albi",
            "COMPAGNY_NAME": "TMES (Mobile Point Albi)",
            "LATITUDE": "43.919414",
            "LONGITUDE": "2.126774",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Romuald MARIE",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 876,
            "ADDRESS_LINE_1": "2 rue Claude Chappe",
            "ADDRESS_LINE_2": "ZAC 2000",
            "CITY": "Le Port",
            "COUNTRY": "",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "97420",
            "MAIN_PHONE": "02 62 42 99 88",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "06 92 09 99 88",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "02 62 42 99 85",
            "EMAIL_ADDRESS": "cib.s@orange.fr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Le Port",
            "COMPAGNY_NAME": "Carrosserie Industrielle Bourgault SARL",
            "LATITUDE": "-20.949732",
            "LONGITUDE": "55.31711",
            "DD": "0",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": "",
            "MOBILEVAN_NUMBER": "",
            "STATUS": "1",
            "HOURS": "1::::,2::::,3::::,4::::,5::::,6::::,7::::"
        },
        {
            "ID": 932,
            "ADDRESS_LINE_1": "ZAC Grand Sud Logisitique",
            "ADDRESS_LINE_2": "7 rue Sepat                                                      ",
            "CITY": "Campsas",
            "COUNTRY": "",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "82370",
            "MAIN_PHONE": "05 63 64 88 96",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "06 45 06 34 31",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "05 63 64 71 48",
            "EMAIL_ADDRESS": "petracco82@orange.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Campsas",
            "COMPAGNY_NAME": "AGT PETRACCO (82) MONTAUBAN",
            "LATITUDE": "43.898239",
            "LONGITUDE": "1.310364",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Fabien PETRACCO                        ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 937,
            "ADDRESS_LINE_1": "ZAC de Nicopolis - RN7",
            "ADDRESS_LINE_2": "80 avenue des Lauriers",
            "CITY": "Brignoles",
            "COUNTRY": "",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "83170",
            "MAIN_PHONE": "(+33) 04 83 38 03 86",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 06 83 25 38 92  (+33) 06 07 24 80 40",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "p.gregoire@azur-trucks.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Brignoles",
            "COMPAGNY_NAME": "Froid Routier Cote D'Azur ",
            "LATITUDE": "43.39603",
            "LONGITUDE": "6.142473",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Steeve IGNACE",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 948,
            "ADDRESS_LINE_1": "ZA Arsac",
            "ADDRESS_LINE_2": "121 allée Durand de Gros",
            "CITY": "Ste Radegonde",
            "COUNTRY": "",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "12850",
            "MAIN_PHONE": "+33565678520",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "+33635342789",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "+33565672970",
            "EMAIL_ADDRESS": "rodez@froidroutierservice.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Ste Radegonde",
            "COMPAGNY_NAME": "FROID ROUTIER SERVICE Rodez",
            "LATITUDE": "44.36309",
            "LONGITUDE": "2.633255",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Stephane Labarthe",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 984,
            "ADDRESS_LINE_1": "42 avenue des frères lumière",
            "ADDRESS_LINE_2": "",
            "CITY": "Lons",
            "COUNTRY": "",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "64140",
            "MAIN_PHONE": "(+33) 5 59 62 38 06",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "(+33) 5 59 92 84 44",
            "EMAIL_ADDRESS": "bearn-frigo-route@wanadoo.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Lons",
            "COMPAGNY_NAME": "BEARN FRIGO ROUTE",
            "LATITUDE": "43.311645",
            "LONGITUDE": "-0.418479",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Eric Ondina",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00:14:00:17:00,7::::"
        },
        {
            "ID": 1010,
            "ADDRESS_LINE_1": "ZAC DU PUY D'ESBAN",
            "ADDRESS_LINE_2": "",
            "CITY": "Ytrac",
            "COUNTRY": "",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "15130",
            "MAIN_PHONE": "04 71 64 57 80",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "06 81 73 11 84  07 88 04 03 53 ",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "04 71 63 47 22",
            "EMAIL_ADDRESS": "vbouygues@hydrolec-services.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Aurillac",
            "COMPAGNY_NAME": "AGT HYDROLEC SERVICES (15) AURILLAC",
            "LATITUDE": "44.89611",
            "LONGITUDE": "2.393992",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Eric Bouygues",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:13:30:18:00,2:8:00:12:00:13:30:18:00,3:8:00:12:00:13:30:18:00,4:8:00:12:00:13:30:18:00,5:8:00:12:00:13:30:18:00,6:8:00:::12:00,7::::"
        },
        {
            "ID": 1011,
            "ADDRESS_LINE_1": "ZI SAINT MAURICE",
            "ADDRESS_LINE_2": "AVENUE SAINT MAURICE",
            "CITY": "Manosque",
            "COUNTRY": "",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "04100",
            "MAIN_PHONE": "(+33) 06 08 08 39 14",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 06 83 75 62 97",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "atf.04@azur-trucks.net",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Manosque",
            "COMPAGNY_NAME": "AZUR TUCK FROID 04",
            "LATITUDE": "43.797379",
            "LONGITUDE": "5.815581",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Loic Dimarcantonio",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:13:30:18:00,2:8:00:12:00:13:30:18:00,3:8:00:12:00:13:30:18:00,4:8:00:12:00:13:30:18:00,5:8:00:12:00:13:30:18:00,6::::,7::::"
        },
        {
            "ID": 1043,
            "ADDRESS_LINE_1": "14 Rue Ampère,",
            "ADDRESS_LINE_2": " ZI Ducos ",
            "CITY": "Nouméa",
            "COUNTRY": "",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "98800",
            "MAIN_PHONE": "+687 750 780",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "thermoparts@mls.nc",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Nouméa",
            "COMPAGNY_NAME": "Pacific Thermo Technik",
            "LATITUDE": "-22.2331",
            "LONGITUDE": "166.44723",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Damien Horner",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:11:30:12:30:16:30,2:7:30:11:30:12:30:16:30,3:7:30:11:30:12:30:16:30,4:7:30:11:30:12:30:16:30,5:7:30:11:30:12:30:15:30,6::::,7::::"
        },
        {
            "ID": 1052,
            "ADDRESS_LINE_1": "ZA des Morandières",
            "ADDRESS_LINE_2": "Rue Copernic",
            "CITY": "CHANGE",
            "COUNTRY": "",
            "COUNTRY_CODE": "FR",
            "POSTAL_CODE": "53810",
            "MAIN_PHONE": "(+33) 2 52 21 00 03",
            "ALT_PHONE": "(+33) 800 913 148",
            "MOBILE_PHONE": "(+33) 7 56 06 89 23",
            "ASSISTANCE_PHONE": "0800 913148",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Franck.saniard@efs-53.fr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "CHANGE",
            "COMPAGNY_NAME": "BS Invest - EFS 53",
            "LATITUDE": "48.09",
            "LONGITUDE": "-0.79",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Franck SANIARD",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 225,
            "ADDRESS_LINE_1": "141 Dromore Road",
            "ADDRESS_LINE_2": "Hillsborough",
            "CITY": "Co Down",
            "COUNTRY": "Ireland",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "BT26 6JA",
            "MAIN_PHONE": "(+44) 02892622530",
            "ALT_PHONE": "(+44) 7900492499",
            "MOBILE_PHONE": "(+44) 7900492499",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "(+44) 02892622540",
            "EMAIL_ADDRESS": "info@carrierni.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Lisburn",
            "COMPAGNY_NAME": "CARRIER NORTHERN IRELAND",
            "LATITUDE": "54.444217",
            "LONGITUDE": "-6.109343",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Vincent MARMION ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::17:30,2:9:00:::17:30,3:9:00:::17:30,4:9:00:::17:30,5:9:00:::17:30,6::::,7::::"
        },
        {
            "ID": 559,
            "ADDRESS_LINE_1": "Preston Farm Industrial Estate",
            "ADDRESS_LINE_2": "Boeing Way",
            "CITY": "Stockton-On-Tees",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "TS18 3TE",
            "MAIN_PHONE": "(+44) 1642 625450",
            "ALT_PHONE": "(+44) 800 521 442",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "(+44) 1642 614395",
            "EMAIL_ADDRESS": "aisha.ali@carriernorthern.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Stockton-On-Tees",
            "COMPAGNY_NAME": "CARRIER NORTHERN",
            "LATITUDE": "54.5462",
            "LONGITUDE": "-1.32706",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Aisha ALI",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:00,2:8:30:::17:00,3:8:30:::17:00,4:8:30:::17:00,5:8:30:::17:00,6::::,7::::"
        },
        {
            "ID": 562,
            "ADDRESS_LINE_1": "Unit 2 Bilton Industrial Estate",
            "ADDRESS_LINE_2": "Lovelace Road",
            "CITY": "Bracknell",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "RG12 8YT",
            "MAIN_PHONE": "(+44) 800 169 1012",
            "ALT_PHONE": "(+44) 800 521 442",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "(+44) 29 2085 8029",
            "EMAIL_ADDRESS": "service@carriersouthern.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bracknell",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD SOUTHERN",
            "LATITUDE": "51.406019",
            "LONGITUDE": "-0.775223",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Simon BAMFORD",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:30,2:8:30:::17:30,3:8:30:::17:30,4:8:30:::17:30,5:8:30:::17:30,6::::,7::::"
        },
        {
            "ID": 563,
            "ADDRESS_LINE_1": "1 Hallam Road,",
            "ADDRESS_LINE_2": "Henry Boot Way,",
            "CITY": "Hull",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "HU4 7DQ   ",
            "MAIN_PHONE": "(+44) 1482 587575",
            "ALT_PHONE": "(+44) 800 521 442",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "(+44) 1482 587999",
            "EMAIL_ADDRESS": "stewart@robsonrefrigeration.co.uk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Hull",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD EAST & NORTH YORKSHIRE",
            "LATITUDE": "53.725057",
            "LONGITUDE": "-0.409307",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Stewart ROBSON",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 7,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:30,2:8:30:::17:30,3:8:30:::17:30,4:8:30:::17:30,5:8:30:::17:30,6::::,7::::"
        },
        {
            "ID": 564,
            "ADDRESS_LINE_1": "Collins House",
            "ADDRESS_LINE_2": "Overbrook Lane ",
            "CITY": "Knowsley",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "L34 9FB",
            "MAIN_PHONE": "(+44) 151 547 7600",
            "ALT_PHONE": "(+44) 800 521 442",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "(+44) 151 547 7610",
            "EMAIL_ADDRESS": "service@cbrefrigeration.co.uk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Liverpool",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD NORTHWEST",
            "LATITUDE": "53.46",
            "LONGITUDE": "-2.8564",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Michael COLLINS, John WHITE ",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:00,2:8:30:::17:00,3:8:30:::17:00,4:8:30:::17:00,5:8:30:::17:00,6::::,7::::"
        },
        {
            "ID": 565,
            "ADDRESS_LINE_1": "Rotherham Road",
            "ADDRESS_LINE_2": "Maltby",
            "CITY": "Maltby",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "S66 8EL",
            "MAIN_PHONE": "(+44) 1302 344411",
            "ALT_PHONE": "(+44) 870 845 8000",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "(+44) 1709 810 213",
            "EMAIL_ADDRESS": "katrina.burns@pullmanfleet.co.uk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Doncaster",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD SOUTH & WEST YORKSHIRE",
            "LATITUDE": "53.42325",
            "LONGITUDE": "-1.22891",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Katrina BURNS",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 8,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 566,
            "ADDRESS_LINE_1": "48a Lichfield Street",
            "ADDRESS_LINE_2": "Fazeley",
            "CITY": "Tamworth",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "B78 3QN",
            "MAIN_PHONE": "(+44) 845 023 0339",
            "ALT_PHONE": "(+44) 870 845 8000",
            "MOBILE_PHONE": "(+44) 800 442 521",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "(+44) 1827 266417",
            "EMAIL_ADDRESS": "kevin.collins@cbrefrigeration.co.uk",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Tamworth",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD WEST MIDLANDS",
            "LATITUDE": "52.6154",
            "LONGITUDE": "-1.7023",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Kevin COLLINS",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 15,
            "MOBILEVAN_NUMBER": 15,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:00,2:8:30:::17:00,3:8:30:::17:00,4:8:30:::17:00,5:8:30:::17:00,6::::,7::::"
        },
        {
            "ID": 567,
            "ADDRESS_LINE_1": "C/O Garn Transport",
            "ADDRESS_LINE_2": "Wardentree Lane",
            "CITY": "Pinchbeck",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "PE11 3UG",
            "MAIN_PHONE": "(+44) 1775 712466",
            "ALT_PHONE": "(+44) 800 521 442",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "(+44) 1775 714126",
            "EMAIL_ADDRESS": "david.bent@pullmanfleet.co.uk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Spalding",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD LINCOLNSHIRE",
            "LATITUDE": "52.8094",
            "LONGITUDE": "-0.1365",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Dave BENT",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 18,
            "MOBILEVAN_NUMBER": 18,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 570,
            "ADDRESS_LINE_1": "18 Greenway",
            "ADDRESS_LINE_2": "Bedwas House Industrial Estate, Bedwas",
            "CITY": "Caerphilly",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "CF83 8DW",
            "MAIN_PHONE": "(+44) 800 169 1012",
            "ALT_PHONE": "(+44) 800 521 442",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "(+44) 29 2085 8029",
            "EMAIL_ADDRESS": "service@carriersouthern.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Caerphilly",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD SOUTHERN",
            "LATITUDE": "51.591896",
            "LONGITUDE": "-3.20554",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Simon BAMFORD",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:30,2:8:30:::17:30,3:8:30:::17:30,4:8:30:::17:30,5:8:30:::17:30,6::::,7::::"
        },
        {
            "ID": 571,
            "ADDRESS_LINE_1": "Unit C Broadway Business Park",
            "ADDRESS_LINE_2": "Broadgate, Chadderton",
            "CITY": "Oldham",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "OL9 9XA",
            "MAIN_PHONE": "(+44) 161 682 0300",
            "ALT_PHONE": "(+44) 800 521 442",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "(+44) 161 682 9200",
            "EMAIL_ADDRESS": "service@carriermanchester.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Manchester",
            "COMPAGNY_NAME": "CARRIER NORTHERN",
            "LATITUDE": "53.5318",
            "LONGITUDE": "-2.1677",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Scott WALSH",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 10,
            "MOBILEVAN_NUMBER": 10,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:30,2:8:00:::17:30,3:8:00:::17:30,4:8:00:::17:30,5:8:00:::17:30,6::::,7::::"
        },
        {
            "ID": 573,
            "ADDRESS_LINE_1": "C/O Woodward Food Services",
            "ADDRESS_LINE_2": "Craigshaw Drive, West Tullos Industrial Estate",
            "CITY": "Aberdeen, ",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "AB12 3AN",
            "MAIN_PHONE": "(+44) 1224 874774",
            "ALT_PHONE": "(+44) 800 521 442",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "(+44) 1224 875775",
            "EMAIL_ADDRESS": "billy@netrs.fsnet.co.uk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Aberdeen, ",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD ABERDEEN",
            "LATITUDE": "57.1212",
            "LONGITUDE": "-2.0965",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Bill RICHARDSON",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,5:8:00:17:00"
        },
        {
            "ID": 574,
            "ADDRESS_LINE_1": "Worthy Road",
            "ADDRESS_LINE_2": "Chittening Industrial Estate, Chittening",
            "CITY": "Avonmouth",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "BS11 0YB",
            "MAIN_PHONE": "(+44) 800 169 1012",
            "ALT_PHONE": "(+44) 800 521 442",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "(+44) 29 2085 8029",
            "EMAIL_ADDRESS": "service@carriersouthern.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bristol",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD SOUTHERN",
            "LATITUDE": "51.5305",
            "LONGITUDE": "-2.6787",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Simon BAMFORD",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:30,2:8:30:::17:30,3:8:30:::17:30,4:8:30:::17:30,5:8:30:::17:30,6::::,7::::"
        },
        {
            "ID": 714,
            "ADDRESS_LINE_1": "Unit 10 Callywith Gate Industrial Estate",
            "ADDRESS_LINE_2": "Launceston Road",
            "CITY": "Bodmin, Cornwall",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "PL31 2RQ",
            "MAIN_PHONE": "+44 0 1208 269145",
            "ALT_PHONE": "(+44) 800 521 442",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "+44 0 1208 269393",
            "EMAIL_ADDRESS": "tim@crtsltd.co.uk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bodmin, Cornwall",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD CORNWALL",
            "LATITUDE": "50.4775",
            "LONGITUDE": "-4.700518",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tim EDWARDS",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:00,2:8:30:::17:00,3:8:30:::17:00,4:8:30:::17:00,5:8:30:::17:00,6::::,7::::"
        },
        {
            "ID": 833,
            "ADDRESS_LINE_1": "Unit 2, Wyvern Way",
            "ADDRESS_LINE_2": "Henwood Industrial Estate",
            "CITY": "Ashford",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "TN24 8DW",
            "MAIN_PHONE": "+44 800 169 1012",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+44 800 169 1012",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "+44 29 2085 8029",
            "EMAIL_ADDRESS": "service@carriersouthern.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Ashford",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD SOUTHERN",
            "LATITUDE": "51.150581",
            "LONGITUDE": "0.88754",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Service Department",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::17:00,2:9:00:::17:00,3:9:00:::17:00,4:9:00:::17:00,5:9:00:::17:00,6::::,7::::"
        },
        {
            "ID": 850,
            "ADDRESS_LINE_1": "Townfoot",
            "ADDRESS_LINE_2": "",
            "CITY": "Longtown",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "CA6 5LY",
            "MAIN_PHONE": "01228 794800",
            "ALT_PHONE": "07500039098",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "01228 794819",
            "EMAIL_ADDRESS": "brianw@warmstrong.co.uk",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Longtown",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD CUMBRIA",
            "LATITUDE": "55.011274",
            "LONGITUDE": "-2.977211",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Matthew MORRISON",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:21:00,2:7:30:21:00,3:7:30:21:00,4:7:30:21:00,5:7:30:21:00,6:8:00:13:00"
        },
        {
            "ID": 861,
            "ADDRESS_LINE_1": "Vehicle Maintenance Unit, ",
            "ADDRESS_LINE_2": "Swan Valley 2,  Swan Valley Way, ",
            "CITY": "Northampton",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "NN49BD",
            "MAIN_PHONE": "+44 845 023 0339",
            "ALT_PHONE": "(+44) 870 845 8000",
            "MOBILE_PHONE": "+44 800 442 521",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "+44 1827 266 417",
            "EMAIL_ADDRESS": "service@cbrefrigeration.co.uk",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Northampton",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD EAST MIDLANDS",
            "LATITUDE": "52.214176",
            "LONGITUDE": "-0.946459",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Kevin COLLINS",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 5,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:00,2:8:30:::17:00,3:8:30:::17:00,4:8:30:::17:00,5:8:30:::17:00,6::::,7::::"
        },
        {
            "ID": 862,
            "ADDRESS_LINE_1": "2 Easter Park",
            "ADDRESS_LINE_2": "Axial Way",
            "CITY": "Colchester",
            "COUNTRY": "United Kingdom",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "CO4 5WY",
            "MAIN_PHONE": "+44 1206 580 475",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+44 845 6531412",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "+44 1206 580 476",
            "EMAIL_ADDRESS": "info@ransomefleetsolutions.co.uk",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Colchester",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD EASTERN",
            "LATITUDE": "51.924968",
            "LONGITUDE": "0.908646",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Leigh WESTLAKE",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 10,
            "MOBILEVAN_NUMBER": 10,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:30,2:8:30:::17:30,3:8:30:::17:30,4:8:30:::17:30,5:8:30:::17:30,6::::,7::::"
        },
        {
            "ID": 1001,
            "ADDRESS_LINE_1": "318 Edinburgh Road,",
            "ADDRESS_LINE_2": "Newhouse",
            "CITY": "Motherwell",
            "COUNTRY": "",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "ML1 5SY",
            "MAIN_PHONE": "+44 (0) 141 641 2161",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "07720 440 144",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "+44 (0) 141 646 1628",
            "EMAIL_ADDRESS": "david@jameshogg.co.uk",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Motherwell",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD SOUTH & CENTRAL SCOTLANDGOW",
            "LATITUDE": "55.827202",
            "LONGITUDE": "-3.9494",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "David Hogg",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 5,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 1002,
            "ADDRESS_LINE_1": "Unit 1 Clashburn Road",
            "ADDRESS_LINE_2": "Bridgend Ind Est",
            "CITY": "Kinross ",
            "COUNTRY": "",
            "COUNTRY_CODE": "GB",
            "POSTAL_CODE": "KY13 8GB",
            "MAIN_PHONE": "+44 (0) 1577 865 876 ",
            "ALT_PHONE": "07479 663 665    ",
            "MOBILE_PHONE": "07771 687 984",
            "ASSISTANCE_PHONE": "0800 9179067",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "sandy@sandybaird.co.uk",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Kinross ",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD EAST SCOTLAND",
            "LATITUDE": "56.19968",
            "LONGITUDE": "-3.4249",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sandy Baird",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 6,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 1021,
            "ADDRESS_LINE_1": "Tbilisi-Leselidze highway 23/800 km",
            "ADDRESS_LINE_2": "",
            "CITY": "Tsitsamuri",
            "COUNTRY": "",
            "COUNTRY_CODE": "GE",
            "POSTAL_CODE": "3311",
            "MAIN_PHONE": "+995 322 49 75 75 ",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+995 593 53 46 47",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "s.silagadze@vakomotors.ge",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Tsitsamuri",
            "COMPAGNY_NAME": "VAKO Motors",
            "LATITUDE": "41.878056",
            "LONGITUDE": "44.730555",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Bakuri Kobakhidze",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::18:00,7::::"
        },
        {
            "ID": 210,
            "ADDRESS_LINE_1": "73, Ag. Annis Street",
            "ADDRESS_LINE_2": "",
            "CITY": "Rentis",
            "COUNTRY": "Greece",
            "COUNTRY_CODE": "GR",
            "POSTAL_CODE": "18233",
            "MAIN_PHONE": "(+30) 210 3470746 ",
            "ALT_PHONE": "(+30) 210 3472105",
            "MOBILE_PHONE": " 0",
            "ASSISTANCE_PHONE": "00800 3222523",
            "FAX_PHONE": "(+30) 2103472763",
            "EMAIL_ADDRESS": "syssa@otenet.gr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Athens",
            "COMPAGNY_NAME": "SYSTEMS S.A.",
            "LATITUDE": "37.978735",
            "LONGITUDE": "23.687285",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "LEKOS  MICHAEL ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,5:8:00:17:00"
        },
        {
            "ID": 212,
            "ADDRESS_LINE_1": "Kteo Kalohoriou",
            "ADDRESS_LINE_2": "",
            "CITY": "Thessaloniki",
            "COUNTRY": "Greece",
            "COUNTRY_CODE": "GR",
            "POSTAL_CODE": "57009",
            "MAIN_PHONE": "(+30)2310755080",
            "ALT_PHONE": "6944282157",
            "MOBILE_PHONE": "6944282157",
            "ASSISTANCE_PHONE": "00800 3222523",
            "FAX_PHONE": "(+30)2310755080",
            "EMAIL_ADDRESS": "info@ctservice.gr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Thessaloniki",
            "COMPAGNY_NAME": "CT SERVICE",
            "LATITUDE": "40.645594",
            "LONGITUDE": "22.866819",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "KITSANTAS THEOFILOS",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,5:8:00:17:00"
        },
        {
            "ID": 213,
            "ADDRESS_LINE_1": "Vipe Sinthou",
            "ADDRESS_LINE_2": "",
            "CITY": "Thessaloniki",
            "COUNTRY": "Greece",
            "COUNTRY_CODE": "GR",
            "POSTAL_CODE": "57009",
            "MAIN_PHONE": "(+30)2310798300",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "00800 3222523",
            "FAX_PHONE": "(+30)2310798370",
            "EMAIL_ADDRESS": "technical@stathis.com.gr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Thessaloniki",
            "COMPAGNY_NAME": "STATHIS",
            "LATITUDE": "40.639407",
            "LONGITUDE": "22.944624",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "ZACHARIAS",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,5:8:00:17:00"
        },
        {
            "ID": 217,
            "ADDRESS_LINE_1": "Filioura  Agios Stefanos Sarvali ",
            "ADDRESS_LINE_2": "",
            "CITY": "Patras",
            "COUNTRY": "Greece",
            "COUNTRY_CODE": "GR",
            "POSTAL_CODE": "26500",
            "MAIN_PHONE": "(+30)2610333994",
            "ALT_PHONE": "6974692819",
            "MOBILE_PHONE": "6937042606",
            "ASSISTANCE_PHONE": "00800 3222523",
            "FAX_PHONE": "(+30)2610333994",
            "EMAIL_ADDRESS": "tspgr@hotmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Patras",
            "COMPAGNY_NAME": "THERMOSERVICE PATRAS",
            "LATITUDE": "38.200355",
            "LONGITUDE": "21.763569",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "PAPADIMITROPOULOS LOS MICHAEL",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,5:8:00:17:00"
        },
        {
            "ID": 218,
            "ADDRESS_LINE_1": "MIAOYLI 10  ",
            "ADDRESS_LINE_2": "",
            "CITY": "Paralia Patras",
            "COUNTRY": "Greece",
            "COUNTRY_CODE": "GR",
            "POSTAL_CODE": "26233",
            "MAIN_PHONE": "(+30)2610525245",
            "ALT_PHONE": "6976561889",
            "MOBILE_PHONE": "6974380696",
            "ASSISTANCE_PHONE": "00800 3222523",
            "FAX_PHONE": "(+30)2610526407",
            "EMAIL_ADDRESS": "nikolaoskikiris@yahoo.gr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Paralia Patras",
            "COMPAGNY_NAME": "Thermo KIKIRIS Services",
            "LATITUDE": "38.193823",
            "LONGITUDE": "21.717936",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Nikos KIKIRIS",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6:8:00:::18:00,7::::"
        },
        {
            "ID": 870,
            "ADDRESS_LINE_1": "xxx Leoforos Nato",
            "ADDRESS_LINE_2": "",
            "CITY": "Aspropyrgos",
            "COUNTRY": "",
            "COUNTRY_CODE": "GR",
            "POSTAL_CODE": "19300",
            "MAIN_PHONE": "+30 210 5598698",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+30 6985163473",
            "ASSISTANCE_PHONE": "00800 3222523",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "npolymenakos@thermo-eng.gr",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Aspropyrgos",
            "COMPAGNY_NAME": "THERMO ENGINEERING SERVICES",
            "LATITUDE": "38.076444",
            "LONGITUDE": "23.564782",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Nick Polymenakos",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::17:00,2:9:00:::17:00,3:9:00:::17:00,4:9:00:::17:00,5:9:00:::17:00,6:9:00:::13:00,7::::"
        },
        {
            "ID": 961,
            "ADDRESS_LINE_1": "10KM VERIAS-NAOUSSAS ,CITY ",
            "ADDRESS_LINE_2": "",
            "CITY": "NAOUSSA ",
            "COUNTRY": "",
            "COUNTRY_CODE": "GR",
            "POSTAL_CODE": "59200",
            "MAIN_PHONE": "00302332043426",
            "ALT_PHONE": "00306973034084",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "00800 3222523",
            "FAX_PHONE": "00302332043426",
            "EMAIL_ADDRESS": "geotkcarrier@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "NAOUSSA ",
            "COMPAGNY_NAME": "GEORGIADIS GEORGIOS",
            "LATITUDE": "40.597397",
            "LONGITUDE": "22.155456",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "GEORGE  GEORGIADIS ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:30,2:8:30:::17:30,3:8:30:::17:30,4:8:30:::17:30,5:8:30:::17:30,6:8:30:14:00::,7::::"
        },
        {
            "ID": 980,
            "ADDRESS_LINE_1": "VIPE HERAKLION STREET H",
            "ADDRESS_LINE_2": "",
            "CITY": "IRAKLION",
            "COUNTRY": "",
            "COUNTRY_CODE": "GR",
            "POSTAL_CODE": "71601",
            "MAIN_PHONE": "+30 2810380078",
            "ALT_PHONE": "+30 6936592050",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "00800 3222523",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "thermofix@windowslive.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "IRAKLION",
            "COMPAGNY_NAME": "K.TRIANTAFYLLAKIS & CO G.P",
            "LATITUDE": "35.321813",
            "LONGITUDE": "25.170879",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "KONSTANTINOS TRIANTAFYLLAKIS",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::20:00,2:8:00:::20:00,3:8:00:::20:00,4:8:00:::20:00,5:8:00:::20:00,6:8:00:::20:00,7::::"
        },
        {
            "ID": 992,
            "ADDRESS_LINE_1": "NATO Av",
            "ADDRESS_LINE_2": "",
            "CITY": "ASPROPYRGOS",
            "COUNTRY": "",
            "COUNTRY_CODE": "GR",
            "POSTAL_CODE": "19300",
            "MAIN_PHONE": "+306986970736",
            "ALT_PHONE": "+306942583394",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "00800 3222523",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "klimakabo@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "ASPROPYRGOS",
            "COMPAGNY_NAME": "KLIMA KABO IKE",
            "LATITUDE": "38.064957",
            "LONGITUDE": "23.626324",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "THANASIS ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:9:00:::17:00,2:9:00:::17:00,3:9:00:::17:00,4:9:00:::17:00,5:9:00:::17:00,6:9:00:15:00::,7::::"
        },
        {
            "ID": 32,
            "ADDRESS_LINE_1": "Posavska ulica 9",
            "ADDRESS_LINE_2": "",
            "CITY": "Rugvica",
            "COUNTRY": "Croatia",
            "COUNTRY_CODE": "HR",
            "POSTAL_CODE": "10370",
            "MAIN_PHONE": "(+385) 1 2043 647",
            "ALT_PHONE": "(+385) 91 505 4654",
            "MOBILE_PHONE": "(+385) 91 505 4654",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+385) 1 2043 649",
            "EMAIL_ADDRESS": "haramustek@haramustek.hr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Zagreb",
            "COMPAGNY_NAME": "HARAMUSTEK D.O.O.",
            "LATITUDE": "45.738073",
            "LONGITUDE": "16.230079",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Nenad HARAMUSTEK",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:16:30,2:8:00:16:30,3:8:00:16:30,4:8:00:16:30,5:8:00:16:30"
        },
        {
            "ID": 37,
            "ADDRESS_LINE_1": "Ulica borova 1",
            "ADDRESS_LINE_2": "",
            "CITY": "Osijek",
            "COUNTRY": "Croatia",
            "COUNTRY_CODE": "HR",
            "POSTAL_CODE": "31000",
            "MAIN_PHONE": "(+385) 31 220 025",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+385) 91 1220 025",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+385) 31 220 001",
            "EMAIL_ADDRESS": "drazen.simic@skojo.hr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Osijek",
            "COMPAGNY_NAME": "SKOJO D.O.O.",
            "LATITUDE": "45.540233",
            "LONGITUDE": "18.648863",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Drazen Simic",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:16:30,2:8:00:16:30,3:8:00:16:30,4:8:00:16:30,5:8:00:16:30"
        },
        {
            "ID": 788,
            "ADDRESS_LINE_1": "Put bana 24",
            "ADDRESS_LINE_2": "",
            "CITY": "Deugopolje",
            "COUNTRY": "Croatia",
            "COUNTRY_CODE": "HR",
            "POSTAL_CODE": "21204",
            "MAIN_PHONE": "+385 21 599 000",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+385 21 599 012",
            "EMAIL_ADDRESS": "infomb@jolly-autoline.hr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Deugopolje",
            "COMPAGNY_NAME": "JOLLY AUTOLINE d.o.o",
            "LATITUDE": "43.582489",
            "LONGITUDE": "16.554916",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:16:30,2:8:00:16:30,3:8:00:16:30,4:8:00:16:30,5:8:00:16:30"
        },
        {
            "ID": 881,
            "ADDRESS_LINE_1": "Kukuljanovo 450",
            "ADDRESS_LINE_2": "",
            "CITY": "Kukuljanovo ",
            "COUNTRY": "",
            "COUNTRY_CODE": "HR",
            "POSTAL_CODE": "51227",
            "MAIN_PHONE": "++385 51 618 062",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@multipartner.hr",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kukuljanovo ",
            "COMPAGNY_NAME": "Multipartner d.o.o",
            "LATITUDE": "45.3292",
            "LONGITUDE": "14.515379",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr. Tino Cernjul",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:16:00::,2:8:00:16:00::,3:8:00:16:00::,4:8:00:16:00::,5:8:00:16:00::,6::::,7::::"
        },
        {
            "ID": 221,
            "ADDRESS_LINE_1": "Kiskörösi utca 18-20",
            "ADDRESS_LINE_2": "",
            "CITY": "Kecskemet",
            "COUNTRY": "Hungary",
            "COUNTRY_CODE": "HU",
            "POSTAL_CODE": "6000",
            "MAIN_PHONE": "(+36) 76573004",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+36) 20 806 4080   ",
            "ASSISTANCE_PHONE": "06800 13526",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "service@robi-mobil.hu",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kecskemet",
            "COMPAGNY_NAME": "Robi-Mobil Hungaria KFT",
            "LATITUDE": "46.894499",
            "LONGITUDE": "19.678702",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sandor ROZSA, Gabor PAPP",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:::17:00,2:7:30:::17:00,3:7:30:::17:00,4:7:30:::17:00,5:7:30:::17:00,6::::,7::::"
        },
        {
            "ID": 596,
            "ADDRESS_LINE_1": "ÚJHEGYI ÚT 2",
            "ADDRESS_LINE_2": "",
            "CITY": "Zalaegerszeg",
            "COUNTRY": "Hungary",
            "COUNTRY_CODE": "HU",
            "POSTAL_CODE": "H 8900",
            "MAIN_PHONE": "(+36) 205367660",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "06800 13526",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "jegsasbt@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Zalaegerszeg",
            "COMPAGNY_NAME": "JÉG SAS KLÍMA KFT",
            "LATITUDE": "46.852209",
            "LONGITUDE": "16.825905",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Lajos ZSIGA  MÁJER NÁNDOR",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:30,2:8:00:::16:30,3:8:00:::16:30,4:8:00:::16:30,5:8:00:::16:30,6::::,7::::"
        },
        {
            "ID": 871,
            "ADDRESS_LINE_1": "Bánki Donát utca 6.",
            "ADDRESS_LINE_2": "",
            "CITY": "Dunaharaszti",
            "COUNTRY": "",
            "COUNTRY_CODE": "HU",
            "POSTAL_CODE": "2330",
            "MAIN_PHONE": "+ 36 70 390 4971 ",
            "ALT_PHONE": "+36 70 488 8170",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "06800 13526",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "rapidszerviz@rapidszerviz.hu",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Dunaharaszti",
            "COMPAGNY_NAME": "Rapid Teherautó Szerviz kft",
            "LATITUDE": "47.347778",
            "LONGITUDE": "19.119444",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Andrea Báthory / Péter Tóth",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00::,2:8:00:17:00::,3:8:00:17:00::,4:8:00:17:00::,5:8:00:17:00::,6::::,7::::"
        },
        {
            "ID": 925,
            "ADDRESS_LINE_1": "Budapesti út 8",
            "ADDRESS_LINE_2": "",
            "CITY": "Szeged",
            "COUNTRY": "",
            "COUNTRY_CODE": "HU",
            "POSTAL_CODE": "6728",
            "MAIN_PHONE": "+36706256562",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "06800 13526",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "emfrigokft@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Szeged",
            "COMPAGNY_NAME": "Expert Mobil Frigo Kft",
            "LATITUDE": "46.274147",
            "LONGITUDE": "20.097011",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Kószó István",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:16:00::,2:8:00:16:00::,3:8:00:16:00::,4:8:00:16:00::,5:8:00:16:00::,6::::,7::::"
        },
        {
            "ID": 949,
            "ADDRESS_LINE_1": "2. Rakodó Iprartelep",
            "ADDRESS_LINE_2": "",
            "CITY": "Cserkút",
            "COUNTRY": "",
            "COUNTRY_CODE": "HU",
            "POSTAL_CODE": "7673",
            "MAIN_PHONE": "+36702937594",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "06800 13526",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "coolszerviz@coolszerviz.hu",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Cserkút",
            "COMPAGNY_NAME": "Coolserviz KFT",
            "LATITUDE": "46.058037",
            "LONGITUDE": "18.131318",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "János Nagy",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6::::,7::::"
        },
        {
            "ID": 224,
            "ADDRESS_LINE_1": "Unit 612 Northwest Business Park",
            "ADDRESS_LINE_2": "Kilshane Avenue",
            "CITY": "Dublin 15",
            "COUNTRY": "Ireland",
            "COUNTRY_CODE": "IE",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+353) 1 8612800",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+353) 87 2532828",
            "ASSISTANCE_PHONE": "1800553286",
            "FAX_PHONE": "(+353) 1 8612005",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Dublin",
            "COMPAGNY_NAME": "TECHNICAL SUPPORT SERVICES LTD",
            "LATITUDE": "53.419827",
            "LONGITUDE": "-6.34939",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "John Brennan",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:17:30,2:8:30:17:30,3:8:30:17:30,4:8:30:17:30,5:8:30:17:30"
        },
        {
            "ID": 226,
            "ADDRESS_LINE_1": "Unit G4 Eastway Business Park",
            "ADDRESS_LINE_2": "Tipperary Road",
            "CITY": "Limerick",
            "COUNTRY": "Ireland",
            "COUNTRY_CODE": "IE",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+353) 061 440243",
            "ALT_PHONE": "(+353) 087 2356935",
            "MOBILE_PHONE": "(+353) 087 2356935",
            "ASSISTANCE_PHONE": "1800553286",
            "FAX_PHONE": "(+353) 353 061 440298",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Limerick",
            "COMPAGNY_NAME": "TECHNICAL SUPPORT SERVICES LTD",
            "LATITUDE": "52.654155",
            "LONGITUDE": "-8.599012",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Martin Byrnes",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:17:30,2:8:30:17:30,3:8:30:17:30,4:8:30:17:30,5:8:30:17:30"
        },
        {
            "ID": 233,
            "ADDRESS_LINE_1": "Old I.Z. Hadera",
            "ADDRESS_LINE_2": "",
            "CITY": "Hadera",
            "COUNTRY": "Israel",
            "COUNTRY_CODE": "IL ",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+972) 4 6341127",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+972) 4 6210263",
            "EMAIL_ADDRESS": "tobol@barak.net.il",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Hadera",
            "COMPAGNY_NAME": "CHISH KOR",
            "LATITUDE": "32.442839",
            "LONGITUDE": "34.920902",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ary TUBUL",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30,7:7:30:16:30"
        },
        {
            "ID": 236,
            "ADDRESS_LINE_1": "2 Hasatat str",
            "ADDRESS_LINE_2": "",
            "CITY": "Eilat",
            "COUNTRY": "Israel",
            "COUNTRY_CODE": "IL ",
            "POSTAL_CODE": "88103",
            "MAIN_PHONE": "(+972) 8 6377429",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+972) 8 6378101",
            "EMAIL_ADDRESS": "n0.email@none.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Eilat",
            "COMPAGNY_NAME": "ARY KOR",
            "LATITUDE": "29.568824",
            "LONGITUDE": "34.956538",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "David",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:16:00,2:7:30:16:00,3:7:30:16:00,4:7:30:16:00,5:7:30:16:00,7:7:30:16:00"
        },
        {
            "ID": 911,
            "ADDRESS_LINE_1": "Kibbutz Givat Hashlosha",
            "ADDRESS_LINE_2": "PO Box 414",
            "CITY": "Kibbutz Givat Hashlosha",
            "COUNTRY": "",
            "COUNTRY_CODE": "IL ",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "+972 3 9374111",
            "ALT_PHONE": "+972 54 646659",
            "MOBILE_PHONE": "+972 54 646690",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+972 3 9374114",
            "EMAIL_ADDRESS": "itai@dyocar.co.il",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kibbutz Givat Hashlosha",
            "COMPAGNY_NAME": "DYOCAR LTD",
            "LATITUDE": "32.095963",
            "LONGITUDE": "34.900966",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 16,
            "MOBILEVAN_NUMBER": 5,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5::::,6::::,7:8:00:::17:00"
        },
        {
            "ID": 237,
            "ADDRESS_LINE_1": "Viale Einstein,3 angolo Viale ",
            "ADDRESS_LINE_2": "Europa,1 ",
            "CITY": "Castelnuovo Scrivia (AL)",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "15063",
            "MAIN_PHONE": "+390131240511",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "+390131823362",
            "EMAIL_ADDRESS": "alefri@ale-fri.it",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Alessandria",
            "COMPAGNY_NAME": "ALE FRI",
            "LATITUDE": "44.972818",
            "LONGITUDE": "8.916897",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Davide Tedeschi",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 241,
            "ADDRESS_LINE_1": "Via Scarlatti 11",
            "ADDRESS_LINE_2": "",
            "CITY": "Trezzano Sul Naviglio",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "20090",
            "MAIN_PHONE": "(+39) 02 4453812",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "+39 329 768 4677",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 02 48400578",
            "EMAIL_ADDRESS": "amministrativo@transfrigoservice.it",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Milano",
            "COMPAGNY_NAME": "TRANSFRIGO SERVICE SRL",
            "LATITUDE": "45.416271",
            "LONGITUDE": "9.107323",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Nicolò Platania, Achille Uzzi",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6:8:00:::12:00,7::::"
        },
        {
            "ID": 242,
            "ADDRESS_LINE_1": "Via Staffali 11",
            "ADDRESS_LINE_2": "",
            "CITY": "Villafranca Di Verona",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "37062",
            "MAIN_PHONE": "(+39) 045 987595",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 045 8618879",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Verona",
            "COMPAGNY_NAME": "FRISCAR SRL",
            "LATITUDE": "45.391021",
            "LONGITUDE": "10.907418",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Augusto Galvani, Claudio Poli",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00"
        },
        {
            "ID": 243,
            "ADDRESS_LINE_1": "Via Oliere e saponerie meridionali",
            "ADDRESS_LINE_2": "Zona A.S.I. Maglia L",
            "CITY": "Molfetta ",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "70056",
            "MAIN_PHONE": "(+39) 080 3370087",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 080 3382404",
            "EMAIL_ADDRESS": "commerciale@leonerefrigerazione.it",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bari",
            "COMPAGNY_NAME": "F.LLI LEONE SNC",
            "LATITUDE": "41.206298",
            "LONGITUDE": "16.558199",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mimmo LEONE",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:13:00:15:00:18:00,2:8:00:13:00:15:00:18:00,3:8:00:13:00:15:00:18:00,4:8:00:13:00:15:00:18:00,5:8:00:13:00:15:00:18:00"
        },
        {
            "ID": 244,
            "ADDRESS_LINE_1": "Via Filos 45 a",
            "ADDRESS_LINE_2": "Z.I. OVEST",
            "CITY": "Lavis",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "38015",
            "MAIN_PHONE": "(+39) 0461 240000",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 0461 245496",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Trento",
            "COMPAGNY_NAME": "FOLGHERAITER E TOMASI SNC",
            "LATITUDE": "46.145945",
            "LONGITUDE": "11.094882",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Vittorio Tomasi",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:19:00,2:8:00:12:00:14:00:19:00,3:8:00:12:00:14:00:19:00,4:8:00:12:00:14:00:19:00,5:8:00:12:00:14:00:19:00,6::::,7::::"
        },
        {
            "ID": 247,
            "ADDRESS_LINE_1": "Via Enrico Fermi,4 ",
            "ADDRESS_LINE_2": "Castelguelfo di Fontevivo ",
            "CITY": "Castelguelfo",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "43010 ",
            "MAIN_PHONE": "(+39) 0521 674099",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39)  0521 347887",
            "EMAIL_ADDRESS": "info@coldpoint.it",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Parma",
            "COMPAGNY_NAME": "COLD POINT SRL",
            "LATITUDE": "44.830372",
            "LONGITUDE": "10.199294",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Davide Dieci  Samuele Curti",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00"
        },
        {
            "ID": 248,
            "ADDRESS_LINE_1": "Via Bachelet, 30",
            "ADDRESS_LINE_2": "Z.A. Torre del Moro",
            "CITY": "Cesena",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "47522",
            "MAIN_PHONE": "(+39) 0547 601150",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 0547 601018",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Cesena",
            "COMPAGNY_NAME": "THERMO SERVICE SNC",
            "LATITUDE": "44.158517",
            "LONGITUDE": "12.210547",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Gilberto Casadei",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00"
        },
        {
            "ID": 249,
            "ADDRESS_LINE_1": "Via Zeccagnuolo 13",
            "ADDRESS_LINE_2": "",
            "CITY": "San Valentino Torio",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "84010",
            "MAIN_PHONE": "(+39) 081 939659",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 081 939973",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Salerno",
            "COMPAGNY_NAME": "O.M.L.",
            "LATITUDE": "40.76794",
            "LONGITUDE": "14.610561",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Carmine Longobardi, Giulio Cordelia",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:13:00:14:00:18:00,2:8:00:13:00:14:00:18:00,3:8:00:13:00:14:00:18:00,4:8:00:13:00:14:00:18:00,5:8:00:13:00:14:00:18:00"
        },
        {
            "ID": 250,
            "ADDRESS_LINE_1": "VIA RIVAROLO 32",
            "ADDRESS_LINE_2": "C/O FRIGO GENOVA",
            "CITY": "Genova",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "16152",
            "MAIN_PHONE": "(+39) 010 6001034",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 010 6599524",
            "EMAIL_ADDRESS": "tecnico@transfrigoservice.it",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Genova",
            "COMPAGNY_NAME": "TRUCK FRIGO",
            "LATITUDE": "44.446546",
            "LONGITUDE": "8.893548",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mauro Ardonato, Bruno Pisani",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:12:30:14:00:18:00,2:8:30:12:30:14:00:18:00,3:8:30:12:30:14:00:18:00,4:8:30:12:30:14:00:18:00,5:8:30:12:30:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 251,
            "ADDRESS_LINE_1": "Via dell'Artigianato 1A Traversa",
            "ADDRESS_LINE_2": "Centubuchi di Monteprandone",
            "CITY": "Monteprandone",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "63076",
            "MAIN_PHONE": "(+39) 0735 705174",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 0735 704900",
            "EMAIL_ADDRESS": "magazzino@frigopoint.it",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Ascoli Piceno",
            "COMPAGNY_NAME": "FRIGO POINT",
            "LATITUDE": "42.89621",
            "LONGITUDE": "13.86657",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Nazareno Falaschetti",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00"
        },
        {
            "ID": 253,
            "ADDRESS_LINE_1": "Via Dell'Avena 15/17",
            "ADDRESS_LINE_2": "",
            "CITY": "Perugia",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "6135",
            "MAIN_PHONE": "(+39) 075 388347",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 075 388347",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Perugia",
            "COMPAGNY_NAME": "FRIGO AER TERMICA SNC",
            "LATITUDE": "43.070408",
            "LONGITUDE": "12.410468",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Marco Castelletti",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:13:00:14:30:18:30,2:8:00:13:00:14:30:18:30,3:8:00:13:00:14:30:18:30,4:8:00:13:00:14:30:18:30,5:8:00:13:00:14:30:18:30,6::::,7::::"
        },
        {
            "ID": 256,
            "ADDRESS_LINE_1": "Zona Industriale",
            "ADDRESS_LINE_2": "Contrada Buttaceto Pezza Grande",
            "CITY": "Catania",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "95121",
            "MAIN_PHONE": "(+39) 095 7139974",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 095 591475",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Catania",
            "COMPAGNY_NAME": "SOTTOZERO  SaS",
            "LATITUDE": "37.451712",
            "LONGITUDE": "15.054102",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Toni Amore",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:13:00:14:00:17:00,2:8:00:13:00:14:00:17:00,3:8:00:13:00:14:00:17:00,4:8:00:13:00:14:00:17:00,5:8:00:13:00:14:00:17:00,6::::,7::::"
        },
        {
            "ID": 259,
            "ADDRESS_LINE_1": "Via E. Maiorana",
            "ADDRESS_LINE_2": "",
            "CITY": "Santa Lucia Di Piave",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "31025",
            "MAIN_PHONE": "(+39) 0438 63917",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 0438 450282",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Trevisco",
            "COMPAGNY_NAME": "CARFRIGO SNC",
            "LATITUDE": "45.85522",
            "LONGITUDE": "12.272952",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Diego Casagrande",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 261,
            "ADDRESS_LINE_1": "Via Ardeatina 2479",
            "ADDRESS_LINE_2": "",
            "CITY": "Roma",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "00134",
            "MAIN_PHONE": "(+39) 06 791 6036",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "0",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 06 791 6036",
            "EMAIL_ADDRESS": "info@frigotermpontina.eu",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Roma",
            "COMPAGNY_NAME": "FRIGOTERM PONTINA",
            "LATITUDE": "41.700719",
            "LONGITUDE": "12.596407",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Gabriele CAROCCI",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:13:00:14:30:18:00,2:8:30:13:00:14:30:18:00,3:8:30:13:00:14:30:18:00,4:8:30:13:00:14:30:18:00,5:8:30:13:00:14:30:18:00,6:8:00:13:00::,7::::"
        },
        {
            "ID": 262,
            "ADDRESS_LINE_1": "Via Diversivo Acquachiara",
            "ADDRESS_LINE_2": "",
            "CITY": "Fondi",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "4022",
            "MAIN_PHONE": "(+39) 0771-532299",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 0771-532499",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Fondi",
            "COMPAGNY_NAME": "TERMOSERVICE TRADE SRL",
            "LATITUDE": "41.340023",
            "LONGITUDE": "13.415251",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Alberto LO STOCCO",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 763,
            "ADDRESS_LINE_1": "Via Bergamo 17",
            "ADDRESS_LINE_2": "",
            "CITY": "Chiuduno-BG",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "24060",
            "MAIN_PHONE": "(+39) 035 4521246 ",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@dandgservicesrl.191.it",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Chiuduno-BG",
            "COMPAGNY_NAME": "D & G SERVICE SRL",
            "LATITUDE": "45.636907",
            "LONGITUDE": "9.855225",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Giancarlo DANIELE",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 799,
            "ADDRESS_LINE_1": "Via dell'Euro 13",
            "ADDRESS_LINE_2": "",
            "CITY": "Vittoria",
            "COUNTRY": "Italy",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "97019",
            "MAIN_PHONE": "+39 0932 513162",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+393687178957",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "+39 0932 511738",
            "EMAIL_ADDRESS": "scribanogian@virgilio.it",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Vittoria",
            "COMPAGNY_NAME": "SG Truck Service s.r.l.s.",
            "LATITUDE": "36.960661",
            "LONGITUDE": "14.509177",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Gianni",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 921,
            "ADDRESS_LINE_1": "via della Scienza 11",
            "ADDRESS_LINE_2": "",
            "CITY": "Fiano Romano",
            "COUNTRY": "",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "65",
            "MAIN_PHONE": "+390765455999 ",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "+390641614347",
            "EMAIL_ADDRESS": "info@truckfrigoservice.it",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Fiano Romano",
            "COMPAGNY_NAME": "TRUCK FRIGO SERVICE",
            "LATITUDE": "42.154204",
            "LONGITUDE": "12.614051",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Giulio Cordella",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6::::,7::::"
        },
        {
            "ID": 941,
            "ADDRESS_LINE_1": "VIA MARE ADRIATICO 74",
            "ADDRESS_LINE_2": "",
            "CITY": "SPOLTORE (PE)",
            "COUNTRY": "",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "65010",
            "MAIN_PHONE": "(+39) 085 4972548",
            "ALT_PHONE": "800791033",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "(+39) 085 4972548",
            "EMAIL_ADDRESS": "INFO@GMELETTROFRIGO.NET",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "SPOLTORE (PE)",
            "COMPAGNY_NAME": "GM ELETTROFRIGO UNIPERSONALE S.R.L.",
            "LATITUDE": "42.437331",
            "LONGITUDE": "14.166728",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "MARCO GIARDINELLI",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:13:00:15:00:19:00,2:8:30:13:00:15:00:19:00,3:8:30:13:00:15:00:19:00,4:8:30:13:00:15:00:19:00,5:8:30:13:00:15:00:19:00,6:8:30:13:00::,7::::"
        },
        {
            "ID": 1024,
            "ADDRESS_LINE_1": "Via Mercalli 7",
            "ADDRESS_LINE_2": "Settimo Milanese -MI",
            "CITY": "Milan",
            "COUNTRY": "",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "20019",
            "MAIN_PHONE": "+39 026636017",
            "ALT_PHONE": "375 7050974 ",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "amministrazione@milanopoint.it",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Milan",
            "COMPAGNY_NAME": "Milano Point srl",
            "LATITUDE": "45.46492",
            "LONGITUDE": "9.05738",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": " Andrea Cugnata",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6::::,7::::"
        },
        {
            "ID": 1030,
            "ADDRESS_LINE_1": "Via dei Giunchi, 119,",
            "ADDRESS_LINE_2": "",
            "CITY": "Sesto  Fiorentino FI",
            "COUNTRY": "",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "50019 ",
            "MAIN_PHONE": "+39 055 046 7902",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "+39 055 046 7902",
            "EMAIL_ADDRESS": "info@icepointsrl.it",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Sesto  Fiorentino FI",
            "COMPAGNY_NAME": "Ice Point srl ",
            "LATITUDE": "43.81718",
            "LONGITUDE": "11.19773",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Giacomo Gallerini ",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:12:30:14:00:18:30,2:8:30:12:30:14:00:18:30,3:8:30:12:30:14:00:18:30,4:8:30:12:30:14:00:18:30,5:8:30:12:30:14:00:18:30,6:8:30:12:30::,7::::"
        },
        {
            "ID": 1044,
            "ADDRESS_LINE_1": "Via Comunale Poggiomarino, 25",
            "ADDRESS_LINE_2": "",
            "CITY": "San Gennaro  Vesuviano (NA)",
            "COUNTRY": "",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "80040",
            "MAIN_PHONE": "( +39 ) 081 529 8476",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "( +39 ) 3396691757",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "officine@fratelligiamundo.it",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "San Gennaro  Vesuviano (NA)",
            "COMPAGNY_NAME": "Fratelli Giamundo di Giamundo Angelo e C. SAS",
            "LATITUDE": "40.8383",
            "LONGITUDE": "14.53039",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Angelo Giamundo",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:30:13:00:14:30:18:00,2:8:30:13:00:14:30:18:00,3:8:30:13:00:14:30:18:00,4:8:30:13:00:14:30:18:00,5:8:30:13:00:14:30:18:00,6::::,7::::"
        },
        {
            "ID": 1051,
            "ADDRESS_LINE_1": "Via Pietro Nenni, 79/D",
            "ADDRESS_LINE_2": "",
            "CITY": "Settimo  Torinese TO,",
            "COUNTRY": "",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "10036",
            "MAIN_PHONE": "+39 011 1983 9640 ",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+39 3461242887",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "frigo.sponde@officinezingarelli.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Settimo  Torinese TO,",
            "COMPAGNY_NAME": "Zingarelli s.r.l. ",
            "LATITUDE": "45.152433",
            "LONGITUDE": "7.754284",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Giuseppe Catizone",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6:8:00:::12:00,7::::"
        },
        {
            "ID": 1053,
            "ADDRESS_LINE_1": "Via dell’Artigianato 1",
            "ADDRESS_LINE_2": "",
            "CITY": "Porpetto UD",
            "COUNTRY": "",
            "COUNTRY_CODE": "IT",
            "POSTAL_CODE": "33050",
            "MAIN_PHONE": "+39 3440648318",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+39 3452263118",
            "ASSISTANCE_PHONE": "800791033",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "INFO@REFCOLD.IT",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Porpetto UD",
            "COMPAGNY_NAME": "REFCOLD SRL ",
            "LATITUDE": "45.849491",
            "LONGITUDE": "13.211436",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:14:00:18:00,2:8:00:12:00:14:00:18:00,3:8:00:12:00:14:00:18:00,4:8:00:12:00:14:00:18:00,5:8:00:12:00:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 910,
            "ADDRESS_LINE_1": "",
            "ADDRESS_LINE_2": "",
            "CITY": "Amman",
            "COUNTRY": "",
            "COUNTRY_CODE": "JO",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "+962 6 416 3565",
            "ALT_PHONE": "+962 799 222 980",
            "MOBILE_PHONE": "+962 777 807 888",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+962 6 416 3566",
            "EMAIL_ADDRESS": "mahmad@adrimagine.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "",
            "COMPAGNY_NAME": "ADR Automotive Trade & Design",
            "LATITUDE": "31.881389",
            "LONGITUDE": "35.958889",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Muath Thyabat",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5::::,6:8:00:::18:00,7:8:00:::18:00"
        },
        {
            "ID": 275,
            "ADDRESS_LINE_1": "88, Patrisa Lumumba street",
            "ADDRESS_LINE_2": "",
            "CITY": "Bishkek",
            "COUNTRY": "Kyrgyzstan",
            "COUNTRY_CODE": "KG",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "996 555 32 66 20",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+ 996 555 32 66 20",
            "EMAIL_ADDRESS": "azatika.carrier@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bishkek",
            "COMPAGNY_NAME": "AZATICA",
            "LATITUDE": "42.889596",
            "LONGITUDE": "74.51543",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Rustam MADAEV",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 274,
            "ADDRESS_LINE_1": "Shuwaikh Industrial Area - Canada Dry Street",
            "ADDRESS_LINE_2": "PO Box 177",
            "CITY": "Safat",
            "COUNTRY": "Kuwait",
            "COUNTRY_CODE": "KW",
            "POSTAL_CODE": "13002",
            "MAIN_PHONE": "+965 24815542 454",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "+965 65571490",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+965) 24839725",
            "EMAIL_ADDRESS": "transportrefrig@almullagroup.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kuwait City",
            "COMPAGNY_NAME": "BADER AL MULLA & BROS. CO.W.L.L.",
            "LATITUDE": "29.327227",
            "LONGITUDE": "47.941761",
            "DD": "1",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Narendran Ath GOPALAKRISHNAN",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:17:30,2:8:30:17:30,3:8:30:17:30,4:8:30:13:30,6:8:30:17:30,7:8:30:17:30"
        },
        {
            "ID": 271,
            "ADDRESS_LINE_1": "190 Sayramskaya str.",
            "ADDRESS_LINE_2": "",
            "CITY": "Shymkent",
            "COUNTRY": "Kazakhstan",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "160016",
            "MAIN_PHONE": "+7 7252 57 12 12",
            "ALT_PHONE": "+7 7056 81 90 63",
            "MOBILE_PHONE": "+7 775 02 62 87",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+7 7252 57 12 12",
            "EMAIL_ADDRESS": "kaz.trans.holod@mail.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Shymkent",
            "COMPAGNY_NAME": "KAZTRANSHOLODSERVICE",
            "LATITUDE": "42.324983",
            "LONGITUDE": "69.648715",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Pavel SHULICHENKO",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 707,
            "ADDRESS_LINE_1": "57B Ryskulova Str",
            "ADDRESS_LINE_2": "",
            "CITY": "Almaty",
            "COUNTRY": "Kazakhstan",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "050050",
            "MAIN_PHONE": "+7 771 7536787",
            "ALT_PHONE": "+7 777 3555638",
            "MOBILE_PHONE": "+7 777 7536459",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+7 727 3122131",
            "EMAIL_ADDRESS": "ospanov.nurzhan@cbc-group.kz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Almaty",
            "COMPAGNY_NAME": "CBC Trans LLP",
            "LATITUDE": "43.287662",
            "LONGITUDE": "76.921663",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Nurzhan OSPANOV",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 772,
            "ADDRESS_LINE_1": "20/1 Sembinov Street",
            "ADDRESS_LINE_2": "",
            "CITY": "Astana",
            "COUNTRY": "Kazakhstan",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "01000",
            "MAIN_PHONE": "+7 717 257 0909",
            "ALT_PHONE": "+7 717 257 0200",
            "MOBILE_PHONE": "+7 707 292 4518",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+7 717 257 0909",
            "EMAIL_ADDRESS": "info@autohouse.kz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Astana",
            "COMPAGNY_NAME": "AVTODOM",
            "LATITUDE": "51.172796",
            "LONGITUDE": "71.453215",
            "DD": "1",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Sergie SEIBEL",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:30:::18:30,2:9:30:::18:30,3:9:30:::18:30,4:9:30:::18:30,5:9:30:::18:30,6::::,7::::"
        },
        {
            "ID": 774,
            "ADDRESS_LINE_1": "119 Square (near Shymkent) Tassay Village",
            "ADDRESS_LINE_2": "Sayram District",
            "CITY": "Shymkent",
            "COUNTRY": "Kazakhstan",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "160813",
            "MAIN_PHONE": "+7 771 711 90 05",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "+7 771 711 90 11",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "shnyakin.stanislav.cbc@mail.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Shymkent",
            "COMPAGNY_NAME": "CBC - South",
            "LATITUDE": "42.370236",
            "LONGITUDE": "69.709885",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Stanislav Alexandrovich SHNYAKIN ",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::18:00,7::::"
        },
        {
            "ID": 957,
            "ADDRESS_LINE_1": "Proselochnaya str., 2A",
            "ADDRESS_LINE_2": "",
            "CITY": "Petropavlovsk",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "150006",
            "MAIN_PHONE": "+7 701 920 5528",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+7 701 920 5528",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "v.sidorenko@tsk.com.kz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Petropavlovsk",
            "COMPAGNY_NAME": "Truck Service Kazakhstan LLP",
            "LATITUDE": "54.90571",
            "LONGITUDE": "69.076663",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Vyacheslav Sidorenko",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::19:00,2:9:00:::19:00,3:9:00:::19:00,4:9:00:::19:00,5:9:00:::19:00,6::::,7::::"
        },
        {
            "ID": 968,
            "ADDRESS_LINE_1": "Torgovoia str., 2/4",
            "ADDRESS_LINE_2": "",
            "CITY": "Pavlodar",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "140000",
            "MAIN_PHONE": "+7 777 7678349",
            "ALT_PHONE": "+ 7 776 8838389",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+ 7 718 2334030",
            "EMAIL_ADDRESS": "lkwservice@bk.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Pavlodar",
            "COMPAGNY_NAME": "CBC-Pavlodar IP., Kutyshev Alexandr ",
            "LATITUDE": "52.309501",
            "LONGITUDE": "76.948796",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ramil Baigildeev",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:9:00:::19:00,2:9:00:::19:00,3:9:00:::19:00,4:9:00:::19:00,5:9:00:::19:00,6::::,7::::"
        },
        {
            "ID": 969,
            "ADDRESS_LINE_1": "Nietkalieva, 93",
            "ADDRESS_LINE_2": "",
            "CITY": "Taraz",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "080000",
            "MAIN_PHONE": "+7 701 8727967",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "ermotraffic@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Taraz",
            "COMPAGNY_NAME": "Termotrafic LLP",
            "LATITUDE": "42.912046",
            "LONGITUDE": "71.33866",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Veniamin Andriyanov",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:10:00:::19:00,2:10:00:::19:00,3:10:00:::19:00,4:10:00:::19:00,5:10:00:::19:00,6:10:00:::19:00,7::::"
        },
        {
            "ID": 970,
            "ADDRESS_LINE_1": "prospect Stroiteley, 33/2",
            "ADDRESS_LINE_2": "",
            "CITY": "Karaganda",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "100026",
            "MAIN_PHONE": "+7 771 5528361",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "vsk_karaganda@mail.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Karaganda",
            "COMPAGNY_NAME": "VIRAZH",
            "LATITUDE": "49.779479",
            "LONGITUDE": "73.13491",
            "DD": "1",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "1",
            "CONTACT": "Anatoliy KULEV",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 976,
            "ADDRESS_LINE_1": "Sankibay Batyra prospect, 22",
            "ADDRESS_LINE_2": "",
            "CITY": "Aqtobe",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "+7 771 5528339",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "emukhamedzhanov.vsk@virazh.kz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Aqtobe",
            "COMPAGNY_NAME": "VIRAZH",
            "LATITUDE": "50.295983",
            "LONGITUDE": "57.13744",
            "DD": "1",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Eugeniy Mukhamedzhanov",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6::::,7::::"
        },
        {
            "ID": 977,
            "ADDRESS_LINE_1": "Aktyubinskaya str., 281",
            "ADDRESS_LINE_2": "",
            "CITY": "Uralsk,",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "090005",
            "MAIN_PHONE": "+7 777 2221050",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "fadin@daf-service.kz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Uralsk,",
            "COMPAGNY_NAME": "DAF Center",
            "LATITUDE": "51.246944",
            "LONGITUDE": "51.452222",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Anton Fedin",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:::19:00,2:8:30:::19:00,3:8:30:::19:00,4:8:30:::19:00,5:8:30:::19:00,6::::,7::::"
        },
        {
            "ID": 978,
            "ADDRESS_LINE_1": "Karbisheva str., 38/2",
            "ADDRESS_LINE_2": "",
            "CITY": "Kostanay",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "110005",
            "MAIN_PHONE": "+7 775 747 4333",
            "ALT_PHONE": "+7 7142 255703",
            "MOBILE_PHONE": "+7 702 703 39 31",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Kostanayseverscan@bk.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kostanay",
            "COMPAGNY_NAME": "Kostanay Sever Scan",
            "LATITUDE": "53.172781",
            "LONGITUDE": "63.584837",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Talgat Karasatov",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:::19:00,2:8:30:::19:00,3:8:30:::19:00,4:8:30:::19:00,5:8:30:::19:00,6:8:30:::19:00,7:8:30:::19:00"
        },
        {
            "ID": 979,
            "ADDRESS_LINE_1": "Industrial zone 2, building 96",
            "ADDRESS_LINE_2": "village Baskudyk",
            "CITY": "Aktau",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "130002",
            "MAIN_PHONE": "+7 701 555 7591",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "refservice.aktau@mail.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Mangistau region",
            "COMPAGNY_NAME": "RefService",
            "LATITUDE": "43.675",
            "LONGITUDE": "51.215806",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Nickolay Demchenko",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:::19:00,2:8:30:::19:00,3:8:30:::19:00,4:8:30:::19:00,5:8:30:::19:00,6:8:30:::19:00,7::::"
        },
        {
            "ID": 985,
            "ADDRESS_LINE_1": "Building 842",
            "ADDRESS_LINE_2": "41 Razyezd",
            "CITY": "Aqtobe",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "D00M9K9",
            "MAIN_PHONE": "+7 771 1044440",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "boriichuk.yuriy@cbc-group.kz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Aqtobe",
            "COMPAGNY_NAME": "CBC-Trans Aqtobe",
            "LATITUDE": "50.23416",
            "LONGITUDE": "57.256209",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Yuriy Boriboriichuk",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 988,
            "ADDRESS_LINE_1": "shosse Alash, 40/1",
            "ADDRESS_LINE_2": "",
            "CITY": "Nur-Sultan",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "'010008",
            "MAIN_PHONE": "+7 777 3555694",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "gavrish.sergey@cbc-group.kz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Nur-Sultan",
            "COMPAGNY_NAME": "CBC",
            "LATITUDE": "51.203889",
            "LONGITUDE": "71.495156",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sergey Gavrish",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 989,
            "ADDRESS_LINE_1": "building 8",
            "ADDRESS_LINE_2": "Kenesary str, Zhibek-Zholy village",
            "CITY": "Zhibek-Zholy village",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "020202",
            "MAIN_PHONE": "+7 702 919 04 94",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "too.m.f.g@mail.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Zhibek-Zholy village",
            "COMPAGNY_NAME": "TOO M.F.G.",
            "LATITUDE": "51.059296",
            "LONGITUDE": "71.800727",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mikhail Nazarenko",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 1013,
            "ADDRESS_LINE_1": "M-38 road, 10 km",
            "ADDRESS_LINE_2": "",
            "CITY": "Semey",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "07000",
            "MAIN_PHONE": "+7 705 101 2998",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "sto8@aikos.kz",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Semey",
            "COMPAGNY_NAME": "AIKOS LLP",
            "LATITUDE": "50.46397",
            "LONGITUDE": "80.227785",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Andrey Reznikov",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 1047,
            "ADDRESS_LINE_1": "building 842B (coal base)",
            "ADDRESS_LINE_2": "41 crossing",
            "CITY": "Aktobe",
            "COUNTRY": "",
            "COUNTRY_CODE": "KZ ",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "+7 700 120 84 10",
            "ALT_PHONE": "+7 700 120 84 10",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "ref.com@bk.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Aktobe",
            "COMPAGNY_NAME": "Autoholod LLP",
            "LATITUDE": "50.233583",
            "LONGITUDE": "57.254833",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Dmitriy Lobko",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::18:00,7::::"
        },
        {
            "ID": 282,
            "ADDRESS_LINE_1": "Keyrouz building",
            "ADDRESS_LINE_2": "Army Highway",
            "CITY": "Hazmiyeh",
            "COUNTRY": "Lebanon",
            "COUNTRY_CODE": "LB",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+961) 950866",
            "ALT_PHONE": "(+961) 644148",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+961) 952866",
            "EMAIL_ADDRESS": "m.faysal@mecanixsal.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Hazmiyeh",
            "COMPAGNY_NAME": "MECANIX SAL",
            "LATITUDE": "33.855413",
            "LONGITUDE": "35.535901",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Michel FAYSAL",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:::17:30,2:7:30:::17:30,3:7:30:::17:30,4:7:30:::17:30,5:7:30:::17:30,6:7:30:::17:30,7:7:30:::17:30"
        },
        {
            "ID": 285,
            "ADDRESS_LINE_1": "Vakarinis aplinkkelis, 16",
            "ADDRESS_LINE_2": "",
            "CITY": "Kaunas",
            "COUNTRY": "Lithuania",
            "COUNTRY_CODE": "LT ",
            "POSTAL_CODE": "LT-48182",
            "MAIN_PHONE": "+370 37 488211",
            "ALT_PHONE": "(+370) 68753891",
            "MOBILE_PHONE": "(+370) 68753891",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+370) 37 377714",
            "EMAIL_ADDRESS": "dainius@sadomaksa.lt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kaunas",
            "COMPAGNY_NAME": "JSC SADOMAKSA",
            "LATITUDE": "54.934409",
            "LONGITUDE": "23.868248",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Dainius LYSAK",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 10,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 288,
            "ADDRESS_LINE_1": "Pamiškės 35,Švepelių km,Klaipėdos rajonas.",
            "ADDRESS_LINE_2": "",
            "CITY": "Klaipeda",
            "COUNTRY": "Lithuania",
            "COUNTRY_CODE": "LT ",
            "POSTAL_CODE": "LT-95102",
            "MAIN_PHONE": "(+370) 61426231",
            "ALT_PHONE": "(+370) 61426233",
            "MOBILE_PHONE": "(+370) 61426231",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+370) 46 343338",
            "EMAIL_ADDRESS": "klaipeda@sadomaksa.lt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Klaipeda",
            "COMPAGNY_NAME": "JSC SADOMAKSA",
            "LATITUDE": "55.684133",
            "LONGITUDE": "21.218817",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Vytautas Trapokas,Aivaras Ignatavicius",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,5:8:00:17:00"
        },
        {
            "ID": 807,
            "ADDRESS_LINE_1": "Panevezio dist., Liudyne",
            "ADDRESS_LINE_2": "1 str. 5",
            "CITY": "Panevezys",
            "COUNTRY": "Lithuania",
            "COUNTRY_CODE": "LT ",
            "POSTAL_CODE": "LT- 38130",
            "MAIN_PHONE": "+370 678 72848",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "+370 678 72848",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "siaureslokys@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Panevezys",
            "COMPAGNY_NAME": "UAB \"Siaures Lokys\"",
            "LATITUDE": "55.691125",
            "LONGITUDE": "24.450556",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Linas STEPONAVICIUS",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 996,
            "ADDRESS_LINE_1": "Aerouosto str. 2 ",
            "ADDRESS_LINE_2": "",
            "CITY": "Siauliai",
            "COUNTRY": "",
            "COUNTRY_CODE": "LT ",
            "POSTAL_CODE": "LT-77103 ",
            "MAIN_PHONE": "+37041550160",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+37061163902",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+37052685504",
            "EMAIL_ADDRESS": "workshop.siauliai@scania.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Siauliai",
            "COMPAGNY_NAME": "UAB Scania Lietuva",
            "LATITUDE": "55.908509",
            "LONGITUDE": "23.358182",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mantas Kaniušas",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6:8:00:::18:00,7::::"
        },
        {
            "ID": 1015,
            "ADDRESS_LINE_1": "Terminalo str. 3A",
            "ADDRESS_LINE_2": "Kuprioniskes, Nemezio sen",
            "CITY": "Vilniaus reg",
            "COUNTRY": "",
            "COUNTRY_CODE": "LT ",
            "POSTAL_CODE": "LT-13279",
            "MAIN_PHONE": "+370 699 14191",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "arunas@sadomaksa.lt",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Vilniaus reg",
            "COMPAGNY_NAME": "JSC Sadomaksa Ltd",
            "LATITUDE": "54.646425",
            "LONGITUDE": "25.327605",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Arunas Zelvys",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 1019,
            "ADDRESS_LINE_1": "1 rue Fontebierg",
            "ADDRESS_LINE_2": "",
            "CITY": " LIVANGE",
            "COUNTRY": "",
            "COUNTRY_CODE": "LU",
            "POSTAL_CODE": "                L-3381 ",
            "MAIN_PHONE": "00352 369051",
            "ALT_PHONE": "00352 691 211139",
            "MOBILE_PHONE": "00352 691 211045 00352 691 211046 00352 621 319407",
            "ASSISTANCE_PHONE": "800 3581",
            "FAX_PHONE": "00352 369131",
            "EMAIL_ADDRESS": "service.carrier@truck-bus.lu",
            "DKV": "1",
            "REPRESENTATIVE_CITY": " LIVANGE",
            "COMPAGNY_NAME": "TRUCK & BUS SERVICE SARL",
            "LATITUDE": "49.531564",
            "LONGITUDE": "6.104515",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "ANTOINE Pierre",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:7:00:::13:00,7::::"
        },
        {
            "ID": 281,
            "ADDRESS_LINE_1": "Maleju steet 2b",
            "ADDRESS_LINE_2": "",
            "CITY": "RIGA",
            "COUNTRY": "Latvia",
            "COUNTRY_CODE": "LV",
            "POSTAL_CODE": "LV1057",
            "MAIN_PHONE": "(+371) 22004421",
            "ALT_PHONE": "(+371) 29499270",
            "MOBILE_PHONE": "(+371) 22004421",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "coldtrans@coldtrans.lv",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Riga",
            "COMPAGNY_NAME": "COLDTRANS SERVISS",
            "LATITUDE": "56.908387",
            "LONGITUDE": "24.217075",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jevgenijs ZABJALO",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::20:00,2:9:00:::20:00,3:9:00:::20:00,4:9:00:::20:00,5:9:00:::20:00,6::::,7::::"
        },
        {
            "ID": 294,
            "ADDRESS_LINE_1": "bd Ahl Loghlam, Indusparc mag nbr 16",
            "ADDRESS_LINE_2": "",
            "CITY": "Casablanca",
            "COUNTRY": "Morocco",
            "COUNTRY_CODE": "MA ",
            "POSTAL_CODE": "20630",
            "MAIN_PHONE": "+212 522 670 718    +212 522 660 896",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+212 522 660 887",
            "EMAIL_ADDRESS": "octm.helpdesk@vmsecurite.ma",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Casablanca",
            "COMPAGNY_NAME": "VENTEC - MAROC SA",
            "LATITUDE": "33.603361",
            "LONGITUDE": "-7.54205",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "M. DAOURI HICHAM  ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 296,
            "ADDRESS_LINE_1": "Tilila Bab Al Medina",
            "ADDRESS_LINE_2": "BP8091 Hay Dakhla",
            "CITY": "Agadir",
            "COUNTRY": "Morocco",
            "COUNTRY_CODE": "MA ",
            "POSTAL_CODE": "800006",
            "MAIN_PHONE": "+212 522 670 718    +212 522 660 896",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+212) 522 660 887",
            "EMAIL_ADDRESS": "octm.helpdesk@vmsecurite.ma",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Agadir",
            "COMPAGNY_NAME": "VENTEC - MAROC SA",
            "LATITUDE": "30.420164",
            "LONGITUDE": "-9.603853",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "M. JALAL ASOUL",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 297,
            "ADDRESS_LINE_1": "83, avenue des Fars",
            "ADDRESS_LINE_2": "",
            "CITY": "Fes Ville Nouvelle",
            "COUNTRY": "Morocco",
            "COUNTRY_CODE": "MA ",
            "POSTAL_CODE": "30000",
            "MAIN_PHONE": "+212 522 670 718    +212 522 660 896",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+212 522 660 887",
            "EMAIL_ADDRESS": "octm.helpdesk@vmsecurite.ma",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Fes Ville Nouvelle",
            "COMPAGNY_NAME": "VENTEC - MAROC SA",
            "LATITUDE": "34.039005",
            "LONGITUDE": "-5.006407",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "1",
            "CONTACT": "M. MOUSLIM   ",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 298,
            "ADDRESS_LINE_1": "Zone Franche d'exportation de Tanger",
            "ADDRESS_LINE_2": "Route de Rabat km13 Commune de Gzenava",
            "CITY": "Tanger",
            "COUNTRY": "Morocco",
            "COUNTRY_CODE": "MA ",
            "POSTAL_CODE": "9000",
            "MAIN_PHONE": "+212 522 670 718    +212 522 660 896",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+212 522 660 887",
            "EMAIL_ADDRESS": "octm.helpdesk@vmsecurite.ma",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Tanger",
            "COMPAGNY_NAME": "VENTEC MAROC",
            "LATITUDE": "35.711875",
            "LONGITUDE": "-5.904942",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "M. AZIZ ZEROUALI",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 300,
            "ADDRESS_LINE_1": "1, Koudiat Laabid - Route de Casablanca",
            "ADDRESS_LINE_2": "",
            "CITY": "Menara-Gueliz",
            "COUNTRY": "Morocco",
            "COUNTRY_CODE": "MA ",
            "POSTAL_CODE": "40000",
            "MAIN_PHONE": "+212 522 670 718    +212 522 660 896",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+212 522 660 887",
            "EMAIL_ADDRESS": "octm.helpdesk@vmsecurite.ma",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Marrakech",
            "COMPAGNY_NAME": "VENTEC - MAROC SA",
            "LATITUDE": "31.657445",
            "LONGITUDE": "-8.017766",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "1",
            "CONTACT": "Faycal JNAINY",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 742,
            "ADDRESS_LINE_1": "Stefan Voda 94 Street",
            "ADDRESS_LINE_2": "Vatra",
            "CITY": "Chisinau",
            "COUNTRY": "Moldova",
            "COUNTRY_CODE": "MD",
            "POSTAL_CODE": "2055",
            "MAIN_PHONE": "+373 69120395",
            "ALT_PHONE": "+373 22 615600",
            "MOBILE_PHONE": "+373 69120395",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "climaautos@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Chisinau",
            "COMPAGNY_NAME": "Clima Autos",
            "LATITUDE": "47.077558",
            "LONGITUDE": "28.726724",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sergiu SIRBU",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::19:00,2:9:00:::19:00,3:9:00:::19:00,4:9:00:::19:00,5:9:00:::19:00,6::::,7::::"
        },
        {
            "ID": 290,
            "ADDRESS_LINE_1": "Street 136 no.105 ",
            "ADDRESS_LINE_2": "",
            "CITY": "Strumica",
            "COUNTRY": "Macedonia",
            "COUNTRY_CODE": "MK",
            "POSTAL_CODE": "2400",
            "MAIN_PHONE": "(+389) 75 432 474",
            "ALT_PHONE": "(+389) 78 432 774",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+389)  34 330 695",
            "EMAIL_ADDRESS": "info@maniservice.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Strumica",
            "COMPAGNY_NAME": "MANI DOEL",
            "LATITUDE": "41.422923",
            "LONGITUDE": "22.656094",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Toni SEKULOV",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:8:00:::15:00,7::::"
        },
        {
            "ID": 678,
            "ADDRESS_LINE_1": "Street 34 no.3",
            "ADDRESS_LINE_2": "",
            "CITY": "Skopje",
            "COUNTRY": "Macedonia",
            "COUNTRY_CODE": "MK",
            "POSTAL_CODE": "1000",
            "MAIN_PHONE": "+389 75 432 474",
            "ALT_PHONE": "+389 78 432 774",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@maniservice.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Skopje",
            "COMPAGNY_NAME": "MANI dooel",
            "LATITUDE": "41.994991",
            "LONGITUDE": "21.55372",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tony Sekulov",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:8:00:::15:00,7::::"
        },
        {
            "ID": 1046,
            "ADDRESS_LINE_1": "7-mi Noemvri",
            "ADDRESS_LINE_2": "",
            "CITY": "Gevgelija - Mrzenci",
            "COUNTRY": "",
            "COUNTRY_CODE": "MK",
            "POSTAL_CODE": "1480",
            "MAIN_PHONE": "+389 70 238 079",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+389 71 207 930",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+389 34 211 004",
            "EMAIL_ADDRESS": "zona_ekspres@t.mk",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Gevgelija - Mrzenci",
            "COMPAGNY_NAME": "Zona Express - Gevgelija",
            "LATITUDE": "41.162884",
            "LONGITUDE": "22.495495",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marenchev Kostadin",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:8:00:::16:00,7::::"
        },
        {
            "ID": 291,
            "ADDRESS_LINE_1": "Burmarrad Road",
            "ADDRESS_LINE_2": "",
            "CITY": "Burmarrad",
            "COUNTRY": "Malta",
            "COUNTRY_CODE": "MT",
            "POSTAL_CODE": "9060",
            "MAIN_PHONE": "(+356) 21573261",
            "ALT_PHONE": "(+356) 99429933",
            "MOBILE_PHONE": "(+356) 99429020",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+356) 21577286",
            "EMAIL_ADDRESS": "maria@bc.com.mt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Burmarrad",
            "COMPAGNY_NAME": "BURMARRAD COMMERCIALS LTD",
            "LATITUDE": "35.931091",
            "LONGITUDE": "14.416214",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:18:00,2:7:00:18:00,3:7:00:18:00,4:7:00:18:00,5:7:00:18:00,6:7:00:12:00"
        },
        {
            "ID": 681,
            "ADDRESS_LINE_1": "9 Ruhr, Northern Industria",
            "ADDRESS_LINE_2": "PO Box 90074",
            "CITY": "Klein Windhoek",
            "COUNTRY": "Namibia",
            "COUNTRY_CODE": "NA",
            "POSTAL_CODE": "9000",
            "MAIN_PHONE": "+264 61210192",
            "ALT_PHONE": "+264 811248725",
            "MOBILE_PHONE": "+264 811248725",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+264 61210723",
            "EMAIL_ADDRESS": "whkcra@iway.na",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Klein Windhoek",
            "COMPAGNY_NAME": "WINDHOEK CONVERSION REFRIGERATION",
            "LATITUDE": "-22.536833",
            "LONGITUDE": "17.077906",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Petrus van WYK",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 831,
            "ADDRESS_LINE_1": "92 Moses Garoeb Strret",
            "ADDRESS_LINE_2": "PO Box 2895",
            "CITY": "Walvis Bay",
            "COUNTRY": "Namibia",
            "COUNTRY_CODE": "NA",
            "POSTAL_CODE": "0207",
            "MAIN_PHONE": "09 264 64 206 790",
            "ALT_PHONE": "+27 0800004503",
            "MOBILE_PHONE": "09 264 811 280117",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "09 264 64 204300",
            "EMAIL_ADDRESS": "andre@atlanticref.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Walvis Bay",
            "COMPAGNY_NAME": "ATLANTIC REFRIGERATION",
            "LATITUDE": "-22.545441",
            "LONGITUDE": "17.031262",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Andre THEART",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 912,
            "ADDRESS_LINE_1": "Oshakati Main Road",
            "ADDRESS_LINE_2": "PO Box 1239",
            "CITY": "Oshakati",
            "COUNTRY": "",
            "COUNTRY_CODE": "NA",
            "POSTAL_CODE": "9000",
            "MAIN_PHONE": "(00264) 65 220 749",
            "ALT_PHONE": "(+27) 0 80 000 4503",
            "MOBILE_PHONE": "(00264) 8 1125 8343",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "advanced@mweb.com.na",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Oshakati",
            "COMPAGNY_NAME": "ADVANCED REFIGERATION & ELECTRICAL",
            "LATITUDE": "-17.789453",
            "LONGITUDE": "15.705779",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marius Nagel",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 304,
            "ADDRESS_LINE_1": "Kryptonstraat 21",
            "ADDRESS_LINE_2": "",
            "CITY": "Zoetermeer",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "2718",
            "MAIN_PHONE": "(+31) 79 3613325",
            "ALT_PHONE": "(+31) 79 3613325",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 79 3619514",
            "EMAIL_ADDRESS": "andre.harnas@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "The Hague",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD NETHERLANDS B.V.",
            "LATITUDE": "52.033657",
            "LONGITUDE": "4.499331",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Andre HARNAS",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 305,
            "ADDRESS_LINE_1": "De Roef 11",
            "ADDRESS_LINE_2": "",
            "CITY": "Drachten",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "9206",
            "MAIN_PHONE": "(+31) 512 530188",
            "ALT_PHONE": "(+31) 512 530188",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 512 524721",
            "EMAIL_ADDRESS": "marten.wijngaarden@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Drachten",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD NETHERLANDS B.V.",
            "LATITUDE": "53.114096",
            "LONGITUDE": "6.077282",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marten WIJNGAARDEN",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 306,
            "ADDRESS_LINE_1": "Dijkgraaf 26",
            "ADDRESS_LINE_2": "",
            "CITY": "Duiven",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "6921",
            "MAIN_PHONE": "(+31) 26 3120012",
            "ALT_PHONE": "(+31) 26 3120012",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 26 3120156",
            "EMAIL_ADDRESS": "duiven@ettc.eu",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Arnhem",
            "COMPAGNY_NAME": "TRUCK & TRAILER SERVICE DUIVEN B.V.",
            "LATITUDE": "51.962789",
            "LONGITUDE": "5.992715",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Teun HOFMAN",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 307,
            "ADDRESS_LINE_1": "Horsterweg 74A",
            "ADDRESS_LINE_2": "",
            "CITY": "Grubbenvorst",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "5971",
            "MAIN_PHONE": "(+31) 77 3230465",
            "ALT_PHONE": "(+31) 77 3230465",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 77 3230466",
            "EMAIL_ADDRESS": "marco.niessen@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Venlo",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD NETHERLANDS B.V.",
            "LATITUDE": "51.42693",
            "LONGITUDE": "6.10103",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marco NIESSEN",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 308,
            "ADDRESS_LINE_1": "Klompenmakerstraat 29",
            "ADDRESS_LINE_2": "",
            "CITY": "Hoogvliet",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "3194",
            "MAIN_PHONE": "(+31) 10 2950460",
            "ALT_PHONE": "(+31) 10 2950460",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 10 2950450",
            "EMAIL_ADDRESS": "micha.debruijn@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Rotterdam",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD NETHERLANDS B.V.",
            "LATITUDE": "51.868337",
            "LONGITUDE": "4.379548",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Micha de Bruijn",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 309,
            "ADDRESS_LINE_1": "Siriusstraat 5",
            "ADDRESS_LINE_2": "",
            "CITY": "Tilburg",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "5015",
            "MAIN_PHONE": "(+31) 13 5321234",
            "ALT_PHONE": "(+31) 13 5321234",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 13 5447551",
            "EMAIL_ADDRESS": "jan.relouw@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Tilburg",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD NETHERLANDS B.V.",
            "LATITUDE": "51.580203",
            "LONGITUDE": "5.115101",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jan Relouw",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 310,
            "ADDRESS_LINE_1": "Afmijnstraat 6",
            "ADDRESS_LINE_2": "",
            "CITY": "Amstelveen",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "1187",
            "MAIN_PHONE": "(+31) 297 365770",
            "ALT_PHONE": "(+31) 297 365770",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 297 365780",
            "EMAIL_ADDRESS": "Sander.Oude-Bennink@Carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Aalsmeer",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD NETHERLANDS B.V.",
            "LATITUDE": "52.260278",
            "LONGITUDE": "4.800833",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sander OUDE-BENNINK",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 311,
            "ADDRESS_LINE_1": "Spinfondsweg 4",
            "ADDRESS_LINE_2": "",
            "CITY": "Vaassen",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "8171",
            "MAIN_PHONE": "(+31) 578 579579",
            "ALT_PHONE": "(+31) 578 579579",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 578 579578",
            "EMAIL_ADDRESS": "j.stevens@heeringholland.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Apeldoorn",
            "COMPAGNY_NAME": "HEERING B.V.",
            "LATITUDE": "52.298631",
            "LONGITUDE": "5.98514",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jan-Willem Stevens",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 312,
            "ADDRESS_LINE_1": "Bandijkweg 60 ",
            "ADDRESS_LINE_2": "",
            "CITY": "Maasdijk",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "2676",
            "MAIN_PHONE": "(+31) (0) 88 8822420",
            "ALT_PHONE": "(+31) (0) 88 8822420",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 174 515569",
            "EMAIL_ADDRESS": "Werkplaats@transportkoelingmaasdijk.nl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Maasdijk",
            "COMPAGNY_NAME": "TRANSPORTKOELING MAASDIJK",
            "LATITUDE": "51.949661",
            "LONGITUDE": "4.228063",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Eric Verheij",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:9:00:12:30::,7::::"
        },
        {
            "ID": 314,
            "ADDRESS_LINE_1": "Nijverheidsstraat 49 ",
            "ADDRESS_LINE_2": "",
            "CITY": "Nijkerk",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "3861",
            "MAIN_PHONE": "(+31) 33 2458114",
            "ALT_PHONE": "(+31) 33 2458114",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 33 2460340",
            "EMAIL_ADDRESS": "receptienijkerk@bakker-bedrijfswagens.nl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Nijkerk",
            "COMPAGNY_NAME": "BAKKER BEDRIJFSWAGENS ",
            "LATITUDE": "52.237682",
            "LONGITUDE": "5.475891",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 315,
            "ADDRESS_LINE_1": "De Poort 8",
            "ADDRESS_LINE_2": "",
            "CITY": "Rilland",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "4411",
            "MAIN_PHONE": "(+31) 113 384063",
            "ALT_PHONE": "(+31) 113 384063",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 113 551112",
            "EMAIL_ADDRESS": "info@doestransportkoeling.nl",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Rilland",
            "COMPAGNY_NAME": "DOES TRANSPORTKOELING B.V.",
            "LATITUDE": "51.42248",
            "LONGITUDE": "4.195619",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Gert Jan Hoekman",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 316,
            "ADDRESS_LINE_1": "Gooiland 40",
            "ADDRESS_LINE_2": "",
            "CITY": "Beverwijk",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "1948",
            "MAIN_PHONE": "(+31) 251 216058",
            "ALT_PHONE": "(+31) 251 216058",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 251 217630",
            "EMAIL_ADDRESS": "peter.de.Waard@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Beverwijk",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD NETHERLANDS B.V.",
            "LATITUDE": "52.472103",
            "LONGITUDE": "4.675734",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Peter de WAARD",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 317,
            "ADDRESS_LINE_1": "Ondernemersweg 6",
            "ADDRESS_LINE_2": "",
            "CITY": "Holten",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "7451 PK",
            "MAIN_PHONE": "(+31) 548 855558",
            "ALT_PHONE": "(+31) 548 855558",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 548 855559",
            "EMAIL_ADDRESS": "koelholten@ettc.eu",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Holten",
            "COMPAGNY_NAME": "TRUCK & TRAILER SERVICE HOLTEN B.V.",
            "LATITUDE": "52.278048",
            "LONGITUDE": "6.396492",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "A MULLER",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:00:::18:00,2:7:00:::18:00,3:7:00:::18:00,4:7:00:::18:00,5:7:00:::18:00,6:8:00:12:00::,7::::"
        },
        {
            "ID": 320,
            "ADDRESS_LINE_1": "De Amert 311",
            "ADDRESS_LINE_2": "",
            "CITY": "Veghel",
            "COUNTRY": "Netherlands",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "5462",
            "MAIN_PHONE": "(+31) 413 310057",
            "ALT_PHONE": "(+31) 413 310057",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "(+31) 413 319578",
            "EMAIL_ADDRESS": "adrie.oerlemans@carrier.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Veghel",
            "COMPAGNY_NAME": "CARRIER TRANSICOLD NETHERLANDS B.V.",
            "LATITUDE": "51.620495",
            "LONGITUDE": "5.517347",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Adrie OERLEMANS",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 952,
            "ADDRESS_LINE_1": "Scheysloot 71",
            "ADDRESS_LINE_2": "",
            "CITY": "Noordwijk",
            "COUNTRY": "",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "2201 GN",
            "MAIN_PHONE": "+31 (0) 71 40 500 80",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Ad.Oudijk@carrier.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Noordwijk",
            "COMPAGNY_NAME": "CT Netherlands Noordwijk",
            "LATITUDE": "52.210575",
            "LONGITUDE": "4.443659",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ad Oudijk",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 9,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 953,
            "ADDRESS_LINE_1": "Lodewijkstraat 3a",
            "ADDRESS_LINE_2": "",
            "CITY": "Eindhoven",
            "COUNTRY": "",
            "COUNTRY_CODE": "NL ",
            "POSTAL_CODE": "5652 AC",
            "MAIN_PHONE": "+31(0)40-2436030",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "0800 0224894",
            "FAX_PHONE": "+31(0)40-2460450",
            "EMAIL_ADDRESS": "info@erf.nl",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Eindhoven",
            "COMPAGNY_NAME": "Eindhovense Radiateuren Fabriek B.V.",
            "LATITUDE": "51.432923",
            "LONGITUDE": "5.449508",
            "DD": "1",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Mr. Dijk, Henk or Stefan",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6::::,7::::"
        },
        {
            "ID": 322,
            "ADDRESS_LINE_1": "Haraldrudveien 22",
            "ADDRESS_LINE_2": "",
            "CITY": "Bjerke",
            "COUNTRY": "Norway",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "0581",
            "MAIN_PHONE": "(+47) 23 17 34 30",
            "ALT_PHONE": "(+47) 481 75 000",
            "MOBILE_PHONE": "(+47) 481 75 000",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "(+47) 22 64 04 21",
            "EMAIL_ADDRESS": "mette@transrep.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Oslo",
            "COMPAGNY_NAME": "TRANSREP AS",
            "LATITUDE": "59.927248",
            "LONGITUDE": "10.825632",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mette Christensen",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::20:00,2:7:00:::20:00,3:7:00:::20:00,4:7:00:::20:00,5:7:00:::15:30,6::::,7::::"
        },
        {
            "ID": 323,
            "ADDRESS_LINE_1": "Halsetsvea 38",
            "ADDRESS_LINE_2": "",
            "CITY": "Ingeberg",
            "COUNTRY": "Norway",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "2323",
            "MAIN_PHONE": "+4791794125",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "+4762358822",
            "EMAIL_ADDRESS": "support@westrum.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Ingeberg",
            "COMPAGNY_NAME": "WESTRUM KAROSSERIVERKSTED AS",
            "LATITUDE": "60.83986",
            "LONGITUDE": "11.09374",
            "DD": "1",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Arne LUTNES",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:::15:30,2:7:30:::15:30,3:7:30:::15:30,4:7:30:::15:30,5:7:30:::15:30,6::::,7:7:30:::15:30"
        },
        {
            "ID": 324,
            "ADDRESS_LINE_1": "Holabekkvegen 80",
            "ADDRESS_LINE_2": "",
            "CITY": "Furnes",
            "COUNTRY": "Norway",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "2320",
            "MAIN_PHONE": "(+47) 62 35 95 00",
            "ALT_PHONE": "(+47) 33 33 57 60",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "magne.dahlsveen@volmax.no",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Furnes",
            "COMPAGNY_NAME": "VOLMAX AS AVD. HAMAR",
            "LATITUDE": "60.84245",
            "LONGITUDE": "11.06316",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Magne DAHLSVEEN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::22:30,2:7:00:::22:30,3:7:00:::22:30,4:7:00:::22:30,5:7:00:::19:00,6::::,7:7:00:::22:30"
        },
        {
            "ID": 325,
            "ADDRESS_LINE_1": "Stormyraveien 52/54",
            "ADDRESS_LINE_2": "",
            "CITY": "Bodo",
            "COUNTRY": "Norway",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "8008",
            "MAIN_PHONE": "(+47) 75 56 55 78",
            "ALT_PHONE": "(+47) 41 63 16 26",
            "MOBILE_PHONE": "0",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "(+47) 75 52 91 29",
            "EMAIL_ADDRESS": "oyvind.anderbakk@teamverksted.no",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Bodo",
            "COMPAGNY_NAME": "TEAM VERKSTED NORD AS",
            "LATITUDE": "67.279412",
            "LONGITUDE": "14.419835",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Øyvind Ånderbakk",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::22:00,2:7:00:::22:00,3:7:00:::22:00,4:7:00:::22:00,5:7:00:::16:00,6::::,7:7:00:::22:00"
        },
        {
            "ID": 327,
            "ADDRESS_LINE_1": "Fosseikeveien 12",
            "ADDRESS_LINE_2": "",
            "CITY": "Sandnes",
            "COUNTRY": "Norway",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "4323",
            "MAIN_PHONE": "(+47) 51644444",
            "ALT_PHONE": "(+47) 51644444",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "(+47) 51644464",
            "EMAIL_ADDRESS": "Kjetil.lunde@termosenteret.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Sandnes",
            "COMPAGNY_NAME": "TERMO SENTERET AS, Avd Rogaland",
            "LATITUDE": "58.801317",
            "LONGITUDE": "5.735511",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Kjetil LUNDE",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6::::,7::::"
        },
        {
            "ID": 329,
            "ADDRESS_LINE_1": "Ramstadlokka 4",
            "ADDRESS_LINE_2": "",
            "CITY": "Mysen",
            "COUNTRY": "Norway",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "1850",
            "MAIN_PHONE": "(+47) 69845770",
            "ALT_PHONE": "(+47) 40413991",
            "MOBILE_PHONE": "(+47) 40413990",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "(+47) 69845779",
            "EMAIL_ADDRESS": "A.Solberg@ramstadlokka.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Mysen",
            "COMPAGNY_NAME": "RAMSTADLOKKA AUTO AS",
            "LATITUDE": "59.553175",
            "LONGITUDE": "11.366038",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Andre SOLBERG",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:16:00,2:8:00:16:00,3:8:00:16:00,4:8:00:10:00,5:8:00:16:00,6:8:00:16:00,7:8:00:16:00"
        },
        {
            "ID": 332,
            "ADDRESS_LINE_1": "Mosseveien 60",
            "ADDRESS_LINE_2": "",
            "CITY": "Råde",
            "COUNTRY": "Norway",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "1640",
            "MAIN_PHONE": "(+47) 69 28 32 33",
            "ALT_PHONE": "(+47) 9417 32 15",
            "MOBILE_PHONE": "(+47) 69 28 32 35",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "(+47) 69 28 20 71",
            "EMAIL_ADDRESS": "carl.henrik.huseby@volmax.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Råde",
            "COMPAGNY_NAME": "VOLMAX AVD. R",
            "LATITUDE": "59.358374",
            "LONGITUDE": "10.838141",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Carl Henrik Huseby",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:15:00,2:7:00:15:00,3:7:00:15:00,4:7:00:15:00,5:7:00:15:00"
        },
        {
            "ID": 335,
            "ADDRESS_LINE_1": "Lonningshaugen 15",
            "ADDRESS_LINE_2": "",
            "CITY": "Blomsterdalen",
            "COUNTRY": "Norway",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "5258",
            "MAIN_PHONE": "(+47) 64 44 44 ",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "arvid@termosenteret.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bergen",
            "COMPAGNY_NAME": "TERMO SENTERET AS Avd Vestland",
            "LATITUDE": "60.280473",
            "LONGITUDE": "5.232968",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Arvid SOEGNESAND",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::22:00,2:7:00:::22:00,3:7:00:::22:00,4:7:00:::22:00,5:7:00:::22:00,6::::,7:7:00:::22:00"
        },
        {
            "ID": 342,
            "ADDRESS_LINE_1": "Øran Øst Fjellgata 7",
            "ADDRESS_LINE_2": "",
            "CITY": "Andalsnes",
            "COUNTRY": "Norway",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "6300",
            "MAIN_PHONE": "(+47) 90827684",
            "ALT_PHONE": "(+47) 90827684",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "(+47) 71222203",
            "EMAIL_ADDRESS": "arilnakk@online.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Andalsnes",
            "COMPAGNY_NAME": "NAKKEN KJOLESERVICE",
            "LATITUDE": "62.56757",
            "LONGITUDE": "7.687168",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Arild NAKKEN",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::17:00,2:7:00:::17:00,3:7:00:::17:00,4:7:00:::17:00,5:7:00:::17:00,6::::,7::::"
        },
        {
            "ID": 343,
            "ADDRESS_LINE_1": "Tennes",
            "ADDRESS_LINE_2": "Postboks 138  9059 Storsteinnes",
            "CITY": "Storsteinnes",
            "COUNTRY": "Norway",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "9050",
            "MAIN_PHONE": "(+47) 77728000",
            "ALT_PHONE": "(+47) 90163800",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "(+47) 77724460",
            "EMAIL_ADDRESS": "steinar83@hotmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Storsteinnes",
            "COMPAGNY_NAME": "NORHEIM KJOLESERVICE",
            "LATITUDE": "69.240852",
            "LONGITUDE": "19.2344",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Steinar NORHEIM",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:18:00,2:7:00:18:00,3:7:00:18:00,4:7:00:18:00,5:7:00:18:00"
        },
        {
            "ID": 344,
            "ADDRESS_LINE_1": "Bergsodden 28",
            "ADDRESS_LINE_2": "Postboks 3022  9498 Harstad",
            "CITY": "Harstad",
            "COUNTRY": "Norway",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "9403",
            "MAIN_PHONE": "(+47) 90767527",
            "ALT_PHONE": "(+47) 90767527",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "(+47) 77 07 28 89",
            "EMAIL_ADDRESS": "hege.hogaas@hlkbb.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Harstad",
            "COMPAGNY_NAME": "HARSTAD AGGREGATSERVICE",
            "LATITUDE": "68.798691",
            "LONGITUDE": "16.541462",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Nils Petter ANDREASEN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:18:00,2:7:00:18:00,3:7:00:18:00,4:7:00:18:00,5:7:00:18:00"
        },
        {
            "ID": 697,
            "ADDRESS_LINE_1": "Borgeskogen 46 ",
            "ADDRESS_LINE_2": "",
            "CITY": "Stokke",
            "COUNTRY": "Norway",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "3160",
            "MAIN_PHONE": "(+47) 33 48 87 00",
            "ALT_PHONE": "(+47) 97 13 98 86",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "(+47) 33 48 87 01",
            "EMAIL_ADDRESS": "ernst.ernstsen@volmax.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Stokke",
            "COMPAGNY_NAME": "VOLMAX AS BORGESKOGEN ",
            "LATITUDE": "59.248045",
            "LONGITUDE": "10.260132",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ernst Ernstsen",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::23:00,2:7:00:::23:00,3:7:00:::23:00,4:7:00:::23:00,5:7:00:::15:00,6::::,7::::"
        },
        {
            "ID": 905,
            "ADDRESS_LINE_1": "Bygastadveien 11",
            "ADDRESS_LINE_2": "",
            "CITY": "Sande i Sunnfjord",
            "COUNTRY": "",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "6973",
            "MAIN_PHONE": "+4797056552",
            "ALT_PHONE": "+48503140300",
            "MOBILE_PHONE": "+48501568802",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "+48814403861",
            "EMAIL_ADDRESS": "gitle@nistadtransport.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Sande i Sunnfjord",
            "COMPAGNY_NAME": "TUNGBILSERVICE AG",
            "LATITUDE": "61.32106",
            "LONGITUDE": "5.793175",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Gitle Sande / Eirik Nistad",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:16:00::,2:8:00:16:00::,3:8:00:16:00::,4:8:00:16:00::,5:8:00:16:00::,6::::,7::::"
        },
        {
            "ID": 956,
            "ADDRESS_LINE_1": "Postboks24",
            "ADDRESS_LINE_2": "Banak",
            "CITY": "Lakselv",
            "COUNTRY": "",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "9711",
            "MAIN_PHONE": "+4790099356",
            "ALT_PHONE": "+4791850528",
            "MOBILE_PHONE": "+4741646155",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Lakselv.verksted@scania.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Lakselv",
            "COMPAGNY_NAME": "Norsk Scania AS Avd. Lakselv",
            "LATITUDE": "70.044724",
            "LONGITUDE": "24.96979",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Pål Børge Monstad",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::15:30,2:7:30:::15:30,3:7:30:::15:30,4:7:30:::15:30,5:7:30:::15:30,6::::,7::::"
        },
        {
            "ID": 986,
            "ADDRESS_LINE_1": "Vestre Strømsbuvei 10",
            "ADDRESS_LINE_2": "",
            "CITY": "Arendal",
            "COUNTRY": "",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "4939",
            "MAIN_PHONE": "+47 92699513",
            "ALT_PHONE": "+47 51644444",
            "MOBILE_PHONE": "+47 92699515",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "+47 51644464",
            "EMAIL_ADDRESS": "Kristoffer@termosenteret.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Arendal",
            "COMPAGNY_NAME": "TERMO SENTERET AS AVD AGDER",
            "LATITUDE": "58.447542",
            "LONGITUDE": "8.74318",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Kristoffer Fjelde",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6::::,7::::"
        },
        {
            "ID": 1018,
            "ADDRESS_LINE_1": "Gjellebekkstubben 29",
            "ADDRESS_LINE_2": "",
            "CITY": "Lierskogen",
            "COUNTRY": "",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "3420",
            "MAIN_PHONE": "+4799077722",
            "ALT_PHONE": "+4797068754",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "post@xn--kjlegutta-m8a.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Lierskogen",
            "COMPAGNY_NAME": "Kjølegutta  as",
            "LATITUDE": "59.803586",
            "LONGITUDE": "10.294179",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Markus Guzowski",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6::::,7::::"
        },
        {
            "ID": 1023,
            "ADDRESS_LINE_1": "Langnesveien 97",
            "ADDRESS_LINE_2": "",
            "CITY": "Tana ",
            "COUNTRY": "",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "9845",
            "MAIN_PHONE": "+47 480 89 283",
            "ALT_PHONE": "+4746286848 / +4791903863",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "jep@industrikulde.no",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Tana ",
            "COMPAGNY_NAME": "Industrikulde AS",
            "LATITUDE": "70.43676",
            "LONGITUDE": "28.24211",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Jan Egil Pettersen",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6::::,7::::"
        },
        {
            "ID": 1039,
            "ADDRESS_LINE_1": "Heggstadmoen 1D",
            "ADDRESS_LINE_2": "",
            "CITY": "Heimdal",
            "COUNTRY": "",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "7080",
            "MAIN_PHONE": "+47 93 64 77 01",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "tip.trondheim@tipeurope.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Heimdal",
            "COMPAGNY_NAME": "TIP Trailer Services ANS, avd Trondheim",
            "LATITUDE": "63.34473",
            "LONGITUDE": "10.35336",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Timo Salminen",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6::::,7::::"
        },
        {
            "ID": 1040,
            "ADDRESS_LINE_1": "Deliveien 27",
            "ADDRESS_LINE_2": "",
            "CITY": "Vestby",
            "COUNTRY": "",
            "COUNTRY_CODE": "NO",
            "POSTAL_CODE": "1540",
            "MAIN_PHONE": "+47 64984300",
            "ALT_PHONE": "+47 91698579",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "80011435",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "christian.olesen@tipeurope.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Vestby",
            "COMPAGNY_NAME": "TIP Trailer Services ANS avd. Vestby",
            "LATITUDE": "59.58522",
            "LONGITUDE": "10.7407",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Christian Olesen",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::15:00,2:7:00:::15:00,3:7:00:::15:00,4:7:00:::15:00,5:7:00:::15:00,6::::,7::::"
        },
        {
            "ID": 345,
            "ADDRESS_LINE_1": "PO Box 421",
            "ADDRESS_LINE_2": "",
            "CITY": "Muscat",
            "COUNTRY": "Oman",
            "COUNTRY_CODE": "OM",
            "POSTAL_CODE": "118",
            "MAIN_PHONE": "+968- 24445402",
            "ALT_PHONE": "+968-96790728",
            "MOBILE_PHONE": "+968-95869574",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "rajeeshn@otegroup.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Muscat",
            "COMPAGNY_NAME": "OMAN GULF ENTREPRISES",
            "LATITUDE": "23.614171",
            "LONGITUDE": "58.590834",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Rajeesh NAIR",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:13:00:16:00:19:00,2:7:30:13:00:16:00:19:00,3:7:30:13:00:16:00:19:00,4:7:30:13:00:16:00:19:00,5::::,6:7:30:13:00:16:00:19:00,7:7:30:13:00:16:00:19:00"
        },
        {
            "ID": 346,
            "ADDRESS_LINE_1": "Po Box 205",
            "ADDRESS_LINE_2": "",
            "CITY": "Sohar",
            "COUNTRY": "Oman",
            "COUNTRY_CODE": "OM",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "+968- 26948380",
            "ALT_PHONE": "+968-99379134",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "vijayanandk@otegroup.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Sohar",
            "COMPAGNY_NAME": "OMAN GULF ENTREPRISES",
            "LATITUDE": "24.379046",
            "LONGITUDE": "56.731328",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr Vijayanand KITTUSWAMY",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:13:00:16:00:19:00,2:7:30:13:00:16:00:19:00,3:7:30:13:00:16:00:19:00,4:7:30:13:00:16:00:19:00,5::::,6:7:30:13:00:16:00:19:00,7:7:30:13:00:16:00:19:00"
        },
        {
            "ID": 347,
            "ADDRESS_LINE_1": "Po Box 2123",
            "ADDRESS_LINE_2": "",
            "CITY": "Salalah",
            "COUNTRY": "Oman",
            "COUNTRY_CODE": "OM",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "(+968) 23210838",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+968-96790711",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "madhup@otegroup.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Salalah",
            "COMPAGNY_NAME": "OMAN GULF ENTREPRISES",
            "LATITUDE": "17.013895",
            "LONGITUDE": "54.092302",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr Madhu Philip",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:13:00:16:00:19:00,2:7:30:13:00:16:00:19:00,3:7:30:13:00:16:00:19:00,4:7:30:13:00:16:00:19:00,5::::,6:7:30:13:00:16:00:19:00,7:7:30:13:00:16:00:19:00"
        },
        {
            "ID": 349,
            "ADDRESS_LINE_1": "Po Box 784",
            "ADDRESS_LINE_2": "",
            "CITY": "Nizwa",
            "COUNTRY": "Oman",
            "COUNTRY_CODE": "OM",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "+968 25437179",
            "ALT_PHONE": "+968 99249085",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "nizwaservice@otegroup.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Nizwa",
            "COMPAGNY_NAME": "OMAN GULF ENTREPRISES",
            "LATITUDE": "22.921797",
            "LONGITUDE": "57.515767",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr Mohan Raj",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:13:00:16:00:19:00,2:7:30:13:00:16:00:19:00,3:7:30:13:00:16:00:19:00,4:7:30:13:00:16:00:19:00,5::::,6:7:30:13:00:16:00:19:00,7:7:30:13:00:16:00:19:00"
        },
        {
            "ID": 698,
            "ADDRESS_LINE_1": "",
            "ADDRESS_LINE_2": "",
            "CITY": "Bani Bu Ali",
            "COUNTRY": "Oman",
            "COUNTRY_CODE": "OM",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "+968 25553387",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "+968 25554240",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+968 99071667",
            "EMAIL_ADDRESS": "banibualisrv@otegroup.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bani Bu Ali",
            "COMPAGNY_NAME": "OMAN GULF ENTERPRISES",
            "LATITUDE": "22.2344",
            "LONGITUDE": "59.33928",
            "DD": "0",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Rashid Al SIYABI",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1::::,2::::,3::::,4::::,5::::,6::::,7::::"
        },
        {
            "ID": 938,
            "ADDRESS_LINE_1": "",
            "ADDRESS_LINE_2": "",
            "CITY": "Barka",
            "COUNTRY": "",
            "COUNTRY_CODE": "OM",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "+968 26883272",
            "ALT_PHONE": "+968 96790757",
            "MOBILE_PHONE": "+968 94394283",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "subbiahs@otegroup.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Barka",
            "COMPAGNY_NAME": "OMAN GULF ENTERPRISE LLC",
            "LATITUDE": "23.669106",
            "LONGITUDE": "57.877842",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mr Subbaih SELVAM",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:13:00:16:00:19:00,2:7:30:13:00:16:00:19:00,3:7:30:13:00:16:00:19:00,4:7:30:13:00:16:00:19:00,5::::,6::::,7:7:30:13:00:16:00:19:00"
        },
        {
            "ID": 295,
            "ADDRESS_LINE_1": "ul. ALEJE JEROZOLIMSKIE 451",
            "ADDRESS_LINE_2": "",
            "CITY": "PRUSZKÓW",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "05-800 ",
            "MAIN_PHONE": "+48 696 055 600",
            "ALT_PHONE": "+48 601 735 136",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "info@frigotech.com.pl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "PRUSZKÓW",
            "COMPAGNY_NAME": "FRIGOTECH",
            "LATITUDE": "52.172359",
            "LONGITUDE": "20.829337",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "1",
            "CONTACT": "KAROL FRANKOWSKI",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:30,2:8:00:::17:30,3:8:00:::17:30,4:8:00:::17:30,5:8:00:::17:30,6:9:00:::13:00,7::::"
        },
        {
            "ID": 350,
            "ADDRESS_LINE_1": "Zascianki 79",
            "ADDRESS_LINE_2": "",
            "CITY": "Miedzyrzec Podlaski",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "21-560",
            "MAIN_PHONE": "(+48) 83 371 41 40",
            "ALT_PHONE": "(+48) 503 352 808",
            "MOBILE_PHONE": "(+48) 503 140 300",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "(+48) 81 440 38 61",
            "EMAIL_ADDRESS": "miedzyrzec@coolmar.pl",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Biala Podlaska",
            "COMPAGNY_NAME": "COOLMAR",
            "LATITUDE": "52.0011",
            "LONGITUDE": "22.7652",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Leszek LAJTAR, Marek SERGIEL",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 11,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::20:00,2:8:00:::20:00,3:8:00:::20:00,4:8:00:::20:00,5:8:00:::20:00,6:8:00:::16:00,7::::"
        },
        {
            "ID": 351,
            "ADDRESS_LINE_1": "Romanow 10B ",
            "ADDRESS_LINE_2": "",
            "CITY": "Kamienica Polska",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "42-260",
            "MAIN_PHONE": "(+48) 34 326 20 04",
            "ALT_PHONE": "(+48) 785 479 493",
            "MOBILE_PHONE": "(+48) 661 313 510",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "(+48) 34 326 20 60",
            "EMAIL_ADDRESS": "nedpol@wanlcki.pl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Czestochowa",
            "COMPAGNY_NAME": "NEDPOL TRUCK SERVICE Sp z.o.o.",
            "LATITUDE": "50.67182",
            "LONGITUDE": "19.122",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Maciej LUDYNIA",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:6:00:::22:00,2:6:00:::22:00,3:6:00:::22:00,4:6:00:::22:00,5:6:00:::22:00,6:7:00:::14:00,7::::"
        },
        {
            "ID": 354,
            "ADDRESS_LINE_1": "Tyniecka 231",
            "ADDRESS_LINE_2": "",
            "CITY": "Kraków",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "30-376",
            "MAIN_PHONE": "(+48) 12 261 70 17",
            "ALT_PHONE": "(+48) 600 635 377",
            "MOBILE_PHONE": "(+48) 602 798 058",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "(+48) 12 261 70 15",
            "EMAIL_ADDRESS": "wieslaw.zak@scania.pl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Kraków",
            "COMPAGNY_NAME": "SCANIA POLSKA S.A. KRAKOW",
            "LATITUDE": "50.024791",
            "LONGITUDE": "19.837157",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "W.Zak",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:00,2:7:30:::16:00,3:7:30:::16:00,4:7:30:::16:00,5:7:30:::16:00,6::::,7::::"
        },
        {
            "ID": 357,
            "ADDRESS_LINE_1": "Poznanska 71",
            "ADDRESS_LINE_2": "",
            "CITY": "Gadki ",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "62-023",
            "MAIN_PHONE": "(+48) 61 898 85 22",
            "ALT_PHONE": "(+48) 601 706 269",
            "MOBILE_PHONE": "(+48) 601 706 269",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "(+48) 61 898 87 29",
            "EMAIL_ADDRESS": "serwis@raben-group.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Poznan",
            "COMPAGNY_NAME": "Raben Truck Service",
            "LATITUDE": "52.307806",
            "LONGITUDE": "17.047667",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Service Technician",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::23:00,2:7:00:::23:00,3:7:00:::23:00,4:7:00:::23:00,5:7:00:::23:00,6:6:00:::14:00,7::::"
        },
        {
            "ID": 359,
            "ADDRESS_LINE_1": "Trzciana 162",
            "ADDRESS_LINE_2": "",
            "CITY": "Trzciana",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "36-071",
            "MAIN_PHONE": "(+48) 17 860 47 30",
            "ALT_PHONE": "(+48) 17 860 47 00",
            "MOBILE_PHONE": "(+48) 604 206 726",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "rzeszow@wanicki.pl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Rzeszów",
            "COMPAGNY_NAME": "WW Wanicki Sp. z o. o.",
            "LATITUDE": "50.07197",
            "LONGITUDE": "21.85011",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jakub POROWSKI",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::22:00,2:7:00:::22:00,3:7:00:::22:00,4:7:00:::22:00,5:7:00:::22:00,6:7:00:::14:00,7::::"
        },
        {
            "ID": 360,
            "ADDRESS_LINE_1": "Katowicka 121/123",
            "ADDRESS_LINE_2": "",
            "CITY": "Rzgow",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "95-030",
            "MAIN_PHONE": "(+48) 42 20922 01",
            "ALT_PHONE": "(+48) 605 551 487",
            "MOBILE_PHONE": "(+48) 691 956 239",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "(+48) 42 209 22 11",
            "EMAIL_ADDRESS": "tb-rzgow@tb.nl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Rzgow",
            "COMPAGNY_NAME": "TB TRUCK & TRAILER SERWIS - RZGOW",
            "LATITUDE": "51.640775",
            "LONGITUDE": "19.496988",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Roman ROZYCKI / Mariusz KALUZYNSKI",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::20:00,2:8:00:::20:00,3:8:00:::20:00,4:8:00:::20:00,5:8:00:::20:00,6::::,7::::"
        },
        {
            "ID": 361,
            "ADDRESS_LINE_1": "Poznanska 50",
            "ADDRESS_LINE_2": "JASIN",
            "CITY": "Jasin",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "62-020",
            "MAIN_PHONE": "(+48) 61 81 81 067",
            "ALT_PHONE": "(+48) 604 608 011",
            "MOBILE_PHONE": "(+48) 604 608 091",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "(+48) 791 608 011",
            "EMAIL_ADDRESS": "michal.dolata@frostsystems.pl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Swarzedz",
            "COMPAGNY_NAME": "FROST SYSTEMS ",
            "LATITUDE": "52.407858",
            "LONGITUDE": "17.075554",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Michal DOLATA / Lukasz WARLYHA / Błażej Pięta",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6::::,7::::"
        },
        {
            "ID": 364,
            "ADDRESS_LINE_1": "Poznanska 290D",
            "ADDRESS_LINE_2": "",
            "CITY": "Torun",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "87-100",
            "MAIN_PHONE": "(+48) 56 658 03 01",
            "ALT_PHONE": "(+48) 600 040 033",
            "MOBILE_PHONE": "(+48) 600 040 033",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "(+48) 56 658 03 00",
            "EMAIL_ADDRESS": "torun@grupadbk.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Torun",
            "COMPAGNY_NAME": "DBK Sp. z.o.o. TORUN",
            "LATITUDE": "52.970308",
            "LONGITUDE": "18.563257",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Michał WRUKOWSKI",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::22:00,2:7:00:::22:00,3:7:00:::22:00,4:7:00:::22:00,5:7:00:::22:00,6:7:00:::14:00,7::::"
        },
        {
            "ID": 365,
            "ADDRESS_LINE_1": "Al. Katowicka 316,",
            "ADDRESS_LINE_2": "",
            "CITY": "Nadarzyn",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "05-830",
            "MAIN_PHONE": "(+48) 22 356 02 12",
            "ALT_PHONE": "(+48) 502 211 247",
            "MOBILE_PHONE": "(+48) 502 211 247",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "(+48) 22 35 60 221",
            "EMAIL_ADDRESS": "pawel.dabrowski@scania.pl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Warszawa",
            "COMPAGNY_NAME": "SCANIA POLSKA SA ",
            "LATITUDE": "52.037106",
            "LONGITUDE": "20.767504",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Pawel DABROWSKI",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:6:00:::14:00,2:6:00:::14:00,3:6:00:::14:00,4:6:00:::14:00,5:6:00:::14:00,6::::,7::::"
        },
        {
            "ID": 366,
            "ADDRESS_LINE_1": "Robotnicza 40",
            "ADDRESS_LINE_2": "",
            "CITY": "Dlugoleka",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "55-095",
            "MAIN_PHONE": "(+48) 669 208 308",
            "ALT_PHONE": "(+48) 601 442 122",
            "MOBILE_PHONE": "(+48) 665 377 007",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "(+48) 71 315 31 56",
            "EMAIL_ADDRESS": "carrier@prochlod.pl",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Wroclaw",
            "COMPAGNY_NAME": "PROCHLOD",
            "LATITUDE": "51.177063",
            "LONGITUDE": "17.201018",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Pawel KUDLA - Artur BRATEK",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6:8:00:::14:00,7::::"
        },
        {
            "ID": 669,
            "ADDRESS_LINE_1": "Gornoslaska 17",
            "ADDRESS_LINE_2": "",
            "CITY": "Pszczyna",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "43-200",
            "MAIN_PHONE": "+48 502 603 821",
            "ALT_PHONE": "(+48) 512 237 732",
            "MOBILE_PHONE": "(+48) 512 237 732",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "+48 32 44 70 773",
            "EMAIL_ADDRESS": "biuro@servocool.pl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Pszczyna",
            "COMPAGNY_NAME": "SERVO COOL Sp z.o.o.",
            "LATITUDE": "49.979608",
            "LONGITUDE": "18.958189",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Przemyslaw ROGOZINSKI",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 5,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:8:00:::14:00,7::::"
        },
        {
            "ID": 674,
            "ADDRESS_LINE_1": "Ignatki 40/4",
            "ADDRESS_LINE_2": "",
            "CITY": "Kleosin",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "16-001",
            "MAIN_PHONE": "+48 85 74 74 999",
            "ALT_PHONE": "+48 602358354",
            "MOBILE_PHONE": "+48 602358354",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "+48 85 868 22 20",
            "EMAIL_ADDRESS": "w.matys@wp.pl",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kleosin",
            "COMPAGNY_NAME": "MAVIS",
            "LATITUDE": "53.072081",
            "LONGITUDE": "23.098283",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marek PUCZKO",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,5:8:00:17:00,6:8:00:14:00"
        },
        {
            "ID": 693,
            "ADDRESS_LINE_1": "Transportowa 11",
            "ADDRESS_LINE_2": "",
            "CITY": "Slubice",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "69-100 ",
            "MAIN_PHONE": "(+48) 523 790 429 ",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+48) 605 053 985",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "+48 95 758 89 38",
            "EMAIL_ADDRESS": "slubice@thermotechnica.pl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Slubice",
            "COMPAGNY_NAME": "THERMO TECHNICA",
            "LATITUDE": "52.33456",
            "LONGITUDE": "14.5958",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marek KRAMARZ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:9:00:::15:00,7::::"
        },
        {
            "ID": 694,
            "ADDRESS_LINE_1": "Jesienna 4",
            "ADDRESS_LINE_2": "",
            "CITY": "Szczecin",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "70-807 ",
            "MAIN_PHONE": "(+48) 728 947 543 ",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+48) 601 091 233",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "+48 91 4 825 422",
            "EMAIL_ADDRESS": "biuro@carrierserwis.pl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Szczecin",
            "COMPAGNY_NAME": "THERMO TECHNICA",
            "LATITUDE": "53.38836",
            "LONGITUDE": "14.66474",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marcin JAKUBIEC",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:9:00:::15:00,7::::"
        },
        {
            "ID": 715,
            "ADDRESS_LINE_1": "ul. Transportowcow 8",
            "ADDRESS_LINE_2": "",
            "CITY": "Debica",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "32-900",
            "MAIN_PHONE": "(+48) 602 179 941",
            "ALT_PHONE": "(+48) 530 272 727",
            "MOBILE_PHONE": "(+48) 530 272 727",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "(+48) 14 68 11 863",
            "EMAIL_ADDRESS": "termodynamika.debica@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Debica",
            "COMPAGNY_NAME": "TERMODYNAMIKA",
            "LATITUDE": "50.06249",
            "LONGITUDE": "21.441736",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Rafal BOREK",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::17:00,2:7:00:::17:00,3:7:00:::17:00,4:7:00:::17:00,5:7:00:::17:00,6::::,7::::"
        },
        {
            "ID": 778,
            "ADDRESS_LINE_1": "Konstruktorów 21",
            "ADDRESS_LINE_2": "",
            "CITY": "Lublin",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "20-209",
            "MAIN_PHONE": "(+48) 81 469 7751",
            "ALT_PHONE": "(+48) 607 999 356",
            "MOBILE_PHONE": "(+48) 607 997 833",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "+48 81 469 77 50",
            "EMAIL_ADDRESS": "carrier@bury.pl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Lublin",
            "COMPAGNY_NAME": "BURY Sp z.o.o",
            "LATITUDE": "51.239891",
            "LONGITUDE": "22.625502",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Service Technician",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:6:00:::22:00,2:6:00:::22:00,3:6:00:::22:00,4:6:00:::22:00,5:6:00:::22:00,6:6:00:::14:00,7::::"
        },
        {
            "ID": 792,
            "ADDRESS_LINE_1": "ul. Piaskowa 122",
            "ADDRESS_LINE_2": "",
            "CITY": "Tomaszow Mazowiecki",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "97-200",
            "MAIN_PHONE": "(+48) 503 094 699",
            "ALT_PHONE": "(+48) 503 094 699",
            "MOBILE_PHONE": "(+48) 884 993 042",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "+48 44 723 04 67",
            "EMAIL_ADDRESS": "serwis@thermoplex.pl",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Tomaszow Mazowiecki",
            "COMPAGNY_NAME": "THERMOPLEX",
            "LATITUDE": "51.551568",
            "LONGITUDE": "20.035986",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Piotr SAWICKI",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:30:::16:30,2:8:30:::16:30,3:8:30:::16:30,4:8:30:::16:30,5:8:30:::16:30,6:8:00:::14:00,7::::"
        },
        {
            "ID": 793,
            "ADDRESS_LINE_1": "ul. Geodetów 19",
            "ADDRESS_LINE_2": "",
            "CITY": "Gdansk",
            "COUNTRY": "Poland",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "80-298 ",
            "MAIN_PHONE": "+48 58 554 65 93",
            "ALT_PHONE": "+48 698 886 434",
            "MOBILE_PHONE": "+48 698 886 434",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "serwis@ecoterm.com.pl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Gdansk",
            "COMPAGNY_NAME": "ECOTERM S.C.",
            "LATITUDE": "54.357984",
            "LONGITUDE": "18.480973",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jarosław DOBROSZEK",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::18:00,2:7:00:::18:00,3:7:00:::18:00,4:7:00:::18:00,5:7:00:::18:00,6:8:00:::12:00,7::::"
        },
        {
            "ID": 901,
            "ADDRESS_LINE_1": "ul. Warszawska 44",
            "ADDRESS_LINE_2": "",
            "CITY": "Lubicz Górny",
            "COUNTRY": "",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "87-162",
            "MAIN_PHONE": "+48 785 231 063",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "t.ordon@carrier-lubicz.pl",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Lubicz Górny",
            "COMPAGNY_NAME": "Truck Nord Center",
            "LATITUDE": "53.023516",
            "LONGITUDE": "18.787785",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tomasz ORDON",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:8:00:::14:00,7::::"
        },
        {
            "ID": 902,
            "ADDRESS_LINE_1": "ul. ZJEDNOCZENIA 118",
            "ADDRESS_LINE_2": "",
            "CITY": "Zielona Gora",
            "COUNTRY": "",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "65-120",
            "MAIN_PHONE": "+48 68 320 77 14",
            "ALT_PHONE": "+48 517 656 603",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "+48 517 656 603",
            "EMAIL_ADDRESS": "szronsystem@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Zielona Gora",
            "COMPAGNY_NAME": "SZRON SYSTEM",
            "LATITUDE": "51.955362",
            "LONGITUDE": "15.479423",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Piotr KALACIŃSKI",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:9:00:::14:00,7::::"
        },
        {
            "ID": 990,
            "ADDRESS_LINE_1": "Polna 5",
            "ADDRESS_LINE_2": "",
            "CITY": "Dabrowka",
            "COUNTRY": "",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "62-069 ",
            "MAIN_PHONE": "+48 607 540 920",
            "ALT_PHONE": "+48 727 941 469",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "kontakt@htr-dabrowka.pl",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Dabrowka",
            "COMPAGNY_NAME": "HTR FRYDENBERG, STANIEWICZ SP. J.",
            "LATITUDE": "52.38565",
            "LONGITUDE": "16.757635",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marcin Staniewicz ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6:8:00:::14:00,7::::"
        },
        {
            "ID": 1037,
            "ADDRESS_LINE_1": "Kapitana Doranta 31",
            "ADDRESS_LINE_2": "",
            "CITY": " Zakroczym",
            "COUNTRY": "",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "05-170",
            "MAIN_PHONE": "+48 570 777 720",
            "ALT_PHONE": "+48 576 777 340",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "p.wronka@s7serwis.eu",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Warsaw",
            "COMPAGNY_NAME": "S7 Serwis",
            "LATITUDE": "52.44663",
            "LONGITUDE": "20.60821",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Piotr Wronka",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6::::,7::::"
        },
        {
            "ID": 1055,
            "ADDRESS_LINE_1": "nº 1471",
            "ADDRESS_LINE_2": "Rua Cimo de Vila,",
            "CITY": "Ovar",
            "COUNTRY": "",
            "COUNTRY_CODE": "PL",
            "POSTAL_CODE": "3881-901 ",
            "MAIN_PHONE": "+351 256 575 659",
            "ALT_PHONE": "+351 963 118 555",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "00800 3211238",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "geral@costareis.pt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Ovar",
            "COMPAGNY_NAME": "Costa & Reis",
            "LATITUDE": "40.0",
            "LONGITUDE": "-8.0",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tiago Costa",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:30:14:00:18:00,2:8:00:12:30:14:00:18:00,3:8:00:12:30:14:00:18:00,4:8:00:12:30:14:00:18:00,5:8:00:12:30:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 369,
            "ADDRESS_LINE_1": "Estrada Nacional",
            "ADDRESS_LINE_2": "249-4 Trajouce",
            "CITY": "S. Domingos De Rana",
            "COUNTRY": "Portugal",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "2785-035",
            "MAIN_PHONE": "(+351) 214449000",
            "ALT_PHONE": "(+351) 214449000",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "(+351) 21 4449053",
            "EMAIL_ADDRESS": "mail@frindus.pt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Lisboa",
            "COMPAGNY_NAME": "FRINDUS FRIO INDUSTRIAL IDA",
            "LATITUDE": "38.729071",
            "LONGITUDE": "-9.334018",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Eng. Luis Bustorff, Sr M. Penim, J. Do Carmo, J. Bustorff",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:18:30,2:8:00:18:30,3:8:00:18:30,4:8:00:18:30,5:8:00:18:30"
        },
        {
            "ID": 370,
            "ADDRESS_LINE_1": "Zona Industrial de Vilamoura",
            "ADDRESS_LINE_2": "Armazém A- Lote 43 - Vilamoura",
            "CITY": "Quarteira",
            "COUNTRY": "Portugal",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "8125-493",
            "MAIN_PHONE": "(+351) 289388110",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "(+351) 289388110",
            "EMAIL_ADDRESS": "alvaropiedade@sapo.pt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Loule",
            "COMPAGNY_NAME": "ALVARO PIEDADE, LDA",
            "LATITUDE": "37.110862",
            "LONGITUDE": "-8.123102",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sr A. Piedade",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:18:30,2:8:00:18:30,3:8:00:18:30,4:8:00:18:30,5:8:00:18:30"
        },
        {
            "ID": 374,
            "ADDRESS_LINE_1": "Estrada Principal Cegonheira",
            "ADDRESS_LINE_2": "CEGONHEIRA",
            "CITY": "Antanhol",
            "COUNTRY": "Portugal",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "3040-585",
            "MAIN_PHONE": "(+351) 962942309",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "(+351) 239 984071",
            "EMAIL_ADDRESS": "joseaugusto_carrier@sapo.pt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Coimbra",
            "COMPAGNY_NAME": "JOSE AUGUSTO",
            "LATITUDE": "40.172389",
            "LONGITUDE": "-8.487818",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Sr. José AUGUSTO",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:18:30,2:8:00:18:30,3:8:00:18:30,4:8:00:18:30,5:8:00:18:30"
        },
        {
            "ID": 375,
            "ADDRESS_LINE_1": "Rua Tenente Brito, 44",
            "ADDRESS_LINE_2": "",
            "CITY": "Ribeira De Frades",
            "COUNTRY": "Portugal",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "3040-861",
            "MAIN_PHONE": "(+351) 239 985091",
            "ALT_PHONE": "(+351) 91 9453549",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "(+351) 239 985093",
            "EMAIL_ADDRESS": "rafael.filhos@sapo.pt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Coimbra",
            "COMPAGNY_NAME": "RAFAEL & FILHOS, LDA",
            "LATITUDE": "40.204124",
            "LONGITUDE": "-8.491863",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sr. Pedro Miguel",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:18:30,2:8:00:18:30,3:8:00:18:30,4:8:00:18:30,5:8:00:18:30"
        },
        {
            "ID": 377,
            "ADDRESS_LINE_1": "Rua Nossa Senhora da Luz, 131",
            "ADDRESS_LINE_2": "Travanca da Bodiosa",
            "CITY": "Viseu",
            "COUNTRY": "Portugal",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "3515-310",
            "MAIN_PHONE": "(+351) 232 971494",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+351) 91 7524580",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "(+351) 232 972694",
            "EMAIL_ADDRESS": "tecnilemos@hotmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Viseu",
            "COMPAGNY_NAME": "TECNILEMOS",
            "LATITUDE": "40.71999",
            "LONGITUDE": "-7.965195",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sr Rogério",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:18:30,2:8:00:18:30,3:8:00:18:30,4:8:00:18:30,5:8:00:18:30"
        },
        {
            "ID": 379,
            "ADDRESS_LINE_1": "Rua Dr. Amilcar Campos",
            "ADDRESS_LINE_2": "Amoreira",
            "CITY": "Amoreira Obd",
            "COUNTRY": "Portugal",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "2510-402",
            "MAIN_PHONE": "(+351) 262 969503",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+351) 96 8021330",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "(+351) 262 969503",
            "EMAIL_ADDRESS": "friobidos@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Obidos",
            "COMPAGNY_NAME": "FRIOBIDOS-MONTAGENS E REPAR. ELECT. E FRIG. LDA",
            "LATITUDE": "39.344852",
            "LONGITUDE": "-9.206629",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sr. Amadeu Rolim",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:18:30,2:8:00:18:30,3:8:00:18:30,4:8:00:18:30,5:8:00:18:30"
        },
        {
            "ID": 380,
            "ADDRESS_LINE_1": "Carrascal da Estrada, Cx Postal nº 1",
            "ADDRESS_LINE_2": "Mata",
            "CITY": "Mata",
            "COUNTRY": "Portugal",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "6005-250",
            "MAIN_PHONE": "(+351) 272 467572",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+351) 96 2800993",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "(+351) 272 467572",
            "EMAIL_ADDRESS": "friofalcao@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Castelo Branco",
            "COMPAGNY_NAME": "ANTONIO MANUEL FALC",
            "LATITUDE": "40.006976",
            "LONGITUDE": "-7.660805",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Sr. Antonio Manuel Falcão",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": ""
        },
        {
            "ID": 383,
            "ADDRESS_LINE_1": "Rua do Outeiro  Quintal dos Casarões",
            "ADDRESS_LINE_2": "S. Pedro da Gafanhoeira",
            "CITY": "Sao Pedro Gafanhoeira",
            "COUNTRY": "Portugal",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "7040-538",
            "MAIN_PHONE": "(+351) 266 497050",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+351) 96 2968524",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "(+351) 266 497050",
            "EMAIL_ADDRESS": "frialentejo@sapo.pt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Arraiolos",
            "COMPAGNY_NAME": "FRIALENTEJO, LDA",
            "LATITUDE": "38.742097",
            "LONGITUDE": "-8.076303",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Sr Abel Catalão",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:18:30,2:8:00:18:30,3:8:00:18:30,4:8:00:18:30,5:8:00:18:30"
        },
        {
            "ID": 384,
            "ADDRESS_LINE_1": "Quinta das Laranjeiras",
            "ADDRESS_LINE_2": "Amoreiras",
            "CITY": "Lamego",
            "COUNTRY": "Portugal",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "5100-090",
            "MAIN_PHONE": "(+351) 254 656970",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "(+351) 254 656970",
            "EMAIL_ADDRESS": "tofrio@iol.pt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Lamego",
            "COMPAGNY_NAME": "FRIPINTO",
            "LATITUDE": "41.129514",
            "LONGITUDE": "-7.765285",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sr Pinto",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": ""
        },
        {
            "ID": 1054,
            "ADDRESS_LINE_1": "Estrada das Ligeiras nº36",
            "ADDRESS_LINE_2": "Arm.3 / ",
            "CITY": "Agualva -Cacém ",
            "COUNTRY": "",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "2735-337",
            "MAIN_PHONE": "+351 924 780 286",
            "ALT_PHONE": "+351 924 780 285",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "geral@frioglacial.pt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Agualva -Cacém ",
            "COMPAGNY_NAME": "FrioGlacial, Lda",
            "LATITUDE": "38.76218",
            "LONGITUDE": "-9.31124",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Francisco Batista",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:30:14:00:17:30,2:8:00:12:30:14:00:17:30,3:8:00:12:30:14:00:17:30,4:8:00:12:30:14:00:17:30,5:8:00:12:30:14:00:17:30,6::::,7::::"
        },
        {
            "ID": 1057,
            "ADDRESS_LINE_1": "Estrada nacional nº2, nº1153,",
            "ADDRESS_LINE_2": "Várzea - Lobão da Beira,",
            "CITY": "Tondela ",
            "COUNTRY": "",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "3460-205",
            "MAIN_PHONE": "+351 232 245 099",
            "ALT_PHONE": "+351 969 309 491",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Frigiton.geral@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Tondela ",
            "COMPAGNY_NAME": "Frigiton – Frio Industrial, Unip Lda",
            "LATITUDE": "40.53046",
            "LONGITUDE": "-8.05602",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ana Almiro / João Ferreira",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:12:30:14:00:18:00,2:8:30:12:30:14:00:18:00,3:8:30:12:30:14:00:18:00,4:8:30:12:30:14:00:18:00,5:8:30:12:30:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 1058,
            "ADDRESS_LINE_1": "Rua da Leica, nº 176",
            "ADDRESS_LINE_2": "",
            "CITY": "Lousado",
            "COUNTRY": "",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "4760-810 ",
            "MAIN_PHONE": "+351 252 492 790 ",
            "ALT_PHONE": "+351 964 854 040",
            "MOBILE_PHONE": "+351 913 810 139",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "geral@esquimofrio.pt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "V.N.Famalicão",
            "COMPAGNY_NAME": "Esquimofrio",
            "LATITUDE": "41.367",
            "LONGITUDE": "-8.53451",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Engº Adão Rocha",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:12:30:14:00:17:00,2:8:30:12:30:14:00:17:00,3:8:30:12:30:14:00:17:00,4:8:30:12:30:14:00:17:00,5:8:30:12:30:14:00:17:00,6::::,7::::"
        },
        {
            "ID": 1060,
            "ADDRESS_LINE_1": "Rua Cimo de Vila, nº 1471",
            "ADDRESS_LINE_2": "",
            "CITY": "Ovar",
            "COUNTRY": "",
            "COUNTRY_CODE": "PT",
            "POSTAL_CODE": "3881-901 ",
            "MAIN_PHONE": "+351 256 575 659",
            "ALT_PHONE": "+351 963 118 555",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "800832283",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "geral@costareis.pt",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Ovar",
            "COMPAGNY_NAME": "Costa & Reis",
            "LATITUDE": "40.87592",
            "LONGITUDE": "-8.58151",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tiago Costa",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:12:30:14:00:18:00,2:8:00:12:30:14:00:18:00,3:8:00:12:30:14:00:18:00,4:8:00:12:30:14:00:18:00,5:8:00:12:30:14:00:18:00,6::::,7::::"
        },
        {
            "ID": 385,
            "ADDRESS_LINE_1": "Po Box 91",
            "ADDRESS_LINE_2": "",
            "CITY": "Doha",
            "COUNTRY": "Qatar",
            "COUNTRY_CODE": "QA",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "(+974) 44510370",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "(+974) 77838135",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+974) 44510371",
            "EMAIL_ADDRESS": "anton.delivera@almanaenterprises.com.qa",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Doha",
            "COMPAGNY_NAME": "ALMANA ENTERPRISES.CO.W.L.L",
            "LATITUDE": "25.16147",
            "LONGITUDE": "51.44612",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Anton De LIVERA",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:13:00,6:8:00:17:00,7:8:00:17:00"
        },
        {
            "ID": 752,
            "ADDRESS_LINE_1": "No. 4A, Padurii street",
            "ADDRESS_LINE_2": "Chitila",
            "CITY": "Ilfov",
            "COUNTRY": "Romania",
            "COUNTRY_CODE": "RO",
            "POSTAL_CODE": "077045",
            "MAIN_PHONE": "(+40) 728 868 018",
            "ALT_PHONE": "+40 725 527 922",
            "MOBILE_PHONE": "(+40) 731 017 032",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "receptie@coolunit.ro",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Ilfov",
            "COMPAGNY_NAME": "COOL UNIT EXPERT S.R.L.",
            "LATITUDE": "44.495556",
            "LONGITUDE": "25.973056",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Marius DUMITRU",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:30,2:8:00:::16:30,3:8:00:::16:30,4:8:00:::16:30,5:8:00:::16:30,6::::,7::::"
        },
        {
            "ID": 753,
            "ADDRESS_LINE_1": "DN1, km 489, ",
            "ADDRESS_LINE_2": "com Floresti, Jud",
            "CITY": "Cluj",
            "COUNTRY": "Romania",
            "COUNTRY_CODE": "RO",
            "POSTAL_CODE": "407280",
            "MAIN_PHONE": "0040 749 190 912",
            "ALT_PHONE": "0040 749 190 912",
            "MOBILE_PHONE": "0",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0040 374 986 931",
            "EMAIL_ADDRESS": "office@carrierthermoservice.ro",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Cluj",
            "COMPAGNY_NAME": "CARRIER THERMOSERVICE",
            "LATITUDE": "46.749858",
            "LONGITUDE": "23.437376",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Manuel BONCEA",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,5:8:00:17:00"
        },
        {
            "ID": 808,
            "ADDRESS_LINE_1": " Str. Andrei Saguna  nr.240 ",
            "ADDRESS_LINE_2": "0",
            "CITY": "Arad",
            "COUNTRY": "Romania",
            "COUNTRY_CODE": "RO",
            "POSTAL_CODE": "410553",
            "MAIN_PHONE": "+40723575905",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+40257281154",
            "EMAIL_ADDRESS": "office@wes.ro",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Arad",
            "COMPAGNY_NAME": "WES System",
            "LATITUDE": "46.16304",
            "LONGITUDE": "21.300013",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Gheorghe POPA",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:30,4:8:00:::16:00,5:8:00:::16:00,6::::,7::::"
        },
        {
            "ID": 875,
            "ADDRESS_LINE_1": "DJ 103 B, Km 30.5",
            "ADDRESS_LINE_2": "FN, Judetul Covasna",
            "CITY": "Chilieni-Ozun",
            "COUNTRY": "",
            "COUNTRY_CODE": "RO",
            "POSTAL_CODE": "527130",
            "MAIN_PHONE": "+4 0733 105 205",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+4 0733 994 397 ",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+4 0372 373 154",
            "EMAIL_ADDRESS": "e.kocsis@cross-cargo.eu",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Chilieni-Ozun",
            "COMPAGNY_NAME": "Cross Cargo Logistics SRL",
            "LATITUDE": "45.815725",
            "LONGITUDE": "25.826347",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "KOCSIS ELEMER",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:::17:30,2:8:30:::17:30,3:8:30:::17:30,4:8:30:::17:30,5:8:30:::17:30,6::::,7::::"
        },
        {
            "ID": 923,
            "ADDRESS_LINE_1": "No. 30, Stejarului street, ",
            "ADDRESS_LINE_2": "Craiova,",
            "CITY": "Dolj",
            "COUNTRY": "",
            "COUNTRY_CODE": "RO",
            "POSTAL_CODE": "200111",
            "MAIN_PHONE": "+40 744 171264",
            "ALT_PHONE": "+40 728 267118 ",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+40 351 439076",
            "EMAIL_ADDRESS": "frigcompany@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Dolj",
            "COMPAGNY_NAME": "FRIG EUROSERV SRL",
            "LATITUDE": "44.32377",
            "LONGITUDE": "23.846399",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "MARIN IONUT",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:17:30::,2:8:30:17:30::,3:8:30:17:30::,4:8:30:17:30::,5:8:30:17:30::,6::::,7::::"
        },
        {
            "ID": 924,
            "ADDRESS_LINE_1": "No.245A , Calea Clujului, ",
            "ADDRESS_LINE_2": "Oradea,",
            "CITY": "Bihor",
            "COUNTRY": "",
            "COUNTRY_CODE": "RO",
            "POSTAL_CODE": "410553 ",
            "MAIN_PHONE": "+40 74 3840410",
            "ALT_PHONE": "+40 74 3840410",
            "MOBILE_PHONE": "+40 766 664950 ",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "autogramar@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Bihor",
            "COMPAGNY_NAME": "AUTOGRAMAR SRL",
            "LATITUDE": "47.036415",
            "LONGITUDE": "21.9915",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "GUIAȘ VIRGIL-GRAȚIAN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:30:17:30::,2:8:30:17:30::,3:8:30:17:30::,4:8:30:17:30::,5:8:30:17:30::,6::::,7::::"
        },
        {
            "ID": 1038,
            "ADDRESS_LINE_1": "No.108 INFRATIRII street",
            "ADDRESS_LINE_2": "BAICOI",
            "CITY": "PRAHOVA",
            "COUNTRY": "",
            "COUNTRY_CODE": "RO",
            "POSTAL_CODE": "105200",
            "MAIN_PHONE": "+40 726 324 896",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+40 758 505 050",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+40 244 268 844",
            "EMAIL_ADDRESS": "service@exclusive-car.ro",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "PRAHOVA",
            "COMPAGNY_NAME": "EXCLUSIVE CAR TRADING SRL",
            "LATITUDE": "45.017305",
            "LONGITUDE": "25.884593",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "CATALIN HERES",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 676,
            "ADDRESS_LINE_1": "Dositejeva 39",
            "ADDRESS_LINE_2": "",
            "CITY": "Šimanovci",
            "COUNTRY": "Serbia",
            "COUNTRY_CODE": "RS",
            "POSTAL_CODE": "22310",
            "MAIN_PHONE": "+381 22 850 522",
            "ALT_PHONE": "+381 63 24 3957",
            "MOBILE_PHONE": "+381 63 24 3957",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+381 22 850 860",
            "EMAIL_ADDRESS": "stjepan.lozic@wbm.rs",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Šimanovci",
            "COMPAGNY_NAME": "West Balkans Machinery d.o.o",
            "LATITUDE": "44.88906",
            "LONGITUDE": "20.08988",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Stjepan LOZIC",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:00,2:7:30:::16:00,3:7:30:::16:00,4:7:30:::16:00,5:7:30:::16:00,6::::,7::::"
        },
        {
            "ID": 677,
            "ADDRESS_LINE_1": "Novi novosadski put bb",
            "ADDRESS_LINE_2": "",
            "CITY": "Batajnica - Beograd",
            "COUNTRY": "Serbia",
            "COUNTRY_CODE": "RS",
            "POSTAL_CODE": "11273",
            "MAIN_PHONE": "+381 11 377 4516",
            "ALT_PHONE": "+381 62 881 6610",
            "MOBILE_PHONE": "+381 62 881 6610",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+381 11 377 4516",
            "EMAIL_ADDRESS": "ivica@bracacrnomarkovic.rs",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Batajnica - Beograd",
            "COMPAGNY_NAME": "BRACA CRNOMARKOVIC doo",
            "LATITUDE": "44.884317",
            "LONGITUDE": "20.308256",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Ivica Vasic",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:18:00,2:8:00:18:00,3:8:00:18:00,4:8:00:18:00,5:8:00:18:00,6:8:00:14:00"
        },
        {
            "ID": 1017,
            "ADDRESS_LINE_1": "Marsala Tita 236, ",
            "ADDRESS_LINE_2": "",
            "CITY": "Crvenka",
            "COUNTRY": "",
            "COUNTRY_CODE": "RS",
            "POSTAL_CODE": "25220",
            "MAIN_PHONE": "+38125730761",
            "ALT_PHONE": "+381631081557",
            "MOBILE_PHONE": "+381631082786",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+38125730980",
            "EMAIL_ADDRESS": "slobodan@zaliv.rs",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Crvenka",
            "COMPAGNY_NAME": "DP Zaliv PTP d.o.o   ",
            "LATITUDE": "45.666282",
            "LONGITUDE": "19.440765",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Slobodan Pena",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:8:00:::14:00,7::::"
        },
        {
            "ID": 299,
            "ADDRESS_LINE_1": "324 Bol'shevistskaya St",
            "ADDRESS_LINE_2": "",
            "CITY": "Krasnodar",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "350900",
            "MAIN_PHONE": "+7 800 272 78 58",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "service@refcar.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Krasnodar",
            "COMPAGNY_NAME": "RefCar",
            "LATITUDE": "45.124281",
            "LONGITUDE": "39.001975",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sergey Romanenko ",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 390,
            "ADDRESS_LINE_1": "Asfaltnaya str, 5",
            "ADDRESS_LINE_2": "",
            "CITY": "Chelyabinsk",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "454091",
            "MAIN_PHONE": "+7 922 702 09 00   +7 922 728 40 38",
            "ALT_PHONE": "+7 916 501 32 32",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "transholod74@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Chelyabinsk",
            "COMPAGNY_NAME": "YUZHURALTRANSKHOLOD",
            "LATITUDE": "55.074752",
            "LONGITUDE": "61.329592",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Vadim POPKOV",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::19:00,2:9:00:::19:00,3:9:00:::19:00,4:9:00:::19:00,5:9:00:::19:00,6:9:00:::18:00,7::::"
        },
        {
            "ID": 391,
            "ADDRESS_LINE_1": "Moscow highway",
            "ADDRESS_LINE_2": "1, Polchaninovka village",
            "CITY": "Saratov region",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "412189",
            "MAIN_PHONE": "+7 927 622 00 27",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "kraveca95-sar@mail.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Saratov region",
            "COMPAGNY_NAME": "REFSAR",
            "LATITUDE": "51.855579",
            "LONGITUDE": "45.602374",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Andrei KRAVETS",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::19:00,2:8:00:::19:00,3:8:00:::19:00,4:8:00:::19:00,5:8:00:::19:00,6:8:00:::19:00,7:8:00:::19:00"
        },
        {
            "ID": 392,
            "ADDRESS_LINE_1": "Entuziastov highway, 2, Western Industrial Zone, ",
            "ADDRESS_LINE_2": "Balashikha",
            "CITY": "Moscow region",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "109428",
            "MAIN_PHONE": "+7 495 223 68 97",
            "ALT_PHONE": "+7 919 991 58 70",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "pyankov@sivtrans.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Moscow region",
            "COMPAGNY_NAME": "SIV - TRANSKHOLOD",
            "LATITUDE": "55.793706",
            "LONGITUDE": "37.901069",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Igor Piyankov",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 14,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::20:00,2:9:00:::20:00,3:9:00:::20:00,4:9:00:::20:00,5:9:00:::20:00,6::::,7::::"
        },
        {
            "ID": 393,
            "ADDRESS_LINE_1": "Bajdukova str, 67",
            "ADDRESS_LINE_2": "",
            "CITY": "Penza",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "440015",
            "MAIN_PHONE": "+7 905 366 38 77",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "termomir58@mail.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Penza",
            "COMPAGNY_NAME": "TERMOMIR",
            "LATITUDE": "53.23468",
            "LONGITUDE": "45.007608",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Anton TEKSIN",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 394,
            "ADDRESS_LINE_1": "Old Tobolsk tract 2nd km, 4, building 11",
            "ADDRESS_LINE_2": "",
            "CITY": "Tuymen region",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "625519",
            "MAIN_PHONE": "+7 912 221 98 88",
            "ALT_PHONE": "+7 922 133 33 36",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@tttrans72.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Tuymen region",
            "COMPAGNY_NAME": "TYUMENTERMOTRANS",
            "LATITUDE": "57.101941",
            "LONGITUDE": "65.687742",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Alekseev Arkady",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::18:00,7::::"
        },
        {
            "ID": 395,
            "ADDRESS_LINE_1": "Polevaya str, 8, Blizhnee Konstantinovo village",
            "ADDRESS_LINE_2": "",
            "CITY": "Nijny Novgorod",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "603152",
            "MAIN_PHONE": "+7 831 216 41 41",
            "ALT_PHONE": "+7 930 680 56 07; +7 903 608 85 52",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "carriernn@yandex.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Nijny Novgorod",
            "COMPAGNY_NAME": "TRANSPORTNY HOLOD",
            "LATITUDE": "56.235005",
            "LONGITUDE": "44.005645",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Yuri KIKHTENKO",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::21:00,2:9:00:::21:00,3:9:00:::21:00,4:9:00:::21:00,5:9:00:::21:00,6:9:00:::21:00,7:9:00:::21:00"
        },
        {
            "ID": 397,
            "ADDRESS_LINE_1": "Lenina str, 1, letter AB, Shushary settlement",
            "ADDRESS_LINE_2": "",
            "CITY": "Sankt Petersburg",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "196626",
            "MAIN_PHONE": "+7 812 320 48 43",
            "ALT_PHONE": "+7 921 944 58 65; +7 921 999 55 62  ",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "ap@reftrans.spb.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Sankt Petersburg",
            "COMPAGNY_NAME": "REFTRANS",
            "LATITUDE": "59.809622",
            "LONGITUDE": "30.397812",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Anton Petushko",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 14,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::21:00,2:9:00:::21:00,3:9:00:::21:00,4:9:00:::21:00,5:9:00:::21:00,6:9:00:::21:00,7:9:00:::21:00"
        },
        {
            "ID": 398,
            "ADDRESS_LINE_1": "Yuzhnoe highway, 22",
            "ADDRESS_LINE_2": "",
            "CITY": "Togliatti",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "445043",
            "MAIN_PHONE": "+7 902 373 88 32",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "service@wernox.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Togliatti",
            "COMPAGNY_NAME": "TEH-SERVICE TL",
            "LATITUDE": "53.552078",
            "LONGITUDE": "49.309954",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Vladimir Melnikov ",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 399,
            "ADDRESS_LINE_1": "Dukacha str, 8B",
            "ADDRESS_LINE_2": "",
            "CITY": "Novosibirsk",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "630041",
            "MAIN_PHONE": "+7 383 292 53 03",
            "ALT_PHONE": "+7 913 739 20 34",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "refsib@yandex.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Novosibirsk",
            "COMPAGNY_NAME": "REF-SIB SERVICE LLC",
            "LATITUDE": "55.004432",
            "LONGITUDE": "82.786823",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Konstantin Vasilevskiy ",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 401,
            "ADDRESS_LINE_1": "Pridorozhnaya str, 21, Sputnik village",
            "ADDRESS_LINE_2": "",
            "CITY": "Samara region",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "443022",
            "MAIN_PHONE": "+7 846 979 17 01",
            "ALT_PHONE": "+7 960 833 01 11 ",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "leader-avto@samtel.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Samara region",
            "COMPAGNY_NAME": "TRANS LIDER",
            "LATITUDE": "53.259407",
            "LONGITUDE": "50.416894",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Igor KONONENKO",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::14:00,7::::"
        },
        {
            "ID": 402,
            "ADDRESS_LINE_1": "Svetlogorskaya str, 8, building 4",
            "ADDRESS_LINE_2": "",
            "CITY": "Perm",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "614023",
            "MAIN_PHONE": "+7 342 259 22 07; +7 (964) 190-22-07",
            "ALT_PHONE": "+7 952 656 58 28",
            "MOBILE_PHONE": "+7 909 117 38 36",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "chys@ptm-t.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Perm",
            "COMPAGNY_NAME": "VECTOR",
            "LATITUDE": "58.04422",
            "LONGITUDE": "56.03362",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Yulia Chudinova ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 403,
            "ADDRESS_LINE_1": "ul. Lesnaya 48a",
            "ADDRESS_LINE_2": "",
            "CITY": "Kaliningrad region",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "236000",
            "MAIN_PHONE": "+7 401 259 73 87",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "vatanrus@mail.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kaliningrad region",
            "COMPAGNY_NAME": "VATAN",
            "LATITUDE": "54.612352",
            "LONGITUDE": "20.482517",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Andrei BOJTSOV",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 405,
            "ADDRESS_LINE_1": "Zapadnaya str, 37",
            "ADDRESS_LINE_2": "",
            "CITY": "Aksay",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "346720",
            "MAIN_PHONE": "+7 863 505 99 73",
            "ALT_PHONE": "+7 928 270 58 30",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "srostov@aksay.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Aksay",
            "COMPAGNY_NAME": "SIV TRANSKHOLOD ROSTOV   ",
            "LATITUDE": "47.275441",
            "LONGITUDE": "39.852329",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Viktor Kuznetsov ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::14:00,7:9:00:::14:00"
        },
        {
            "ID": 406,
            "ADDRESS_LINE_1": "Omskaya str, 134",
            "ADDRESS_LINE_2": "",
            "CITY": "Kurgan",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "640027",
            "MAIN_PHONE": "+7 352 254 57 54",
            "ALT_PHONE": "+7 912 834 40 66; +7 919 571 09 00",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "ahtoh1909@mail.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Kurgan",
            "COMPAGNY_NAME": "KURGANTRANSKHOLOD",
            "LATITUDE": "55.469882",
            "LONGITUDE": "65.374967",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Anton Menschikov ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::21:30,2:9:00:::21:30,3:9:00:::21:30,4:9:00:::21:30,5:9:00:::21:30,6:9:00:::21:30,7:10:00:::19:00"
        },
        {
            "ID": 408,
            "ADDRESS_LINE_1": "Village Bely Rast, 26B, Dmitrovsky urban district",
            "ADDRESS_LINE_2": "",
            "CITY": "Moscow region",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "141870",
            "MAIN_PHONE": "+7 495 983 33 92",
            "ALT_PHONE": "+7 915 037 17 40",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "sirotkin@sivtrans.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Moscow region",
            "COMPAGNY_NAME": "SIV TRANSKHOLOD 2",
            "LATITUDE": "56.131195",
            "LONGITUDE": "37.404636",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Aleksey Sirotkin",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 12,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::20:00,2:9:00:::20:00,3:9:00:::20:00,4:9:00:::20:00,5:9:00:::20:00,6:9:00:::20:00,7:9:00:::20:00"
        },
        {
            "ID": 410,
            "ADDRESS_LINE_1": "Rozhdestvenskogo str, 18, letter E",
            "ADDRESS_LINE_2": "",
            "CITY": "Astrakhan",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "414057",
            "MAIN_PHONE": "+7 851 246 46 31",
            "ALT_PHONE": "+7 927 581 31 13; +7 908 610 51 19",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "+7 8512 633244",
            "EMAIL_ADDRESS": "amir_113@mail.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Astrakhan",
            "COMPAGNY_NAME": "ARKTIKA",
            "LATITUDE": "46.313632",
            "LONGITUDE": "48.072651",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Amir VALEEV",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::17:00,2:9:00:::17:00,3:9:00:::17:00,4:9:00:::17:00,5:9:00:::17:00,6::::,7::::"
        },
        {
            "ID": 412,
            "ADDRESS_LINE_1": "Transportnaya str, 1B, Severny poselok",
            "ADDRESS_LINE_2": "",
            "CITY": "Belgorod",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "308010",
            "MAIN_PHONE": "+74722732731",
            "ALT_PHONE": "+79205558677",
            "MOBILE_PHONE": "+7 919 281 83 13",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "zhuravlev@beltruck.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Belgorod",
            "COMPAGNY_NAME": "BIZNESTRUKSERVICE",
            "LATITUDE": "50.661461",
            "LONGITUDE": "36.560668",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sergey Zhuravlev ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::21:00,2:9:00:::21:00,3:9:00:::21:00,4:9:00:::21:00,5:9:00:::21:00,6:9:00:::21:00,7:9:00:::21:00"
        },
        {
            "ID": 413,
            "ADDRESS_LINE_1": "Elektrolesovskaya str, 15B",
            "ADDRESS_LINE_2": "",
            "CITY": "Volgograd",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "400002",
            "MAIN_PHONE": "+78442419482",
            "ALT_PHONE": "+7 917 338 12 77; +7 917 842 66 07; +7 917 727 45 33",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@vlgauto.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Volgograd",
            "COMPAGNY_NAME": "AVTO 34 RUS",
            "LATITUDE": "48.666812",
            "LONGITUDE": "44.455755",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Vadim Abdullaev",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::18:00,7::::"
        },
        {
            "ID": 414,
            "ADDRESS_LINE_1": "Bahchivandzhi str, 2A",
            "ADDRESS_LINE_2": "",
            "CITY": "Ekaterinburg",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "620910",
            "MAIN_PHONE": "+7 922 208 98 88",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "info@tsholod.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Ekaterinburg",
            "COMPAGNY_NAME": "TRANSKHOLOD",
            "LATITUDE": "56.757969",
            "LONGITUDE": "60.795724",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sergey Sudovikov ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 15,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:10:00:::21:00,2:10:00:::21:00,3:10:00:::21:00,4:10:00:::21:00,5:10:00:::21:00,6:10:00:::21:00,7:10:00:::21:00"
        },
        {
            "ID": 420,
            "ADDRESS_LINE_1": "Block 4, building 6, Petrovskoe village, Selyatino,",
            "ADDRESS_LINE_2": "Naro-Fominskiy district",
            "CITY": "Moscow region",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "143395",
            "MAIN_PHONE": "+7 916 964 11 30",
            "ALT_PHONE": "+7 919 777 62 53",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "TSCT4@yandex.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Moscow region",
            "COMPAGNY_NAME": "TERMOSFERA",
            "LATITUDE": "55.528447",
            "LONGITUDE": "36.981682",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Artyom Belousov",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::21:00,2:9:00:::21:00,3:9:00:::21:00,4:9:00:::21:00,5:9:00:::21:00,6:9:00:::21:00,7:9:00:::21:00"
        },
        {
            "ID": 423,
            "ADDRESS_LINE_1": "1st Lugovya str, 9a, Selo Troitskoe",
            "ADDRESS_LINE_2": "",
            "CITY": "Omsk region",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "644520",
            "MAIN_PHONE": "+7 951 426 44 11 ",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+7 950 216 23 23",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "barashkov@trucksto.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Omsk region",
            "COMPAGNY_NAME": "GRUZOVYE REZERVY",
            "LATITUDE": "54.870109",
            "LONGITUDE": "73.289385",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Sergey Barashkov ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::18:00,7:9:00:::18:00"
        },
        {
            "ID": 425,
            "ADDRESS_LINE_1": "1475 km of highway M5",
            "ADDRESS_LINE_2": "",
            "CITY": "Ufa",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "450000",
            "MAIN_PHONE": "+7 347 229 40 35",
            "ALT_PHONE": "+7 917 437 71 56; +7 917 444 41 69",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "servisufa@inbox.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Ufa",
            "COMPAGNY_NAME": "REKTORG PLUS",
            "LATITUDE": "54.652153",
            "LONGITUDE": "56.092547",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Alexey KABANOV",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::14:00,7::::"
        },
        {
            "ID": 690,
            "ADDRESS_LINE_1": "Montazhny lane, 3",
            "ADDRESS_LINE_2": "",
            "CITY": "Voronezh",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "394028",
            "MAIN_PHONE": "+7 4732 33 09 30",
            "ALT_PHONE": "+7 961 181 15 00",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "carrier-vrn@mail.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Voronezh",
            "COMPAGNY_NAME": "REMTRANSHOLOD",
            "LATITUDE": "51.643838",
            "LONGITUDE": "39.282115",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Aleksei Goliadkin ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 9,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 708,
            "ADDRESS_LINE_1": "Novoryanzanskoe highway, 23rd, Tomilino settlement",
            "ADDRESS_LINE_2": "",
            "CITY": "Moscow region",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "140072",
            "MAIN_PHONE": "+7 916 964 11 30",
            "ALT_PHONE": "+7 919 777 62 53",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "TSCT2@yandex.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Moscow region",
            "COMPAGNY_NAME": "TERMOSFERA 2",
            "LATITUDE": "55.63439",
            "LONGITUDE": "37.927177",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Vladimir Poklad ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::20:00,2:9:00:::20:00,3:9:00:::20:00,4:9:00:::20:00,5:9:00:::20:00,6:9:00:::20:00,7:9:00:::20:00"
        },
        {
            "ID": 757,
            "ADDRESS_LINE_1": "Smolyaninova str, 7A",
            "ADDRESS_LINE_2": "",
            "CITY": "Smolensk",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "214031",
            "MAIN_PHONE": "+7 920 311 31 31",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+7 920 311 31 84",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "avto-holod67@yandex.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Smolensk",
            "COMPAGNY_NAME": "Holod-66",
            "LATITUDE": "54.777246",
            "LONGITUDE": "32.10507",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ivan Kartavenko ",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 758,
            "ADDRESS_LINE_1": "Industrial zone, 2",
            "ADDRESS_LINE_2": "Yuski village, Zavyalovsky district",
            "CITY": "Udmurtia",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "427009",
            "MAIN_PHONE": "+7 912 856 13 49",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "krial-avto@mail.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Udmurtia",
            "COMPAGNY_NAME": "Krial-Avto",
            "LATITUDE": "56.66009",
            "LONGITUDE": "53.10748",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Andrei STYAZHKIN",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:10:00:::22:00,2:10:00:::22:00,3:10:00:::22:00,4:10:00:::22:00,5:10:00:::22:00,6::::,7::::"
        },
        {
            "ID": 759,
            "ADDRESS_LINE_1": "Venevskoe highway, 13",
            "ADDRESS_LINE_2": "",
            "CITY": "Tula",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "300004",
            "MAIN_PHONE": "+7 487 279 03 36",
            "ALT_PHONE": "+7 920 780 31 11; +7 950 900 71 71",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "office@avhol.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Tula",
            "COMPAGNY_NAME": "Avhol",
            "LATITUDE": "54.217299",
            "LONGITUDE": "37.643336",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Igor LEBEDINSKIY",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:10:00:::18:00,2:10:00:::18:00,3:10:00:::18:00,4:10:00:::18:00,5:10:00:::18:00,6::::,7::::"
        },
        {
            "ID": 851,
            "ADDRESS_LINE_1": "Severny proezd, 9",
            "ADDRESS_LINE_2": "",
            "CITY": "Novosibirsk",
            "COUNTRY": "Russia",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "630088",
            "MAIN_PHONE": "+7 383 310 91 99",
            "ALT_PHONE": "+7 913 920 58 12",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "service@stcold.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Novosibirsk",
            "COMPAGNY_NAME": "SibTransCold",
            "LATITUDE": "54.948996",
            "LONGITUDE": "82.912686",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Aleksandr Sherstov",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::21:00,2:9:00:::21:00,3:9:00:::21:00,4:9:00:::21:00,5:9:00:::21:00,6:9:00:::21:00,7:9:00:::21:00"
        },
        {
            "ID": 890,
            "ADDRESS_LINE_1": "Marshala Zhukova 172, Naro-Fominsk",
            "ADDRESS_LINE_2": "",
            "CITY": "Moscow region",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "143300",
            "MAIN_PHONE": "+7 495 741 32 32",
            "ALT_PHONE": " +7 915 456 99 22",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "ref@naraavto.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Moscow region",
            "COMPAGNY_NAME": "Nara Avto",
            "LATITUDE": "55.376626",
            "LONGITUDE": "36.690224",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mukhan Babayan ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::22:00,2:8:00:::22:00,3:8:00:::22:00,4:8:00:::22:00,5:8:00:::22:00,6:8:00:::22:00,7:8:00:::22:00"
        },
        {
            "ID": 891,
            "ADDRESS_LINE_1": "Makovskogo str, 7, Odintsovo",
            "ADDRESS_LINE_2": "",
            "CITY": "Moscow region",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "143006",
            "MAIN_PHONE": "+78001003844",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "msk@refct.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Moscow region",
            "COMPAGNY_NAME": "RefComTrans",
            "LATITUDE": "55.659967",
            "LONGITUDE": "37.28542",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Nikolai Avdeev",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::21:00,2:9:00:::21:00,3:9:00:::21:00,4:9:00:::21:00,5:9:00:::21:00,6:9:00:::21:00,7:9:00:::21:00"
        },
        {
            "ID": 907,
            "ADDRESS_LINE_1": "Shosseynaya Str, 44G, Suponevo village",
            "ADDRESS_LINE_2": "",
            "CITY": "Bryansk region",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "241520",
            "MAIN_PHONE": "+7 930 735 80 00",
            "ALT_PHONE": "+7 910 299 66 48",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "+74832590225",
            "EMAIL_ADDRESS": "service@holod-trans.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bryansk region",
            "COMPAGNY_NAME": "HOLODTRANS",
            "LATITUDE": "53.21255",
            "LONGITUDE": "34.296797",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ruslan ADAMIA",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 908,
            "ADDRESS_LINE_1": "8 Neftejuganskoe shosse",
            "ADDRESS_LINE_2": "",
            "CITY": "Surgut",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "628406",
            "MAIN_PHONE": "+7 346 265 15 56",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "vsholod@mail.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Surgut",
            "COMPAGNY_NAME": "TRANSKHOLOD-C",
            "LATITUDE": "61.273431",
            "LONGITUDE": "73.38551",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Valery SKOROPUPOV",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::22:00,2:8:00:::22:00,3:8:00:::22:00,4:8:00:::22:00,5:8:00:::22:00,6:8:00:::22:00,7:8:00:::22:00"
        },
        {
            "ID": 930,
            "ADDRESS_LINE_1": "Zavodskaya str, 34, Mayakovskogo hutor",
            "ADDRESS_LINE_2": "",
            "CITY": "Aksay ",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "346703",
            "MAIN_PHONE": "+7 863 505 99 73",
            "ALT_PHONE": "+7 928 270 58 32",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "service2@sivrostov.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Aksay ",
            "COMPAGNY_NAME": "SIV TRANSHOLOD ROSTOV 2",
            "LATITUDE": "47.125104",
            "LONGITUDE": "39.864439",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Andrey Samoilenko",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::14:00,7:9:00:::14:00"
        },
        {
            "ID": 936,
            "ADDRESS_LINE_1": "Solnechny lane, 2, Ilinka village, Khabarovsk",
            "ADDRESS_LINE_2": "",
            "CITY": "Khabarovsk",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "680509",
            "MAIN_PHONE": "+7 914 408 41 02",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "service@atlantic-khv.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Khabarovsk",
            "COMPAGNY_NAME": "ATLANTIK",
            "LATITUDE": "48.369003",
            "LONGITUDE": "135.127603",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Aleksander ANOSOV",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::21:00,2:9:00:::21:00,3:9:00:::21:00,4:9:00:::21:00,5:9:00:::21:00,6:9:00:::21:00,7:9:00:::21:00"
        },
        {
            "ID": 954,
            "ADDRESS_LINE_1": "Office 2, Letter L",
            "ADDRESS_LINE_2": "Prospect Oktyabrya, 87А, letter L",
            "CITY": "Yaroslavl",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "150044",
            "MAIN_PHONE": "+7 485 267 16 16",
            "ALT_PHONE": "+7 960 535 50 00; +7 980 705 55 46",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "service-holod@post76.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Yaroslavl",
            "COMPAGNY_NAME": "YarPart",
            "LATITUDE": "57.656108",
            "LONGITUDE": "39.841585",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ilia Korolev",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::20:30,2:8:00:::20:30,3:8:00:::20:30,4:8:00:::20:30,5:8:00:::20:30,6:8:00:::20:30,7:8:00:::20:30"
        },
        {
            "ID": 955,
            "ADDRESS_LINE_1": "Energostroiteley proezd, 2, building 4",
            "ADDRESS_LINE_2": "",
            "CITY": "Chita, Zabaykalskiy region",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "672022",
            "MAIN_PHONE": "+7 924 502 20 00",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Vdo_dv@mail.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Chita, Zabaykalskiy region",
            "COMPAGNY_NAME": "CHITAREFSERVICE",
            "LATITUDE": "52.059175",
            "LONGITUDE": "113.354884",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Aleksey Rusakov ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::21:00,2:9:00:::21:00,3:9:00:::21:00,4:9:00:::21:00,5:9:00:::21:00,6:9:00:::21:00,7:9:00:::21:00"
        },
        {
            "ID": 962,
            "ADDRESS_LINE_1": "Tsentralnaya str, 38, Mamoni village",
            "ADDRESS_LINE_2": "",
            "CITY": "Irkutsk region",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "664013",
            "MAIN_PHONE": "+7 984 273 33 07 ",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "sk38ev@yandex.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Irkutsk region",
            "COMPAGNY_NAME": "REFAVTOTSENTR-IRKUTSK",
            "LATITUDE": "52.32184",
            "LONGITUDE": "104.188179",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Evgeny TIKHONOV ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::17:00,7::::"
        },
        {
            "ID": 963,
            "ADDRESS_LINE_1": "northeast at a distance of 300 m, Divasovskoe rural settlement, Olsha village",
            "ADDRESS_LINE_2": "",
            "CITY": "Smolensk region",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "214533",
            "MAIN_PHONE": "+7 4812 25 00 10",
            "ALT_PHONE": "+7 964 618 02 94; +7 961 137 32 37",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "service@cszapad.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Smolensk region",
            "COMPAGNY_NAME": "Center-serviceZapadny",
            "LATITUDE": "54.84642",
            "LONGITUDE": "31.84958",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Vasiliy Malaschenkov",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::20:00,2:8:00:::20:00,3:8:00:::20:00,4:8:00:::20:00,5:8:00:::20:00,6:8:00:::20:00,7:8:00:::20:00"
        },
        {
            "ID": 964,
            "ADDRESS_LINE_1": "Menzelinsky trakt, 32",
            "ADDRESS_LINE_2": "",
            "CITY": "Naberezhye Chelny",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "423800",
            "MAIN_PHONE": "+7 927 481 59 70 ",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+7 855 292 69 26",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "g.sorokin@kateko.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Naberezhye Chelny",
            "COMPAGNY_NAME": "KTK",
            "LATITUDE": "55.703013",
            "LONGITUDE": "52.404749",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Georgy Sorokin",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::20:00,2:8:00:::20:00,3:8:00:::20:00,4:8:00:::20:00,5:8:00:::20:00,6:8:00:::20:00,7:8:00:::20:00"
        },
        {
            "ID": 1016,
            "ADDRESS_LINE_1": "Zapadnaya str, 20, Artem",
            "ADDRESS_LINE_2": "",
            "CITY": "Artem",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "692756",
            "MAIN_PHONE": "+7 914 197 87 47",
            "ALT_PHONE": "+7 (914)1586980",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "d.anosov@atlantic-khv.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Artem",
            "COMPAGNY_NAME": "ATLANTIK 2",
            "LATITUDE": "43.359097",
            "LONGITUDE": "132.147092",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Dmitriy Anosov",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::21:00,2:9:00:::21:00,3:9:00:::21:00,4:9:00:::21:00,5:9:00:::21:00,6:9:00:::21:00,7:9:00:::21:00"
        },
        {
            "ID": 1031,
            "ADDRESS_LINE_1": "Magistralnaya str, 17а, Kamenka village, Kardymovskiy district, ",
            "ADDRESS_LINE_2": "",
            "CITY": "Smolensk region",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "215866",
            "MAIN_PHONE": "+7 481 672 01 11",
            "ALT_PHONE": "+7 967 989 47 36; +7 961 137 21 16",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "n.stepkin@cszapad.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Smolensk region",
            "COMPAGNY_NAME": "Center-service Zapad",
            "LATITUDE": "55.019382",
            "LONGITUDE": "32.306059",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Nikolai Stepkin",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::20:00,2:8:00:::20:00,3:8:00:::20:00,4:8:00:::20:00,5:8:00:::20:00,6:8:00:::20:00,7:8:00:::20:00"
        },
        {
            "ID": 1041,
            "ADDRESS_LINE_1": "letter AA1",
            "ADDRESS_LINE_2": "promuzel Zorinski, ",
            "CITY": "Saratov region",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "410536",
            "MAIN_PHONE": "+7 (8452) 77 80 00",
            "ALT_PHONE": "+7 (937) 253 79 93",
            "MOBILE_PHONE": "+7 (937) 249 53 35",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "a.rusanov@gk-lider.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Saratov region",
            "COMPAGNY_NAME": "S-AUTO",
            "LATITUDE": "51.640795",
            "LONGITUDE": "46.017971",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Rusanov Aleksandr",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 1048,
            "ADDRESS_LINE_1": "street Novostroevskaya, 23",
            "ADDRESS_LINE_2": "p. Kholmogorovka",
            "CITY": "Zelenograd district",
            "COUNTRY": "",
            "COUNTRY_CODE": "RU",
            "POSTAL_CODE": "238546",
            "MAIN_PHONE": "+7 (4012) 30 34 33",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+7 (909) 790 10 15",
            "ASSISTANCE_PHONE": "81080020031032",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "services@carrier-kaliningrad.ru",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Kaliningrad region, ",
            "COMPAGNY_NAME": "Euroservice",
            "LATITUDE": "54.791094",
            "LONGITUDE": "20.420442",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Dmitry Varlamov ",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::19:00,2:9:00:::19:00,3:9:00:::19:00,4:9:00:::19:00,5:9:00:::19:00,6:9:00:::15:00,7::::"
        },
        {
            "ID": 426,
            "ADDRESS_LINE_1": "Po Box 358",
            "ADDRESS_LINE_2": "",
            "CITY": "Riyadh",
            "COUNTRY": "Saudi Arabia",
            "COUNTRY_CODE": "SA",
            "POSTAL_CODE": "11383",
            "MAIN_PHONE": "(+966) 1 2650990",
            "ALT_PHONE": "(+966) 570238678",
            "MOBILE_PHONE": "(+966) 541533699",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+966) 1 2651079",
            "EMAIL_ADDRESS": "barry.ainsworth@cgs.com.sa",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Riyadh",
            "COMPAGNY_NAME": "CGS (COLDSTORES GROUP OF SAUDI ARABIA)",
            "LATITUDE": "24.688073",
            "LONGITUDE": "46.722418",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Barry Ainsworth",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 17,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:11:45::,5::::,6:7:30:::16:30,7:7:30:::16:30"
        },
        {
            "ID": 427,
            "ADDRESS_LINE_1": "Phase III, Industrial City",
            "ADDRESS_LINE_2": "P.O. Box 16952 ",
            "CITY": "Jeddah",
            "COUNTRY": "Saudi Arabia",
            "COUNTRY_CODE": "SA",
            "POSTAL_CODE": "21474",
            "MAIN_PHONE": "(+966) 2 6379220",
            "ALT_PHONE": "(+966) 558424842",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+966) 2 6379237 ",
            "EMAIL_ADDRESS": "Danni.jensen@cgs.com.sa",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Jeddah",
            "COMPAGNY_NAME": "CGS (COLDSTORES GROUP OF SAUDI ARABIA)",
            "LATITUDE": "21.43002",
            "LONGITUDE": "39.222736",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Danni Jensen",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 12,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:15:::15:45,2:7:15:::15:45,3:7:15:::15:45,4:7:15:::15:45,5::::,6::::,7:7:15:::15:45"
        },
        {
            "ID": 428,
            "ADDRESS_LINE_1": "Po Box 4287",
            "ADDRESS_LINE_2": "",
            "CITY": "Dammam",
            "COUNTRY": "Saudi Arabia",
            "COUNTRY_CODE": "SA",
            "POSTAL_CODE": "31491",
            "MAIN_PHONE": "(+966) 3 8471445",
            "ALT_PHONE": "(+966) 500099482",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+966) 3 8484288",
            "EMAIL_ADDRESS": "Joachim.Kons@cgs.com.sa",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Dammam",
            "COMPAGNY_NAME": "CGS (COLDSTORES GROUP OF SAUDI ARABIA)",
            "LATITUDE": "26.439413",
            "LONGITUDE": "50.080447",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Joachim Kons",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 7,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5::::,6:7:30:::16:30,7:7:30:::16:30"
        },
        {
            "ID": 764,
            "ADDRESS_LINE_1": "NEW INDUSTRIAL.",
            "ADDRESS_LINE_2": "",
            "CITY": "Khamis Mushayt",
            "COUNTRY": "Saudi Arabia",
            "COUNTRY_CODE": "SA",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+966) 7 2225124",
            "ALT_PHONE": "05557406005",
            "MOBILE_PHONE": "0563856418",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Fajrabha@yahoo.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Khamis Mushayt",
            "COMPAGNY_NAME": "COOLING CENTER",
            "LATITUDE": "18.314441",
            "LONGITUDE": "42.73304",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Alex",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::18:00,2:8:00:::18:00,3:8:00:::18:00,4:8:00:::18:00,5:8:00:::18:00,6::::,7::::"
        },
        {
            "ID": 935,
            "ADDRESS_LINE_1": "Old Jeddah Road",
            "ADDRESS_LINE_2": "",
            "CITY": "Madina",
            "COUNTRY": "",
            "COUNTRY_CODE": "SA",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+966) 55 7172606",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Imran.khan@cgs.com.sa",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Madina",
            "COMPAGNY_NAME": "CGS (COLDSTORES GROUP OF SAUDI ARABIA)",
            "LATITUDE": "24.25122",
            "LONGITUDE": "39.32502",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Imran Khan",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:15:::15:45,2:7:15:::15:45,3:7:15:::15:45,4:7:15:::15:45,5::::,6::::,7:7:15:::15:45"
        },
        {
            "ID": 279,
            "ADDRESS_LINE_1": "Tuvevägen 35",
            "ADDRESS_LINE_2": "",
            "CITY": "Göteborg",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "417 05 ",
            "MAIN_PHONE": "+46 31 57 00 00",
            "ALT_PHONE": "+46 31 57 00 00",
            "MOBILE_PHONE": "+46 708 200228",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "jour@570000.se",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Göteborg",
            "COMPAGNY_NAME": "570 000 i GOTEBORG AB",
            "LATITUDE": "57.735284",
            "LONGITUDE": "11.942573",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Claes HERZMAN STHYRE",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::17:00,2:7:00:::17:00,3:7:00:::17:00,4:7:00:::17:00,5:7:00:::17:00,6::::,7::::"
        },
        {
            "ID": 489,
            "ADDRESS_LINE_1": "Aspögatan 5",
            "ADDRESS_LINE_2": "",
            "CITY": "Malmö",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "S-211 24",
            "MAIN_PHONE": "(+46) 40 29 50 30 ",
            "ALT_PHONE": "(+46) 768 00 77 67",
            "MOBILE_PHONE": "(+46) 768 00 77 67",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 40 29 50 31",
            "EMAIL_ADDRESS": "bmj.mobilkylservice@telia.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Malmö",
            "COMPAGNY_NAME": "BMJ MOBILKYLSERVICE AB",
            "LATITUDE": "55.623622",
            "LONGITUDE": "13.04281",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Magnus JÖNSSON",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:00:::16:00,2:7:00:::16:00,3:7:00:::16:00,4:7:00:::16:00,5:7:00:::16:00,6::::,7::::"
        },
        {
            "ID": 491,
            "ADDRESS_LINE_1": "Rudanvägen 4",
            "ADDRESS_LINE_2": "",
            "CITY": "Jordbro",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "136 50",
            "MAIN_PHONE": "+46 8 400 21 918",
            "ALT_PHONE": "+46 735 44 06 15",
            "MOBILE_PHONE": "+46 707 65 52 24",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 8 50020303",
            "EMAIL_ADDRESS": "jordbro@bmckyl.se",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Jordbro",
            "COMPAGNY_NAME": "BMC TRANSPORTKYLSERVICE ",
            "LATITUDE": "59.15249",
            "LONGITUDE": "18.12",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Thomas Öberg",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:00:::17:00,2:7:00:::17:00,3:7:00:::17:00,4:7:00:::17:00,5:7:00:::17:00,6::::,7::::"
        },
        {
            "ID": 492,
            "ADDRESS_LINE_1": "Orkangatan 4",
            "ADDRESS_LINE_2": "",
            "CITY": "Karlstad",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "S-652 21",
            "MAIN_PHONE": "(+46) 54 86 01 66",
            "ALT_PHONE": "(+46) 10 2316528",
            "MOBILE_PHONE": "(+46) 10 2316528",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": " noemail@",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Karlstad",
            "COMPAGNY_NAME": "BIL & KYLTJÄNST AB",
            "LATITUDE": "59.378884",
            "LONGITUDE": "13.550262",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tommy KARLSSON",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:16:00,2:7:00:16:00,3:7:00:16:00,4:7:00:16:00,5:7:00:16:00"
        },
        {
            "ID": 494,
            "ADDRESS_LINE_1": "Pikullgatan 1",
            "ADDRESS_LINE_2": "",
            "CITY": "Örebro",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "702 27",
            "MAIN_PHONE": "(+46) 19 270751 ",
            "ALT_PHONE": "(+46) 19 270751 ",
            "MOBILE_PHONE": "(+46) 19 270751 ",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 19 272130",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Örebro",
            "COMPAGNY_NAME": "AO LASTBILSVERKSTAD AB",
            "LATITUDE": "59.208502",
            "LONGITUDE": "15.392833",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Rick ANDERSSON",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::16:00,2:7:00:::16:00,3:7:00:::16:00,4:7:00:::16:00,5:7:00:::16:00,6::::,7::::"
        },
        {
            "ID": 495,
            "ADDRESS_LINE_1": "Stackvägen 2",
            "ADDRESS_LINE_2": "",
            "CITY": "Umea",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "S-901 37",
            "MAIN_PHONE": "(+46) 90 22110",
            "ALT_PHONE": "(+46) 703 496682",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 90 22470",
            "EMAIL_ADDRESS": "noemail@",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Umea",
            "COMPAGNY_NAME": "DJARV KYL AB",
            "LATITUDE": "63.849786",
            "LONGITUDE": "20.139699",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Lars PEDERSEN",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 496,
            "ADDRESS_LINE_1": "Magasinv",
            "ADDRESS_LINE_2": "",
            "CITY": "Mantorp",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "590 20",
            "MAIN_PHONE": "(+46) 142 20035",
            "ALT_PHONE": "(+46) 702 203591",
            "MOBILE_PHONE": "(+46) 702 203591",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 142 20890",
            "EMAIL_ADDRESS": "boss@svekyl.se",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Mantorp",
            "COMPAGNY_NAME": "SVENSK KYLINDUSTRI AB",
            "LATITUDE": "58.347338",
            "LONGITUDE": "15.283635",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "G",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:16:00,2:7:00:16:00,3:7:00:16:00,4:7:00:16:00,5:7:00:16:00"
        },
        {
            "ID": 497,
            "ADDRESS_LINE_1": "Laxgangen 1",
            "ADDRESS_LINE_2": "",
            "CITY": "Gävle",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "S-801 04",
            "MAIN_PHONE": "(+46) 706657039",
            "ALT_PHONE": "(+46) 706905724",
            "MOBILE_PHONE": "(+46) 706657039",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 26665720",
            "EMAIL_ADDRESS": "jorgen.linander@bilmetro.se",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Gävle",
            "COMPAGNY_NAME": "BILMETRO LASTBILAR ",
            "LATITUDE": "60.695231",
            "LONGITUDE": "17.155548",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jorgen LINANDER",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::16:00,2:7:00:::16:00,3:7:00:::16:00,4:7:00:::16:00,5:7:00:::16:00,6::::,7::::"
        },
        {
            "ID": 498,
            "ADDRESS_LINE_1": "Hagvägen 10",
            "ADDRESS_LINE_2": "",
            "CITY": "Östersund",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "S-831 48",
            "MAIN_PHONE": "(+46) 63101313",
            "ALT_PHONE": "(+46) 63101313",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 63128780",
            "EMAIL_ADDRESS": "otsab@telia.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Östersund",
            "COMPAGNY_NAME": "ODENSKOGS TVÄTT & SMÖRJHALLAR AB",
            "LATITUDE": "63.172066",
            "LONGITUDE": "14.690142",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jöhrn VEJDELAND",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:17:00,2:7:00:17:00,3:7:00:17:00,4:7:00:17:00,5:7:00:17:00"
        },
        {
            "ID": 499,
            "ADDRESS_LINE_1": "Fordonsvägen  8",
            "ADDRESS_LINE_2": "",
            "CITY": "Jönköping",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "55302",
            "MAIN_PHONE": "+46 36 36 90 30",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+46  70 203 40 60",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "+46 36 12 75 18",
            "EMAIL_ADDRESS": "info@skeppsbrons.se",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Jönköping",
            "COMPAGNY_NAME": "Skeppsbrons i Jönköping AB",
            "LATITUDE": "57.744058",
            "LONGITUDE": "14.169188",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Göran Thorell",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::18:00,2:7:00:::18:00,3:7:00:::18:00,4:7:00:::18:00,5:7:00:::16:00,6::::,7::::"
        },
        {
            "ID": 501,
            "ADDRESS_LINE_1": "Hammargatan 9",
            "ADDRESS_LINE_2": "",
            "CITY": "Borlänge",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "78171",
            "MAIN_PHONE": "(+46) 243 216400",
            "ALT_PHONE": "(+46) 705917870",
            "MOBILE_PHONE": "(+46) 243 216475",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 243 216450",
            "EMAIL_ADDRESS": "scaniaverkstad.borlange@bilmetro.se",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Borlänge",
            "COMPAGNY_NAME": "BILMETRO LASTBILAR",
            "LATITUDE": "60.471598",
            "LONGITUDE": "15.443001",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Patrick JOHANSSON / Lars IMLAND",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::16:00,2:7:00:::16:00,3:7:00:::16:00,4:7:00:::16:00,5:7:00:::16:00,6:7:00:::13:00,7::::"
        },
        {
            "ID": 502,
            "ADDRESS_LINE_1": "Omformargatan 8",
            "ADDRESS_LINE_2": "",
            "CITY": "Västeras",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "S-721 37",
            "MAIN_PHONE": "(+46) 21810310",
            "ALT_PHONE": "(+46) 21810310",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 21810536",
            "EMAIL_ADDRESS": "noemail@",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Västeras",
            "COMPAGNY_NAME": "MALTE MANSSON",
            "LATITUDE": "59.647839",
            "LONGITUDE": "16.571803",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Johan Åkerberg",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:16:00,2:7:00:16:00,3:7:00:16:00,4:7:00:16:00,5:7:00:16:00"
        },
        {
            "ID": 503,
            "ADDRESS_LINE_1": "Innervik 17",
            "ADDRESS_LINE_2": "",
            "CITY": "Skelleftea",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "S-931 91",
            "MAIN_PHONE": "(+46) 910 56060",
            "ALT_PHONE": "(+46) 703496680",
            "MOBILE_PHONE": "(+46) 703496680",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 910 56032",
            "EMAIL_ADDRESS": "noemail@",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Skelleftea",
            "COMPAGNY_NAME": "DJARV KYL AB",
            "LATITUDE": "64.713181",
            "LONGITUDE": "21.031861",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ake DJÄRV",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 504,
            "ADDRESS_LINE_1": "Kardanvagen 57",
            "ADDRESS_LINE_2": "",
            "CITY": "Trollhattan",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "46138",
            "MAIN_PHONE": "(+46) 520 180 50",
            "ALT_PHONE": "(+46) 520 180 50",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 520 180 55",
            "EMAIL_ADDRESS": "",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Trollhattan",
            "COMPAGNY_NAME": "BR. JONSSONS KYLSERVICE AB",
            "LATITUDE": "58.296523",
            "LONGITUDE": "12.346487",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Erling Jonsson",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:16:00,2:7:00:16:00,3:7:00:16:00,4:7:00:16:00,5:7:00:16:00"
        },
        {
            "ID": 509,
            "ADDRESS_LINE_1": "Hanögatan 10",
            "ADDRESS_LINE_2": "",
            "CITY": "Malmö",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "21124",
            "MAIN_PHONE": "(+46) 40 930100",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 40 930 487",
            "EMAIL_ADDRESS": "anders@bil-marinel.se",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Malmö",
            "COMPAGNY_NAME": "BIL & MARIN ELEKTRISKA AB ",
            "LATITUDE": "55.618655",
            "LONGITUDE": "13.037231",
            "DD": "1",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Anders BJ",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:17:00,2:7:30:17:00,3:7:30:17:00,4:7:30:17:00,5:7:30:17:00"
        },
        {
            "ID": 511,
            "ADDRESS_LINE_1": "Vaksala-Eke",
            "ADDRESS_LINE_2": "",
            "CITY": "Uppsala",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "75594",
            "MAIN_PHONE": "(+46) 18 106650",
            "ALT_PHONE": "(+46) 18 106652",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "(+46) 18 106651",
            "EMAIL_ADDRESS": "tony@uppsalakarosseri.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Uppsala",
            "COMPAGNY_NAME": "UPPSALA KAROSSERI BR",
            "LATITUDE": "59.845634",
            "LONGITUDE": "17.683858",
            "DD": "1",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Tony",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:16:30,2:7:00:16:30,3:7:00:16:30,4:7:00:16:30,5:7:00:14:00"
        },
        {
            "ID": 732,
            "ADDRESS_LINE_1": "Box 93 (Sälshögsvägen 6)",
            "ADDRESS_LINE_2": "",
            "CITY": "Tomelilla",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "27322",
            "MAIN_PHONE": "+46 417  199 56",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "070 548 62 42",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "+46 417  145 17",
            "EMAIL_ADDRESS": "ja@norfrig.se",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Tomelilla",
            "COMPAGNY_NAME": "NORFRIG SERVICE AB",
            "LATITUDE": "55.549247",
            "LONGITUDE": "13.956327",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jan Andersson",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::15:30,2:7:00:::15:30,3:7:00:::15:30,4:7:00:::15:30,5:7:00:::15:30,6::::,7::::"
        },
        {
            "ID": 854,
            "ADDRESS_LINE_1": "Fornminnesgatan 22",
            "ADDRESS_LINE_2": "",
            "CITY": "Helsingborg",
            "COUNTRY": "Sweden",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "253 68 ",
            "MAIN_PHONE": "+46 42 294020",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+46 706394020",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "+46 42 294050",
            "EMAIL_ADDRESS": "info@smtransportkyla.se",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Helsingborg",
            "COMPAGNY_NAME": "SM Transportkylservice AB",
            "LATITUDE": "56.005791",
            "LONGITUDE": "12.76978",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Patrik FREDRIKSSON",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 9,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::18:00,2:7:00:::18:00,3:7:00:::18:00,4:7:00:::18:00,5:7:00:::16:00,6::::,7::::"
        },
        {
            "ID": 898,
            "ADDRESS_LINE_1": "Tuna Gårdsväg 12",
            "ADDRESS_LINE_2": "",
            "CITY": "Tumba",
            "COUNTRY": "",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "147 43",
            "MAIN_PHONE": "+46840021919",
            "ALT_PHONE": "+46707655224",
            "MOBILE_PHONE": "+46735440615",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "+46850020303",
            "EMAIL_ADDRESS": "tumba@bmckyl.se",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Tumba",
            "COMPAGNY_NAME": "BMC Transportkylservice",
            "LATITUDE": "59.211944",
            "LONGITUDE": "17.84",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Daniel Widman",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:17:00::,2:7:00:17:00::,3:7:00:17:00::,4:7:00:17:00::,5:7:00:17:00::,6::::,7::::"
        },
        {
            "ID": 899,
            "ADDRESS_LINE_1": "Kristinehedsvägen 1",
            "ADDRESS_LINE_2": "",
            "CITY": "Halmstad",
            "COUNTRY": "",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "302 44",
            "MAIN_PHONE": "+46 (0)35 2020160",
            "ALT_PHONE": "+46(0)706 394020",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "erik@smtransportkyla.se",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Halmstad",
            "COMPAGNY_NAME": "SM-Transportkylservice AB",
            "LATITUDE": "56.67777",
            "LONGITUDE": "12.880216",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Erik Bondesson",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 10,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:00:16:00::,2:7:00:16:00::,3:7:00:16:00::,4:7:00:16:00::,5:7:00:16:00::,6::::,7::::"
        },
        {
            "ID": 987,
            "ADDRESS_LINE_1": "Upplagsvägen 3",
            "ADDRESS_LINE_2": "",
            "CITY": "Luleå",
            "COUNTRY": "",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "972 54 ",
            "MAIN_PHONE": "+46(0)920221105",
            "ALT_PHONE": "+46(0)703496680",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Andreas@djarvkyl.se",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Luleå",
            "COMPAGNY_NAME": "Djärv Kyl AB Luleå",
            "LATITUDE": "65.571208",
            "LONGITUDE": "22.076728",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Andreas Djärv",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::16:00,2:7:00:::16:00,3:7:00:::16:00,4:7:00:::16:00,5:7:00:::16:00,6::::,7::::"
        },
        {
            "ID": 993,
            "ADDRESS_LINE_1": "Kurumsgatan 6",
            "ADDRESS_LINE_2": "",
            "CITY": "Tibro",
            "COUNTRY": "",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "543 50 ",
            "MAIN_PHONE": "+46 (0) 504 10931",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "tibro@maltemanson.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Tibro",
            "COMPAGNY_NAME": "Malte Månson Verkstäder AB",
            "LATITUDE": "58.413692",
            "LONGITUDE": "14.132534",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Andreas Strålman",
            "H24": "0",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::16:30,2:7:00:::16:30,3:7:00:::16:30,4:7:00:::16:30,5:7:00:::16:30,6::::,7::::"
        },
        {
            "ID": 994,
            "ADDRESS_LINE_1": "Källundavägen 285-25",
            "ADDRESS_LINE_2": "",
            "CITY": "Önnestad",
            "COUNTRY": "",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "291 93 ",
            "MAIN_PHONE": "+46 (0) 44 3000370",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Onnestad@maltemanson.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Önnestad",
            "COMPAGNY_NAME": "Malte Månson Verkstäder AB",
            "LATITUDE": "56.036687",
            "LONGITUDE": "14.042102",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": "",
            "STATUS": "1",
            "HOURS": "1:7:00:::16:00,2:7:00:::16:00,3:7:00:::16:00,4:7:00:::16:00,5:7:00:::16:00,6::::,7::::"
        },
        {
            "ID": 995,
            "ADDRESS_LINE_1": "Stinavägen 5",
            "ADDRESS_LINE_2": "",
            "CITY": "Växjö",
            "COUNTRY": "",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "352 46 ",
            "MAIN_PHONE": "+46 (0) 470 742940",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+46 (0) 76 5383780",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "vaxjo@maltemanson.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Växjö",
            "COMPAGNY_NAME": "Malte Månson Verkstäder AB",
            "LATITUDE": "56.894752",
            "LONGITUDE": "14.771023",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Emil Rosberg",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:::16:00,2:7:00:::16:00,3:7:00:::16:00,4:7:00:::16:00,5:7:00:::16:00,6::::,7::::"
        },
        {
            "ID": 1014,
            "ADDRESS_LINE_1": "Varggatan 23",
            "ADDRESS_LINE_2": "",
            "CITY": "Enköping",
            "COUNTRY": "",
            "COUNTRY_CODE": "SE",
            "POSTAL_CODE": "74940",
            "MAIN_PHONE": "+46 8 400 21 637",
            "ALT_PHONE": "+46 735 44 06 15",
            "MOBILE_PHONE": "+46 707 655 224",
            "ASSISTANCE_PHONE": "020 790470",
            "FAX_PHONE": "+46 8 500 203 03",
            "EMAIL_ADDRESS": "enkoping@bmckyl.se",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Enköping",
            "COMPAGNY_NAME": "BMC Transportkylservice AB",
            "LATITUDE": "59.639927",
            "LONGITUDE": "17.152821",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Magnus Nilsson",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:00:::17:00,2:7:00:::17:00,3:7:00:::17:00,4:7:00:::17:00,5:7:00:::17:00,6::::,7::::"
        },
        {
            "ID": 439,
            "ADDRESS_LINE_1": "Hrastje 52",
            "ADDRESS_LINE_2": "",
            "CITY": "Kranj",
            "COUNTRY": "Slovenia",
            "COUNTRY_CODE": "SI",
            "POSTAL_CODE": "4000",
            "MAIN_PHONE": "(+386) 4 2351 351",
            "ALT_PHONE": "(+386) 41 886 231",
            "MOBILE_PHONE": "(+386) 51 631 795",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+386) 4 23 51 350",
            "EMAIL_ADDRESS": "info.frimag@siol.net",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kranj",
            "COMPAGNY_NAME": "FRIMAG D.O.O.",
            "LATITUDE": "46.224065",
            "LONGITUDE": "14.37995",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Maja MAROLT / Gregor PETROVCIC",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:8:00:::14:00,7::::"
        },
        {
            "ID": 960,
            "ADDRESS_LINE_1": "Lopata 53",
            "ADDRESS_LINE_2": "",
            "CITY": "CELJE",
            "COUNTRY": "",
            "COUNTRY_CODE": "SI",
            "POSTAL_CODE": "3000",
            "MAIN_PHONE": "00 38 640217128",
            "ALT_PHONE": "00 38 651366819",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "aljosa@frigo.si",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "CELJE",
            "COMPAGNY_NAME": "FRIGOTRANSPORT PIESK & HSF d.o.o.",
            "LATITUDE": "46.260565",
            "LONGITUDE": "15.230141",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Aljoša Štorek",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:8:00:13:00::,7::::"
        },
        {
            "ID": 431,
            "ADDRESS_LINE_1": "Cabajska 28",
            "ADDRESS_LINE_2": "",
            "CITY": "Nitra",
            "COUNTRY": "Slovakia",
            "COUNTRY_CODE": "SK",
            "POSTAL_CODE": "94901",
            "MAIN_PHONE": "(+421) 37 69 219 30",
            "ALT_PHONE": "(+421) 905 452 630",
            "MOBILE_PHONE": "(+421) 905 452 630",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+421) 37 69 219 24",
            "EMAIL_ADDRESS": "juraj.kanas@dcplus.sk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Nitra",
            "COMPAGNY_NAME": "PRIMACLIMA, a.s.",
            "LATITUDE": "48.288262",
            "LONGITUDE": "18.074698",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Juraj KANAS",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:12:00:12:30:15:30,2:7:00:12:00:12:30:15:30,3:7:00:12:00:12:30:15:30,4:7:00:12:00:12:30:15:30,5:7:00:12:00:12:30:15:30"
        },
        {
            "ID": 435,
            "ADDRESS_LINE_1": "Zvolenská cesta 14 - Byvaly Areal ZVT",
            "ADDRESS_LINE_2": "",
            "CITY": "Banská Bystrica",
            "COUNTRY": "Slovakia",
            "COUNTRY_CODE": "SK",
            "POSTAL_CODE": "97401",
            "MAIN_PHONE": "(+421) 48 41 643 318",
            "ALT_PHONE": "(+421) 903 805 571",
            "MOBILE_PHONE": "(+421) 903 805 571",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+421) 484 164 319",
            "EMAIL_ADDRESS": "echos@stonline.sk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Banská Bystrica",
            "COMPAGNY_NAME": "ECHOS s.r.o.",
            "LATITUDE": "48.706127",
            "LONGITUDE": "19.136781",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Vladimir KUBIK",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:15:30,2:7:00:15:30,3:7:00:15:30,4:7:00:15:30,5:7:00:15:30"
        },
        {
            "ID": 436,
            "ADDRESS_LINE_1": "Demanovska ulica 800",
            "ADDRESS_LINE_2": "",
            "CITY": "Liptovsky Mikulas",
            "COUNTRY": "Slovakia",
            "COUNTRY_CODE": "SK",
            "POSTAL_CODE": "03101",
            "MAIN_PHONE": "(+421) 44 55  645 11",
            "ALT_PHONE": "(+421) 908 932 164",
            "MOBILE_PHONE": "(+421) 908 932 164",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+421) 44 55 145 11",
            "EMAIL_ADDRESS": "procar@procar.sk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Liptovsky Mikulas",
            "COMPAGNY_NAME": "PROCAR a.s",
            "LATITUDE": "49.080093",
            "LONGITUDE": "19.599287",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Dalibor IIANOVSKY",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:15:30,2:7:00:15:30,3:7:00:15:30,4:7:00:15:30,5:7:00:15:30"
        },
        {
            "ID": 665,
            "ADDRESS_LINE_1": "Štrková 23/B",
            "ADDRESS_LINE_2": "",
            "CITY": "Žilina",
            "COUNTRY": "Slovakia",
            "COUNTRY_CODE": "SK",
            "POSTAL_CODE": "SK01001",
            "MAIN_PHONE": "+421  415078011",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+421  415078011",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+421 415078016",
            "EMAIL_ADDRESS": "dobak@procar.sk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Žilina",
            "COMPAGNY_NAME": "PROCAR a.s.",
            "LATITUDE": "49.19002",
            "LONGITUDE": "18.728321",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Boris Dobak",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:00:15:30,2:7:00:15:30,3:7:00:15:30,4:7:00:15:30,5:7:00:15:30"
        },
        {
            "ID": 840,
            "ADDRESS_LINE_1": "Petrovianska 36,areál Mercedes Motor Car Prešov ",
            "ADDRESS_LINE_2": "",
            "CITY": "Prešov ",
            "COUNTRY": "Slovakia",
            "COUNTRY_CODE": "SK",
            "POSTAL_CODE": "080 06",
            "MAIN_PHONE": "+421 9048 225 366",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "+421 9048 225 366",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "hric@tcservis.sk",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Prešov ",
            "COMPAGNY_NAME": "Transcool servis s.r.o. ",
            "LATITUDE": "48.959971",
            "LONGITUDE": "21.256986",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Lubomír HRIC",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:16:00,2:7:30:16:00,3:7:30:16:00,4:7:30:16:00,5:7:30:16:00"
        },
        {
            "ID": 922,
            "ADDRESS_LINE_1": "Šenkvická cesta 11",
            "ADDRESS_LINE_2": "",
            "CITY": "Pezinok",
            "COUNTRY": "",
            "COUNTRY_CODE": "SK",
            "POSTAL_CODE": "90201 ",
            "MAIN_PHONE": "0911769450",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "0904239105",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "tomas.grancic@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Pezinok",
            "COMPAGNY_NAME": "GXB s.r.o",
            "LATITUDE": "48.293174",
            "LONGITUDE": "17.279026",
            "DD": "1",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "Tomas GRANCIC",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:00,2:7:30:::16:00,3:7:30:::16:00,4:7:30:::16:00,5:7:30:::16:00,6::::,7::::"
        },
        {
            "ID": 946,
            "ADDRESS_LINE_1": "Závodná 76",
            "ADDRESS_LINE_2": "",
            "CITY": "Bratislava",
            "COUNTRY": "",
            "COUNTRY_CODE": "SK",
            "POSTAL_CODE": "82106",
            "MAIN_PHONE": "00421905601405",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "00421905566345",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "00421240252213",
            "EMAIL_ADDRESS": "vizy@tittl.sk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bratislava",
            "COMPAGNY_NAME": "TITTL s.r.o.",
            "LATITUDE": "48.134885",
            "LONGITUDE": "17.189288",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jozef  Vízy",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:12:00:13:00:17:00,2:8:00:12:00:13:00:17:00,3:8:00:12:00:13:00:17:00,4:8:00:12:00:13:00:17:00,5:8:00:12:00:13:00:17:00,6::::,7::::"
        },
        {
            "ID": 536,
            "ADDRESS_LINE_1": "Rue de l'",
            "ADDRESS_LINE_2": "ZI - GP1 KM7 -",
            "CITY": "Ben Arous",
            "COUNTRY": "Tunisia",
            "COUNTRY_CODE": "TN",
            "POSTAL_CODE": "2013",
            "MAIN_PHONE": "(+216) 71 385930",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+216) 71 387703",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Tunis",
            "COMPAGNY_NAME": "COLDEQ",
            "LATITUDE": "36.743596",
            "LONGITUDE": "10.231919",
            "DD": "0",
            "TRUCK": "0",
            "TRAILER": "0",
            "MT": "0",
            "CONTACT": "M. Bechir BOUJDAI",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:12:30:14:30:18:00,2:8:30:12:30:14:30:18:00,3:8:30:12:30:14:30:18:00,4:8:30:12:30:14:30:18:00,5:8:30:12:30:14:30:18:00,6:8:30:12:30"
        },
        {
            "ID": 538,
            "ADDRESS_LINE_1": "5. Sanayi Sitesi 7419/1 Sok. D:13",
            "ADDRESS_LINE_2": "",
            "CITY": "Bornova / Izmir",
            "COUNTRY": "Turkey",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+90) 232 478 27 57",
            "ALT_PHONE": "(+90) 536 677 61 82  (+90) 541 410 58 67",
            "MOBILE_PHONE": "(+90) 536 677 61 82  (+90) 541 410 58 67",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+90) 232 4783212",
            "EMAIL_ADDRESS": "info@gunklima.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Bornova / Izmir",
            "COMPAGNY_NAME": "GUN KLIMA",
            "LATITUDE": "38.478765",
            "LONGITUDE": "27.108679",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Guncan Kilimci, Gokhan Kilimci",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:::15:30,2:8:30:::15:30,3:8:30:::15:30,4:8:30:::15:30,5:8:30:::15:30,6:8:30:::15:30,7::::"
        },
        {
            "ID": 543,
            "ADDRESS_LINE_1": "bahcekapi mah. 2480 sok. no: 9",
            "ADDRESS_LINE_2": "",
            "CITY": "Etimesgut/Ankara ",
            "COUNTRY": "Turkey",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "(+90) 312 278 38 48",
            "ALT_PHONE": "(+90) 554 392 2093",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+90) 312 2784737",
            "EMAIL_ADDRESS": "fatih.aktas@serefoto.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Etimesgut/Ankara ",
            "COMPAGNY_NAME": "SEREF OTOMOTIV",
            "LATITUDE": "39.941705",
            "LONGITUDE": "32.718224",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Fatih AKTAS",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::15:30,2:8:30:::15:30,3:8:30:::15:30,4:8:30:::15:30,5:8:30:::15:30,6:8:30:::15:30,7::::"
        },
        {
            "ID": 652,
            "ADDRESS_LINE_1": "Sirabademler Mah. Sanayi Sitesi ",
            "ADDRESS_LINE_2": "2.Ada No:29",
            "CITY": "Karacabey / Bursa",
            "COUNTRY": "Turkey",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+90) 224 6718135",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+90) 224 6718135",
            "EMAIL_ADDRESS": "yagcilaroto@hotmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Karacabey / Bursa",
            "COMPAGNY_NAME": "Yagcilar Oto",
            "LATITUDE": "40.214538",
            "LONGITUDE": "28.926659",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mustafa YAGCI",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::18:00,2:8:30:::18:00,3:8:30:::18:00,4:8:30:::18:00,5:8:30:::18:00,6:9:30:::15:00,7::::"
        },
        {
            "ID": 749,
            "ADDRESS_LINE_1": "Sukru Karahasanoglu oymaagac mah. ",
            "ADDRESS_LINE_2": "Oto servisleri sit 3102 sk no: 45",
            "CITY": "Kayseri",
            "COUNTRY": "Turkey",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "+90 533 7254282",
            "ALT_PHONE": "0",
            "MOBILE_PHONE": "0",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "m.karahasanoglu@msn.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kayseri",
            "COMPAGNY_NAME": "Kardesler Oto",
            "LATITUDE": "38.743406",
            "LONGITUDE": "35.346966",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Sukru KARAHASANOGLU",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::18:00,2:8:30:::18:00,3:8:30:::18:00,4:8:30:::18:00,5:8:30:::18:00,6:9:00:::15:00,7::::"
        },
        {
            "ID": 750,
            "ADDRESS_LINE_1": "Sanayi Mah",
            "ADDRESS_LINE_2": "Sanayi Sitesi 315 Sok. No:32 Merkez",
            "CITY": "Ortahisar/ Trabzon",
            "COUNTRY": "Turkey",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "+90 462 325 23 52",
            "ALT_PHONE": "(+90) 532 514 58 69",
            "MOBILE_PHONE": "0",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+90 462 3257702",
            "EMAIL_ADDRESS": "farukyy@hotmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Ortahisar/ Trabzon",
            "COMPAGNY_NAME": "Kayikcioglu",
            "LATITUDE": "41.001019",
            "LONGITUDE": "39.754672",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Hüseyin KAYIKÇIOGLU",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::18:00,2:8:30:::18:00,3:8:30:::18:00,4:8:30:::18:00,5:8:30:::18:00,6:9:00:::15:00,7::::"
        },
        {
            "ID": 751,
            "ADDRESS_LINE_1": "Sevket Sumer Mah. Turgut Ozal Bulvari Hasan Seker Tır garajı icindeki Emmare TIR Garajı ici 154. CD. NO:39",
            "ADDRESS_LINE_2": "",
            "CITY": "Akdeniz / Mersin",
            "COUNTRY": "Turkey",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "+90 538 339 12 33",
            "ALT_PHONE": "(+90) 537 522 41 99",
            "MOBILE_PHONE": "0",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "parlakyol@hotmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Akdeniz / Mersin",
            "COMPAGNY_NAME": "PARLAKYOL",
            "LATITUDE": "36.833646",
            "LONGITUDE": "34.653084",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Yusuf YOL   Gokhan Gokkurt",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::18:00,2:8:30:::18:00,3:8:30:::18:00,4:8:30:::18:00,5:8:30:::18:00,6:9:00:::15:00,7::::"
        },
        {
            "ID": 813,
            "ADDRESS_LINE_1": "Sarisik Mah. Degirmenduzu Sokak No:42 Samsun Corum Karayolu Uzeri SAMSUN",
            "ADDRESS_LINE_2": "",
            "CITY": "Semsun",
            "COUNTRY": "Turkey",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "+90 362 465 56 18",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+90 532 615 68 10",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+90 362 4655618",
            "EMAIL_ADDRESS": "yarsogutma@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Semsun",
            "COMPAGNY_NAME": "Yar Sogutma Sistemleri",
            "LATITUDE": "41.291443",
            "LONGITUDE": "36.290918",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Haci Mehmet YAR",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:::15:30,2:8:30:::15:30,3:8:30:::15:30,4:8:30:::15:30,5:8:30:::15:30,6:8:30:::15:30,7::::"
        },
        {
            "ID": 815,
            "ADDRESS_LINE_1": "Guzelburc Beldesi Gul Sanayi Sitesi A Blok No:1",
            "ADDRESS_LINE_2": "",
            "CITY": "Hatay",
            "COUNTRY": "Turkey",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "65031",
            "MAIN_PHONE": "+90 326 221 45 65",
            "ALT_PHONE": "+90 532 732 66 77",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+90 326 2214565",
            "EMAIL_ADDRESS": "ozcoskunservis@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Hatay",
            "COMPAGNY_NAME": "Ozcoskun Otomotiv",
            "LATITUDE": "36.24304",
            "LONGITUDE": "36.191111",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tuncay YOL",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:30:::19:00,2:8:30:::19:00,3:8:30:::19:00,4:8:30:::19:00,5:8:30:::19:00,6:9:30:::15:00,7::::"
        },
        {
            "ID": 973,
            "ADDRESS_LINE_1": "Adnan Menderes Caddesi Adem ",
            "ADDRESS_LINE_2": "Yoruk Sanayi Sitesi no:17 ",
            "CITY": "Dosemealti / Antalia ",
            "COUNTRY": "",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "+90 242 221 22 94",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+90 533 746 23 52",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "ozgurkrbrk@gmail.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Dosemealti / Antalia ",
            "COMPAGNY_NAME": "Arkadas Otomotiv",
            "LATITUDE": "36.896891",
            "LONGITUDE": "30.713323",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Abdullah KARABORK",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:::18:00,2:8:30:::18:00,3:8:30:::18:00,4:8:30:::18:00,5:8:30:::18:00,6:9:00:::15:00,7::::"
        },
        {
            "ID": 974,
            "ADDRESS_LINE_1": "FERHATPASA MAH. GAZIPASA CAD",
            "ADDRESS_LINE_2": "59. SOK. NO:32 34888 ",
            "CITY": "ATASEHIR / ISTANBUL ",
            "COUNTRY": "",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+90) 216 688 33 88",
            "ALT_PHONE": "(+90) 535 980 80 88 ",
            "MOBILE_PHONE": "(+90) 530 641 3832  (+90) 533 426 6711 ",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Unsal.angar@ascosogutma.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "",
            "COMPAGNY_NAME": "ASCO SOGUTMA SISTEMLERI",
            "LATITUDE": "40.981694",
            "LONGITUDE": "29.190642",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Ünsal Angar   ",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:::18:30,2:8:30:::18:30,3:8:30:::18:30,4:8:30:::18:30,5:8:30:::18:30,6:9:00:::14:30,7::::"
        },
        {
            "ID": 1032,
            "ADDRESS_LINE_1": "veyselkarini mah. 1136 sok. No:18A",
            "ADDRESS_LINE_2": "",
            "CITY": "Merkez / Afyon",
            "COUNTRY": "",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+90) 272 223 23 37",
            "ALT_PHONE": "(+90) 532 673 60 52",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "cosgunotoklima@hotmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Merkez / Afyon",
            "COMPAGNY_NAME": "Coskun oto klima",
            "LATITUDE": "38.775278",
            "LONGITUDE": "30.594167",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Gokhan Coskun",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 1033,
            "ADDRESS_LINE_1": "Beskopru Mah. D100 Karayolu Cd. No:292",
            "ADDRESS_LINE_2": "",
            "CITY": "Serdivan / SAKARYA",
            "COUNTRY": "",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+90) 532 244 12 96",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Serdivan / SAKARYA",
            "COMPAGNY_NAME": "Sezginler",
            "LATITUDE": "40.734167",
            "LONGITUDE": "30.369444",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Cemal USTA",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 1034,
            "ADDRESS_LINE_1": "Yenikoy Mah. 654 Sok. Sanayi Sit. ",
            "ADDRESS_LINE_2": "F Blok No:32 Ic Kapi No:9",
            "CITY": "Dosemealtı / Antalya",
            "COUNTRY": "",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+90) 538 885 30 45",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Dosemealtı / Antalya",
            "COMPAGNY_NAME": "Ince Iklimlendirme",
            "LATITUDE": "37.05761",
            "LONGITUDE": "30.59978",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mehmet INCE",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 1035,
            "ADDRESS_LINE_1": "Ozgurluk Mah. Cumhuriyet Bulv. No: 150  ",
            "ADDRESS_LINE_2": "",
            "CITY": "Akdeniz/Mersin",
            "COUNTRY": "",
            "COUNTRY_CODE": "TR",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+90) 324 234 43 39",
            "ALT_PHONE": "(+90) 532 285 15 29",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "serdar.sogutma@hotmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Akdeniz/Mersin",
            "COMPAGNY_NAME": "Sedar Sogutma",
            "LATITUDE": "36.0",
            "LONGITUDE": "34.0",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mehmet INCE",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 549,
            "ADDRESS_LINE_1": "2a, Lyatoshinskogo str.",
            "ADDRESS_LINE_2": "",
            "CITY": "Kiev",
            "COUNTRY": "Ukraine",
            "COUNTRY_CODE": "UA",
            "POSTAL_CODE": "3191",
            "MAIN_PHONE": "(+38) 044 250 6750",
            "ALT_PHONE": "(+38) 044 502 68 99",
            "MOBILE_PHONE": "(+38) 067 232 69 02",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+38) 044 250 8190",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kiev",
            "COMPAGNY_NAME": "TRANSKHOLOD",
            "LATITUDE": "50.375768",
            "LONGITUDE": "30.4529",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Valentin GRINYUK",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:8:00:20:00,2:8:00:20:00,3:8:00:20:00,4:8:00:20:00,5:8:00:20:00,6:8:00:20:00,7:8:00:20:00"
        },
        {
            "ID": 550,
            "ADDRESS_LINE_1": "29B Pastera Ste",
            "ADDRESS_LINE_2": "",
            "CITY": "Nerubaiskoe City",
            "COUNTRY": "Ukraine",
            "COUNTRY_CODE": "UA",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "(+38) 048 774 03 09",
            "ALT_PHONE": "(+38) 067 484 03 09",
            "MOBILE_PHONE": "(+38) 067 484 03 09",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+38) 048 774 03 09",
            "EMAIL_ADDRESS": "noemail@gmail.ru",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Odessa",
            "COMPAGNY_NAME": "REFHOLOD Ltd",
            "LATITUDE": "46.530912",
            "LONGITUDE": "30.644491",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Mikhail LAVRUK",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:18:00,2:9:00:18:00,3:9:00:18:00,4:9:00:18:00,5:9:00:18:00"
        },
        {
            "ID": 551,
            "ADDRESS_LINE_1": "18, Morozova str. ",
            "ADDRESS_LINE_2": "",
            "CITY": "Kharkov",
            "COUNTRY": "Ukraine",
            "COUNTRY_CODE": "UA",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "(+38) 057 756 14 24",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "(+38) 067 572 70 95",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+380) 57 252 21 36",
            "EMAIL_ADDRESS": "",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kharkov",
            "COMPAGNY_NAME": "TAHOSPECSERVICE Ltd",
            "LATITUDE": "49.953677",
            "LONGITUDE": "36.280675",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Alexander DITKOVSKIY",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:18:00,2:9:00:18:00,3:9:00:18:00,4:9:00:18:00,5:9:00:18:00"
        },
        {
            "ID": 553,
            "ADDRESS_LINE_1": "1 Kilceva Str, Yampil",
            "ADDRESS_LINE_2": "Lvov Region",
            "CITY": "Lvov",
            "COUNTRY": "Ukraine",
            "COUNTRY_CODE": "UA",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "(+38) 032 247 30 80",
            "ALT_PHONE": "(+38) 067 382 77 20",
            "MOBILE_PHONE": "(+38) 067 382 77 20",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "2473080@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Lvov",
            "COMPAGNY_NAME": "TRANSKHOLOD-LVIV Ltd",
            "LATITUDE": "49.87366",
            "LONGITUDE": "24.164146",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Viktoria ANDRUSENKO",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:18:00,2:9:00:18:00,3:9:00:18:00,4:9:00:18:00,5:9:00:18:00"
        },
        {
            "ID": 554,
            "ADDRESS_LINE_1": "72, Primakova str.",
            "ADDRESS_LINE_2": "",
            "CITY": "Vinnitsa",
            "COUNTRY": "Ukraine",
            "COUNTRY_CODE": "UA",
            "POSTAL_CODE": "000",
            "MAIN_PHONE": "(+38) 043 263 09 13",
            "ALT_PHONE": "(+38) 067 900 50 75",
            "MOBILE_PHONE": "(+38) 067 900 50 75",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+38) 043 263 09 14",
            "EMAIL_ADDRESS": "stanislav32@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Vinnitsa",
            "COMPAGNY_NAME": "LIDER HOLOD",
            "LATITUDE": "49.205276",
            "LONGITUDE": "28.48315",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Stanislav SKIP",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:18:00,2:9:00:18:00,3:9:00:18:00,4:9:00:18:00,5:9:00:18:00,6:8:00:14:00"
        },
        {
            "ID": 599,
            "ADDRESS_LINE_1": "Kraynya Street, 1",
            "ADDRESS_LINE_2": "",
            "CITY": "Novomoskovsk",
            "COUNTRY": "Ukraine",
            "COUNTRY_CODE": "UA",
            "POSTAL_CODE": "52001",
            "MAIN_PHONE": "(+380) 67 533 10 81",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Vladislav.Shvets@ctu.com.ua",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Dnepropetrovsk region",
            "COMPAGNY_NAME": "Dnipro Service Station",
            "LATITUDE": "48.598333",
            "LONGITUDE": "35.172222",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Vladislav SHVETS",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 991,
            "ADDRESS_LINE_1": "Str.Dubenska 11a",
            "ADDRESS_LINE_2": "village Velika Omelyana",
            "CITY": "Rivne region",
            "COUNTRY": "",
            "COUNTRY_CODE": "UA",
            "POSTAL_CODE": "35360 ",
            "MAIN_PHONE": "+380954195851",
            "ALT_PHONE": "+380505423100   +380504353525",
            "MOBILE_PHONE": "+380504358922",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+380362 631952",
            "EMAIL_ADDRESS": "transholodzahid@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Rivne region",
            "COMPAGNY_NAME": "Transholod Zahid Ltd",
            "LATITUDE": "50.593333",
            "LONGITUDE": "26.240278",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Anna Kalenska",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6::::,7::::"
        },
        {
            "ID": 440,
            "ADDRESS_LINE_1": "76 Manhatten Road",
            "ADDRESS_LINE_2": "Airport Industria",
            "CITY": "Cape Town",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "7490",
            "MAIN_PHONE": "(+27) 021 551 6360",
            "ALT_PHONE": "800004503",
            "MOBILE_PHONE": "(+27) 0 828806589",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+27) 021 551 6374",
            "EMAIL_ADDRESS": "bernard.ct@pecsser.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Cape Town",
            "COMPAGNY_NAME": "PECSSER CAPE TOWN",
            "LATITUDE": "-33.85246",
            "LONGITUDE": "18.52173",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Bernard DANIEL",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 441,
            "ADDRESS_LINE_1": "45 Rudo Nell Street",
            "ADDRESS_LINE_2": "Jet Park",
            "CITY": "Boksburg",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "1469",
            "MAIN_PHONE": "(+27) 011 397 8845",
            "ALT_PHONE": "800004503",
            "MOBILE_PHONE": "(+27) 0 766413243",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+27) 011 397 8849",
            "EMAIL_ADDRESS": "heink@pecsser.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Johannesburg",
            "COMPAGNY_NAME": "PECSSER JOHANNESBURG",
            "LATITUDE": "-26.173951",
            "LONGITUDE": "28.173951",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Hein KUHN",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 9,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 442,
            "ADDRESS_LINE_1": "22 Brickworks Way",
            "ADDRESS_LINE_2": "Briardene",
            "CITY": "Durban",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "4051",
            "MAIN_PHONE": "(+27) 031 705 3052",
            "ALT_PHONE": "800004503",
            "MOBILE_PHONE": "(+27) 0829027424",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+27) 031 705 3131",
            "EMAIL_ADDRESS": "johan@pecsser.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Durban",
            "COMPAGNY_NAME": "PECSSER DURBAN ",
            "LATITUDE": "-29.809336",
            "LONGITUDE": "30.876847",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Johan BOSHOFF",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 443,
            "ADDRESS_LINE_1": "c/o Discovery & Pioneer Road",
            "ADDRESS_LINE_2": "Bacaltsdorp Industria, PO Box 1279",
            "CITY": "George",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "6530",
            "MAIN_PHONE": "(+27) 044 878 1811",
            "ALT_PHONE": "800004503",
            "MOBILE_PHONE": "(+27) 083 284 9039",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0866634211",
            "EMAIL_ADDRESS": "exousia@telkom.net",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "George",
            "COMPAGNY_NAME": "EXOUSIA TRANSPORT REFRIGERATION",
            "LATITUDE": "-33.99334",
            "LONGITUDE": "22.440453",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Louis BARNARD",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 447,
            "ADDRESS_LINE_1": "43 Malherbe Street",
            "ADDRESS_LINE_2": "",
            "CITY": "Upington",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "8800",
            "MAIN_PHONE": "(+27) 054 331 1245",
            "ALT_PHONE": "800004503",
            "MOBILE_PHONE": "(+27) 082 823 1292",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+27) 086 636 9834",
            "EMAIL_ADDRESS": "ssstanderherstel@telkomsa.net",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Upington",
            "COMPAGNY_NAME": "S.S. STANDER HERSTELWERK",
            "LATITUDE": "-28.455694",
            "LONGITUDE": "21.235725",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Fanie STANDER",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 448,
            "ADDRESS_LINE_1": "19 Piekienie Street",
            "ADDRESS_LINE_2": "Pellisier",
            "CITY": "Bloemfontein",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "9301",
            "MAIN_PHONE": "(+27) 051 432 3711",
            "ALT_PHONE": "800004503",
            "MOBILE_PHONE": "(+27) 0716809441",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+27) 051 432 3712",
            "EMAIL_ADDRESS": "steven@mlservices.co.za",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bloemfontein",
            "COMPAGNY_NAME": "M L SERVICES",
            "LATITUDE": "-29.11835",
            "LONGITUDE": "26.22493",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Steven COETSER",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 8,
            "MOBILEVAN_NUMBER": 8,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 449,
            "ADDRESS_LINE_1": "21 Tom Miller Str,",
            "ADDRESS_LINE_2": "Sybrand van Niekerk Park, PO Box 1182,",
            "CITY": "Meyerton",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "1960",
            "MAIN_PHONE": "(+27) 016 362 2254",
            "ALT_PHONE": "800004503",
            "MOBILE_PHONE": "(+27) 083 263 8106",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+27) 016 362 3380",
            "EMAIL_ADDRESS": "refsatrans@ravyse.co.za",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Vaal Triangle",
            "COMPAGNY_NAME": "REFSATRANS",
            "LATITUDE": "-26.57512",
            "LONGITUDE": "28.00971",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Walther RAVYSE",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 450,
            "ADDRESS_LINE_1": "Plot 1A ,Ottosdal",
            "ADDRESS_LINE_2": "PO Box 315, Hartbeesfontein",
            "CITY": "Klerksdorp",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "2600",
            "MAIN_PHONE": "(+27) 018 4310801",
            "ALT_PHONE": "0800004503",
            "MOBILE_PHONE": "(+27) 0823264569",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+27) 018 4310254",
            "EMAIL_ADDRESS": "topaxworkshop@koshcom.co.za",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Klerksdorp",
            "COMPAGNY_NAME": "TOPAX WORKSHOP",
            "LATITUDE": "-26.7735",
            "LONGITUDE": "26.4143",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Jose Da SILVA",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 451,
            "ADDRESS_LINE_1": "6 Belvoir Place ",
            "ADDRESS_LINE_2": "Cambridge West",
            "CITY": "East London",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "5247",
            "MAIN_PHONE": "(+27) 043 726 5691",
            "ALT_PHONE": "0800004503",
            "MOBILE_PHONE": "(+27) 082 374 9614",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+27) 043 726 5691",
            "EMAIL_ADDRESS": "boschfam22@mweb.co.za",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "East London",
            "COMPAGNY_NAME": "BD BOSCH",
            "LATITUDE": "-32.977694",
            "LONGITUDE": "27.87716",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Basil BOSCH",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 453,
            "ADDRESS_LINE_1": "Appie Visser Street ",
            "ADDRESS_LINE_2": "PO Box 372",
            "CITY": "Springbok",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "8240",
            "MAIN_PHONE": "(+27) 027 718 3740",
            "ALT_PHONE": "0800004503",
            "MOBILE_PHONE": "(+27) 0844013932",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+27) 027 7183740",
            "EMAIL_ADDRESS": "springbokauto@telkomsa.net",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Springbok",
            "COMPAGNY_NAME": "SPRINGBOK AUTO ELECTRICAL",
            "LATITUDE": "-29.67536",
            "LONGITUDE": "17.884087",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Herman JOUBERT",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 680,
            "ADDRESS_LINE_1": "Plot 151 Trevor Road",
            "ADDRESS_LINE_2": "Ivydale, PO 31550, Superbia",
            "CITY": "Polokwane",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "0759",
            "MAIN_PHONE": "+27 0 15 2959276",
            "ALT_PHONE": "0800004503",
            "MOBILE_PHONE": "+27 0 833757220",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+27 0 15 2959276",
            "EMAIL_ADDRESS": "shivers@mweb.co.za",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Polokwane",
            "COMPAGNY_NAME": "SHIVERS",
            "LATITUDE": "-23.931505",
            "LONGITUDE": "29.43718",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "PJ WEIR",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 4,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 682,
            "ADDRESS_LINE_1": "129 Smits Sreet",
            "ADDRESS_LINE_2": "Rocky Drift",
            "CITY": "Nelspruit",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "1240",
            "MAIN_PHONE": "+27 0 82 889 2322",
            "ALT_PHONE": "+27 080004503",
            "MOBILE_PHONE": "+27 0 82 889 2322",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "koosdp@pecsser.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "NELSPRUIT",
            "COMPAGNY_NAME": "PECSSER NELSPRUIT",
            "LATITUDE": "-25.3763",
            "LONGITUDE": "30.97822",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Koos DU PLOOY",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 821,
            "ADDRESS_LINE_1": "Veertiende Street",
            "ADDRESS_LINE_2": "",
            "CITY": "Volkstrust",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "2740",
            "MAIN_PHONE": "+27 0 83 5410833",
            "ALT_PHONE": "+27 0800004503",
            "MOBILE_PHONE": "+27 0 83 5410833",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "admin@aktruckservcies.co.za",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Volkstrust",
            "COMPAGNY_NAME": "AK TRUCK & FIELD SERVICES",
            "LATITUDE": "-27.362026",
            "LONGITUDE": "29.887305",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "0",
            "CONTACT": "Arrie LOMBARD",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 822,
            "ADDRESS_LINE_1": "Unit B3, Abattoir Road, Ashburnham",
            "ADDRESS_LINE_2": "Post Net Suite 106, P/Bag x2, Diamond",
            "CITY": "Kimberly",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "8305",
            "MAIN_PHONE": "+27 0 53 841 1107",
            "ALT_PHONE": "+27 0800004503",
            "MOBILE_PHONE": "+27 0 73 9118167",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+27 086 587 6194",
            "EMAIL_ADDRESS": "smith-james@alantic.net",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Kimberly",
            "COMPAGNY_NAME": "JJ COOLING SERVICES",
            "LATITUDE": "-28.71464",
            "LONGITUDE": "24.77067",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "James SMITH",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 823,
            "ADDRESS_LINE_1": "6 Ceremic Curve",
            "ADDRESS_LINE_2": "PO Box 8750",
            "CITY": "Alton",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "3900",
            "MAIN_PHONE": "+27 0 35 7511581",
            "ALT_PHONE": "+27 0800004503",
            "MOBILE_PHONE": "+27 0 718929000",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+27 0357511345",
            "EMAIL_ADDRESS": "gi.geere@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Alton",
            "COMPAGNY_NAME": "SHIVERSCOOL",
            "LATITUDE": "-28.75463",
            "LONGITUDE": "32.029242",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Gerald GEERE",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 824,
            "ADDRESS_LINE_1": "11 Nicoll Street, Neave Township",
            "ADDRESS_LINE_2": "PO Box 35125, Newton Park",
            "CITY": "Port Elizabeth",
            "COUNTRY": "South Africa",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "6055",
            "MAIN_PHONE": "+27 0 41 8114707",
            "ALT_PHONE": "+27 071 896 2490",
            "MOBILE_PHONE": "+27 083 4002884",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+27 086 6204904",
            "EMAIL_ADDRESS": "chris@sprinterzone.co.za",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Port Elizabeth",
            "COMPAGNY_NAME": "SPRINTERZONE",
            "LATITUDE": "-33.91645",
            "LONGITUDE": "25.588553",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Chris LOURENS Jean van ROOY",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:7:30:16:30,2:7:30:16:30,3:7:30:16:30,4:7:30:16:30,5:7:30:16:30"
        },
        {
            "ID": 916,
            "ADDRESS_LINE_1": "7 Bensley Street",
            "ADDRESS_LINE_2": "PO Box 1130",
            "CITY": "Beaufort West",
            "COUNTRY": "",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "+27 23 414 4105",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+27 72 522 9173",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "susankriel@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Beaufort West",
            "COMPAGNY_NAME": "BEAUFORT WEST REFRIGERATION",
            "LATITUDE": "-32.35826",
            "LONGITUDE": "22.595854",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Francios KRIEL",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 917,
            "ADDRESS_LINE_1": "28 Industria Way",
            "ADDRESS_LINE_2": "",
            "CITY": "Harrismith",
            "COUNTRY": "",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "9880",
            "MAIN_PHONE": "+27 0 58 6222 095",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+27 0 71 496 9086",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "jjandsons@vodamail.co.za",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Harrismith",
            "COMPAGNY_NAME": "JJ & SONS",
            "LATITUDE": "-28.26982",
            "LONGITUDE": "29.13125",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "John BANNAU",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 918,
            "ADDRESS_LINE_1": "1A church Street",
            "ADDRESS_LINE_2": "",
            "CITY": "Bethlehem",
            "COUNTRY": "",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "2600",
            "MAIN_PHONE": "+27 0 58 303 5400",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+27 0 82 777 3188",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "airmark@telkomsa.net",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Bethlehem",
            "COMPAGNY_NAME": "AIRMARK",
            "LATITUDE": "-26.7735",
            "LONGITUDE": "26.4143",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Koos van RENSBURG",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 919,
            "ADDRESS_LINE_1": "2 Louis Street",
            "ADDRESS_LINE_2": "Posbus 462 Eppindust",
            "CITY": "Worcester",
            "COUNTRY": "",
            "COUNTRY_CODE": "ZA ",
            "POSTAL_CODE": "7475",
            "MAIN_PHONE": "+27 0 23 347 2578",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+27 0 23 342 4829",
            "EMAIL_ADDRESS": "shhaam@addtrucks.co.za",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Worcester",
            "COMPAGNY_NAME": "ADD TRUCK & BUS (PTY) LTD",
            "LATITUDE": "-33.631167",
            "LONGITUDE": "19.471959",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Shihaam PANDY",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:::16:30,2:7:30:::16:30,3:7:30:::16:30,4:7:30:::16:30,5:7:30:::16:30,6::::,7::::"
        },
        {
            "ID": 834,
            "ADDRESS_LINE_1": "114 Seke Road",
            "ADDRESS_LINE_2": "Granltside",
            "CITY": "Harare",
            "COUNTRY": "Zimbabwe",
            "COUNTRY_CODE": "ZW",
            "POSTAL_CODE": "9990",
            "MAIN_PHONE": "+26 377 0790",
            "ALT_PHONE": "+27 0800004503",
            "MOBILE_PHONE": "+26 377 4186982",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+26 3770790",
            "EMAIL_ADDRESS": "theo@hms.co.za",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Harare",
            "COMPAGNY_NAME": "COLD CONTROL",
            "LATITUDE": "-17.851656",
            "LONGITUDE": "31.053384",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Theo FONSECA",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:6:30:16:30,2:6:30:16:30,3:6:30:16:30,4:6:30:16:30,5:6:30:16:30"
        },
        {
            "ID": 38,
            "ADDRESS_LINE_1": "13 Larnakos Street",
            "ADDRESS_LINE_2": "2572 Pera Chorio Nisou",
            "CITY": "Nicosia",
            "COUNTRY": "Cyprus",
            "COUNTRY_CODE": "CY",
            "POSTAL_CODE": "CY",
            "MAIN_PHONE": "(+357) 22 525417",
            "ALT_PHONE": "(+357) 22 489666 ",
            "MOBILE_PHONE": "(+357) 99 620101 / 656535",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "(+357) 22 521171",
            "EMAIL_ADDRESS": "r.polydorou@tigersprings.com.cy",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Nicosia",
            "COMPAGNY_NAME": "TIGER SPRINGS (MANUFACTURES) LTD",
            "LATITUDE": "35.0167",
            "LONGITUDE": "33.3875",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Rena POLYDOROU",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:7:30:17:30,2:7:30:17:30,3:7:30:17:30,4:7:30:17:30,5:7:30:17:30"
        },
        {
            "ID": 913,
            "ADDRESS_LINE_1": "Lužansko polje 7",
            "ADDRESS_LINE_2": "",
            "CITY": "Sarajevo",
            "COUNTRY": "",
            "COUNTRY_CODE": "BA",
            "POSTAL_CODE": "71000",
            "MAIN_PHONE": "+387 33 763 265",
            "ALT_PHONE": "+38761175276",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "",
            "FAX_PHONE": "+38733763266",
            "EMAIL_ADDRESS": "info@slt.ba",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Sarajevo",
            "COMPAGNY_NAME": "SLT doo",
            "LATITUDE": "43.832275",
            "LONGITUDE": "18.294253",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Nenad Sepi",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:30,2:8:00:::16:30,3:8:00:::16:30,4:8:00:::16:30,5:8:00:::16:30,6:8:00:::16:30,7:8:00:::16:30"
        },
        {
            "ID": 1036,
            "ADDRESS_LINE_1": "Str. 203. brigade,  n 88",
            "ADDRESS_LINE_2": "",
            "CITY": "Matuzići-Doboj",
            "COUNTRY": "",
            "COUNTRY_CODE": "BA",
            "POSTAL_CODE": "74203",
            "MAIN_PHONE": "+387 (0)32 941 950",
            "ALT_PHONE": "+387 (0)63 398 288",
            "MOBILE_PHONE": "+387 (0)63 398 276",
            "ASSISTANCE_PHONE": "",
            "FAX_PHONE": "+387 (0)32 691 102",
            "EMAIL_ADDRESS": "vladimir.vasiljevic@kamibus.ba",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Matuzići-Doboj",
            "COMPAGNY_NAME": "KAM i BUS d.o.o. Doboj",
            "LATITUDE": "44.696702",
            "LONGITUDE": "18.043242",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Vladimir Vasiljević",
            "H24": "1",
            "ENGINELESS": "1",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:8:00:::16:00,7::::"
        },
        {
            "ID": 767,
            "ADDRESS_LINE_1": "Turnahvarf 8",
            "ADDRESS_LINE_2": "",
            "CITY": " 203 Kopavogur",
            "COUNTRY": "Iceland",
            "COUNTRY_CODE": "IS",
            "POSTAL_CODE": "203",
            "MAIN_PHONE": "+354 664 1333",
            "ALT_PHONE": "+354 8942700",
            "MOBILE_PHONE": "+354 8640305",
            "ASSISTANCE_PHONE": "NULL",
            "FAX_PHONE": "+354 5544476",
            "EMAIL_ADDRESS": "daniel@kapp.is",
            "DKV": "1",
            "REPRESENTATIVE_CITY": " 203 Kopavogur",
            "COMPAGNY_NAME": "KAPP ehf",
            "LATITUDE": "64.09497",
            "LONGITUDE": "-21.81787",
            "DD": "0",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Daniel Snaer Heimisson",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 5,
            "MOBILEVAN_NUMBER": 3,
            "STATUS": "1",
            "HOURS": "1:8:00:::17:00,2:8:00:::17:00,3:8:00:::17:00,4:8:00:::17:00,5:8:00:::17:00,6::::,7::::"
        },
        {
            "ID": 837,
            "ADDRESS_LINE_1": "Rua da Resistencia 840",
            "ADDRESS_LINE_2": "Malhangalene",
            "CITY": "Maputo",
            "COUNTRY": "Mozambique",
            "COUNTRY_CODE": "MZ",
            "POSTAL_CODE": "1100",
            "MAIN_PHONE": "+26 377 4186983",
            "ALT_PHONE": "+27 0800004503",
            "MOBILE_PHONE": "+26 377 4186983",
            "ASSISTANCE_PHONE": "",
            "FAX_PHONE": "0",
            "EMAIL_ADDRESS": "phillip604@live.co.za",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Maputo",
            "COMPAGNY_NAME": "FRIONAIS REFRIGERATION",
            "LATITUDE": "-25.957758",
            "LONGITUDE": "32.582021",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Phillip WOLMARANS",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "1",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 1,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:6:30:16:30,2:6:30:16:30,3:6:30:16:30,4:6:30:16:30,5:6:30:16:30"
        },
        {
            "ID": 754,
            "ADDRESS_LINE_1": "4 JCHS Sharea Faisal",
            "ADDRESS_LINE_2": "",
            "CITY": "Karachi",
            "COUNTRY": "Pakistan",
            "COUNTRY_CODE": "PK",
            "POSTAL_CODE": "75320",
            "MAIN_PHONE": "+92 21 34521825",
            "ALT_PHONE": "+92 300 8257648",
            "MOBILE_PHONE": "",
            "ASSISTANCE_PHONE": "",
            "FAX_PHONE": "+92 21 34545905",
            "EMAIL_ADDRESS": "aabros@aabros.com.pk",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Karachi",
            "COMPAGNY_NAME": "ANIS AHMED & BROTHERS",
            "LATITUDE": "24.87",
            "LONGITUDE": "67.68",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Anees ALY",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 0,
            "MOBILEVAN_NUMBER": 0,
            "STATUS": "1",
            "HOURS": "1:9:00:17:30,2:9:00:17:30,3:9:00:17:30,4:9:00:17:30,5:9:00:17:30"
        },
        {
            "ID": 849,
            "ADDRESS_LINE_1": "Porte No 1798",
            "ADDRESS_LINE_2": "Dalifort Forail Pikine Rue DF 266",
            "CITY": "DAKAR",
            "COUNTRY": "Senegal",
            "COUNTRY_CODE": "SN",
            "POSTAL_CODE": "",
            "MAIN_PHONE": "+221 773088650",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+221 773088650",
            "ASSISTANCE_PHONE": "",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "etsfrigo@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "DAKAR",
            "COMPAGNY_NAME": "EQUIPMENTS TRUCK SERVICES",
            "LATITUDE": "14.74629",
            "LONGITUDE": "-17.408513",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Adrien FLEURY",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:30:16:30,2:8:30:16:30,3:8:30:16:30,4:8:30:16:30,5:8:30:16:30"
        },
        {
            "ID": 853,
            "ADDRESS_LINE_1": "St. 612 B124-11 ",
            "ADDRESS_LINE_2": "Bole Sub-city Kebele 03/05 H# 557",
            "CITY": "Addis Ababa",
            "COUNTRY": "Ethiopia",
            "COUNTRY_CODE": "ET",
            "POSTAL_CODE": "0",
            "MAIN_PHONE": "+251 116 84968",
            "ALT_PHONE": "+251 911 235365",
            "MOBILE_PHONE": "+251 939 665032  31",
            "ASSISTANCE_PHONE": "",
            "FAX_PHONE": "+251 116 625171",
            "EMAIL_ADDRESS": "astunet01@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Addis Ababa",
            "COMPAGNY_NAME": "ASTUNET Business Enterprises PLC",
            "LATITUDE": "9.000451",
            "LONGITUDE": "38.781895",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Tamrat Asmamaw / Daniel Wondimu",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "1",
            "TECHNICIAN_NUMBER": 4,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00,2:8:00:17:00,3:8:00:17:00,4:8:00:17:00,5:8:00:17:00,6:8:00:17:00"
        },
        {
            "ID": 872,
            "ADDRESS_LINE_1": "P.O.BOX DS 1461",
            "ADDRESS_LINE_2": "",
            "CITY": "DANSOMAN",
            "COUNTRY": "",
            "COUNTRY_CODE": "GH",
            "POSTAL_CODE": "1461",
            "MAIN_PHONE": "+233 271 477 333",
            "ALT_PHONE": "+233 243 858 008",
            "MOBILE_PHONE": "+233 243 858 008",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "addfra2@yahoo.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Accra",
            "COMPAGNY_NAME": "ADDFRA METAL ENGINEERING AND ADVERTISING  LTD.",
            "LATITUDE": "5.564058",
            "LONGITUDE": "-0.25122",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "FRANCIS ADDAE",
            "H24": "1",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 2,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:17:00::,2:8:00:17:00::,3:8:00:17:00::,4:8:00:17:00::,5:8:00:17:00::,6:9:00:14:00::,7::::"
        },
        {
            "ID": 873,
            "ADDRESS_LINE_1": "Boska Buhe bb",
            "ADDRESS_LINE_2": "",
            "CITY": "Podgorica",
            "COUNTRY": "",
            "COUNTRY_CODE": "ME",
            "POSTAL_CODE": "81101",
            "MAIN_PHONE": "+382 20 269 904",
            "ALT_PHONE": "+382 69 187 357",
            "MOBILE_PHONE": "+382 69 741 393",
            "ASSISTANCE_PHONE": "(+32) 9 255 67 89",
            "FAX_PHONE": "+382 20 269 910",
            "EMAIL_ADDRESS": "frimax.vukovic@gmail.com",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Podgorica",
            "COMPAGNY_NAME": "FRIGOMAX VUKOVIC",
            "LATITUDE": "42.460233",
            "LONGITUDE": "19.236481",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Vlatko Vukovic",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "0",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 2,
            "STATUS": "1",
            "HOURS": "1:8:00:16:30::,2:8:00:16:30::,3:8:00:16:30::,4:8:00:16:30::,5:8:00:16:30::,6::::,7::::"
        },
        {
            "ID": 909,
            "ADDRESS_LINE_1": "Autostrada Tirane Durres",
            "ADDRESS_LINE_2": "",
            "CITY": "Tirana",
            "COUNTRY": "",
            "COUNTRY_CODE": "AL",
            "POSTAL_CODE": "1032",
            "MAIN_PHONE": "+355 42 408201",
            "ALT_PHONE": "+355 42 408201",
            "MOBILE_PHONE": "+355 689027696",
            "ASSISTANCE_PHONE": "",
            "FAX_PHONE": "+355 42 408203",
            "EMAIL_ADDRESS": "admir.dervishi@albascan.al",
            "DKV": "0",
            "REPRESENTATIVE_CITY": "Tirana",
            "COMPAGNY_NAME": "Albascan Trucks and Buses SHPK",
            "LATITUDE": "41.370254",
            "LONGITUDE": "19.68346",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Admir DERVISHI ",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "0",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 3,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:8:00:::16:00,2:8:00:::16:00,3:8:00:::16:00,4:8:00:::16:00,5:8:00:::16:00,6:8:00:::16:00,7::::"
        },
        {
            "ID": 971,
            "ADDRESS_LINE_1": "304 toot",
            "ADDRESS_LINE_2": "Bayangol district 4-r khoroo Tegsh-Undrakh tuv",
            "CITY": "Ulaanbaatar city  ",
            "COUNTRY": "",
            "COUNTRY_CODE": "MNG",
            "POSTAL_CODE": "15170",
            "MAIN_PHONE": "+976 77141487",
            "ALT_PHONE": "",
            "MOBILE_PHONE": "+976 99887048",
            "ASSISTANCE_PHONE": "",
            "FAX_PHONE": "",
            "EMAIL_ADDRESS": "Info@suldtruck.com",
            "DKV": "1",
            "REPRESENTATIVE_CITY": "Ulaanbaatar city  ",
            "COMPAGNY_NAME": "SULD UNITED LLC",
            "LATITUDE": "47.913056",
            "LONGITUDE": "106.783056",
            "DD": "1",
            "TRUCK": "1",
            "TRAILER": "1",
            "MT": "1",
            "CONTACT": "Budjav Tegshjargal",
            "H24": "0",
            "ENGINELESS": "0",
            "FREE_NUMBER": "0",
            "EXACT_GEOLOC": "1",
            "MOBILE_CENTER": "1",
            "SPHERE_OF_OPERATION": "0",
            "TECHNICIAN_NUMBER": 6,
            "MOBILEVAN_NUMBER": 1,
            "STATUS": "1",
            "HOURS": "1:9:00:::18:00,2:9:00:::18:00,3:9:00:::18:00,4:9:00:::18:00,5:9:00:::18:00,6:9:00:::18:00,7::::"
        }
    ]
}