import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
//import {} from '../../assets/i18n'


@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  baseUrl = environment.apiBaseUrl + 'api/managemanual/';

  data: any = {};
  constructor(private http: HttpClient) { }

  use(lang: string) {
    //const langPath = `./assets/i18n/${lang || 'en'}.json`;
    const langPath = `../../assets/i18n/${lang || 'en'}.json`;
    //console.log(langPath);
   return this.http.get(langPath);

  }

  /** added by sandeep on May 16, 2023 */
  getAllTranslations() {
    this.baseUrl = environment.apiBaseUrl + 'api/CountryCityState/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    return this.http.get<any>(this.baseUrl + '/GetTranslations');
  }
}
