import { Injectable } from '@angular/core';
import { Http, Response } from "@angular/http";

import { AllRegions } from '../contentpage/allRegionData';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { dealerModel } from '../models/dealerModel';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Observable } from 'rxjs/internal/Observable';
import { dealerLevelData } from '../admin/createdealer/dealerLevelData';

@Injectable({
  providedIn: 'root'
})
export class DealerService {
  baseUrl = environment.apiBaseUrl + 'api/dealer/';
  savedToken: any = "";
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.savedToken
    })
  };
  
  UserClaim :any ="";
  constructor(private http: HttpClient) { }

  /** Created getAllDealersV2() to replace getAllDealers() method */
  // Uses http.get() to load data from a single API endpoint
  // getAllDealers() {
  //   // this.baseUrl = environment.apiBaseUrl + 'api/Test/';  
  //   // var authToken='Bearer '+this.savedToken;
  //   // var headers = new HttpHeaders().set('Authorization',authToken);
  //   //console.log(authToken);
  //   // let headers: HttpHeaders = new HttpHeaders();
  //   // headers = headers.append('Accept', 'application/json');
  //   // console.log(this.baseUrl)
  //   return this.http.get<any>(this.baseUrl + 'getall?ApiAccessKey=9ZQxFMZB4o4gWvBnAlLZEQpuFOFWNEyzm65aV5huSjc=&MobAppKey=com.carriertransicold.dealerlocator'//,{headers}
  //   );
  // }

  /** added by sandeep on dec 12, 2022 */
  getAllDealersV2() {
    // this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = 'http://localhost:22598/api/Test/'; //for local
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    return this.http.get<any>(this.baseUrl + 'getallV2?ApiAccessKey=9ZQxFMZB4o4gWvBnAlLZEQpuFOFWNEyzm65aV5huSjc=&MobAppKey=com.carriertransicold.dealerlocator');
  }

  getDealerOpenClose(dealerId:any){
    // this.baseUrl = environment.apiBaseUrl + 'api/Dealer/'; 
    // this.baseUrl = 'http://localhost:22598/api/Dealer/'; //for local
    return this.http.get<any>(this.baseUrl + 'StoreStatus?ApiAccessKey=9ZQxFMZB4o4gWvBnAlLZEQpuFOFWNEyzm65aV5huSjc=&MobAppKey=com.carriertransicold.dealerlocator&id='+dealerId);
  }

  getDealerHistory(id: any): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/Dealer/';  
    // this.baseUrl = 'http://localhost:22598/api/Dealer/'; //for local
    return this.http.get<any>(this.baseUrl + 'getHistory?id='+id);
  }


  /** Created getDealerV2() for the replacement of getDealer() */
  // getDealer(id: number) {
  //   // this.baseUrl = environment.apiBaseUrl + 'api/Dealer/';  
  //   // this.baseUrl= "http://localhost:22598/api/Dealer/";
  //   return this.http.get<dealerModel>(this.baseUrl + 'get/' + id);
  // }

  getAllRegions() {
     return AllRegions
  }


  getTimeZone(url: any) {
    return this.http.get<any>(url);
  }

  /** Created updatedDealerV2() for the replacement of updateDealer() */
  // updateDealer(dealer: dealerModel): Observable<dealerModel> {
  //   // this.baseUrl = environment.apiBaseUrl + 'api/Dealer/';  
  //   dealer.modifiedBy = localStorage.getItem('currentUser');
  //   //console.log("Dealer Modifyby :" ,dealer.modifiedBy); //Vaishnavi added to check the localstorage token on 23 Feb 2023
  //   return this.http.put<dealerModel>(this.baseUrl + 'update/', dealer, this.httpOptions);
  // }

  /** Created SaveDealerV2() for the replacement of saveDealer() */
  // saveDealer(dealer: dealerModel): Observable<dealerModel> {

  //   // this.baseUrl = environment.apiBaseUrl + 'api/Dealer/';  
  //   dealer.createdBy = localStorage.getItem('currentUser');
  //   //console.log("Dealer Createdby :" ,dealer.createdBy); //Vaishnavi added to check the localstorage token on 23 Feb 2023
   
  //   return this.http.post<dealerModel>(this.baseUrl + 'add?dealer=', dealer, this.httpOptions);
  // }

  deleteDealer(id: any): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/Dealer/';  
    var user = localStorage.getItem('currentUser');
    //console.log("user :" ,user); //Vaishnavi added to check the localstorage token on 23 Feb 2023
   
    return this.http.post<any>(this.baseUrl + 'delete/?user=' + user + '&id=' + id, this.httpOptions)
  }

  getDealerLevels(): any[] {
    return dealerLevelData;
  }
  getuniqueName(name: any, city: any, state: any): any {
    // this.baseUrl = environment.apiBaseUrl + 'api/Dealer/';     
    return this.http.get<any>(this.baseUrl + 'GetByName/' + name + '/' + city + '/' + state)
  }

  //Test for europe api
  euroApiTest() {
    this.baseUrl = environment.apiBaseUrl + 'api/Test/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    // return this.http.get<any>('https://carrier.locator.isee-u.fr/webservices/get_data_center');//, this.httpOptions);
    return this.http.get<any>(this.baseUrl + 'GetEuroDealers')
  }

  SaveDealerV2(dealer: dealerModel): Observable<dealerModel> {

    // this.baseUrl = environment.apiBaseUrl + 'api/TestDealer/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    dealer.createdBy = localStorage.getItem('currentUser');
    return this.http.post<dealerModel>(this.baseUrl + 'addV2?dealer=', dealer, this.httpOptions);
  }

  /** added by sandeep on Mar 02, 2023 */
  getDealerV2(id: number) {
    // console.log(this.baseUrl + 'get/' + id);
    // this.baseUrl = environment.apiBaseUrl + 'api/TestDealer/';
    // this.baseUrl = 'http://localhost:22598/api/TestDealer/'; //for local
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    return this.http.get<dealerModel>(this.baseUrl + 'getV2/' + id);
  }

  /** added by sandeep on Mar 06, 2023 */
  updateDealerV2(dealer: dealerModel): Observable<dealerModel> {
    // console.log(dealer)
    // this.baseUrl = environment.apiBaseUrl + 'api/TestDealer/';
    // this.baseUrl = "https://app-dealerlocatordr.azurewebsites.net/api/Test/";
    dealer.modifiedBy = localStorage.getItem('currentUser');
    return this.http.put<dealerModel>(this.baseUrl + 'updateV2/', dealer, this.httpOptions);
  }

  /** added by sandeep on Jun 21, 2023 */
  addEuropeDealer(dealer: dealerModel): Observable<dealerModel> {
    this.baseUrl = environment.apiBaseUrl + 'api/TestDealer/';
    dealer.createdBy = localStorage.getItem('currentUser');
    // return this.http.post<dealerModel>(this.baseUrl + 'AddEuroDealer?dealer=', dealer, this.httpOptions);
    return this.http.post<dealerModel>('http://localhost:22598/api/TestDealer/' + 'AddEuroDealer?dealer=', dealer, this.httpOptions);
    
  }
}




