import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, Input } from '@angular/core';
import { dealerModel } from '../models/dealerModel';
import { DealerService } from '../services/dealer.service';
import { GeocodeService } from '../services/geocode.service';
import { DataServiceService } from '../services/data-service.service';
import { Subscription } from 'rxjs';
import { filterModel } from '../models/filterModel';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { dealerTimingModel } from '../models/dealerTimingModel';
import { locationModel } from '../models/locationModel';
import { advanceFilterModel } from '../models/advanceFilterModel';
import { BsModalService, BsModalRef } from '../../../node_modules/ngx-bootstrap';
import { ErrorHandlerService } from '../services/error-handler.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { languagePhrasesModel } from '../models/languagePhrasesModel';
import { AppInsightService } from '../services/AppInsight.service';
import { dealerServiceTypeModel } from '../models/dealerServiceTypeModel';
import { V } from '@angular/core/src/render3';


@Component({
  selector: 'app-dealerlist',
  templateUrl: './dealerlist.component.html',
  styleUrls: ['./dealerlist.component.css'],
  providers: [DealerService, GeocodeService]
})


export class DealerlistComponent implements OnInit {

  @Input() AllDealers;

  _selectedDealer: any = {};
  dealerArray: any[] = [];
  dealerCompleteArray: any[] = [];
  serviceTypeMobile: boolean = false;
  serviceTypeAPU: boolean = false;
  serviceTypeTruckTrailer: boolean = false;
  serviceTypeDealerLevelPath: any;

  /** added by sandeep on Apr 6th, 2023 */
  serviceTypeEngineless: boolean = false;

  loc: locationModel;
  origin: locationModel;
  destination: locationModel;

  loading: boolean;
  loadData: boolean

  userLoclat: any;
  userLoclng: any;

  selectedRow: number = 0;
  message: filterModel;

  filterargs: filterModel;
  advSearchFilterArgs: advanceFilterModel;
  subscription: Subscription;
  subscriptionAdvSearchFilter: Subscription;

  checkStoreStatusObj: boolean = false;
  TodayTimingdata: any = {};
  TimingsObj: any[];
  finalTimingsObj: any[];

  filterObj: any = {};
  country: string[] = [];
  emailAlert: boolean = false;
  emailInputText: any;

  currentUrl: any;
  currentUrlDealerID: any;
  dealer: any;
  loadFullSite: boolean = true;
  subscriptionPhrases: Subscription;
  languagePhrases: languagePhrasesModel;

  filteredByCountry: dealerModel[];

  ///added by sandeep on dec 12, 2022
  filteredByRegion: dealerModel[];
  region: string[] = [];

  currentLocation: locationModel;
  dealerTimingModel: any;
  dealerTimingModelObj: any;

  KmMiles: any = "miles";
  isUS: boolean = false;

  serviceTypeDealerOfTheYear: any;
  mailText: any;
  hideClose: boolean;

  /** added by sandeep on May 18, 2023 */
  allTranslations: any;

  /** added by sandeep on Jun 14, 2023 */
  selectedDealerServiceTypes: dealerServiceTypeModel[];

  /** added by sandeep on Aug 16, 2023 */
  ranks: any = [
    {rank: 1, serviceType_uid : 6},
      {rank: 2, serviceType_uid : 2},
      {rank: 3, serviceType_uid : 9},
      {rank: 4, serviceType_uid : 5},
      {rank: 5, serviceType_uid : 10},
      {rank: 6, serviceType_uid : 4},
      {rank: 7, serviceType_uid : 3},
      {rank: 8, serviceType_uid : 7},
      {rank: 9, serviceType_uid : 8},
      {rank: 10, serviceType_uid : 1}
  ]

  @ViewChild('shareStoreDetailmodal') shareStoreDetailmodal: ElementRef;
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: "my-modal"
  };

  constructor(private _dealerService: DealerService,
    private geocodeService: GeocodeService,
    private ref: ChangeDetectorRef,
    private _dataService: DataServiceService,
    private spinnerService: Ng4LoadingSpinnerService,
    private modalService: BsModalService,
    //private errorHandlerService: ErrorHandlerService,
    private route: ActivatedRoute, private appInsightsService: AppInsightService,
    private _geocodeService: GeocodeService
  ) {
  }

  ngOnInit() {

    this.dealerCompleteArray = this.AllDealers;
    // console.log(this.dealerCompleteArray);
    this.getMessages();
    this.getPhrases();
    this.spinnerService.hide();
    
    //added by sandeep on dec 7, 2022
    localStorage.setItem('results', 'false');
    // console.log('dealerlist - ngoninit - false')

    /** added by sandeep on May 11, 2023 */
    this._dataService.getMapviewToggle().subscribe(obj => {
      if(obj.id != 0) {
        // console.log('dealerlist - ', obj);
        this.dealerArray.filter((dealer, index) => {
          // console.log(dealer.uid == obj.id, index);
          if(dealer.uid == obj.id) {
            this.selectDealer(dealer, index);
            return dealer;
          }
        });
      }
    });

    /** added by sandeep on May 18, 2023 */
    this.getAllTranslations();

    
  }

  getMessages() {
    /*
    * get all filters from contentPage component to show filtered dealers 
     */
    this.subscription = this._dataService.getMessage.subscribe(message => {
      // console.log('dealerlist - translation clicked')
      this.filterargs = message.text;
      if (this.dealerCompleteArray.length !== 0) {
        this.dealerCompleteArray.forEach(element => {
          if (element.dealerLatitude) {
            element.distance = "";
          }
        });
        /*
          * filter list to show filtered dealers 
        */
        this.filterDealerArray(this.dealerCompleteArray, this.filterargs);
      }
      //added for test
      // console.log(this.dealerArray)
    });

        /*
          * get advance filter items to filter dealers 
        */
    this.subscriptionAdvSearchFilter = this._dataService.getAdvanceSearchFilterMessage().subscribe(message => {
      this.advSearchFilterArgs = message.text;

      if (this.dealerCompleteArray.length !== 0) {
        /*
          * advance filter to show filtered dealers 
        */
        this.filterDealersAdvanceSearch(this.dealerCompleteArray, this.advSearchFilterArgs);
      }
    });
  }


  InitApp() {
    this._selectedDealer = new dealerModel();
    this._selectedDealer.dealerTimingModel = new dealerTimingModel();

    let dealer = this.dealerCompleteArray.find(x => x.uid == +this.currentUrlDealerID);

    if (dealer) {
      var dlrArray = [];
      dlrArray.push(dealer);
    
      /** added by sandeep on May 24, 2023 */
    if(this._geocodeService.getLocationCoordinates()) {
      var filter = new filterModel;
      filter.kmMiles = 'miles';
      filter.userLat = this._geocodeService.getLocationCoordinates().lat.toString();
      filter.userLong = this._geocodeService.getLocationCoordinates().lng.toString();
      this.currentLocation = new locationModel();
      this.currentLocation.lat = filter.userLat;
      this.currentLocation.lng = filter.userLong;
      var res = this.applyHaversine(dlrArray, filter.kmMiles);
      this.dealerArray.push(res[0]);
      this.selectDealer(this.dealerArray[0], 0);
      this.scrollToTop();
    }
    else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          /** added by sandeep sep 28, 2023 */
          this.setCoordinates(position.coords.latitude, position.coords.longitude);

          var filter = new filterModel;
          filter.kmMiles = 'miles';
          filter.userLat = position.coords.latitude.toString();
          filter.userLong = position.coords.longitude.toString();
          this.currentLocation = new locationModel();
          this.currentLocation.lat = filter.userLat;
          this.currentLocation.lng = filter.userLong;
          var res = this.applyHaversine(dlrArray, filter.kmMiles);
          this.dealerArray.push(res[0]);
          this.selectDealer(this.dealerArray[0], 0);
          this.scrollToTop();
        }, () => {
          // console.log(this.dealerArray.length,dlrArray)
          // console.log(this.dealerArray[0].uid == dlrArray[0].uid)
          // console.log(this.dealerArray.length, dlrArray.length, this.dealerArray, dlrArray)
          /** added by sandeep on July 18, 2023 */
          if(!(this.dealerArray.length > 0 && dlrArray.length > 0 && this.dealerArray[0].uid == dlrArray[0].uid))
          this.dealerArray.push(dlrArray[0]);
          this.selectDealer(this.dealerArray[0], 0);
          this.scrollToTop();
        });
      }
    }
    }
  }

  getCurrentUrlDealerID() {
    this.currentUrlDealerID = this.route.snapshot.queryParamMap.get('id');
    this.InitApp();
  }

  
  /*
  * select 1st dealer and init other components after the dealer is sorted 
   */
  selectDealer(dealer, index) {
    this._selectedDealer = dealer;
    if (this._selectedDealer) {
      this.appInsightsService.logEvent("Dealer Details", { 'Name': this._selectedDealer.name, 'City': this._selectedDealer.dealerAddressDetailModel.city, 'State': this._selectedDealer.dealerAddressDetailModel.state });
    }
    this.selectedRow = index;
    this.setImagesServiceType();
    this.setDealerLevelImage();
    this.checkStoreStatus();

    // console.log(this._selectedDealer)
  }

   /*
      * set dealer level images on dealer change
   */
  setDealerLevelImage() {
    this.serviceTypeDealerLevelPath = "";
    this.serviceTypeDealerOfTheYear = "";
    if (this._selectedDealer) {
      if (this._selectedDealer.dealerOfYear) {
        this.serviceTypeDealerOfTheYear = "assets/img/Dealer of the Year no date.png";
      }
      if (this._selectedDealer.dealerLevel === "Gold") {
        this.serviceTypeDealerLevelPath = "assets/img/Gold award no date.png";
      }
      else if (this._selectedDealer.dealerLevel === "Silver") {
        this.serviceTypeDealerLevelPath = "assets/img/Silver Award no date.png";
      }
      else if (this._selectedDealer.dealerLevel === "Platinum") {
        this.serviceTypeDealerLevelPath = "assets/img/Platinum Award no date.png";
      }
    }
  }

  /*
      * get direction on button click for selected dealer
   */
  getDirection() {
    let url;
    this.appInsightsService.logEvent("Get Directions", { 'Name': this._selectedDealer.name });
    if (this.filterargs) {
      if (this.filterargs.userLat && this.filterargs.userLong && this._selectedDealer.dealerLatitude && this._selectedDealer.dealerLongitude) {
        url = "https://www.google.com/maps/dir/'" + this.filterargs.userLat + "," + this.filterargs.userLong + "'/'"
          + this._selectedDealer.dealerLatitude + "," + this._selectedDealer.dealerLongitude + "'";
        this.showLocationInMap(url);

      }
      else if (this._selectedDealer.dealerLatitude && this._selectedDealer.dealerLongitude) {
        url = "https://www.google.com/maps/dir/''/'"
          + this._selectedDealer.dealerLatitude + "," + this._selectedDealer.dealerLongitude + "'";
        this.showLocationInMap(url);
      }
    }
    else if (this._selectedDealer.dealerLatitude && this._selectedDealer.dealerLongitude) {
      url = "https://www.google.com/maps/dir/''/'"
        + this._selectedDealer.dealerLatitude + "," + this._selectedDealer.dealerLongitude + "'";
      this.showLocationInMap(url);
    }
  }

  /*
      * to show location on map
   */
  showLocationInMap(url: any) {
    var win = window.open(url, '_blank');
    win.focus();
  }

     // getFiltersFromContent() {
  //   this.subscription = this._dataService.getMessage.subscribe(message => {
  //     this.filterargs = message.text;
  //     if (this.dealerCompleteArray.length !== 0) {
  //       this.dealerCompleteArray.forEach(element => {
  //         if (element.dealerLatitude) {
  //           element.distance = "";
  //         }
  //       });
  //       this.filterDealerArray(this.dealerCompleteArray, this.filterargs);
  //     }
  //     else {
  //       this.spinnerService.show();
  //       this._dealerService.getAllDealers().subscribe((data: any) => {
  //         this.dealerCompleteArray = data.dealers;
  //         if (this.dealerCompleteArray) {
  //           this.filterDealerArray(this.dealerCompleteArray, this.filterargs);
  //         }
  //         this.spinnerService.hide();
  //       },
  //         err => alert("Error Code: " + err.status));
  //     }
  //   });
  // }

   /*
      * scroll to 1st seleted dealer
   */
  scrollToTop() {
    const ele = document.getElementById('list-scroll');
    if (ele) ele.scroll(0, 0);
  }

   /*
      * filter dealer list with given filters
   */
  filterDealerArray(items: any, filter: filterModel) {
    if (filter && items.length > 0) {

      // changed by sandeep on dec 12, 2022 
      // this.country = this._dataService.getSelectedCountries(filter.region);
      // this.filteredByCountry = this._dataService.filterCountryData(items, this.country);
      this.region = this._dataService.getSelectedTrueRegion(filter.region);
      this.filteredByRegion = this._dataService.filterTrueRegionData(items, this.region);

      if (filter.inputText) {
        var location = filter.inputText.trim();
        this.currentLocation = new locationModel();
        this.filterObj = filter;
        this.addressToCoordinates(location);
      }
      else {
        this.currentLocation = new locationModel();
        var result = [];
        if (filter.userLat && filter.userLong) {
          this.currentLocation.lat = filter.userLat;
          this.currentLocation.lng = filter.userLong;

          // changed by sandeep on dec 12, 2022
          // result = this.applyHaversine(this.filteredByCountry, filter.kmMiles).sort(this.compare);
          result = this.applyHaversine(this.filteredByRegion, filter.kmMiles).sort(this.compare);
          
          if (filter.distance) {
            result = this.filterByDistance(result, filter.distance);
            if (result)
              this.selectDealer(result[0], 0);
            this.scrollToTop();

          }
        }
        else {
          // changed by sandeep on dec 12, 2022
          // result = this.filteredByCountry;
          result = this.filteredByRegion;

          result = this.filterBracketsPhoneMobileFax(result);
        }

        if (filter.dealerServices && filter.dealerServices.length > 0) {
          result = this.filterByServices(result, filter.dealerServices);
          if (result)
            this.selectDealer(result[0], 0);
          this.scrollToTop();

        }

        if (result) {
          this.selectDealer(result[0], 0);
          this.dealerArray = [];

          this.dealerArray.push(...result);
          this.scrollToTop();
        }
      }
    }
    else {
      this.dealerArray = [];
    }
  }

   /*
      * remove brackets from phone mobile fax
   */
  filterBracketsPhoneMobileFax(list: any) {
    var result = [];
    list.forEach(element => {
      element.phone = this.replaceBrackets(element.phone)
      element.mobile = this.replaceBrackets(element.mobile);
      element.fax = this.replaceBrackets(element.fax);
      result.push(element);
    });
    return result;
  }

  replaceBrackets(str: any): any {
    var i = 0, strLength = str.length;
    for (i; i < strLength; i++) {
      str = str.replace("()", "");
    }
    return str;
  }

  /*
      * filter dealer list with given advance filters
   */
  filterDealersAdvanceSearch(items: dealerModel[], filter: advanceFilterModel) {
    if (items.length > 0 && filter) {

      var result = [];
      if (filter.postalCode && filter.postalCode.length > 0) {
        this.dealerArray = items.filter(a => {
          if (a.dealerAddressDetailModel)
            return a.dealerAddressDetailModel.postalCode === filter.postalCode;
        });

        if (this.dealerArray)
          this.selectDealer(this.dealerArray[0], 0);
        this.scrollToTop();

      }
      else {
        if(filter.trueRegion && (filter.region == "All Countries")) {
          this.region = this._dataService.getSelectedTrueRegion(filter.trueRegion);
          items = this._dataService.filterTrueRegionData(items, this.region);

        }
        else {
          this.country = this._dataService.getSelectedCountries(filter.region);
          items = this._dataService.filterCountryData(items, this.country);
        }

        if (filter.selectedStates && filter.selectedStates.length > 0) {
          items.filter(a => {
            if (a.dealerAddressDetailModel) {
              filter.selectedStates.forEach(element => {
                // console.log('state - ' ,element)
                /** modified by sandeep on July 7, 2023 */
                // if (element.name.toLowerCase() == a.dealerAddressDetailModel.state.toLowerCase()) {
                if(a.dealerAddressDetailModel.stateCode != null) {
                  if (element.id.toLowerCase() == a.dealerAddressDetailModel.stateCode.toLowerCase()) {
                    result.push(a);
                  }
                }
              });
            }
          });
        }
        else {
          result = items;
          result = this.filterBracketsPhoneMobileFax(result);
        }
        if (filter.selectedCities && filter.selectedCities.length > 0) {
          var cityResult = [];
          result.filter(a => {
            if (a.dealerAddressDetailModel) {
              filter.selectedCities.forEach(element => {
                // console.log('city - ',element)
                /** modified by sandeep on May 23, 2023 */
                // console.log(typeof element == 'object')
                /*if ((typeof element == 'object') && ( element.name.toLowerCase() == a.dealerAddressDetailModel.city.toLowerCase())) {
                  cityResult.push(a);
                }
                else if ((typeof element == 'string') && (element.toLowerCase() == a.dealerAddressDetailModel.city.toLowerCase())) {
                  cityResult.push(a);
                } */
                if ((typeof element == 'object') && ( element.id.trim().toLowerCase() == a.dealerAddressDetailModel.city.trim().toLowerCase())) {
                  cityResult.push(a);
                }
                else if ((typeof element == 'string') && (element.trim().toLowerCase() == a.dealerAddressDetailModel.city.trim().toLowerCase())) {
                  cityResult.push(a);
                }
              });
            }
          });
          result = cityResult;
        }

        if (filter.dealerServices && filter.dealerServices.length > 0) {
          result = this.filterByServices(result, filter.dealerServices);
        }
        if (filter.distance) {
          result = this.filterByDistance(result, filter.distance);
        }


        if (result.length > 0) {

          /** added by sandeep on July 18, 2023 */
          // var res = this.applyHaversine(result, filter.kmMiles);
          if(filter.kmMiles)
            var res = this.applyHaversine(result, filter.kmMiles);
          else 
            var res = this.applyHaversine(result, this.languagePhrases.KmorMiles);

          if (res) {
            result = res.sort(this.compare);
          }
          this.dealerArray = Array.from(new Set(result));
          if (this.dealerArray)
            this.selectDealer(this.dealerArray[0], 0);
          this.scrollToTop();
        }
        else
          this.dealerArray = [];
      }
    }
  }

  /*
      * set images of the service types
   */
  setImagesServiceType() {
    // console.log('test')
    // console.log(this._selectedDealer)
    if (this._selectedDealer) {
      // console.log('test2')
      // this.serviceTypeMobile = false;
      // this.serviceTypeTruckTrailer = false;
      // this.serviceTypeAPU = false;

      /** added by sandeep on Mar 02, 2023 */
      // this.serviceTypeEngineless = false;

      let dealerServiceTypes = this._selectedDealer.dealerServiceTypeModel;
      this.selectedDealerServiceTypes = dealerServiceTypes;
      if (dealerServiceTypes) {

      /** modified by sandeep on Aug 16, 2023 */
        // this.selectedDealerServiceTypes = [];
        //  for (let ServiceType of dealerServiceTypes) 
        // {
        //   // if (ServiceType.serviceType_uid == "1") {
        //   //   this.serviceTypeMobile = true;
        //   // }
        //   // else if (ServiceType.serviceType_uid == "2") {
        //   //   this.serviceTypeTruckTrailer = true;
        //   // }
        //   // else if (ServiceType.serviceType_uid == "3") {
        //   //   this.serviceTypeAPU = true;
        //   // }

        //   // else if(ServiceType.serviceType_uid == "4") {
        //   //   this.serviceTypeEngineless = true;
        //   // }

        //   /** added by sandeep on Jun 14, 2023 */
        //   if (ServiceType.serviceType_uid == "6") {
        //       this.selectedDealerServiceTypes.push(ServiceType);
        //   } else if (ServiceType.serviceType_uid == "2") {
        //       this.selectedDealerServiceTypes.push(ServiceType);
        //   } 
          
        //   /** added by sandeep on July 4, 2023 */
        //   else if (ServiceType.serviceType_uid == "9") {
        //     this.selectedDealerServiceTypes.push(ServiceType);
        //   }
          
        //   else if (ServiceType.serviceType_uid == "5") {
        //       this.selectedDealerServiceTypes.push(ServiceType);
        //   } 
          
        //   /** added by sandeep on July 4, 2023 */
        //   else if (ServiceType.serviceType_uid == "10") {
        //     this.selectedDealerServiceTypes.push(ServiceType);
        //   }
          
        //   else if (ServiceType.serviceType_uid == "4") {
        //       this.selectedDealerServiceTypes.push(ServiceType);
        //   } else if (ServiceType.serviceType_uid == "3") {
        //       this.selectedDealerServiceTypes.push(ServiceType);
        //   } else if (ServiceType.serviceType_uid == "7") {
        //       this.selectedDealerServiceTypes.push(ServiceType);
        //   } else if (ServiceType.serviceType_uid == "8") {
        //       this.selectedDealerServiceTypes.push(ServiceType);
        //   } else if (ServiceType.serviceType_uid == "1") {
        //       this.selectedDealerServiceTypes.push(ServiceType);
        //   }

        // } 
        this.selectedDealerServiceTypes.sort((a, b) => {
          return this.ranks.find(_ => _.serviceType_uid === a.serviceType_uid).rank - this.ranks.find(_ => _.serviceType_uid === b.serviceType_uid).rank
        })

        // console.log('init - ', dealerServiceTypes);
        // console.log(this._selectedDealer)
      }
      
      // console.log('-',this.selectedDealerServiceTypes);
    }
  }

  /*
      * to redirect to url of seleted dealer on website link
   */
  goToSpecificUrl(): void {
    var win = window.open(this._selectedDealer.webUrl, '_blank');
    win.focus();
  }

  /*
      * check store status if open or close
   */
  checkStoreStatus() {
    this.finalTimingsObj = [];
    this.TodayTimingdata = "";

    if (this._selectedDealer)
      if (this._selectedDealer.dealerTimingModel) {

        this.dealerTimingModel = new dealerTimingModel;
        this.dealerTimingModelObj = new dealerTimingModel;
        this.dealerTimingModel = JSON.parse(JSON.stringify(this._selectedDealer.dealerTimingModel));
        var resp = this._dataService.dealerTiming(this.dealerTimingModel);

        resp.forEach(element => {
          var re = /Monday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Monday);
          re = /Tuesday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Tuesday);
          re = /Wednesday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Wednesday);
          re = /Thursday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Thursday);
          re = /Friday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Friday);
          re = /Saturday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Saturday);
          re = /Sunday/gi;
          element.openingDay = element.openingDay.replace(re, this.languagePhrases.Sunday);
          re = /Closed/gi;
          element.startTime = element.startTime.replace(re, this.languagePhrases.Closed);
        });

        this.finalTimingsObj = resp;
      }
    if (this._selectedDealer) {
      if (this._selectedDealer.dealerLatitude && this._selectedDealer.dealerLongitude) {
        this.calcStoreTime(this._selectedDealer.dealerLatitude, this._selectedDealer.dealerLongitude);
      }
    }
    else {
      this.checkStoreStatusObj = false;
    }

  }

  /*
      * to show dealer timings
   */
  calcStoreTime(lat, long) {
    this.hideClose = false;
    if (lat && long && this._selectedDealer.dealerTimingModel) {
      this.spinnerService.show();
      this._dealerService.getDealerOpenClose(this._selectedDealer.uid).subscribe(res => {
        this.checkStoreStatusObj = res.open;

        if (res.open == true) {
          this.dealerTimingModelObj = JSON.parse(JSON.stringify(this._selectedDealer.dealerTimingModel));
          
          /** modified by sandeep on July 12, 2023 */
          // this.TodayTimingdata = this.dealerTimingModelObj.filter(a => a.day === res.day);
          if(this.dealerTimingModelObj)
            this.TodayTimingdata = this.dealerTimingModelObj.filter(a => a.day === res.day);


          if (this.TodayTimingdata[0]) {
            var open = this.addZero(this.TodayTimingdata[0].startTime);//.format('H:mm');
            var close = this.addZero(this.TodayTimingdata[0].endTime);//.format('H:mm');

            var openAMPM = this._dataService.converTime(this.TodayTimingdata[0].startTime);
            var cloesAMPM = this._dataService.converTime(this.TodayTimingdata[0].endTime);
            this.TodayTimingdata[0].startTime = '    ' + this.TodayTimingdata[0].openingDay + ' ' + openAMPM + ' to ' + cloesAMPM;


            var re = /Monday/gi;
            this.TodayTimingdata[0].startTime = this.TodayTimingdata[0].startTime.replace(re, this.languagePhrases.Monday);
            re = /Tuesday/gi;
            this.TodayTimingdata[0].startTime = this.TodayTimingdata[0].startTime.replace(re, this.languagePhrases.Tuesday);
            re = /Wednesday/gi;
            this.TodayTimingdata[0].startTime = this.TodayTimingdata[0].startTime.replace(re, this.languagePhrases.Wednesday);
            re = /Thursday/gi;
            this.TodayTimingdata[0].startTime = this.TodayTimingdata[0].startTime.replace(re, this.languagePhrases.Thursday);
            re = /Friday/gi;
            this.TodayTimingdata[0].startTime = this.TodayTimingdata[0].startTime.replace(re, this.languagePhrases.Friday);
            re = /Saturday/gi;
            this.TodayTimingdata[0].startTime = this.TodayTimingdata[0].startTime.replace(re, this.languagePhrases.Saturday);
            re = /Sunday/gi;
            this.TodayTimingdata[0].startTime = this.TodayTimingdata[0].startTime.replace(re, this.languagePhrases.Sunday);
            re = /Closed/gi;
            this.TodayTimingdata[0].startTime = this.TodayTimingdata[0].startTime.replace(re, this.languagePhrases.Closed);


          }
        }
      },
        error => {
          console.log(error); this.hideClose = true;
          this.spinnerService.hide();
        });
      this.spinnerService.hide();
    } else {
      this.checkStoreStatusObj = false;
    }
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  /*
      * filter dealers array with distance given
   */
  filterByDistance(items: any, distance: any) {
    switch (+distance) {
      case 1: {
        return items;
        break;
      }
      case 2: {
        return items.filter(a => a.distance <= 50);
        break;
      }
      case 3: {
        return items.filter(a => a.distance <= 100);
        break;
      }
      case 4: {
        return items.filter(a => a.distance <= 150);
        break;
      }
      case 5: {
        return items.filter(a => a.distance <= 200);
        break;
      }
      case 6: {
        return items.filter(a => a.distance <= 250);
        break;
      }
      default: {
        return items;
      }
    }
  }

  /*
      * filter dealer list by service types checked
   */
  filterByServices(dealers: dealerModel[], services: any) {
    if (services) {
      var result = [];
      dealers.forEach((item) => {
        if (item.dealerServiceTypeModel) {
          item.dealerServiceTypeModel.forEach(dealerService => {
            for (var service of services) {
              if (service.id === dealerService.serviceType_uid) {
                result.push(item);
              }
            }
          });
        }
      })
      return result = Array.from(new Set(result));
    }
    else { return dealers; }
  }

  /*
      * convert the typed address of input box into the coordinates
   */
  addressToCoordinates(address) {
    this.geocodeService.geocodeAddress(address)
      .toPromise().then(
        location => {
          this.loc = location;
          this._dataService.hideDistanceServiceType = false;
          this.assignLatLng();
          this.ref.detectChanges();
        }
      );
  }

  /*
      * assign the lat lngs to filters as fetched from address to coordinates
   */
  assignLatLng() {
    if (this.loc.lat && this.loc.lng) {

      this.filterObj.userLat = this.currentLocation.lat = this.loc.lat;
      this.filterObj.userLong = this.currentLocation.lng = this.loc.lng;

      var result = [];
      // changed by sandeep on dec 12, 2022
      // result = this.applyHaversine(this.filteredByCountry, this.filterObj.kmMiles).sort(this.compare);
      result = this.applyHaversine(this.filteredByRegion, this.filterObj.kmMiles).sort(this.compare);

      if (this.filterObj.distance) {
        result = this.filterByDistance(result, this.filterObj.distance);

      }
      if (this.filterObj.dealerServices && this.filterObj.dealerServices.length > 0) {
        result = this.filterByServices(result, this.filterObj.dealerServices);

      }
      if (result) {
        this.selectDealer(result[0], 0);
        this.dealerArray = result;
        this.scrollToTop();

      }

    }
    else {
      /** removed for test */
      alert("Location Not Found");
      this.dealerArray = [];

      /** added for test  --- need to add distance filter, region filter & service filter */
      // this.dealerArray = this.dealerCompleteArray.filter(dealer => {
      //   return (dealer.name.toLowerCase()).includes(this.filterObj.inputText.toLowerCase());
      // });
      
      // this.selectDealer(this.dealerArray[0], 0);
      // this.scrollToTop();
      // console.log('search - ', this.dealerArray);
    }
  }

/*
      * calculate distance formula
   */
  applyHaversine(locations, kmMiles) {
    if (this.currentLocation) {
      if (this.currentLocation.lat && this.currentLocation.lng) {
        locations.map((location) => {
          location.phone = this.replaceBrackets(location.phone);
          location.mobile = this.replaceBrackets(location.mobile);
          location.fax = this.replaceBrackets(location.fax);
          let placeLocation = {
            lat: location.dealerLatitude,
            lng: location.dealerLongitude
          };
          if (kmMiles == 'miles') {
            this.isUS = true;
            if (placeLocation.lat !== 0 && placeLocation.lng !== 0) {
              location.distance = this.getDistanceBetweenPoints(
                this.currentLocation.lat, this.currentLocation.lng,
                placeLocation,
                'miles'
              ).toFixed(1);
            }
          }
          else {
            {
              this.isUS = false;
              if (placeLocation.lat !== 0 && placeLocation.lng !== 0) {
                location.distance = this.getDistanceBetweenPoints(
                  this.currentLocation.lat, this.currentLocation.lng,
                  placeLocation,
                  'K'
                ).toFixed(1);
              }
            }
          }
        });

        return locations;
      }
    }
  }

  /*
      * calculate distance between points
   */
  getDistanceBetweenPoints(lat1, lon1, end, unit) {
    var lat2 = end.lat;
    var lon2 = end.lng;
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    if (unit == "miles") { d = d / 1.6; }
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

   /*
      * compare distance for two dealers
   */
  compare(a, b) {
    if (Number(a.distance) < Number(b.distance))
      return -1;
    if (Number(a.distance) > Number(b.distance))
      return 1;
    return 0;
  }

 /*
      * open share store details model on button click
   */
  openShareStoreDetailmodal() {
    this.modalRef = this.modalService.show(this.shareStoreDetailmodal
      , this.config);
    this.emailAlert = false;
  }

   /*
      * send email on share store details
   */
  sendEmail(email: any, emailText: any) {
    this.appInsightsService.logEvent("Share details",
      { 'Name': this._selectedDealer.name });
    var customText = emailText;

    var link = environment.webBaseUrl + 'dealer?id=' + this._selectedDealer.uid; //environment.webBaseUrl + 'dealer/' + this._selectedDealer.uid;

    var body = link + '\r\n' + customText;
    body = encodeURIComponent(body);

    var name = encodeURIComponent(this._selectedDealer.name);

    var subject = "Carrier Transicold Dealer - " + name;
    this.mailText = "mailto:" + email + "?subject=" + subject + "&body=" + body
    window.location.href = this.mailText;
    this.modalRef.hide();
  }

   /*
      * get phrases for multi language
   */
  getPhrases() {
    this.subscriptionPhrases = this._dataService.getPhrases().subscribe(message => {
      this.languagePhrases = message.text;
      this.getCurrentUrlDealerID();
      // console.log(message)
      // console.log(this._selectedDealer)
    });
    // console.log('dealerlist - getphrases')
  }
  /*
      * open website on button click
   */
  openWebsite(webUrl: any) {
    let url: string = '';
    if (!/^http[s]?:\/\//.test(webUrl)) {
      url += 'http://';
    }
    url += webUrl;
    window.open(url, '_blank');
  }

  /** added by sandeep on May 18, 2023 */
  getAllTranslations() {
    this.spinnerService.show();
    this._dataService.getAllTranslations().subscribe(translations => {
      this.spinnerService.show();
      this.allTranslations = translations;
      //added for test
      // console.log("translation clicked");
      // console.log('dealers -',this.dealerArray)
      if(this.dealerArray.length > 0) {
        // console.log("select dealer called");
        this.selectDealer(this.dealerArray[0], 0);
      }
      
      this.spinnerService.hide();
    }, error => {
      console.log(error);
      this.spinnerService.hide();
    });
  }

  /** added by sandeep on sep 28, 2023 */
  setCoordinates(lat: number, long: number) {
    this._geocodeService.setLocationCoordinates(lat, long);
    // console.log('called');
  }

}













