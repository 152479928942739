import { Component, OnInit, NgZone, AfterContentInit } from '@angular/core';
import { serviceTypeModel } from '../models/serviceTypeModel';
import { statesModel } from '../models/statesModel';
import { citiesModel } from '../models/citiesModel';
import { ServiceTypeService } from '../services/service-type.service';
import { DealerService } from '../services/dealer.service';
import { AllRegionsService } from '../services/all-regions.service';
import { allRegionsModel } from '../models/allRegionsModel';
import { filterModel } from '../models/filterModel';
import { DataServiceService } from '../services/data-service.service';
import { advanceFilterModel } from '../models/advanceFilterModel';
import { languagePhrasesModel } from '../models/languagePhrasesModel';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AppInsightService } from '../services/AppInsight.service';
import { GeocodeService } from '../services/geocode.service';

@Component({
  selector: 'app-contentpage',
  templateUrl: './contentpage.component.html',
  styleUrls: ['./contentpage.component.css'],
  providers: [ServiceTypeService, AllRegionsService]
})

export class ContentpageComponent implements OnInit {
  toggleDropdown: boolean = false;
  toggleFilters: boolean = false;
  serviceType: serviceTypeModel[];

  allcitystatecountry: any[];
  filteredStates: statesModel[] = [];
  filterdCities: citiesModel[] = [];
  allcities: any[];

  flagAdvanceSearch: boolean = true;
  isAdvFilter: boolean = true;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  /** added by sandeep on dec 14, 2022 */
  selectedCountries: any[] = [];
  
  selectedFilterStatesIds: statesModel[] = [];
  selectedFilterCitiesIds: any[] = [];
  selectedServiceIds: any[] = [];

  filteredCountryDetails: any[] = [];
  public addrKeys: string[];
  public addr: object;

  _allRegionsList: allRegionsModel[];

  searchText: any;
  inputText: any;
  userLoclat: any;
  userLoclng: any;

  dataFilter: filterModel;
  advanceDataFilterObj: advanceFilterModel;
  advanceDataFilter: advanceFilterModel;

  advSearchCountry: any; //[] = [];
  advSearchPostalCode: any;
  advSearchDistance: any;

  selectedRegion: any;

  // added by sandeep on dec 12, 2022
  selectedTrueRegion: any;

  myRadio: any = "miles";

  // myRadio: any = "km";
  languagePhrases: languagePhrasesModel;
  subscriptionPhrases: Subscription;

  hideAdvanceSearch: boolean = false;
  hideCountry: boolean = false;

  /// added by sandeep on 05-11-2022
  _allRegionsList2: any;
  subCountryList: allRegionsModel[];

  /** added by sandeep on May 17, 2023 */
  allTranslations: any;

  /** added by sandeep on July 19, 2023 */
  isMobileView: boolean = false;
  watchMedia: any;

  //hideDistanceServiceType: boolean = false;
  constructor(private serviceTypeService: ServiceTypeService,
    private _dealerService: DealerService,
    private _statescitiesService: AllRegionsService,
    public _dataService: DataServiceService,
    private zone: NgZone,
    private route: ActivatedRoute, private appInsightsService: AppInsightService,
    private _geocodeService: GeocodeService

  ) {

    
    /*
     * get all countries to display in dropdown
     */
    /// commented on 13-12-2022 by sandeep
    // this._allRegionsList = _dealerService.getAllRegions();
    
    //added 30-06-2022 by sandeep
    // this._allRegionsList.sort((a, b) => {return (a.name < b.name ? -1 : 1)});
  }

  ngOnInit() {

    // localStorage.setItem('results', 'false');
    // console.log('contentpage - ngoninit - false');

    this._dataService.hideDistanceServiceType = !this._dataService.hideDistanceServiceType;
    let currentUrlDealerID = this.route.snapshot.queryParamMap.get('id');

    /** added by sandeep on May 24, 2023 */
    if(this._geocodeService.getLocationCoordinates()) {
      this.userLoclat = this._geocodeService.getLocationCoordinates().lat;
      this.userLoclng = this._geocodeService.getLocationCoordinates().lng;

        /*
          * if no DealerId in Url then call function onSerchClick
      */
        if (!currentUrlDealerID) {
          this.onSerchClick("", 1, 1, "", this.userLoclat, this.userLoclng);
        }
        this._dataService.hideDistanceServiceType = !this._dataService.hideDistanceServiceType;
      
    }
    else {

      /*
        *get current location lat lng
        */
      navigator.geolocation.getCurrentPosition((position) => {
        this.userLoclat = position.coords.latitude;
        this.userLoclng = position.coords.longitude;

        /** added by sandeep on sep 28, 2023 */
        this.setCoordinates(position.coords.latitude, position.coords.longitude);

        /*
          * if no DealerId in Url then call function onSerchClick
      */
        if (!currentUrlDealerID) {
          this.onSerchClick("", 1, 1, "", this.userLoclat, this.userLoclng);
        }
        this._dataService.hideDistanceServiceType = !this._dataService.hideDistanceServiceType;
      });
    }


    //Multi Drowdown Settings
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      enableSearchFilter: true,
    };

    this.advSearchDistance = 1;

    this.getAllCountryCityState();
    this.getPhrases();

    /** added by sandeep on May 17, 2023 */
    this.getAllTranslations();

    /** removed by sandeep on May 18, 2023 */
    // this.serviceType = this.serviceTypeService.getAllServices();

    /// added 13-12-2022 by sandeep
    this._statescitiesService.getAllCountriesList().subscribe((data: any) => {
      this._allRegionsList = data.countries;
      // console.log(this._allRegionsList)
      
      /** modified by sandeep on May 18, 2023 */
    // this.subCountryList = this._allRegionsList;
      this.subCountryList = this._allRegionsList.sort((c1, c2) => {
        if(c1.name > c2.name) return 1;
        if(c1.name < c2.name) return -1;
        return 0;
      });
    // console.log(this.subCountryList);
    }, err => console.log(err));
    

    // added by sandeep on nov 22, 2022
    // this.myRadio = "miles";

    ///added 12-12-2022 by sandeep
    this._statescitiesService.getAllOnlyRegionsList().subscribe((data: any) => {
      this._allRegionsList2 = data.regions;
    }, err => console.log(err));

    /** added by sandeep on dec 13, 2022 */
    this.selectedTrueRegion = "All Regions";
    
    /** added by sandeep on May 10, 2023 */
    this._dataService.getMapviewToggle().subscribe(obj => {
      if(obj.id != 0) {
        this.flagAdvanceSearch = true;
        this.toggleFilter('advSearch');
        // console.log('contentpage - ', obj);
      }
    })

    /** test */
    // setTimeout(() =>
    // {
    //   this.watchMedia = window.matchMedia("(max-width: 930px)");
    //   this.watchMediaChange(this.watchMedia);
    //   this.watchMedia.addEventListener("change", this.watchMediaChange);
    // }
    // , 1000);

  }

  /** test */
  // watchMediaChange(media) {
  //   // console.log(media)
  //     let dropdowns = document.getElementsByClassName("dropdowns");
  //     let filter_more = document.getElementsByClassName("filter-more");
  //   if(media.matches) {
  //     // console.log(document.getElementsByClassName("dropdowns")[0].classList.contains("dis-flex"));
  //     // console.log(document.getElementsByClassName("filter-more")[0].classList);
  //     this.isMobileView = true;
  //     // console.log(this.isMobileView);
      
  //     /// if dropdowns class doesnot contains "hide" then display block
  //     if(dropdowns[0].classList.contains("dis-flex")) {
  //       // console.log('dropdowns - remove flex1')
  //       dropdowns[0].classList.remove("dis-flex");
  //       // dropdowns[0].classList.add("show");
  //     }
  //     if(filter_more[0].classList.contains("dis-flex")) {
  //       // console.log('filter_more - remove flex2')
  //       filter_more[0].classList.remove("dis-flex");
  //       // filter_more[0].classList.add("show");
  //     }
  //     // if(this.flagAdvanceSearch && dropdowns[0].classList.contains("hide")) {

  //     // }
  //     // else {
  //     //   dropdowns[0].classList.remove("dis-flex");
  //     //   dropdowns[0].classList.add("show");
  //     // }

  //     // if((this.isAdvFilter || this.flagAdvanceSearch) && filter_more[0].classList.contains("hide")) {

  //     // }
  //     // else {
  //     //   filter_more[0].classList.remove("dis-flex");
  //     //   filter_more[0].classList.add("show");
  //     // }

  //   }
  //   else {
  //     this.isMobileView = false;
  //     // console.log(this.isMobileView);
  //     if(dropdowns[0].classList.contains("show")) {
  //       console.log('dropdowns - remove show, add flex')

  //       dropdowns[0].classList.add("dis-flex");
  //       dropdowns[0].classList.remove("show");
  //     }
  //     if(filter_more[0].classList.contains("show")) {
  //       console.log('filter_more - remove show, add flex')
  //       filter_more[0].classList.add("dis-flex");
  //       filter_more[0].classList.remove("show");
  //     }
  //     // if(dropdowns[0].classList.contains("show")) {
  //     //   dropdowns[0].classList.remove("show");

  //     // }
  //     // else {
  //     // }
  //     // if(filter_more[0].classList.contains("show")) {
  //     //   filter_more[0].classList.remove("show");

  //     // }
  //     // else {
  //     // }
  //   }
  // }

  

  test() {
    return true;
  }

  getAllCountryCityState() {
    /*
       * Get all city states and countries data
    */
    /** modifed by sandeep on Jun 26, 2023 */
    // this._statescitiesService.getAllRegions().subscribe((data: any) => {
    this._statescitiesService.getAllCountriesList().subscribe((data: any) => {
      this.allcitystatecountry = data.countries;
    }, err => console.log(err));
  }

  /*
      * On country dropdown change do filter data with countries
  */
  /** commented by sandeep on dec 13, 2022 */
  onDropdownChange(country: any) {
    // console.log('contentpage - ondropdownchange() - ', country)
    this.filteredCountryDetails = [];

    /** changed by sandeep on dec 13, 2022 */
    this.advSearchCountry = country;
    // this.advSearchCountry.push(country);

    const selectedCountry = this._dataService.getSelectedCountries(this.selectedRegion);

    /*
      * Filter data by country
  */
    this.filteredCountryDetails = this._dataService.filterCountry(this.allcitystatecountry, selectedCountry);

    this.filteredStates = [];
    this.filterdCities = [];

    this.selectedFilterStatesIds = [];
    this.selectedFilterCitiesIds = [];

    /*
     * Filter data by country and states
    */
    if (this.filteredCountryDetails) {
      for (var i = 0; i < this.filteredCountryDetails.length; i++) {
        this.filteredCountryDetails[i].stateList.forEach(element => {
          /** modified by sandeep on May 17, 2023 */
          // this.filteredStates.push(new statesModel(element.code, element.name));
          // if(this._dataService.getTranslatedName(this.allTranslations, element.name) == "No Trans")
          //   console.log(element.name)
          this.filteredStates.push(new statesModel(element.code, this._dataService.getTranslatedName(this.allTranslations, element.name)));
        });
        if (this.filteredStates) {
          var states = this.filteredStates.sort((a, b) => a.name.localeCompare(b.name));
          this.filteredStates = Array.from(new Set(states))
        }
      }
    }

    /** added by sandeep on Mar 31, 2023 */
    // this.getFilteredCities();

    if (!this.flagAdvanceSearch) {
      this.selectRegionData();

      /** added by sandeep on Jul 7, 2023 */
      this.onItemDeSelectAllState(null);
    }
  }

  /** added by sandeep on dec 14, 2022 */
  onTrueRegionSelect(region: any) {
    // console.log('contentpagecomponent - ',region);
    if(region == "All Regions") {
      this.subCountryList = this._allRegionsList;
    }
    else {
      this.subCountryList = this._allRegionsList.filter(reg => {
        return (reg.region == region);
      });
    }
    // console.log(this._allRegionsList);
    if (!this.flagAdvanceSearch) {
      this.selectRegionData();
    }


  }

  /** added by sandeep on dec 13, 2022 */
   // onCountrySelect(country: any) {
  //   console.log('contentpage - oncountryselect()')
  //   this.filteredCountryDetails = [];
  //   this.advSearchCountry.push(this.selectedCountries);

  //   const selectedCountry = this._dataService.getSelectedCountries(this.selectedCountries);

  //   /*
  //     * Filter data by country
  // */
  //   this.filteredCountryDetails = this._dataService.filterCountry(this.allcitystatecountry, selectedCountry);

  //   this.filteredStates = [];
  //   this.filterdCities = [];

  //   this.selectedFilterStatesIds = [];
  //   this.selectedFilterCitiesIds = [];

  //   /*
  //    * Filter data by country and states
  //   */
  //   if (this.filteredCountryDetails) {
  //     for (var i = 0; i < this.filteredCountryDetails.length; i++) {
  //       this.filteredCountryDetails[i].stateList.forEach(element => {
  //         this.filteredStates.push(new statesModel(element.code, element.name));
  //       });
  //       if (this.filteredStates) {
  //         var states = this.filteredStates.sort((a, b) => a.name.localeCompare(b.name));
  //         this.filteredStates = Array.from(new Set(states))
  //       }
  //     }
  //   }
  //   if (!this.flagAdvanceSearch) {
  //     this.selectRegionData();
  //   }
  // // }

  /*
     * Filter  states with respect to country 
     * added by sandeep on dec 14, 2022
    */
  // getFilteredStates() { //need to modify according to states
  //   this.filterdCities = [];
  //   this.selectedFilterCitiesIds = [];
  //   var result: any[] = [];

  //   if (this.selectedFilterStatesIds.length > 0) {
  //     for (var i = 0; i < this.filteredCountryDetails.length; i++) {
  //       var res = this._dataService.filterCities(this.filteredCountryDetails[i].cityList, this.selectedFilterStatesIds)

  //       if (res.length > 0) {
  //         result.push(res);
  //       }
  //     }
  //     for (var i = 0; i < result.length; i++) {
  //       if (result[i].length > 0) {
  //         result[i].forEach(element => {
  //           this.filterdCities.push(new citiesModel(element.code, element.name));
  //         });
  //         if (this.filterdCities) {
  //           var cities = this.filterdCities.sort((a, b) => a.name.localeCompare(b.name));
  //           this.filterdCities = Array.from(new Set(cities))
  //         }
  //       }
  //     }
  //   }
  //   else {
  //     this.filterdCities = [];
  //   }

  // }

  /*
     * Set advance search filters
    */
  selectRegionData() {
    // console.log('contentpage - selectRegionData');
    if (!this.advanceDataFilterObj) {
      this.advanceDataFilterObj = new advanceFilterModel();
    }

    /** added by sandeep on dec 15, 2022 */
    this.advanceDataFilterObj.trueRegion = this.selectedTrueRegion;
    
    this.advanceDataFilterObj.region = this.selectedRegion;
    this.sendAdvanceSearchMessage(this.advanceDataFilterObj);
  }

  onItemSelectServices(item: any) {
    this.searchWithSelectedServices();
  }
  onItemSelectAllServices(items: any) {
    this.selectedServiceIds = [];
    items.forEach(element => {
      this.selectedServiceIds.push(element)
    });
    
    this.searchWithSelectedServices();
  }
  onItemDeSelectService(item: any) {
    //this.selectedServiceIds.splice(this.selectedServiceIds.indexOf(item.id), 1);
   
    this.searchWithSelectedServices();
  }
  onItemDeSelectAllService(item: any) {
    this.selectedServiceIds = [];
   
    this.searchWithSelectedServices();
  }
    /*
     * Set advance search filter -- services
    */
  searchWithSelectedServices() {
    if (!this.flagAdvanceSearch) {
      if (!this.advanceDataFilterObj) {
        this.advanceDataFilterObj = new advanceFilterModel();
      }
      this.advanceDataFilterObj.dealerServices = this.selectedServiceIds;
      this.sendAdvanceSearchMessage(this.advanceDataFilterObj);
    }
    else {
      if (!this.dataFilter) {
        this.dataFilter = new filterModel();
      }
      this.dataFilter.dealerServices = this.selectedServiceIds;
      this.sendMessage(this.dataFilter);
    }
  }

  onItemSelectState(item: any) {
    this.getFilteredCities();
    this.createAdvanceFilterObject();
  }

  onItemDeSelectState(item: any) {
    this.getFilteredCities();
    this.createAdvanceFilterObject();
  }

  onItemDeSelectAllState(item: any) {
    this.selectedFilterStatesIds = [];
    this.getFilteredCities();
    this.createAdvanceFilterObject();
  }

  onSelectAllStates(items: any) {
    this.selectedFilterStatesIds = [];
    items.forEach(element => {
      var state = { id: element.id, name: element.name ,countryCode:element.countryCode};
      // var state = { id: element.id, name: element.name };
      this.selectedFilterStatesIds.push(state);
    });
    this.getFilteredCities();
    this.createAdvanceFilterObject();
  }
  onSelectAllCity(items: any) {
    items.forEach(element => {
      this.selectedFilterCitiesIds.push(element)
    });
    this.createAdvanceFilterObject();
  }
  onItemSelectCity(item: any) {
    this.selectedFilterCitiesIds.push(item);
    this.createAdvanceFilterObject();
  }
  onItemDeSelectCity(item: any) {
    var result = [];
    this.selectedFilterCitiesIds.forEach(element => {
      if (element !== item) {
        result.push(element);
      }
    });
    this.selectedFilterCitiesIds = result;
    this.createAdvanceFilterObject();
  }


  /*
     * Filter  cities with respect to country and states
    */
  getFilteredCities() {
    this.filterdCities = [];
    this.selectedFilterCitiesIds = [];
    var result: any[] = [];

    if (this.selectedFilterStatesIds.length > 0) {
      for (var i = 0; i < this.filteredCountryDetails.length; i++) {
        var res = this._dataService.filterCities(this.filteredCountryDetails[i].cityList, this.selectedFilterStatesIds)

        if (res.length > 0) {
          result.push(res);
        }
      }
      for (var i = 0; i < result.length; i++) {
        if (result[i].length > 0) {
          result[i].forEach(element => {
            /** modified by sandeep on May 17, 2023 */
            // this.filterdCities.push(new citiesModel(element.code, element.name));
            // if(this._dataService.getTranslatedName(this.allTranslations, element.name) == "No Trans")
            //   console.log(element.name)
            this.filterdCities.push(new citiesModel(element.code, this._dataService.getTranslatedName(this.allTranslations, element.name)));
          });
          if (this.filterdCities) {
            var cities = this.filterdCities.sort((a, b) => a.name.localeCompare(b.name));
            this.filterdCities = Array.from(new Set(cities))
          }
        }
      }
    }
    else {
      this.filterdCities = [];
    }

  }

  /*
     * setting complete object of advance filter
    */
  createAdvanceFilterObject() {
    this.advanceDataFilterObj = new advanceFilterModel();

    /** added by sandeep on dec 15, 2022 */
    this.advanceDataFilterObj.trueRegion = this.selectedTrueRegion;

    this.advanceDataFilterObj.region = this.advSearchCountry;
    this.advanceDataFilterObj.selectedStates = this.selectedFilterStatesIds;
    this.advanceDataFilterObj.selectedCities = this.selectedFilterCitiesIds;
    this.advanceDataFilterObj.postalCode = this.advSearchPostalCode;
    this.advanceDataFilterObj.distance = this.advSearchDistance;
    this.advanceDataFilterObj.dealerServices = this.selectedServiceIds;
    this.sendAdvanceSearchMessage(this.advanceDataFilterObj);
  }

  /*
     * Toggle Filters on search and advance Search
    */
  toggleFilter(type) {
    //added by sandeep on dec 7, 2022
    localStorage.setItem('results', 'true');
    // console.log('dealerlist - ngoninit - true')
    this.mapviewToggle(false, 0);

    if (type === 'Search') {
      this.hideCountry = false;
      this.isAdvFilter = !this.isAdvFilter;
      this.flagAdvanceSearch = !this.flagAdvanceSearch;

      this.advSearchDistance = 1;

      /** added by sandeep on dec 13, 2022 */
    this.selectedTrueRegion = "All Regions";

      this.selectedServiceIds = [];

      if (this.dataFilter) {
        this.selectedRegion = this.dataFilter.region;
        this.inputText = this.dataFilter.inputText;
        this.dataFilter.distance = "1";
        this.dataFilter.dealerServices = [];
      }

      this._dataService.hideDistanceServiceType = false;
      if (!(this.userLoclat && this.userLoclng && this.dataFilter.userLat && this.dataFilter.userLong)) {
        this._dataService.hideDistanceServiceType = true;
      }
      this.sendMessage(this.dataFilter);
    }
    else if (type === 'advSearch') {
      this.appInsightsService.logEvent("Complete List");
      this.hideCountry = true;
      this._dataService.hideDistanceServiceType = false;
      this.flagAdvanceSearch = !this.flagAdvanceSearch;
      this.isAdvFilter = false;
      this.advSearchDistance = 1;

      /** added by sandeep on dec 13, 2022 */
    this.selectedTrueRegion = "All Regions";

      this.selectedServiceIds = [];
      if (!this.flagAdvanceSearch) {
        this.setAdvSearchRegion();
      }
    }
    else if (type === 'noSearch') {
      this.isAdvFilter = !this.isAdvFilter;
    }

    /** test */
    // this.watchMediaChange(this.watchMedia);
  }
  /*
      * on advance search region change
   */
  setAdvSearchRegion() {
    // this.selectedRegion = this.languagePhrases.AllCountries; //"All Countries";
    this.selectedRegion = "All Countries";

    /** added by sandeep on dec 12, 2022 */
    this.selectedTrueRegion = "All Regions";
    // this.onCountrySelect(this.selectedCountries);

    this.onDropdownChange(this.selectedRegion);

  }

   /*
     * Function to set the filters for search button click
    */
  onSerchClick(searchText?, region?, distanceDropdown?, dealerServices?, userLoclat?, userLoclng?) {

    //added by sandeep on dec 7, 2022
    localStorage.setItem('results', 'true');
    // console.log('dealerlist - ngoninit - true')
    // console.log(this.advSearchDistance)

    if (searchText) {
      this.appInsightsService.logEvent("Search Location", { 'Search Location': searchText });
    }
    //this._dataService.hideDistanceServiceType=false;
    if (this.flagAdvanceSearch) {
      this.dataFilter = new filterModel();
      this.dataFilter.inputText = searchText;

      // changed by sandeep on dec 12, 2022
      // this.dataFilter.region = this.selectedRegion;
      this.dataFilter.region = this.selectedTrueRegion;
      
      /// changed by sandeep on jul 4, 2023
      // this.dataFilter.kmMiles = this.myRadio;
      this.dataFilter.kmMiles = this.myRadio = this.languagePhrases.KmorMiles;
       

      if (distanceDropdown > 1) {
        /** modified by sandeep on July 20, 2023 */
        // this.dataFilter.distance = distanceDropdown;
        this.dataFilter.distance = this.advSearchDistance;
      }
      this.dataFilter.dealerServices = dealerServices;

      if (userLoclat && userLoclng) {
        this.dataFilter.userLat = this.userLoclat;
        this.dataFilter.userLong = this.userLoclng;
        
      }
      this.sendMessage(this.dataFilter);
    }
    else {
      if (!this.advanceDataFilterObj) {
        this.advanceDataFilterObj = new advanceFilterModel;
      }
      else {
        /** modified by sandeep on July 20, 2023 */
        // this.advanceDataFilterObj.distance = distanceDropdown;
        this.advanceDataFilterObj.distance = this.advSearchDistance;
        this.sendAdvanceSearchMessage(this.advanceDataFilterObj);
      }
    }
  }

   /*
     * Send the message to dealerList component to filter data
    */
  sendMessage(filterData: filterModel): void {
    this._dataService.sendMessage(filterData);
  }

   /*
     * Send the message to dealerList component to advance filter data
    */
  sendAdvanceSearchMessage(filterData: advanceFilterModel): void {
    this._dataService.sendAdvanceSearchFilterMessage(filterData);
  }

  setAddress(addrObj) {
    this.zone.run(() => {
      this.addr = addrObj;
      this.addrKeys = Object.keys(addrObj);
    });
  }


   /*
     * Set multi language phrases
    */
  getPhrases() {
    this.subscriptionPhrases = this._dataService.getPhrases().subscribe(message => {
      this.languagePhrases = message.text;

      /// commented by sandeep on 05-11-2022
      // this._allRegionsList[0].name = this.languagePhrases.AllCountries;
      // this.selectedRegion = this.languagePhrases.AllCountries;
      this.selectedRegion = "All Countries";

      /** added by sandeep on dec 12, 2022 */
      this.selectedTrueRegion = "All Regions";

      // added by sandeep on dec 07, 2022
      if(this.myRadio != this.languagePhrases.KmorMiles && 
        (localStorage.getItem('results') == 'true') 
        && this.dataFilter) {
        this.myRadio = this.languagePhrases.KmorMiles;
        // console.log('datafilter - ', this.dataFilter);
        this.changeKmMiles(null);
      }
        
      
      
      // if (this.dataFilter) {
      //   this.dataFilter.kmMiles = this.myRadio;
      // }
      
    });
  }

   /*
     * Change km to miles and miles to km distance
    */
  changeKmMiles(event) {
    // console.log('changeKmMiles called');
    if (this.flagAdvanceSearch) {
      if (!this.dataFilter) {
        this.dataFilter = new filterModel();
      }
      /** changed by sandeep on July 4, 2023 */
      // this.dataFilter.kmMiles = this.myRadio;
      this.dataFilter.kmMiles = (event)? this.myRadio = event : this.myRadio;

      this.sendMessage(this.dataFilter);

    } else {
      if (!this.advanceDataFilterObj) {
        this.advanceDataFilterObj = new advanceFilterModel;
      }
      else {
        /** changed by sandeep on July 18, 2023 */
        // this.advanceDataFilterObj.kmMiles = this.myRadio;
        this.advanceDataFilterObj.kmMiles = (event)? this.myRadio = event : this.myRadio;
        this.sendAdvanceSearchMessage(this.advanceDataFilterObj);
      }
    }
  }

   /*
     * Hide/show advance search bar
    */
  hideAdvanceSearchBar() {
    //this.hideAdvanceSearch=!this.hideAdvanceSearch;
    //console.log(this.hideAdvanceSearch);
  }

  /** added by sandeep on May 10, 2023 */
  mapviewToggle(value: boolean, id: any) {
    if(value) {
      // this.hideCountry = true;
      // this.isAdvFilter = false;
      this.flagAdvanceSearch = false;
      // this.advSearchDistance = 1;
      this._dataService.hideDistanceServiceType = true;
    }

    this._dataService.setMapviewToggle(value, id);
  }

  /** added by sandeep on May 17, 2023 */
  getAllTranslations() {
    this._dataService.getAllTranslations().subscribe(translations => {
      this.allTranslations = translations;
      
      /** added by sandeep on May 18, 2023 */
      this.serviceType = this.getAllServices();

      /** added by sandeep on Jul 7, 2023 */
      // console.log('states - ', this.filteredStates)
      // console.log(this.selectedRegion)
      if(this.filteredStates.length > 0) {
        this.onDropdownChange(this.selectedRegion);
      }

      /** added by sandeep on Jun 23, 2023 */
      // this.onDropdownChange(this.languagePhrases.AllRegions);
    }, error => {
      console.log(error);
    });
  }

  /** added by sandeep on May 17, 2023 */
  getAllServices(): serviceTypeModel[] {
    // console.log(this.allTranslations);
    var services = this.serviceTypeService.getAllServices();
    var translatedServices = services.map(service => {
      // console.log(service.name)
      var name  = this._dataService.getTranslatedName(this.allTranslations, service.name);
      // console.log(service.name)
      return {id: service.id, name: name} //service;
    });
    // console.log(translatedServices);
    return translatedServices;
  }

  /** added by sandeep on July 28, 2023 */
  getLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.setCoordinates(position.coords.latitude, position.coords.longitude);

      /** added by sandeep on sep 28, 2023 */
      // console.log(position.coords);
      // let currentUrlDealerID = this.route.snapshot.queryParamMap.get('id');
      // if (!currentUrlDealerID) {
      //   this.onSerchClick("", 1, 1, "", <any>position.coords.latitude, <any>position.coords.longitude);
      // }
      // this._dataService.hideDistanceServiceType = !this._dataService.hideDistanceServiceType;
    
      // alert('Location accessed'+ position.coords.latitude)
    },function(){
        // alert('User not allowed')
    })
    // ,{maximumAge: 0, timeout: 2000}
  }

  /** added by sandeep on July 28, 2023 */
  setCoordinates(lat: number, long: number) {
    this._geocodeService.setLocationCoordinates(lat, long);
    // console.log('called');
  }

}

