import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
//import { MyfrontpageComponent } from './myfrontpage/myfrontpage.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContentpageComponent } from './contentpage/contentpage.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DealerlistComponent } from './dealerlist/dealerlist.component';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { GooglePlacesDirective } from './dealerlist/google-places.directive';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '../app/services/translate.service';
//import { MsAdalAngular6Module, AuthenticationGuard } from 'microsoft-adal-angular6'; //Vaishnavi commented on 20 Feb 2023
import { HomeComponent } from './home/home.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DealersResolver } from './services/dealersResolver.service';
import { AppInsightService } from './services/AppInsight.service';
import { ServiceinfoComponent } from './serviceinfo/serviceinfo.component';
import { AlldealersMapviewComponent } from './alldealers-mapview/alldealers-mapview.component';

import { OAuthModule } from 'angular-oauth2-oidc';
import { TestComponent } from './test/test.component';


 
@NgModule({
  declarations: [
    AppComponent,
    //MyfrontpageComponent,
    HeaderComponent,
    FooterComponent,
    ContentpageComponent,    
    DealerlistComponent,
    GooglePlacesDirective,
    PageNotFoundComponent,    
    HomeComponent, ServiceinfoComponent, AlldealersMapviewComponent, TestComponent
  ],
  imports: [AppRoutingModule, 
    FormsModule, 
    ModalModule.forRoot(),
    BrowserAnimationsModule, 
    BrowserModule, 
    ReactiveFormsModule, 
    AgmCoreModule.forRoot({
     
    }), AgmDirectionModule, NgMultiSelectDropDownModule.forRoot(), HttpClientModule, Ng4LoadingSpinnerModule.forRoot(),
    HttpClientModule,//DataTableModule,
    /* //Vaishnavi Commented the Micorsoft authentication on 20 Feb 2023
    MsAdalAngular6Module.forRoot({
       tenant: 'cb5d3e9b-b11e-47c2-af23-b095fdf3da17',            
       clientId: 'd3b9f139-f417-4524-afc3-7cc8734b96cd',     
      redirectUri:        
        "http://localhost:4200/admin",       
        // "https://locator.ttdealers.carrier.com/admin",   // redirect to admin url after login successfull
        
      endpoints: {
        "https://localhost/Api/":
          "xxx-bae6-4760-b434-xxx" 
      },
      navigateToLoginRequestUrl:false, cacheLocation:'localStorage',
    }),*/
    OAuthModule.forRoot() //Vaishnavi Added on 20 Feb 2023
   
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ModalModule, CookieService, TranslateService, DealersResolver ,AppInsightService/*,AuthGuard,AuthService*/
    // Vaishnavi removed the "AuthenticationGuard" from the providers array on 20 Feb 2023
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
