import { Component, OnInit } from '@angular/core';
import { DealerService } from '../services/dealer.service';
import {dealerModel} from '../models/dealerModel';
import { ActivatedRoute } from '@angular/router';
import { DataServiceService } from '../services/data-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DealerService]
})
export class HomeComponent implements OnInit {
  AllDealers:any;
  MapviewToggle: boolean;
  constructor(private _dealerService: DealerService, 
    private route: ActivatedRoute,
    private _dataService: DataServiceService
    ) {   
  }

  ngOnInit() {
    /*
      * get all the dealers on application load
   */
    this.AllDealers = this.route.snapshot.data.dealers.dealers;

    /** added by sandeep on May 10, 2023 */
    /**
     * getting the value of allDealerMapviewToggle
     */
    this._dataService.getMapviewToggle().subscribe(obj => {
      this.MapviewToggle = obj.toggleValue;
    }, error => {
      console.log(error);
    });

  }
}
