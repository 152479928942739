export class languagePhrasesModel {
  SeeCompleteList: any;
  CompleteDealerList: any;
  Accept: any;
  DownloadMobileApplication: any;
  DownloadMobileApp: any;
  Products: any;
  GetDirection: any;
  PrivacyNotice: any;
  AdvanceSearch: any;
  PostalCode: any;
  DistanceInMiles: any;
  AllDealerServices: any;
  ServiceandSupport: any;
  CustomerConnection: any;
  Sustainability: any;
  AboutUs: any;
  TermsOfUse: any;
  CarrierCorporation2018: any;
  CarrierTransicold: any;
  EnterCityNamePostalCode: any;
  Search: any;
  CookiePolicy: any;
  CookiePolicyInformation: any;
  GetTheCarrierMobileApp: any;
  GoBackToSearch: any;
  AdditionalInformation: any;
  Address: any;
  PhoneNumber: any;
  MobileNumber: any;
  Hours: any;
  Closed: any;
  EnterEmailAddresses: any;
  EnterCustomMessage: any;
  NoDataFound: any;
  CarrierIsPart: any;
  Security: any;
  aUnitOf: any;
  Website: any;
  AllStates: any; AllCities: any;AllCountries:any;
  MappingServicesTerms: any;
  HideFilters: any; ShowMoreFilters: any;
  OpenToday: any; MappingAt: any;
  GooglePrivacy: any; InTheEventConflict: any; privacyUrl: any; ProductLink: any;
  ServiceSupportLink: any;
  CustomerConnectionLink: any;
  SustainabilityLink: any;
  AboutUsLink: any;
  TermsOfUseLink: any;
  eMail: any;
  ShareStoreDetails: any;
  ShareStoreDetailsOthers: any;
  SendEmail:any;
   Monday :any;
   Tuesday:any;
   Wednesday:any;
  Thursday:any;
  Friday:any;
  Saturday:any;
  Sunday:any;
  A:any;
  Company:any;
  CarrierCorporation2019:any;
  ServiceInfo: any;

  // added by sandeep on nov 22, 2022
  KmorMiles: any;

  /** added by sandeep on dec 12, 2022 */
  AllRegions: any;

  AllDealersMapView: any;
  GetLocation: any
}