// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   webBaseUrl:'https://dealerlocatorapp-stg.azurewebsites.net/',
   apiBaseUrl:'https://dealerlocatorapp-stg.azurewebsites.net/',
  //webBaseUrl: 'http://localhost:22598/',
  //apiBaseUrl: 'http://localhost:22598/',
   //webBaseUrl: 'https://app-dealerlocatordr.azurewebsites.net/',
   //apiBaseUrl: 'https://app-dealerlocatordr.azurewebsites.net/',
  apiKey:'AIzaSyAlkNX16Z-bwTS-HYYqwIq-xQXg-bt0McQ',   
  appInsights: {
    instrumentationKey: '2ff86082-d92a-4b91-b4ea-b84b02f972dc'    
    }
};
 
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
